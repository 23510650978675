import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
  Select as Selectable,
  makeStyles,
} from '@material-ui/core'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { registerCategory } from 'redux/actions/categoryActions'
import { CATEGORY_REGISTER_RESET } from 'redux/constants/categoryConstants'
import styles from './styles/registerCategoryScreenStyles'

const useStyles = makeStyles(styles)

const AltaCategoryScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [codCategoryError, setCodCategoryError] = useState('')
  const [importeError, setImporteError] = useState('')
  const [codCategory, setCodCategory] = useState('')
  const [tipoActuacion, setTipoActuacion] = useState('')
  const [importe, setImporte] = useState('')
  const [alert, setAlert] = useState(null)

  const { loadingCategoryRegister, successCategoryRegister, errorCategoryRegister } = useSelector(
    (state) => state.categoryRegister
  )

  useEffect(() => {
    if (successCategoryRegister) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='GUARDADO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Categoría guardada correctamente
        </SweetAlert>
      )
    }
  }, [successCategoryRegister])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!codCategory) {
      return setCodCategoryError('Por favor ingrese un Código de Categoria')
    }
    if (!importe) {
      return setImporteError('Por favor ingrese un importe')
    }
    dispatch(
      registerCategory({
        codCategory,
        tipoActuacion,
        importe,
      })
    )
  }
  const confirmSuccess = () => {
    setAlert(null)
    setCodCategory('')
    setTipoActuacion('')
    setImporte('')
    dispatch({ type: CATEGORY_REGISTER_RESET })
  }

  return (
    <>
      <Card>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CÓDIGO DE CATEGORÍA DE PLIEGO'}
                      id='codecategory'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: codCategory,
                        onChange: (e) => setCodCategory(e.target.value),
                        type: 'text',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'TIPO DE ACTUACIÓN'}
                      id='tipo_actuacion'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: tipoActuacion,
                        onChange: (e) => setTipoActuacion(e.target.value),
                        type: 'text',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'IMPORTE'}
                      id='importe'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: importe,
                        onChange: (e) => setImporte(e.target.value),
                        type: 'number',
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='submit' color='primary'>
                  {loadingCategoryRegister ? 'Guardando...' : 'Registrar Categoria'}
                </Button>
              </GridItem>
              {errorCategoryRegister && (
                <GridItem xs={12}>
                  <SnackbarContent message={errorCategoryRegister} color='danger' />
                </GridItem>
              )}
              {codCategoryError && !codCategory && (
                <GridItem xs={12}>
                  <SnackbarContent message={codCategoryError} color='danger' />
                </GridItem>
              )}
              {importeError && !importe && (
                <GridItem xs={12}>
                  <SnackbarContent message={importeError} color='danger' />
                </GridItem>
              )}
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      {alert}
    </>
  )
}

export default AltaCategoryScreen
