import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputAdornment, Icon, makeStyles } from '@material-ui/core'
import { AlternateEmail } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import { logout, login } from 'redux/actions/userActions'
import styles from './styles/loginScreenStyles'
import axios from 'axios';
import CustomInput from 'components/CustomInput/CustomInput'

const useStyles = makeStyles(styles)

const LoginScreen = ({ location, history }) => {
   const dispatch = useDispatch()
   const classes = useStyles()

   const [userName, setuserName] = useState('')
   const [password, setPassword] = useState('')
   const [cardAnimaton, setCardAnimation] = useState('cardHidden')

   const { loadingUserInfo, errorUserInfo, userInfo } = useSelector((state) => state.userLogin)
   const redirect = location.search ? location.search.split('=')[1] : '/admin/user-page'

   useEffect(() => {
      if (userInfo) {
         //Chequeamos si el token es valido, sino se tiene que autenticar.
         const tokenToVerify = localStorage.getItem('setADA') ? JSON.parse(localStorage.getItem('setADA')) : null
         axios({
         method: 'POST',
         url: '/api/users/verifyToken',
         headers:  {
                     Authorization: `Bearer ${tokenToVerify}`,
                     'Cache-Control': 'no-cache',
                     },
         withCredentials: true
         }).then(response => {
         history.push(redirect)
         })
         .catch(error => {
         //Borramos la informacion ya que esta expirada Haciendo logout.
         dispatch(logout())
         
         return function cleanup() {
            window.clearTimeout(id)
         }
         })
      }
      else {
         if(!loadingUserInfo) {
         axios({
            method: 'GET',
            url: '/whoami',
            withCredentials: true
         })
         .then(response => {
            if (response.data.codAyre)
            {
               dispatch(login(response.data))
            }
            else
            {
               return function cleanup() {
               window.clearTimeout(id)
               }
            }
         })
         .catch(error => {
            return function cleanup() {
               window.clearTimeout(id)
            }
         })
         }
      }
   }, [history, userInfo, redirect])

   useEffect(() => {
      let id = setTimeout(function () {
         setCardAnimation('')
      }, 200)

      return function cleanup() {
         window.clearTimeout(id)
      }
   })

   const loginHandler = (e) => {
      e.preventDefault()

      dispatch(login({ userName: userName, password: password}))
   }

   return (
      <div className={classes.container}>
         <GridContainer justifyContent='center'>
         <GridItem xs={12} sm={10} md={4}>
            <form onSubmit={loginHandler} autoComplete='off'>
               <Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
               <CardHeader className={classes.cardHeader} color='primary'>
                  <h4 className={classes.cardTitle}>Accede con tu cuenta</h4>
               </CardHeader>
               <CardBody>
                  <GridContainer>
                     <GridItem xs={12}>
                     <CustomInput
                        formControlProps={{
                           fullWidth: true,
                           className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                           type: 'username',
                           value: userName,
                           onChange: (e) => setuserName(e.target.value),
                           startAdornment: (
                           <InputAdornment position='start' className={classes.inputAdornment}>
                              <AlternateEmail className={classes.inputAdornmentIcon} />
                           </InputAdornment>
                           ),
                           placeholder: 'Usuario...',
                        }}
                     />
                     <CustomInput
                        formControlProps={{
                           fullWidth: true,
                           className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                           type: 'password',
                           value: password,
                           onChange: (e) => setPassword(e.target.value),
                           startAdornment: (
                           <InputAdornment position='start' className={classes.inputAdornment}>
                              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                           </InputAdornment>
                           ),
                           placeholder: 'Password...',
                        }}
                     />

                     <Button color='primary' size='lg' block type='submit'>
                        {loadingUserInfo ? 'Iniciando...' : 'Conectar'}
                     </Button>
                     </GridItem>
                     {errorUserInfo && (
                     <GridItem xs={12}>
                        <div className={classes.messageError}>{errorUserInfo}</div>
                     </GridItem>
                     )}
                  </GridContainer>
               </CardBody>
               </Card>
            </form>
         </GridItem>
         </GridContainer>
      </div>
   )
}

export default LoginScreen
