import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CloneActionModal from './CloneActionModal'
import { registerPlanning } from 'redux/actions/planningActions'
import { PLANNING_REGISTER_RESET } from 'redux/constants/planningConstants'

const ClonePlanningModal = ({ open, closeCloneInfoModal, planningInfo }) => {
   const dispatch = useDispatch()
   const { loadingPlanningRegister, successPlanningRegister, errorPlanningRegister, planningRegistered } = useSelector(
      (state) => state.planningRegister
   )

   const [alert, setAlert] = useState(null)

   useEffect(() => {
      let timeOut = ''
      if ( successPlanningRegister ) {
         timeOut = setTimeout(() => {
            dispatch({ type: PLANNING_REGISTER_RESET })
            closeCloneInfoModal()
         }, 1000)
      }

      return () => clearTimeout(timeOut)
   }, [successPlanningRegister])


   useEffect(() => {

      return () => {
         dispatch({ type: PLANNING_REGISTER_RESET })
      }
   }, [])

   const clonePlanningHandler = (e, newName) => {
      e.preventDefault()

      dispatch(registerPlanning({ plannning: null, cloneId: planningInfo.id, newName }))
   }

   return (
      <>
         <CloneActionModal
            open={open}
            handleCloseModal={closeCloneInfoModal}
            handleSubmit={clonePlanningHandler}
            modalTitle='Duplicar Planificación'
            errorClone={errorPlanningRegister}
            loadingClone={loadingPlanningRegister}
            customWidth={"lg"}
            showCloneInfo={planningInfo.name}
         />
         {alert}
      </>
   )
}

export default ClonePlanningModal
