import axios from 'axios'
import {
  TECHNICIANSTRACKING_LIST_REQUEST,
  TECHNICIANSTRACKING_LIST_SUCCESS,
  TECHNICIANSTRACKING_LIST_FAIL,
} from '../constants/trackingConstants'
import roles from 'config/roles/roles'

export const getTechniciansTracking = ( inputData ) => async (dispatch, getState) => {
   try {
      dispatch({ type: TECHNICIANSTRACKING_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const adminPermission = ( userInfo?.permiso.includes(roles.ADMIN_ROLE) 
         || userInfo?.permiso.includes(SUPER_ROLE)
         || userInfo?.permiso.includes(roles.TECNICO_ROLE) )
            ? true 
            : false

      if ( !adminPermission ) {
         dispatch({
            type: TECHNICIANSTRACKING_LIST_FAIL,
            payload: "Su perfil no le permite acceder a estos datos",
         })
      }


      const { data } = await axios.post(`/api/seguimientos/tecnicos/`, inputData ,config)

      dispatch({ type: TECHNICIANSTRACKING_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: TECHNICIANSTRACKING_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

