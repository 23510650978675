import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import DeleteActionModal from 'components/ReactTableActions/Delete/DeleteActionModal'
import { deleteCategory } from 'redux/actions/categoryActions'
import { CATEGORY_LIST_RESET, CATEGORY_DELETE_RESET, CATEGORY_UPDATE_RESET } from 'redux/constants/categoryConstants'

const DeleteCategoryModal = ({ handleCloseDeleteCategoryModal, deleteCategoryModal, showDeleteCategoryInfo }) => {
  const dispatch = useDispatch()
  const active = showDeleteCategoryInfo.activo !== 'SI'

  const { successCategoryDelete, errorCategoryDelete, loadingCategoryDelete } = useSelector((state) => state.categoryDelete)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    let timeOut = ''
    if (successCategoryDelete) {
      timeOut = setTimeout(() => {
        dispatch({ type: CATEGORY_LIST_RESET })
        dispatch({ type: CATEGORY_DELETE_RESET })
        handleCloseDeleteCategoryModal()
      }, 1000)
    }
    return () => clearTimeout(timeOut)
  }, [successCategoryDelete])

  useEffect(() => {
    return () => dispatch({ type: CATEGORY_DELETE_RESET })
  }, [dispatch])
  useEffect(() => {
    return () => dispatch({ type: CATEGORY_UPDATE_RESET })
  }, [dispatch])

  const handleDeleteCategory = (e) => {
    e.preventDefault()
    dispatch(deleteCategory(showDeleteCategoryInfo?.id_tipo_categoria))
  }

  return (
    <>
        <DeleteActionModal
          open={deleteCategoryModal}
          handleCloseModal={handleCloseDeleteCategoryModal}
          handleSubmit={handleDeleteCategory}
          modalTitle='Eliminar Categoría'
          showDeleteInfo={showDeleteCategoryInfo?.categoria_pliego}
          loadingDelete={loadingCategoryDelete}
          successDelete={successCategoryDelete}
          errorDelete={errorCategoryDelete}
        />
      {alert}
    </>
  )
}

export default DeleteCategoryModal
