export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REFRESH_TOKEN_REQUEST = 'USER_REFRESH_TOKEN_REQUEST'
export const USER_REFRESH_TOKEN_SUCCESS = 'USER_REFRESH_TOKEN_SUCCESS'
export const USER_REFRESH_TOKEN_FAIL = 'USER_REFRESH_TOKEN_FAIL'
export const USER_REFRESH_TOKEN_RESET = 'USER_REFRESH_TOKEN_RESET'

export const USER_PROFILE_INFO_REQUEST = 'USER_PROFILE_INFO_REQUEST'
export const USER_PROFILE_INFO_SUCCESS = 'USER_PROFILE_INFO_SUCCESS'
export const USER_PROFILE_INFO_FAIL = 'USER_PROFILE_INFO_FAIL'
export const USER_PROFILE_INFO_RESET = 'USER_PROFILE_INFO_RESET'

export const USER_LIST_BY_PROFILE_ID_REQUEST = 'USER_LIST_BY_PROFILE_ID_REQUEST'
export const USER_LIST_BY_PROFILE_ID_SUCCESS = 'USER_LIST_BY_PROFILE_ID_SUCCESS'
export const USER_LIST_BY_PROFILE_ID_FAIL = 'USER_LIST_BY_PROFILE_ID_FAIL'
export const USER_LIST_BY_PROFILE_ID_RESET = 'USER_LIST_BY_PROFILE_ID_RESET'

export const USER_LIST_BY_ROLE_NAME_REQUEST = 'USER_LIST_BY_ROLE_NAME_REQUEST'
export const USER_LIST_BY_ROLE_NAME_SUCCESS = 'USER_LIST_BY_ROLE_NAME_SUCCESS'
export const USER_LIST_BY_ROLE_NAME_FAIL = 'USER_LIST_BY_ROLE_NAME_FAIL'
export const USER_LIST_BY_ROLE_NAME_RESET = 'USER_LIST_BY_ROLE_NAME_RESET'

export const TECHNICIAN_LIST_REQUEST = 'TECHNICIAN_LIST_REQUEST'
export const TECHNICIAN_LIST_SUCCESS = 'TECHNICIAN_LIST_SUCCESS'
export const TECHNICIAN_LIST_FAIL = 'TECHNICIAN_LIST_FAIL'
export const TECHNICIAN_LIST_RESET = 'TECHNICIAN_LIST_RESET'