import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import ActiveAndDesactiveActionModal from 'components/ReactTableActions/ActiveAndDesactive/ActiveAndDesactiveActionModal'
import { actUpdateInfo } from 'redux/actions/actActions'
import { ACT_LIST_RESET, ACT_UPDATE_RESET } from 'redux/constants/actConstants'

const ActiveAndDesactiveActModal = ({ activeModal, closeActiveModal, info, active }) => {
  const dispatch = useDispatch()

  const { loadingActUpdate, errorActUpdate, successActUpdate } = useSelector((state) => state.actUpdate)

  useEffect(() => {
    if (successActUpdate) {
      dispatch({ type: ACT_LIST_RESET })
      setTimeout(() => {
        dispatch({ type: ACT_UPDATE_RESET })
        closeActiveModal()
      }, 1000)
    }
  }, [successActUpdate])
  const activeOrDesactive = (e) => {
    e.preventDefault()
    if (active) {
      dispatch(actUpdateInfo({ ...info, activo: 'SI' }))
    } else {
      dispatch(actUpdateInfo({ ...info, activo: 'NO', fecha_baja: format(new Date(), 'yyyy-MM-dd') }))
    }
  }
  return (
    <ActiveAndDesactiveActionModal
      open={activeModal}
      handleCloseModal={closeActiveModal}
      handleSubmit={activeOrDesactive}
      message={
        <>
          Va a {active ? 'activar' : 'desactivar'} el acto <strong>{info.codigo_acto}</strong>
        </>
      }
      modalTitle={`${active ? 'Activar' : 'Desactivar'} Acto`}
      error={errorActUpdate}
      success={successActUpdate}
      loading={loadingActUpdate}
      loadingMessageButton={active ? 'Activando...' : 'Desactivando'}
      succesMessageButton={active ? 'Activar' : 'Desactivar'}
    />
  )
}

export default ActiveAndDesactiveActModal
