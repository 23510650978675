import {
  CATEGORY_REGISTER_REQUEST,
  CATEGORY_REGISTER_SUCCESS,
  CATEGORY_REGISTER_FAIL,
  CATEGORY_REGISTER_RESET,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_RESET,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_RESET,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_RESET,
} from '../constants/categoryConstants.js'

export const categoryRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_REGISTER_REQUEST:
      return { loadingCategoryRegister: true }
    case CATEGORY_REGISTER_SUCCESS:
      return {
        loadingCategoryRegister: false,
        successCategoryRegister: true,
        category: action.payload,
      }
    case CATEGORY_REGISTER_FAIL:
      return {
        loadingCategoryRegister: false,
        errorCategoryRegister: action.payload,
      }
    case CATEGORY_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loadingCategoryList: true }
    case CATEGORY_LIST_SUCCESS:
      return {
        loadingCategoryList: false,
        successCategoryList: true,
        categories: action.payload,
      }
    case CATEGORY_LIST_FAIL:
      return { loadingCategoryList: false, errorCategoryList: action.payload }
    case CATEGORY_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const categoryUpdateReducer = (state = { categoryUpdated: {} }, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loadingCategoryUpdate: true }
    case CATEGORY_UPDATE_SUCCESS:
      return {
        loadingCategoryUpdate: false,
        successCategoryUpdate: true,
        categoryUpdated: action.payload,
      }
    case CATEGORY_UPDATE_FAIL:
      return { loadingCategoryUpdate: false, errorCategoryUpdate: action.payload }
    case CATEGORY_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loadingCategoryDelete: true }
    case CATEGORY_DELETE_SUCCESS:
      return { loadingCategoryDelete: false, successCategoryDelete: true }
    case CATEGORY_DELETE_FAIL:
      return { loadingCategoryDelete: false, errorCategoryDelete: action.payload }
    case CATEGORY_DELETE_RESET:
      return {}
    default:
      return state
  }
}
