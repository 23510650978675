
/**
 * Podrá administrar los catálogos.
 */
 const ADMIN_ROLE = 'administrador'

/**
 * Podrá ver sus datos (mi perfil) y sus actividades.
 */
 const VALIDADOR_ROLE = 'validador'

 /**
 * Podrá ver sus datos (mi perfil), los cuadros de mando y los informes de seguimiento.
 */
const RESPONSABLE_ROLE = 'responsable'

/**
 * Podrá ver sus datos (mi perfil), y la gestión del trabajo de los validadores.
 */
const DESPACHADOR_ROLE = 'despachador'

/**
 * Podrá ver sus datos (mi perfil), y su propia gestión de trabajo.
 */
 const TECNICO_ROLE = 'tecnico'

/**
 * Podrá ver sus datos (mi perfil), y su propia gestión de trabajo.
 */
 const ADMINISTRATIVO_ROLE = 'administrativo'

const ALL_USER_ROLES = [
  ADMIN_ROLE,
  VALIDADOR_ROLE,
  RESPONSABLE_ROLE,
  DESPACHADOR_ROLE,
  TECNICO_ROLE,
  ADMINISTRATIVO_ROLE,
]

export default {
  ALL_USER_ROLES,
  ADMIN_ROLE,
  VALIDADOR_ROLE,
  RESPONSABLE_ROLE,
  DESPACHADOR_ROLE,
  TECNICO_ROLE,
  ADMINISTRATIVO_ROLE,
}
