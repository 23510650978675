import { useState } from "react"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import { es } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { makeStyles, FormControl, InputLabel, MenuItem, Select as Selectable, TextField } from '@material-ui/core'

import styles from "./styles/updateWorkStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CustomInput from "components/CustomInput/CustomInput"

const useStyles = makeStyles(styles)

export const TabPanelInfo = ({ info, technicians }) => {

   const { acts_data } = info

   const [value, setValue] = useState(0)

   const handleChange = (e, newValue) => {
      setValue( newValue )
   }

   return (
      <Box sx={{ width: "100%" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs 
               value={value} 
               onChange={handleChange} 
               variant="scrollable"
               aria-label="basic tabs example"
            >
               {
                  acts_data.map(act => {
                     const act_name = act[0].act_name || "Control"

                     return (
                        <Tab
                           key={act_name}
                           label={act_name}
                           {...allyProps(0)}
                        />
                     )
                  })
               }
            </Tabs>
         </Box>
         {         
            acts_data.map((act, index) => {
               const act_data = Object.entries(act)[0]

               return (
                  <TabPanel value={value} index={index}>
                     {tabPanelContent( act, index, technicians )}
                  </TabPanel>
               )
            })
         }

      </Box>
   )
}

const getFormColumn = ( act, index, technicians ) => {

   const classes = useStyles()

   return act.map((column, i) => {
      const column2={...column}
      let cData = column.data
      if ( cData != null && cData && column.data_type === "date" ) {

         if (!isDate(cData)) {
            const [day, month, year] = cData.split("-")
            cData = format(new Date(+year, +month -1, +day), "dd-MM-yyyy")
         }

      }
      if ( cData != null && cData && column.data_type === "decimal" ) {

         cData = isNaN(cData) ? cData : cData.toFixed(2)
      }

      let notRegistered = false
      let cType = column.data_type
      if ( cType.includes("string") ) cType = "text"
      else if ( cType === "decimal" ) cType = "text"
      else if ( cType === "int" ) cType = "number"
      else if ( cType === "user"  && cData !== "" && !technicians.map(tech => tech.usuario).includes(cData) ) {
         notRegistered = true
         cData = "Técnico no registrado"
      }
      else if ( cType === "date"){

         return (
            <GridItem xs={12} key={`tab_${column.name_db}_${index}_`}>
               <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label={column.name_excel}
                     inputFormat="dd-MM-yyyy"
                     value={cData || " "}
                     disabled={true}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%", border: "2px solid blue !important"}}
                           required={column.error}
                           {...params}
                           error={column.error}
                        />
                     }}
                     
                     required={false}
                  />
               </LocalizationProvider>
            </GridItem>
         )
      }else {
         cType = "text"
      }

      return (
         <GridItem xs={12} key={`tab_${column.name_db}_${index}_`}>
            <CustomInput
               labelText={column.name_excel}
               formControlProps={{
                  fullWidth: true,
               }}
               inputProps={{
                  value: cData || " ",
                  type: cType,
                  required: false,
                  disabled: true,
                  error: notRegistered
               }}
            />
         </GridItem>
      )
   })
}

const isDate = (date) => {
   return (new Date(date) !== "Invalid Date") || !isNaN(new Date(date))
}

const tabPanelContent = ( act_data, index, technicians ) => {

   const act_data_length = act_data.length
   const act_data_half_length = Math.ceil( act_data.length / 2 )
   const act_data_first_column = act_data.slice( 0, act_data_half_length )
   const act_data_second_column = act_data.slice( act_data_half_length, act_data_length )

   return (
      <GridContainer spacing={1}>
         <GridItem xs={6}>
            <GridContainer>
               {
                  getFormColumn(act_data_first_column, index, technicians)
               }
            </GridContainer>
         </GridItem>

         <GridItem xs={6}>
            <GridContainer>
               {
                  getFormColumn(act_data_second_column, index, technicians)
               }
            </GridContainer>
         </GridItem>
      </GridContainer>
   )
}

const TabPanel = ({ children, value, index, ...other }) => {

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${ index }`}
         aria-labelledby={`simple-tab-${ index }`}
         {...other}
      >
         {
            value === index
            && <Box sx={{ p:3 }}>
               <Typography>{ children }</Typography>
            </Box>
         }
      </div>
   )
}

const allyProps = ( index ) => {

   return {
      id: `simple-tab-${ index }`,
      "aria-controls": `simple-tabpanel-${ index }`,
   }
}


