import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, makeStyles, MenuItem, Select as Selectable } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import { getProfiles } from 'redux/actions/profileActions'
import styles from '../styles/profileToManagerScreenStyles'

const useStyles = makeStyles(styles)

const ProfileManagerSelect = ({ disabled, setProfileId, profileId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    loadingProfileList,
    profiles,
  } = useSelector((state) => state.profileList)

  useEffect(() => {
      dispatch(getProfiles())
  }, [])  
  
  const capitalizeFirstLetter = (str) => str[0].toUpperCase() + str.slice(1)

  return (
      !loadingProfileList && (
        <GridItem xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel htmlFor='permission-list'>Permisos *</InputLabel>
          <Selectable
            inputProps={{
              name: 'permission-list',
              id: 'permission-list',
            }}
            onChange={(e) => setProfileId(e.target.value)}
            value={profileId}
            disabled={disabled}
          >
            <MenuItem disabled>Selecciona uno</MenuItem>
            {profiles && profiles.map((permission, index) => (
              <MenuItem value={permission.id_perfil} key={index}>
                {capitalizeFirstLetter(permission.codigo_perfil)}
              </MenuItem>
            ))}
          </Selectable>
        </FormControl>
      </GridItem>
      )
  )
}

export default ProfileManagerSelect
