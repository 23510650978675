import {
  ACT_REGISTER_REQUEST,
  ACT_REGISTER_SUCCESS,
  ACT_REGISTER_FAIL,
  ACT_REGISTER_RESET,
  ACT_LIST_REQUEST,
  ACT_LIST_SUCCESS,
  ACT_LIST_FAIL,
  ACT_LIST_RESET,
  ACT_UPDATE_SUCCESS,
  ACT_UPDATE_REQUEST,
  ACT_UPDATE_FAIL,
  ACT_UPDATE_RESET,
  ACT_DELETE_FAIL,
  ACT_DELETE_REQUEST,
  ACT_DELETE_SUCCESS,
  ACT_DELETE_RESET,
} from '../constants/actConstants'

export const actRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case ACT_REGISTER_REQUEST:
      return { loadingActRegister: true }
    case ACT_REGISTER_SUCCESS:
      return {
        loadingActRegister: false,
        successActRegister: true,
        act: action.payload,
      }
    case ACT_REGISTER_FAIL:
      return {
        loadingActRegister: false,
        errorActRegister: action.payload,
      }
    case ACT_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const actListReducer = (state = { acts: [] }, action) => {
  switch (action.type) {
    case ACT_LIST_REQUEST:
      return { loadingActList: true }
    case ACT_LIST_SUCCESS:
      return {
        loadingActList: false,
        successActList: true,
        acts: action.payload,
      }
    case ACT_LIST_FAIL:
      return { loadingActList: false, errorActList: action.payload }
    case ACT_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const actUpdateReducer = (state = { actUpdated: {} }, action) => {
  switch (action.type) {
    case ACT_UPDATE_REQUEST:
      return { loadingActUpdate: true }
    case ACT_UPDATE_SUCCESS:
      return {
        loadingActUpdate: false,
        successActUpdate: true,
        actUpdated: action.payload,
      }
    case ACT_UPDATE_FAIL:
      return { loadingActUpdate: false, errorActUpdate: action.payload }
    case ACT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const actDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ACT_DELETE_REQUEST:
      return { loadingActDelete: true }
    case ACT_DELETE_SUCCESS:
      return { loadingActDelete: false, successActDelete: true }
    case ACT_DELETE_FAIL:
      return { loadingActDelete: false, errorActDelete: action.payload }
    case ACT_DELETE_RESET:
      return {}
    default:
      return state
  }
}
