import axios from 'axios'
import {
  LINES_REGISTER_REQUEST,
  LINES_REGISTER_SUCCESS,
  LINES_REGISTER_FAIL,
} from '../constants/importLinesConstants'

export const registerLines = (lines) => async (dispatch, getState) => {
   try {
      dispatch({ type: LINES_REGISTER_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()
      

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      const { data } = await axios.post('/api/utilidades/importalineas', lines, config)
      
      dispatch({ type: LINES_REGISTER_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: LINES_REGISTER_FAIL,
         payload: error.response && error.response.data ? error.response.data : error.message,
      })
   }
}