
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CardBody from 'components/Card/CardBody'
import { USERLIST_CHANGEPASSWORD_RESET } from 'redux/constants/userlistConstants'
import { userlistChangePassword } from 'redux/actions/userlistActions'
import styles from './styles/changePasswordStyles'

const useStyles = makeStyles(styles)

const ChangePasswordModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [alert, setAlert] = useState(null)
  const [claveant, setClaveant] = useState('')
  const [clave1, setClave1] = useState('')
  const [clave2, setClave2] = useState('')
  const [codClaveError, setCodClaveError] = useState('')
  const { userInfo } = useSelector((state) => state.userLogin)

  const style={
    boxShadow:"2px 2px 3px 3px #ccc",
    border:"2px #eee",
    padding:"20px",
    marginTop:"25px"
  }

  const { loadingUserlistChangePassword, errorUserlistChangePassword, successUserlistChangePassword } = useSelector((state) => state.userlistChangePassword)

  useEffect(() => {
    if (successUserlistChangePassword) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='CONTRASEÑA CAMBIADA!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Contraseña cambiada correctamente
        </SweetAlert>
      )
    }
  }, [successUserlistChangePassword])

  const changePasswordHandler = (e) => {
     e.preventDefault()
     if (clave1.length < 8) {
        return setCodClaveError('La contraseña debe tener al menos 8 caracteres')
     }
     if (!clave1.match(/[A-Z]/)) {
        return setCodClaveError('La contraseña debe tener al menos una mayúscula')
     }
     if (!clave1.match(/[a-z]/)) {
        return setCodClaveError('La contraseña debe tener al menos una minúscula')
     }
     if (!clave1.match(/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/)) {
        return setCodClaveError('La contraseña debe tener al menos un número o un caracter especial')
     }
     if (!(clave1 === clave2 && clave1!=="")) {
        return setCodClaveError('La contraseña debe coincidir con la confirmación de contraseña')
     }
       
     dispatch(
         userlistChangePassword({
            id_usuario: userInfo.id_usuario,
            password_anterior: claveant,
            password_nueva: clave1,
         })
     )
    
  }

  const confirmSuccess = () => {
    setClaveant('')
    setClave1('')
    setClave2('')
    setCodClaveError('')
    setAlert(null)
    dispatch({ type: USERLIST_CHANGEPASSWORD_RESET })
  }
  
  return (
    <>    
      <GridContainer>
        <GridItem xs={12} md={8} style={{ margin: 'auto' }}>
          <Card>
            <CardBody>
              <form onSubmit={changePasswordHandler}>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONTRASEÑA ANTERIOR'}
                      id='claveant'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: claveant,
                        onChange: (e) => setClaveant(e.target.value),
                        type: 'text',
                        maxLength: 20,
                        required: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONTRASEÑA'}
                      id='clave1'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: clave1,
                        onChange: (e) => setClave1(e.target.value),
                        type: 'text',
                        maxLength: 20,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONFIRMAR CONTRASEÑA'}
                      id='clave2'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: clave2,
                        onChange: (e) => setClave2(e.target.value),
                        type: 'text',
                        maxLength: 20,
                      }}
                    />
                  </GridItem>
                  <div className="container"> 
                    <div className="row">
                        <div className="col-md-4"></div>      
                        <div className="col-md-4">
                          <div style={style}>
                              <p style={{fontWeight:"bold"}}>Todos los checks deben ponerse verdes. La contraseña debe cumplir:</p>
                              {clave1.length >= "8" ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos 8 caracteres</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos 8 caracteres</p> 
                              )}
                              {clave1.match(/[A-Z]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra mayúscula</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra mayúscula</p> 
                              )}
                              {clave1.match(/[a-z]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra minúscula</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra minúscula</p> 
                              )}
                              {clave1.match(/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos un número o un caracter especial</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos un número o un caracter especial</p> 
                              )}
                              {clave1 === clave2 && clave1!=="" ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Contraseña === Confirmar contraseña</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Contraseña === Confirmar contraseña</p> 
                              )}
                          </div>
                        </div>     
                        <div className="col-md-4"></div>
                    </div>
                  </div>
                  {clave2 === "" ? "" :
                     (clave1 === clave2  ? <p style={{color:"green",fontWeight:"bold"}}> Contraseñas iguales </p> :
                      <p style={{color:"red",fontWeight:"bold"}}> Contraseñas diferentes </p>
                  )}

                  <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='submit' color='primary'>
                      {loadingUserlistChangePassword ? 'Cambiando contraseña...' : 'Cambiar contraseña'}
                    </Button>
                    <NavLink to={'/admin/user-page'} >
                      <Button color='primary' onClick={() => confirmSuccess()}>
                        Volver
                      </Button>
                    </NavLink>
                  </GridItem>                  
                </GridContainer>
                
              </form>              
              {errorUserlistChangePassword && (
                <GridContainer>
                  <GridItem xs={12}>
                    <SnackbarContent message={errorUserlistChangePassword} color='danger' />
                  </GridItem>
                </GridContainer>
              )}
              {codClaveError && (
                <GridItem xs={12}>
                  <SnackbarContent message={codClaveError} color='danger' />
                </GridItem>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </>
  )
}

export default ChangePasswordModal