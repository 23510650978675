import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core'
import { Checkbox, ListItemText, TextField, Divider } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import DateFnsUtils from "@date-io/date-fns"
import { es } from "date-fns/locale"
import * as dayjs from "dayjs"
import ExcelFile from 'react-data-export/dist/ExcelPlugin/components/ExcelFile.js'

import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import ReactTable from 'components/ReactTable/ReactTable'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent'

import styles from '../styles/trackingsStyles'
import { getTechnicians } from 'redux/actions/userActions'
import { getTechniciansTracking } from "redux/actions/trackingActions"
import { TECHNICIANSTRACKING_LIST_RESET } from 'redux/constants/trackingConstants'
import { TECHNICIAN_LIST_RESET } from 'redux/constants/userConstants'
import { ClipLoader } from 'react-spinners'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal'
import { formatNumber } from 'utils/formatNumber'



const useStyles = makeStyles(styles)


const TechniciansTrackingScreen = () => {

   const dispatch = useDispatch()
   const classes = useStyles()

   const { loadingTechnicianList, successTechnicianList, errorTechnicianList, technicians } = useSelector(
      (state) => state.technicianList
   )
   const { loadingTechniciansTrackingList, successTechniciansTrackingList, errorTechniciansTrackingList, techniciansTracking } = useSelector(
      (state) => state.techniciansTrackingList
   )

   const [tableTechTrackingCols, setTableTechTrackingCols] = useState([])
   const [tableTechTrackingData, setTableTechTrackingData] = useState([])
   const [selectedTechs, setSelectedTechs] = useState([])
   const [selectAllTechs, setSelectAllTechs] = useState(true)
   const [startDate, setStartDate] = useState(null)
   const [endDate, setEndDate] = useState(null)
   const [techsError, setTechsError] = useState("")
   const [startDateError, setStartDateError] = useState("")
   const [endDateError, setEndDateError] = useState("")
   const [excel, setExcel] = useState(false)
   const [downloadExcel, setDownloadExcel] = useState(false)


   useEffect(() => {

      dispatch(getTechnicians())
      

      return () => {
         dispatch({ type: TECHNICIANSTRACKING_LIST_RESET })
         dispatch({ type: TECHNICIAN_LIST_RESET })
      }
   }, [])

   useEffect(() => {

      if ( successTechnicianList && technicians != null && technicians.length ) {
         setSelectedTechs(technicians.map(tech => tech.usuario))
      }

   }, [successTechnicianList, technicians])

   useEffect(() => {

      if ( successTechniciansTrackingList && techniciansTracking != null && techniciansTracking.length ) {

         const dates = []
         techniciansTracking[0].dates.forEach(date => {
            dates.push({
               Header: ""+dayjs(new Date(date.date)).format("MM-DD"),
               accessor: date.date,
            })
         })
         const cols = [{
            Header: "Técnico",
            accessor: "tech"
         }].concat(dates).concat([
            {
               Header: "Media",
               accessor: "average"
            },
            {
               Header: "Total",
               accessor: "total"
            },
            {
               Header: "Número de días",
               accessor: "numDays"
            },
         ])

         const rows = techniciansTracking.map(tech => {
            const object_to_push = {}
            let sumActions = 0
            object_to_push["tech"] = `${tech.tech.oftec} - ${tech.tech.name}`
            tech.dates.forEach(date => {
               object_to_push[`${date.date}`] = date.actions
               sumActions += date.actions
            })
            object_to_push["average"] = ( sumActions / tech.dates.length ).toFixed(2)
            object_to_push["total"] = ( sumActions ).toFixed(2)
            object_to_push["numDays"] = ( tech.dates.length ).toFixed(2)

            return object_to_push
         })

         setTableTechTrackingCols(cols)
         setTableTechTrackingData(rows)
      }

   }, [successTechniciansTrackingList, techniciansTracking])

   useEffect(() => {
      if ( selectedTechs.length ) setTechsError("")
      if ( startDate != null ) setStartDateError("")
      if ( endDate != null ) setEndDateError("")
   }, [selectedTechs, startDate, endDate])



   // funciones
   const onChangeTechniciansHandler = (e) => {
      const newVal = e.target.value
      const lastVal = newVal[newVal.length - 1]

      if ( typeof lastVal === "boolean" ) {
         if ( selectAllTechs ) {
            setSelectedTechs([])
            setSelectAllTechs(last => !last)
         }else {
            setSelectedTechs(technicians.map(tech => tech.usuario))
            setSelectAllTechs(last => !last)
         }
      }else {
         setSelectedTechs(typeof newVal === "string" ? value.split(",") : newVal)
         
      }
   }

   const onChangeStartDate = ( newVal ) => {
      if ( endDate == null || newVal > endDate ) {
         setStartDate(newVal)
         setEndDate(newVal)
      }else {
         setStartDate(newVal)
      }
   }

   const onChangeEndDate = ( newVal ) => {
      if ( startDate == null || newVal < startDate ) {
         setStartDate(newVal)
         setEndDate(newVal)
      }else {
         setEndDate(newVal)
      }
   }

   const onClickSearchTrackHandler = () => {

      if ( !selectedTechs.length ) setTechsError("Introduce algún técnico antes de realizar la búsqueda.")
      else if ( startDate == null ) setStartDateError("Introduce una fecha de inicio antes de realizar la búsqueda.")
      else if ( endDate == null ) setEndDateError("Introduce una fecha de fin antes de realizar la búsqueda.")
      else {
         const techsData = technicians.filter(tech => selectedTechs.includes(tech.usuario))
            .map(tech => {
               return {
                  oftec: tech.usuario,
                  name: tech.nombre,
               }
            })

         dispatch(getTechniciansTracking({
            techs: techsData,
            startDate,
            endDate
         }))
      }
   }

   return (
   <>
   <Card>
      <CardBody>
         {
         loadingTechnicianList
            ? "Cargando..."
            : ( technicians != null && technicians.length )
               ?  (
                     
                           <GridContainer>
                              {/* SELECTOR TÉCNICO */}
                              <GridItem xs={3}>            
                                 <FormControl fullWidth>
                                    <InputLabel id="select-block-checkbox" htmlFor='select-techs'>Técnicos a mostrar</InputLabel>
                                    <Select
                                       labelId="select-block-checkbox"
                                       MenuProps={{
                                          className: classes.selectMenu,
                                       }}
                                       multiple
                                       className={classes.select}
                                       value={selectedTechs}
                                       onChange={onChangeTechniciansHandler}
                                       error={!selectedTechs}
                                       renderValue={(selected) => selected.join(", ")}
                                       inputProps={{
                                          name: 'select-techs',
                                          id: 'select-techs',
                                          required: true,
                                       }}
                                    >
                                       <MenuItem
                                          disabled
                                          classes={{
                                             root: classes.selectMenuItem,
                                          }}
                                       >
                                          Selecciona el técnico a mostrar
                                       </MenuItem>
                                       <MenuItem
                                          value={selectAllTechs}
                                          key={`select_techs_checkbox_all`}
                                          classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                       >
                                          <Checkbox checked={selectAllTechs} />
                                          <ListItemText primary={"Seleccionar todos"} />
                                       </MenuItem>
                                       <Divider />
                                       {
                                          technicians.map((tech, index) => {
                                             const value = tech.usuario
                                             
                                             return (
                                                <MenuItem
                                                   value={value}
                                                   key={`select_techs_checkbox_${ index }`}
                                                   classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                >
                                                   <Checkbox checked={selectedTechs.indexOf(value) > -1} />
                                                   <ListItemText primary={value} />
                                                </MenuItem>
                                             )
                                          })
                                       }
                                    </Select>
                                 </FormControl>
                              </GridItem>

                              {/* FECHA INICIO */}
                              <GridItem xs={3}>
                                 <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={es}
                                    utils={DateFnsUtils}
                                 >
                                    <DesktopDatePicker
                                       label={"Seleccione la fecha de inicio"}
                                       inputFormat="dd-MM-yyyy"
                                       // views={["month", "year"]}
                                       value={startDate}
                                       onChange={onChangeStartDate}
                                       maxDate={new Date()}
                                       renderInput={(params) => {
                                          return <TextField
                                             style={{width:"100%"}}
                                             InputLabelProps={{ shrink: true }}
                                             {...params}
                                             // error={error_startDate || filterDates.start_date == null}
                                          />
                                       }}
                                                            
                                    />
                                 </LocalizationProvider>
                              </GridItem>

                              {/* FECHA FIN */}
                              <GridItem xs={3}>
                                 <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={es}
                                    utils={DateFnsUtils}
                                 >
                                    <DesktopDatePicker
                                       label={"Seleccione la fecha de fin"}
                                       inputFormat="dd-MM-yyyy"
                                       // views={["month", "year"]}
                                       value={endDate}
                                       onChange={onChangeEndDate}
                                       maxDate={new Date()}
                                       renderInput={(params) => {
                                          return <TextField
                                             style={{width:"100%"}}
                                             InputLabelProps={{ shrink: true }}
                                             {...params}
                                             error={false}
                                          />
                                       }}                                 
                                       required={false}
                                    />
                                 </LocalizationProvider>
                              </GridItem>
                              
                              {/* BUSCAR */}
                              <GridItem
                                 xs={3}
                                 style={{ display: "flex", alignItems: "center" }}
                              >
                                 <Button
                                    // style={{ width: "100%", height: "80%", cursor: "pointer" }}
                                    fullWidth
                                    onClick={onClickSearchTrackHandler}
                                    type='submit' 
                                    color='primary'
                                 >
                                    {
                                       loadingTechniciansTrackingList
                                       ?  <>
                                          <span style={{ marginRight: "10px" }}>
                                             <ClipLoader
                                                color="#041f24"
                                                size={20}            
                                             /> 
                                          </span>
                                          Buscando...
                                          </>
                                       : 
                                       "Buscar"
                                    }
                                 </Button>
                              </GridItem>          
                           </GridContainer>     
                        
                  )
               : "No hay técnicos que mostrar"
         }
      </CardBody>
   </Card>

   
   
{
loadingTechniciansTrackingList
   ? "Cargando seguimiento..."
   :  ( techniciansTracking != null && techniciansTracking.length )
      ?  (
         <>
         <Card>
            <CardBody>
               <GridContainer>
                  {/* TABLA DATOS */}
                  <GridItem xs={12}>
                     <ReactTable
                        columns={tableTechTrackingCols}
                        data={tableTechTrackingData}
                        search={false}
                        colWidth={"100px"}
                        toolTip={"15px"}
                        stickyFirst
                     />         
                  </GridItem>

                  {/* EXCEL */}
                  <GridItem xs={12}>
                     <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                     {
                        excel
                        && (
                        <ExcelFile
                           element={<Button color='primary'>Exportar Excel</Button>}
                           filename='Seguimiento - Técnicos'
                           hideElement={true}
                        >
                           {
                              getTechniciansExcelSheet(techniciansTracking)
                           }                      
                        </ExcelFile>
                        )
                     }
                     <Button 
                        color='primary'
                        onClick={() =>setDownloadExcel(true)}
                        style={{ marginLeft: '10px' }}
                     >
                        Exportar a EXCEL
                     </Button>
                  </div>                
               </GridItem>
            </GridContainer>
         </CardBody>
      </Card>
      </>
      )
      : ""
   }
         

   
   {
      (errorTechniciansTrackingList != null && errorTechniciansTrackingList)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={errorTechniciansTrackingList || errorTechniciansTrackingList.message} color='danger' />
         </GridItem>
      )
   }
   {
      techsError
      ? (
         <GridItem xs={12}>
            <SnackbarContent message={techsError} color='danger' />   
         </GridItem>
      )
      : startDateError
         ? (
            <GridItem xs={12}>
               <SnackbarContent message={startDateError} color='danger' />   
            </GridItem>
         )
         : endDateError
         && (
            <GridItem xs={12}>
               <SnackbarContent message={endDateError} color='danger' />   
            </GridItem>
         )
   }

   {/* EXCEL */}
   {
      downloadExcel
      && <DownloadConfirmModal
            downloadFile={downloadExcel}
            setDownloadFile={setDownloadExcel}
            setFile={setExcel}
            tableName='Seguimiento - Técnicos'
            FileExtension='Excel'
         />      
   }

   </>
   )
}

const getTechniciansExcelSheet = ( techsData ) => {
   // ============================================================================
   // ======================            COLUMNAS             =====================
   // ============================================================================
   const createCol = ({title, width = 15, font = 10, bold = false, color = "#000000"}) => {
      
      return {
         title,
         width: {wch: width},
         style: {
            fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: font, bold, color: {rgb: color}},
            alignment: {horizontal: "center", vertical: "center", wrapText: true}
         },
      }
   }

   const cols = [
      createCol({ title: "Técnico", width: 15, font: 12, bold: true }),
      createCol({ title: "Nombre", width: 30, font: 12, bold: true }),

   ]
   techsData[0].dates.forEach(dateData => cols.push(createCol({ title: `${dateData.date.replace("-", " ")}`, width: 5, font: 12, bold: true })))
   cols.push(createCol({title: "Media diaria", width: 15, font: 12, bold: true}))
   cols.push(createCol({title: "Suma total", width: 15, font: 12, bold: true}))
   cols.push(createCol({title: "Número de días", width: 15, font: 12, bold: true}))


   // ============================================================================
   // =======================            FILAS             =======================
   // ============================================================================
   const createColRow = ({value, border, bold}) => {
      
      return {
         value,
         style: {
            border: {
               top: {
                  style: border != null && border.includes("top") ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               bottom: {
                  style: border != null && border.includes("bottom") ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               left: {
                  style: border != null && border.includes("left") ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               right: {
                  style: border != null && border.includes("right") ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
            },
            font: {sz: "10", bold: (bold == null ? false : bold)},
            alignment: {horizontal: "center", vertical: "center", wrapText: true},
         }
      }
   }

   const rows = techsData.map(data => {
      const techName = data.tech.name
      const techOftec = data.tech.oftec
      const row = [
         createColRow({ value: techOftec, bold: true }),
         createColRow({ value: techName, bold: true }),
      ]

      let actionsSum = 0
      const datesRows = data.dates.map((dateData, i) => {
         actionsSum += dateData.actions

         if ( i === data.dates.length - 1 ) createColRow({ value: dateData.actions, border: "bottom" })
         return createColRow({value: dateData.actions || " "})
      })
      
      const averateActions = actionsSum / data.dates.length
      const sumActions = actionsSum
      const numDays = data.dates.length
      return row.concat(datesRows).concat(createColRow({value: formatNumber(averateActions), bold: true, border: ["left, right"] }))
         .concat(createColRow({value: formatNumber(sumActions, 0), bold: true, border: ["left, right"] }))
         .concat(createColRow({value: formatNumber(numDays, 0), bold: true, border: ["left, right"] }))
   })

   const multiDataSet = [{
      columns: cols,
      data: rows
   }]


   return (
      <ExcelFile.ExcelSheet dataSet={multiDataSet} name="Resumen" />
   )
}


export default TechniciansTrackingScreen
