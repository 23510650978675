import { useState } from "react"
import { Box, Tab, Tabs } from "@mui/material"

import { TabPanelContent } from "./TabPanelContent"
import ChangeTabPanelUpdateModal from "./ChangeTabPanelUpdateModal"

export const TabPanelUpdate = ({ info, setActToUpdate, canUpdate, setCanUpdate, submitHandler, setSuccessUpdate, technicians }) => {
   
   const { acts_data } = {...info} ?? []

   const [actsD, setActsD] = useState(acts_data)
   const [openChangeTabModal, setOpenChangeTabModal] = useState(false)
   const [tabIndex, setTabIndex] = useState(0)
   const [newTabIndex, setNewTabIndex] = useState(0)
   
   const handleChange = (e, newValue) => {
      if ( canUpdate ) {
           
         setNewTabIndex(newValue)
         setOpenChangeTabModal(true)
      }else {
         setCanUpdate(false)
         setSuccessUpdate(false)
         setTabIndex( newValue )
      }

   }

   return (
      <Box sx={{ width: "100%" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs 
               value={tabIndex}
               onChange={handleChange} 
               variant="scrollable"
               aria-label="basic tabs example"
            >
               {
                  JSON.parse(JSON.stringify(actsD)).map(act => {
                     const act_name = act[0].act_name || "Control"

                     return (
                        <Tab
                           key={act_name}
                           label={act_name}
                           {...allyProps(0)}
                        />
                     )
                  })
               }
            </Tabs>
         </Box>

         {
            JSON.parse(JSON.stringify(actsD)).map((act, index) => {

               return (
                  <TabPanel value={tabIndex} index={index} key={`${act[0].name_db}_${tabIndex}_${index}`}>
                     <TabPanelContent
                        value={tabIndex}
                        act_data={ JSON.parse(JSON.stringify(act)) }
                        acts_data={ JSON.parse(JSON.stringify(actsD)) }
                        setActsD={setActsD}
                        technicians={technicians}
                        index={index}
                        setActToUpdate={setActToUpdate}
                        setCanUpdate={setCanUpdate}
                        submitHandler={submitHandler}
                        setSuccessUpdate={setSuccessUpdate}
                      />
                  </TabPanel>
               )
            })
         }

         <ChangeTabPanelUpdateModal
            open={openChangeTabModal}
            setOpen={setOpenChangeTabModal}
            setTabIndex={setTabIndex}
            newTabIndex={newTabIndex}
            setCanUpdate={setCanUpdate}
            setSuccessUpdate={setSuccessUpdate}
         />
      </Box>

      
   )
}

const TabPanel = ({ children, value, index, ...other }) => {

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${ index }`}
         aria-labelledby={`simple-tab-${ index }`}
         {...other}
      >
         {
            value === index
            && <Box sx={{ p:3 }}>
               { children }
            </Box>
         }
      </div>
   )
}

const allyProps = ( index ) => {

   return {
      id: `simple-tab-${ index }`,
      "aria-controls": `simple-tabpanel-${ index }`,
   }
}


