import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ReactExport from 'react-data-export'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Visibility, Edit } from '@mui/icons-material'

import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal'
import UpdateWorkInfoActModal from '../components/UpdateWorkInfoActModal'
import ViewWorkInfoActModal from '../components/ViewWorkInfoActModal'

import styles from './styles/workAdministrativeNoticeListScreenStyles'
import { WORK_LIST_RESET } from 'redux/constants/workConstants'
import { TECHNICIAN_LIST_RESET } from 'redux/constants/userConstants'
import { getWorks } from 'redux/actions/workActions'
import { getTechnicians } from 'redux/actions/userActions'
import { ClipLoader } from 'react-spinners'
import { getActs } from 'redux/actions/actActions'
import { ACT_LIST_RESET } from 'redux/constants/actConstants'
import { getRules } from 'redux/actions/workActions'
import { WORK_CONTROL_RULES_RESET } from 'redux/constants/workConstants'

const useStyles = makeStyles(styles)

const WorkAdministrativeNoticeListScreen = () => {
   const dispatch = useDispatch()
   const ExcelFile = ReactExport.ExcelFile
   const classes = useStyles()

   
   const [data, setData] = useState([])
   const [downloadExcel, setDownloadExcel] = useState(false)
   const [downloadPdf, setDownloadPdf] = useState(false)
   const [excel, setExcel] = useState(false)
   const [pdf, setPdf] = useState(false)
   const [viewInfoActModal, setViewInfoActModal] = useState(false)
   const [updateInfoActModal, setUpdateInfoActModal] = useState(false)
   const [showUpdateInfoActModal, setShowUpdateInfoActModal] = useState({})
   const [showViewInfoActModal, setShowViewInfoActModal] = useState({})
   const [reactTableCols, setReactTableCols] = useState([])
   const [filterActs, setFilterActs] = useState([])
   const [selectedRule, setSelectedRule] = useState(null)
   const [ruleError, setRuleError] = useState("")
   const [notControlsMessage, setNotControlsMessage] = useState("")

   const { loadingWorkList, works, successWorkList, errorWorkList } = useSelector(
      (state) => state.workList
   )

   const { loadingTechnicianList, successTechnicianList, errorTechnicianList, technicians } = useSelector(
      (state) => state.technicianList
   )

   const { loadingActList, successActList, errorActList, acts } = useSelector( // <=
      (state) => state.actList
   )

   const { loadingWorkRulesList, successWorkRulesList, errorWorkRulesList, rules } = useSelector(
      (state) => state.workRuleList
   )

   useEffect(() => {

      if (successWorkList && works != null && works.length) {
         const { columns_data, control_data } = works[0]
         const reactTableCols_info = []

         control_data.forEach(data => {

            const table_name_db = Object.keys(data)[0]
            let table_name_excel = ""

            if ( table_name_db !== "categoria_derivada" ) {
               const col = columns_data.find(column => {
                  return column?.nombre_columna_tabla === table_name_db
               })

               table_name_excel = col.nombre_columna_excel

               reactTableCols_info.push({
                  Header: table_name_excel,
                  accessor: (table_name_db === "categoria_pliego") ? "categoria_pliego_derivada" : table_name_db, 
               })
            }
         })

         reactTableCols_info.push({
            Header: 'ACCIONES',
            accessor: 'actions',
         })

         setReactTableCols( reactTableCols_info )

         const list = works[0].result.map((work) => {
            if ( work.categoria_derivada != null && work.categoria_pliego !== work.categoria_derivada ) {
               work.categoria_pliego_derivada = `${ work.categoria_pliego } => ${ work.categoria_derivada }`
            }else {
               work.categoria_pliego_derivada = work.categoria_pliego
            }
            
            return {
               ...work,
               usuario: `${work.usuario || '-'}`,
               actions: (
                  <div className='actions-right'>
                     <Button
                        justIcon
                        round
                        simple
                        onClick={() => showUpdateActividadHandler(work.id_control)}
                        color='primary'
                        className='edit'
                     >
                        <Tooltip title='Editar Información'>
                           <Edit fontSize="large" />
                        </Tooltip>
                     </Button>

                     <Button
                        justIcon
                        round
                        simple
                        onClick={() => showViewInfoActHandler(work.id_control)}
                        color='success'
                        className='edit'
                     >
                        <Tooltip title='Ver más información'>
                           <Visibility fontSize="large" />
                        </Tooltip>
                     </Button>
                  </div>
               ),
            }
         })

         setData(list)
      }
      if (successWorkList && !loadingWorkList && works != null && !works.length) {
         setNotControlsMessage("No hay registros para este tipo de aviso")
      }
   }, [successWorkList, works, loadingWorkList])

   useEffect(() => {
      
      dispatch(getTechnicians())
      dispatch(getRules("Administrativo_Aviso"))

      return () => {
         dispatch({ type: WORK_LIST_RESET })
         dispatch({ type: TECHNICIAN_LIST_RESET })
         dispatch({ type: ACT_LIST_RESET })
         dispatch({ type: WORK_CONTROL_RULES_RESET })
      }
   }, [])

   useEffect(() => {
      if (pdf) {
         exportPDF()
      }
   }, [pdf])

   // useEffect(() => {

   //    if (works != null){
   //       const new_val = [...works]
   //       setWorks(new_val)
   //    }
   // }, [works])

   const showUpdateActividadHandler = (id) => {
      setShowUpdateInfoActModal(id)
      setUpdateInfoActModal(true)
   }

   const closeUpdateInfoActModal = () => {
      setUpdateInfoActModal(false)
      setShowUpdateInfoActModal({})
   }

   const showViewInfoActHandler = (id) => {
      setShowViewInfoActModal(id)
      setViewInfoActModal(true)
   }

   const closeViewInfoActModal = () => {
      setViewInfoActModal(false)
      setShowViewInfoActModal({})
   }

   const onChangeRuleHandler = (e) => {
      const ruleToFilter = e.target.value

      setSelectedRule( ruleToFilter )
      setNotControlsMessage("")
   }

   const onSearchClickHandler = () => {

      if ( selectedRule == null ) setRuleError("No hay ningún tipo de aviso seleccionado")
      else {
         setRuleError("")
         dispatch(getWorks({all: false, noticeRule: selectedRule}))      
      }
   }

   const exportPDF = () => {
      const doc = new jsPDF()
      doc.text('Trabajos', 20, 10)
      doc.autoTable({
         body: data,
         columns: [
            { header: 'Referencia solicitud', dataKey: 'referencia_solicitud' },
            { header: 'Linea', dataKey: 'linea' },
            { header: 'Año convocatoria', dataKey: 'anio_convocatoria' },
            { header: 'Bloque / orden / revisión', dataKey: 'bloque_orden_revision' },
            { header: 'Categoría', dataKey: 'categoria_pliego' },
            { header: 'Acto', dataKey: 'descripcion_acto' },
            { header: 'Usuario', dataKey: 'usuario' },
         ],
      })
      doc.save('trabajos.pdf')
   }


   return (
   <>
      <GridContainer>
         <GridItem xs={12} className={classes.rootItem}>
            <Card>
               <CardBody>
                  <GridContainer>              
                     {
                        rules == null || !rules.length
                        ? ( 'Cargando...' ) 
                        : (
                           <>
                           {/* SELECTOR TIPO AVISO ( REGLA ) */}
                           <GridItem xs={9}>
                              <FormControl fullWidth>
                                 <InputLabel htmlFor='select-rule'>Tipo de aviso</InputLabel>
                                 <Select
                                    MenuProps={{
                                       className: classes.selectMenu,
                                    }}
                                    className={classes.select}
                                    value={selectedRule}
                                    onChange={onChangeRuleHandler}
                                    inputProps={{
                                       name: 'select-rule',
                                       id: 'select-rule',
                                       required: true,
                                    }}
                                 >
                                    <MenuItem
                                       disabled
                                       classes={{
                                          root: classes.selectMenuItem,
                                       }}
                                    >
                                       Seleccione el aviso
                                    </MenuItem>
                                    {
                                       rules.map((rule, index) => {
                                          const value = rule

                                          return (
                                             <MenuItem
                                                value={value}
                                                key={`${value} - ${index}`}
                                                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                             >
                                                {value.descripcion_regla}
                                             </MenuItem>
                                          )
                                       })
                                    }
                                 </Select>
                              </FormControl>
                           </GridItem>
                           </>
                        )              
                     }
                     {/* BUSCAR */}
                     <GridItem
                        xs={3}
                        style={{ display: "flex", alignItems: "center" }}
                     >
                        <Button
                           fullWidth
                           onClick={onSearchClickHandler}
                           type='submit' 
                           color='primary'
                        >
                           {
                              loadingWorkList
                              ?  <>
                                 <span style={{ marginRight: "10px" }}>
                                    <ClipLoader 
                                       color="#041f24"
                                       size={20}            
                                    /> 
                                 </span>
                                 Buscando...
                                 </>
                              : "Buscar"
                           }
                        </Button>
                     </GridItem>
                  </GridContainer>
               </CardBody>
            </Card>

            {
               errorWorkList || ruleError
               ? (
                  errorWorkList
                  ? (
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={errorWorkList || errorWorkList.message} color='danger' />
                        </GridItem>
                     </GridContainer>
                  )
                  : (
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={ruleError} color='danger' />
                        </GridItem>
                     </GridContainer>
                  )
               )
               :
                  works != null && works.length 
                  ? (
                     <Card>
                        <CardBody>
                           {
                           loadingWorkList || loadingTechnicianList
                           ? ( <>
                              Cargando Trabajos... 
                              <div 
                              style={{ marginRight: "10px", display: "flex", justifyContent: "center",
                                 marginTop: "20px" }}
                              >
                                 <ClipLoader
                                    color="#041f24"
                                    size={50}            
                                 /> 
                              </div>
                              </>
                           )
                           :
                              works != null && works.length 
                              ?
                              <>
                                 <ReactTable
                                    columns={reactTableCols}
                                    data={data}
                                    stickyLast
                                 />
                                 <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                 {excel && (
                                 <ExcelFile
                                    element={<Button color='primary'>Exportar Excel</Button>}
                                    filename='trabajos'
                                    hideElement={true}
                                 >
                                    <ExcelFile.ExcelSheet data={data} name='Trabajos'>
                                       <ExcelFile.ExcelColumn label='Referencia solicitud' value='referencia_solicitud' />
                                       <ExcelFile.ExcelColumn label='Línea' value='linea' />
                                       <ExcelFile.ExcelColumn label='Convocatoria' value='anio_convocatoria' />
                                       <ExcelFile.ExcelColumn label='Bloque/orden/revisión' value='bloque_orden_revision' />
                                       <ExcelFile.ExcelColumn label='Categoría' value='categoria_pliego' />
                                       <ExcelFile.ExcelColumn label='Acto' value='descripcion_acto' />
                                       <ExcelFile.ExcelColumn label='Usuario' value='usuario' />
                                    </ExcelFile.ExcelSheet>
                                 
                                 </ExcelFile>
                                 )}
                                 {
                                    !( loadingWorkList || loadingTechnicianList )
                                    && (
                                       <>
                                       <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                                       Exportar EXCEL
                                       </Button>
                                       <Button color='primary' onClick={() => setDownloadPdf(true)} style={{ marginLeft: '10px' }}>
                                       Exportar PDF
                                       </Button>
                                       </>
                                    )
                                 }
                                 
                              </div>
                              </>
                              : works != null && !works.length && "No existen registros"
                           }
                           
                        </CardBody>
                        <GridItem>
                           {
                           errorWorkList 
                           && <GridContainer>
                                 <GridItem xs={12}>
                                 <SnackbarContent message={errorWorkList} color='danger' />
                                 </GridItem>
                              </GridContainer>
                           }
                        </GridItem>
                     </Card>
                  )
                  : (
                     selectedRule != null && notControlsMessage
                     ?
                        <Card>
                           <CardBody>
                              <GridContainer>
                                 <GridItem>                 
                                    <span style={{ fontSize: "1rem" }}>{notControlsMessage}</span>
                                 </GridItem>
                              </GridContainer>
                           </CardBody>
                        </Card>
                     : ""
                  )
            }

         </GridItem>
      </GridContainer>
   
      {/* MODAL SHOW - CONTROL + ACTS */}
      {viewInfoActModal && (
      <ViewWorkInfoActModal
         viewInfo={viewInfoActModal}
         closeViewInfoModal={closeViewInfoActModal}
         info={showViewInfoActModal}
         technicians={technicians}
      />
      )}
      {/* MODAL UPDATE - CONTROL + ACTS */}
      {updateInfoActModal && (
      <UpdateWorkInfoActModal
         open={updateInfoActModal}
         closeUpdateInfoModal={closeUpdateInfoActModal}
         controlId={showUpdateInfoActModal}
         technicians={technicians}
      />
      )}
      {/* MODAL EXCEL */}
      {downloadExcel && (
      <DownloadConfirmModal
         downloadFile={downloadExcel}
         setDownloadFile={setDownloadExcel}
         setFile={setExcel}
         tableName='Trabajos'
         FileExtension='Excel'
      />
      )}
      {/* MODAL PDF */}
      {downloadPdf && (
      <DownloadConfirmModal
         downloadFile={downloadPdf}
         setDownloadFile={setDownloadPdf}
         setFile={setPdf}
         tableName='Trabajos'
         FileExtension='PDF'
      />
      )}
   </>
   )
}

export default WorkAdministrativeNoticeListScreen