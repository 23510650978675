import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from '../styles/tabPanelStyles'

const useStyles = makeStyles(styles)

const UpdateActionModal = ({open, handleCloseModal, modalTitle, customWidth, children, updateError}) => {

   const classes = useStyles(styles)

   return (
      <Dialog
         classes={{
            root: classes.modalRoot,
            paper: customWidth ? '': classes.modal,
         }}
         className={classes.custdialog}
         maxWidth={customWidth} 
         fullWidth={!!customWidth}
         open={open}
         keepMounted
         onClose={handleCloseModal}
         aria-labelledby='update-modal-slide-title'
         aria-describedby='update-modal-slide-description'
      >
         <div className={classes.dialogTitleContainer}>
         <DialogTitle
            id='update-modal-slide-title' 
            disableTypography 
            className={classes.modalHeader}
            fullWidth={!!customWidth}
         >
            <GridContainer style={{ padding: "0", marginBottom: "0"}}>           
               <GridItem xs={4}>
                  <h4 className={classes.modalTitle}>{modalTitle}</h4>
               </GridItem>
               <GridItem xs={7}>
                  {
                  updateError
                     &&                                

 
                        <SnackbarContent                                        
                           message={updateError}
                           color='danger'
                        />


                  }                                                                               
               </GridItem>
               <GridItem xs={1}>
                  <Button
                     justIcon
                     className={classes.modalCloseButton}
                     key='close'
                     aria-label='Close'
                     color='transparent'
                     onClick={handleCloseModal}
                     >
                     <Close className={classes.modalClose} />
                  </Button>
               </GridItem>
            </GridContainer>   
            
         </DialogTitle>
         </div>
         <DialogContent id='update-modal-slide-description' className={classes.modalBody}>
            <GridItem xs={12}>{children}</GridItem>
            {/* Add error Snackbar to hire */}
         </DialogContent>
      </Dialog>
   )
}

export default UpdateActionModal
