import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Typography from '@mui/material/Typography'
import { USERLIST_RESETPASSWORD_RESET } from 'redux/constants/userlistConstants'
import { userlistResetPassword } from 'redux/actions/userlistActions'
import styles from '../styles/updateUserModalStyles'

const useStyles = makeStyles(styles)

const ResetPasswordModal = ({ handleCloseModal, resetPassword, showResetPassword }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [infoUser, setInfoUser] = useState(showResetPassword)
  const [clave1, setClave1] = useState('')
  const [clave2, setClave2] = useState('')
  const [codClaveError, setCodClaveError] = useState('')

  const style={
    boxShadow:"2px 2px 3px 3px #ccc",
    border:"2px #eee",
    padding:"20px",
    marginTop:"25px"
  }

  const { loadingUserlistResetPassword, errorUserlistResetPassword, successUserlistResetPassword } = useSelector((state) => state.userlistResetPassword)

  const resetPasswordHandler = (e) => {
     e.preventDefault()
     if (clave1.length < 8) {
        return setCodClaveError('La contraseña debe tener al menos 8 caracteres')
     }
     if (!clave1.match(/[A-Z]/)) {
        return setCodClaveError('La contraseña debe tener al menos una mayúscula')
     }
     if (!clave1.match(/[a-z]/)) {
        return setCodClaveError('La contraseña debe tener al menos una minúscula')
     }
     if (!clave1.match(/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/)) {
        return setCodClaveError('La contraseña debe tener al menos un número o un caracter especial')
     }
     if (!(clave1 === clave2 && clave1!=="")) {
        return setCodClaveError('La contraseña debe coincidir con la confirmación de contraseña')
     }
       
     dispatch(
         userlistResetPassword({
            id_usuario: infoUser.id_usuario,
            password: clave1,
         })
     )
    
     setTimeout(() => {
        dispatch({ type: USERLIST_RESETPASSWORD_RESET })
        handleCloseModal()
     }, 1000)
  }
  return (
    <Dialog
      open={resetPassword}
      keepMounted
      onClose={handleCloseModal}
      aria-labelledby='notice-modal-slide-title'
      aria-describedby='notice-modal-slide-description'
    >
      <form onSubmit={resetPasswordHandler} autoComplete='false'>
        <DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{`Resetear contraseña de usuario`}</h4>
        </DialogTitle>
        <DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONTRASEÑA'}
                      id='clave1'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: clave1,
                        onChange: (e) => setClave1(e.target.value),
                        type: 'text',
                        maxLength: 20,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONFIRMAR CONTRASEÑA'}
                      id='clave2'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: clave2,
                        onChange: (e) => setClave2(e.target.value),
                        type: 'text',
                        maxLength: 20,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                     <Typography>
                        <p style={{color:"blue",fontSize:"20px"}}>IMPORTANTE: Comunique al nuevo usuario su contraseña para que pueda acceder a la aplicación</p>
                     </Typography>
                  </GridItem>
                  <div className="container"> 
                    <div className="row">
                        <div className="col-md-4"></div>      
                        <div className="col-md-4">
                          <div style={style}>
                              <p style={{fontWeight:"bold"}}>Todos los checks deben ponerse verdes. La contraseña debe cumplir:</p>
                              {clave1.length >= "8" ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos 8 caracteres</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos 8 caracteres</p> 
                              )}
                              {clave1.match(/[A-Z]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra mayúscula</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra mayúscula</p> 
                              )}
                              {clave1.match(/[a-z]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra minúscula</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra minúscula</p> 
                              )}
                              {clave1.match(/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos un número o un caracter especial</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos un número o un caracter especial</p> 
                              )}
                              {clave1 === clave2 && clave1!=="" ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Contraseña === Confirmar contraseña</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Contraseña === Confirmar contraseña</p> 
                              )}
                          </div>
                        </div>     
                        <div className="col-md-4"></div>
                    </div>
                  </div>
                  {clave2 === "" ? "" :
                     (clave1 === clave2  ? <p style={{color:"green",fontWeight:"bold"}}> Contraseñas iguales </p> :
                      <p style={{color:"red",fontWeight:"bold"}}> Contraseñas diferentes </p>
                  )}
            <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
              <Button type='submit' color={successUserlistResetPassword ? 'success' : 'primary'} block>
                {loadingUserlistResetPassword
                  ? 'Actualizando...'
                  : successUserlistResetPassword
                  ? 'Usuario actualizado'
                  : 'Actualizar usuario'}
              </Button>
            </GridItem>
          </GridContainer>
          {errorUserlistResetPassword && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorUserlistResetPassword} color='danger' />
              </GridItem>
            </GridContainer>
          )}
          {codClaveError && (
            <GridItem xs={12}>
                <SnackbarContent message={codClaveError} color='danger' />
            </GridItem>
          )}
        </DialogContent>
      </form>
    </Dialog>
  )
}

export default ResetPasswordModal