import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import TransferList from 'components/TransferList/TransferList'
import Button from 'components/CustomButtons/Button'
import styles from './styles/profileToManagerScreenStyles'
import ProfileManagerSelect from './components/ProfileManagerSelect'
import { PROFILE_TO_MANAGER_LIST_RESET, PROFILE_TO_MANAGER_REGISTER_RESET } from 'redux/constants/profileToManagerConstants'
import { PROFILE_LIST_RESET } from 'redux/constants/profileConstants'
import { getProfileToManagers, registerProfileToManager } from 'redux/actions/profileToManagerActions'

const useStyles = makeStyles(styles)

const ProfileToManagerScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [dataLeft, setDataLeft] = useState([])
  const [dataRight, setDataRight] = useState([])
  const [alert, setAlert] = useState(null)
  const [hasSearch, toggleHasSearch] = useState(false)
  const [profileId, setProfileId] = useState('')

  const { loadingProfileToManagerList, successProfileToManagerList, profileToManagers } = useSelector(
    (state) => state.profileToManagerList
  )

  const { loadingProfileToManagerRegister, successProfileToManagerRegister, } = useSelector(
    (state) => state.profileToManagerRegister
  )

  useEffect(() => {
    if (successProfileToManagerList) {
      const profilesRigth = []
      const profilesLeft = []

      profileToManagers?.assigned?.map((profile) => {
        profilesRigth.push({
          id: profile.id_usuario,
          fullName: `ID: ${profile.usuario}   NOMBRE: ${profile.nombre}`,
        })
      })
      profileToManagers?.pendings?.map((profile) => {
        profilesLeft.push({
          id: profile.id_usuario,
          fullName: `ID: ${profile.usuario}   NOMBRE: ${profile.nombre}`,
        })
      })
      setDataRight(profilesRigth)
      setDataLeft(profilesLeft)
    }
  }, [successProfileToManagerList])

  useEffect(() => {
    if (successProfileToManagerRegister) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='GUARDADO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          La asignacion de perfiles ha sido guardada correctamente
        </SweetAlert>
      )
      setDataLeft([])
      setDataRight([])
      setProfileId('')
      toggleHasSearch(false)
      dispatch({ type: PROFILE_TO_MANAGER_LIST_RESET })
      dispatch({ type: PROFILE_TO_MANAGER_REGISTER_RESET })
      dispatch({ type: PROFILE_LIST_RESET })
    }
  }, [successProfileToManagerRegister])

  useEffect(() => {
    return () => {
      dispatch({ type: PROFILE_TO_MANAGER_LIST_RESET })
      dispatch({ type: PROFILE_TO_MANAGER_REGISTER_RESET })
    }
  }, [dispatch])

  const confirmSuccess = () => {
    setAlert(null)
  }
  const hideAlert = () => {
    setAlert(null)
  }

  const cancelAction = () => {
    dispatch({ type: PROFILE_TO_MANAGER_LIST_RESET })
    dispatch({ type: PROFILE_TO_MANAGER_REGISTER_RESET })
    setDataLeft([])
    setDataRight([])
    setProfileId('')
    toggleHasSearch(false)
  }

  const handleProfilesByManager = (e) => {
    const profiles = {
      assigned: dataRight,
      managerId: profileId,
    }

    dispatch(registerProfileToManager(profiles))
  }

  return (
    <GridContainer>
      <GridItem xs={8}>
        <GridContainer style={{ marginBottom: '16px' }}>
          <ProfileManagerSelect
            disabled={successProfileToManagerList}
            profileId={profileId}
            setProfileId={setProfileId}
          ></ProfileManagerSelect>
          <GridItem xs={4}>
            <Button
              disabled={hasSearch || !profileId}
              color='primary'
              onClick={() => {
                toggleHasSearch(true)
                dispatch(getProfileToManagers(profileId))
              }}
            >
              Buscar asignaciones
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
      <div style={{ marginTop: '50px', width: '100%' }}>
        {loadingProfileToManagerList ? (
          <>Cargando</>
        ) : (
          <>
            <TransferList
              titleRigth='ASIGNADOS'
              titleLeft='PENDIENTES DE ASIGNAR'
              dataLeft={dataLeft}
              dataRight={dataRight}
              setDataLeft={setDataLeft}
              setDataRight={setDataRight}
            />
            <GridContainer xs={12} style={{ marginTop: '20px', justifyContent: 'center' }}>
              <GridItem>
                <Button
                  style={{ marginRight: '20px' }}
                  color={successProfileToManagerRegister ? `success` : 'primary'}
                  onClick={() => handleProfilesByManager()}
                >
                  {loadingProfileToManagerRegister
                    ? `Cargando`
                    : successProfileToManagerRegister
                    ? `Hecho`
                    : `Guardar`}
                </Button>
                <Button color='primary' onClick={() => cancelAction()}>
                  Cancelar
                </Button>
              </GridItem>
            </GridContainer>
            </>
        )}
      </div>
      {alert}
    </GridContainer>
  )
}

export default ProfileToManagerScreen
