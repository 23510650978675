export const ACTIVIDAD_LIST_REQUEST = 'ACTIVIDAD_LIST_REQUEST'
export const ACTIVIDAD_LIST_SUCCESS = 'ACTIVIDAD_LIST_SUCCESS'
export const ACTIVIDAD_LIST_FAIL = 'ACTIVIDAD_LIST_FAIL'
export const ACTIVIDAD_LIST_RESET = 'ACTIVIDAD_LIST_RESET'

export const ACTIVIDAD_UPDATE_REQUEST = 'ACTIVIDAD_UPDATE_REQUEST'
export const ACTIVIDAD_UPDATE_FAIL ='ACTIVIDAD_UPDATE_FAIL'
export const ACTIVIDAD_UPDATE_RESET = 'ACTIVIDAD_UPDATE_RESET'
export const ACTIVIDAD_UPDATE_SUCCESS = 'ACTIVIDAD_UPDATE_SUCCESS'



