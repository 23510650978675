import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core'
import * as dayjs from "dayjs"

import { PLANNING_LIST_RESET, PLANNING_LINES_LIST_RESET, PLANNING_INDICATORS_LIST_RESET } from 'redux/constants/planningConstants'
import { CATEGORY_LIST_RESET } from 'redux/constants/categoryConstants'
import { getPlannings, getPlanningLines, getPlanningIndicators, deletePlanning } from 'redux/actions/planningActions'
import { getCategories } from 'redux/actions/categoryActions'
import styles from './styles/planningsListScreenStyles'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import ReactTable from 'components/ReactTable/ReactTable'
import { Delete, Edit, Visibility, ContentCopy } from '@mui/icons-material'
import DeletePlanningModal from './components/DeletePlanningModal'
import ViewPlanningInfoModal from './components/ViewPlanningInfoModal'
import UpdatePlanningInfoModal from './components/UpdatePlanningInfoModal'
import ClonePlanningModal from './components/ClonePlanningInfoModal'


const useStyles = makeStyles(styles)


const PlanningsListScreen = () => {
   const dispatch = useDispatch()
   const classes = useStyles()

   const { loadingPlanningsLinesList, successPlanningsLinesList, errorPlanningsLinesList, planningsLines } = useSelector(
      (state) => state.planningLinesList
   )

   const { loadingPlanningIndicatorsList, successPlanningIndicatorsList, errorPlanningIndicatorsList, planningsIndicators } = useSelector(
      (state) => state.planningIndicatorsList
   )

   const { loadingCategoryList, successCategoryList, errorCategoryList, categories } = useSelector(
      (state) => state.categoryList
   )

   const { loadingPlanningsList, successPlanningsList, errorPlanningsList, plannings } = useSelector(
      (state) => state.planningList
   )

   const { successPlanningDelete, errorPlanningDelete, loadingPlanningDelete } = useSelector(
      (state) => state.planningDelete
   )


   const [tablePlanningsCols, setTablePlanningsCols] = useState([])
   const [tablePlanningsData, setTablePlanningsData] = useState([])
   const [reloadPlannings, setReloadPlannings] = useState(false)
   // update
   const [showUpdatePlanningInfoModal, setShowUpdatePlanningInfoModal] = useState({})
   const [updatePlanningInfoModal, setUpdatePlanningInfoModal] = useState(false)
   // clone
   const [showClonePlanningInfoModal, setShowClonePlanningInfoModal] = useState({})
   const [clonePlanningInfoModal, setClonePlanningInfoModal] = useState(false)
   // show
   const [showViewPlanningInfoModal, setShowViewPlanningInfoModal] = useState({})
   const [viewPlanningInfoModal, setViewPlanningInfoModal] = useState(false)
   // delete
   const [deletePlanningModal, setDeletePlanningModal] = useState(false)
   const [showDeletePlanningInfo, setShowDeletePlanningInfo] = useState({})

   const cols = [{
      Header: "Nombre",
      accessor: "nombre", 
   },
   {
      Header: "Fecha Inicio",
      accessor: "fecha_inicio", 
   },
   {
      Header: "Fecha Fin",
      accessor: "fecha_fin", 
   },
   {
      Header: "% Ejecución",
      accessor: "porcentaje_ejecucion", 
   },
   {
      Header: "ACCIONES",
      accessor: "actions", 
   }]

   useEffect(() => {
      dispatch(getPlanningLines())
      dispatch(getPlanningIndicators())
      dispatch(getCategories())
      
      
      return () => {
         dispatch({ type: PLANNING_LIST_RESET })
         dispatch({ type: PLANNING_LINES_LIST_RESET })
         dispatch({ type: PLANNING_INDICATORS_LIST_RESET })
         dispatch({ type: CATEGORY_LIST_RESET })
      }
   }, [])

   useEffect(() => {

      if (successPlanningsList && plannings != null && plannings.length) {

         const plannings_table_data = plannings.map(planning => {
            const general_data = planning.general_data
            const planning_formatted_object = []
            Object.entries(general_data).forEach((col, i) => {
               const regex_p = /(\d{4})-(([0-1])\d)-[0-3]\d/g
               if ( regex_p.exec(col[1]) != null ) {
                  planning_formatted_object[`${col[0]}`] = dayjs(new Date(col[1])).format("YYYY-MM-DD")
               }
               else planning_formatted_object[`${col[0]}`] = col[1]
            
            })

            return {
               ...planning_formatted_object,
               
               actions: (
                  <div>
                     <Button
                        justIcon
                        round
                        simple
                        onClick={() => showPlanningUpdateHandler(general_data.id_planificacion)}
                        color='primary'
                        className='edit'
                     >
                        <Tooltip title='Editar Información'>
                           <Edit fontSize="large" />
                        </Tooltip>
                     </Button>

                     <Button
                        justIcon
                        round
                        simple
                        onClick={() => showPlanningCloneHandler(general_data.id_planificacion)}
                        color='info'
                        className='edit'
                     >
                        <Tooltip title='Duplicar Planificación'>
                           <ContentCopy fontSize="large" />
                        </Tooltip>
                     </Button>

                     <Button
                        justIcon
                        round
                        simple
                        onClick={() => showPlanningInfoHandler(general_data.id_planificacion)}
                        color='success'
                        className='edit'
                     >
                        <Tooltip title='Ver más información'>
                           <Visibility fontSize="large" />
                        </Tooltip>
                     </Button>

                     <Button
                        justIcon
                        round
                        simple
                        onClick={() => showPlanningDeleteHandler(general_data.id_planificacion)}
                        color='danger'
                        className='delete'
                     >
                        <Tooltip title='Borrar'>
                           <Delete fontSize="large"  />
                        </Tooltip>
                     </Button>
                  </div>
               )
            }
         })

         setTablePlanningsData(plannings_table_data)
         setTablePlanningsCols(cols)
      }

   }, [successPlanningsList, plannings])


   useEffect(() => {
      if (!deletePlanningModal) dispatch(getPlannings())

   }, [deletePlanningModal])

   // funciones
   // PLANNING UPDATE
   const showPlanningUpdateHandler = ( id ) => {
      const planning = plannings.find((plan) => plan.general_data.id_planificacion === id)
      setShowUpdatePlanningInfoModal(planning)
      setUpdatePlanningInfoModal(true)
   }

   const closeUpdatePlanningInfoModal = () => {
      setShowUpdatePlanningInfoModal({})
      setUpdatePlanningInfoModal(false)
      dispatch(getPlanningLines())
      dispatch(getPlanningIndicators())
      dispatch(getCategories())
      dispatch(getPlannings())
   }
   // PLANNING CLONE
   const showPlanningCloneHandler = ( id ) => {
      const planning = plannings.find((plan) => plan.general_data.id_planificacion === id)
      setShowClonePlanningInfoModal({
         id: planning.general_data.id_planificacion,
         name: planning.general_data.nombre
      })
      setClonePlanningInfoModal( true )
   }

   const closeClonePlanningModalHandler = () => {
      setShowClonePlanningInfoModal({})
      setClonePlanningInfoModal(false)
      dispatch(getPlannings())
      dispatch(getPlanningLines())
      dispatch(getPlanningIndicators())
      dispatch(getCategories())
   }

   // PLANNING INFO
   const showPlanningInfoHandler = ( id ) => {
      const planning = plannings.find((plan) => plan.general_data.id_planificacion === id)
      setShowViewPlanningInfoModal(planning)
      setViewPlanningInfoModal(true)
   }

   const closeViewPlanningInfoModal = () => {
      setShowViewPlanningInfoModal({})
      setViewPlanningInfoModal(false)
   }
   // PLANNING DELETE
   const showPlanningDeleteHandler = ( id ) => {
      const planning = plannings.find((plan) => plan.general_data.id_planificacion === id)
      setShowDeletePlanningInfo(planning.general_data)
      setDeletePlanningModal(true)
   }
   const closeDeletePlanningModalHandler = () => {
      setDeletePlanningModal(false)
      setShowDeletePlanningInfo({})
   }


   return (
   <>
   {
       planningsLines != null && planningsLines.length
       && planningsIndicators != null && planningsIndicators.length
       && categories != null && categories.length
       && 
         <GridContainer>
            <Card>
               <CardBody>
                  {
                     loadingPlanningsList
                     ? ( "Cargando planificaciones" )
                     : plannings != null && plannings.length
                        ?  (
                           <ReactTable
                              columns={tablePlanningsCols}
                              data={tablePlanningsData}
                              lastColCenter
                           />
                           )
                        : "No existen planificaciones"
                  }

                  <GridItem>
                     {
                        errorPlanningsList
                        && <GridContainer>
                              <GridItem xs={12}>
                              <SnackbarContent message={errorPlanningsList} color='danger' />
                              </GridItem>
                           </GridContainer>
                     }
                  </GridItem>

                  
               </CardBody>
            </Card>
         </GridContainer>
   }
   {
   successPlanningsList && false
   && <Card>
         <CardBody>
            <GridContainer>
               <GridItem xs={12}>
                  {/* <TabPanelContainer report={JSON.parse(JSON.stringify(reportData))} /> */}
               </GridItem>
            </GridContainer>
         </CardBody>
      </Card>
   }
   {/* UPDATE PLANNING MODAL */}
   {
      updatePlanningInfoModal
      && (
         <UpdatePlanningInfoModal
            open={updatePlanningInfoModal}
            closeViewInfoModal={closeUpdatePlanningInfoModal}
            planning={showUpdatePlanningInfoModal}
            update={true}
            view={null}
         />
      )
   }
   {/* CLONE PLANNING MODAL */}
   {
      clonePlanningInfoModal
      && (
         <ClonePlanningModal
            open={clonePlanningInfoModal}
            closeCloneInfoModal={closeClonePlanningModalHandler}
            planningInfo={showClonePlanningInfoModal}
         />
      )
   }
   {/* INFO PLANNING MODAL */}
   {
      viewPlanningInfoModal
      && (
         <UpdatePlanningInfoModal
            open={viewPlanningInfoModal}
            closeViewInfoModal={closeViewPlanningInfoModal}
            planning={showViewPlanningInfoModal}
            update={null}
            view={true}
         />
      )
   }
   {/* DELETE PLANNING MODAL */}
   {
      deletePlanningModal
      && (
         <DeletePlanningModal
            handleCloseDeletePlanningModal={closeDeletePlanningModalHandler}
            deletePlanningModal={deletePlanningModal}
            showDeletePlanningInfo={showDeletePlanningInfo}
         />
      )
   }

   </>
   )
}


export default PlanningsListScreen
