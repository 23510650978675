import axios from 'axios'
import {
   WORK_CONTROL_REQUEST,
   WORK_CONTROL_SUCCESS,
   WORK_CONTROL_FAIL,
   WORK_CONTROLS_REQUEST,
   WORK_CONTROLS_SUCCESS,
   WORK_CONTROLS_FAIL,
   WORK_LIST_REQUEST,
   WORK_LIST_SUCCESS,
   WORK_LIST_FAIL,
   WORK_UPDATE_REQUEST,
   WORK_UPDATE_SUCCESS,
   WORK_UPDATE_FAIL,
   WORK_CONTROL_ACTS_REQUEST,
   WORK_CONTROL_ACTS_SUCCESS,
   WORK_CONTROL_ACTS_FAIL,
   WORK_UPDATE_CONTROL_ACTS_REQUEST,
   WORK_UPDATE_CONTROL_ACTS_SUCCESS,
   WORK_UPDATE_CONTROL_ACTS_FAIL,
   WORK_CONTROL_RULES_REQUEST,
   WORK_CONTROL_RULES_SUCCESS,
   WORK_CONTROL_RULES_FAIL,
} from '../constants/workConstants.js'

export const getControl = (controlId) => async (dispatch, getState) => {
   try {
      dispatch({ type: WORK_CONTROL_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get(`/api/trabajos/control/${controlId}`, config)
      
      dispatch({ type: WORK_CONTROL_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_CONTROL_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getControls = () => async (dispatch, getState) => {
   try {
      dispatch({ type: WORK_CONTROLS_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get(`/api/trabajos/controles/`, config)
      
      dispatch({ type: WORK_CONTROLS_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_CONTROLS_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getWorks = ({all = true, toDo = false, toDoAct = null, done = false, doneAct = null,
    correctionAct = null, userName = null, tech = false, validator = false, toDoRule = null, noticeRule = null }) => async (dispatch, getState) => {
   try {
      dispatch({ type: WORK_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const body = { all, toDo, toDoAct, done, doneAct, correctionAct, userName, tech, validator, toDoRule, noticeRule }
      
      const { data } = await axios.post('/api/trabajos', body, config)

      dispatch({ type: WORK_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const workUpdateInfo = (work) => async (dispatch, getState) => {
   try {
      dispatch({ type: WORK_UPDATE_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()
      

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      const { data } = await axios.put(`/api/trabajos/${work.id_acto_control}`, work, config)

      dispatch({ type: WORK_UPDATE_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_UPDATE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getControlActsData = (control_id) => async(dispatch, getState) => {
   try {
      dispatch({ type: WORK_CONTROL_ACTS_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()
      

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      const { data } = await axios.get(`/api/trabajos/actos_control/${control_id}`, config)

      dispatch({ type: WORK_CONTROL_ACTS_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_CONTROL_ACTS_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const updateControlActsData = (control_id, new_control) => async(dispatch, getState) => {
   try {
      dispatch({ type: WORK_UPDATE_CONTROL_ACTS_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()
      

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      const { data } = await axios.put(`/api/trabajos/actos_control/${control_id}`, new_control, config)

      dispatch({ type: WORK_UPDATE_CONTROL_ACTS_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_UPDATE_CONTROL_ACTS_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getRules = ( type ) => async (dispatch, getState) => {
   try {
      dispatch({ type: WORK_CONTROL_RULES_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const body = { type }

      const { data } = await axios.post(`/api/trabajos/reglas/`, body, config)

      dispatch({ type: WORK_CONTROL_RULES_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WORK_CONTROL_RULES_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}
