export const WORK_CONTROL_REQUEST = "WORK_CONTROL_REQUEST"
export const WORK_CONTROL_SUCCESS = "WORK_CONTROL_SUCCESS"
export const WORK_CONTROL_FAIL = "WORK_CONTROL_FAIL"
export const WORK_CONTROL_RESET = "WORK_CONTROL_RESET"

export const WORK_CONTROLS_REQUEST = "WORK_CONTROL_REQUEST"
export const WORK_CONTROLS_SUCCESS = "WORK_CONTROL_SUCCESS"
export const WORK_CONTROLS_FAIL = "WORK_CONTROL_FAIL"
export const WORK_CONTROLS_RESET = "WORK_CONTROL_RESET"

export const WORK_LIST_REQUEST = "WORK_LIST_REQUEST"
export const WORK_LIST_SUCCESS = "WORK_LIST_SUCCESS"
export const WORK_LIST_FAIL = "WORK_LIST_FAIL"
export const WORK_LIST_RESET = "WORK_LIST_RESET"

export const WORK_UPDATE_REQUEST = "WORK_UPDATE_REQUEST"
export const WORK_UPDATE_SUCCESS = "WORK_UPDATE_SUCCESS"
export const WORK_UPDATE_FAIL = "WORK_UPDATE_FAIL"
export const WORK_UPDATE_RESET = "WORK_UPDATE_RESET"

export const WORK_VALIDATOR_REQUEST = "WORK_VALIDATOR_REQUEST"
export const WORK_VALIDATOR_SUCCESS = "WORK_VALIDATOR_SUCCESS"
export const WORK_VALIDATOR_FAIL = "WORK_VALIDATOR_FAIL"
export const WORK_VALIDATOR_RESET = "WORK_VALIDATOR_RESET"

export const WORK_CONTROL_ACTS_REQUEST = "WORK_CONTROL_ACTS_REQUEST"
export const WORK_CONTROL_ACTS_SUCCESS = "WORK_CONTROL_ACTS_SUCCESS"
export const WORK_CONTROL_ACTS_FAIL = "WORK_CONTROL_ACTS_FAIL"
export const WORK_CONTROL_ACTS_RESET = "WORK_CONTROL_ACTS_FAIL"

export const WORK_UPDATE_CONTROL_ACTS_REQUEST = "WORK_UPDATE_CONTROL_ACTS_REQUEST"
export const WORK_UPDATE_CONTROL_ACTS_SUCCESS = "WORK_UPDATE_CONTROL_ACTS_SUCCESS"
export const WORK_UPDATE_CONTROL_ACTS_FAIL = "WORK_UPDATE_CONTROL_ACTS_FAIL"
export const WORK_UPDATE_CONTROL_ACTS_RESET = "WORK_UPDATE_CONTROL_ACTS_FAIL"

export const WORK_CONTROL_RULES_REQUEST = "WORK_CONTROL_RULES_REQUEST"
export const WORK_CONTROL_RULES_SUCCESS = "WORK_CONTROL_RULES_SUCCESS"
export const WORK_CONTROL_RULES_FAIL = "WORK_CONTROL_RULES_FAIL"
export const WORK_CONTROL_RULES_RESET = "WORK_CONTROL_RULES_FAIL"
