import { useState, useEffect } from "react"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import ReactTable from "components/ReactTable/ReactTable"
import { formatNumber } from "utils/formatNumber"


export const TabPanelContent = ({ controls, summary }) => {

   const [table_cols, setTable_cols] = useState([])
   const [table_data, setTable_data] = useState([])

   useEffect(() => {
      // CONTROLES
      if (controls != null && controls.length) {
         // COLUMNAS
         const cols = [{
            Header: "Indicador de Seguimiento",
            accessor: "tracking_indicator",
         },
         {
            Header: "Referencia Solicitud",
            accessor: "referencia_solicitud",
         },
         {
            Header: "Línea",
            accessor: "linea",
         },
         {
            Header: "Año Convocatoria",
            accessor: "anio_convocatoria",
         },
         {
            Header: "Actuación",
            accessor: "actuacion",
         },
         {
            Header: "Mes",
            accessor: "mes",
         },
         {
            Header: "Técnico AEI",
            accessor: "tech_aei",
         }]
         // FILAS
         const rows = controls.reduce((acc, control) => {
            const object_to_push = {}
            object_to_push["tracking_indicator"] = Object.keys(control)[0]

            const rows_ = Object.values(control)[0].map(row => ({...object_to_push, ...row}))

            return acc.concat(rows_)
         }, [])

         setTable_cols( cols )
         setTable_data( rows )
      } else if (summary != null && Object.values(summary).length) { // RESUMEN
         // COLUMNAS
         const cols = [{
            Header: "Línea",
            accessor: "linea",
         },
         {
            Header: "Año de convocatoria",
            accessor: "anio_convocatoria",
         },
         {
            Header: "Categoría",
            accessor: "categoria",
         },
         {
            Header: "Revisión Cuenta Justificativa",
            accessor: "Revisión cuenta Justificativa - CYTSA",
         },
         {
            Header: "Revisión alegaciones al RS",
            accessor: "Borrador AIPR/I.Cierre - CYTSA",
         },
         {
            Header: "Revisión alegaciones al AIPR",
            accessor: "Borrador RR - CYTSA",
         },
         {
            Header: "Total actuaciones",
            accessor: "total",
         },
         {
            Header: "€ Importe",
            accessor: "billing",
         },
         {
            Header: "% Ejecución Contrato",
            accessor: "milestone",
         }]
         // FILAS
         const rows = Object.values(summary).map(el => {
            const objectToPush = {}
            
            objectToPush["linea"] = el.linea
            objectToPush["anio_convocatoria"] = el.anio_convocatoria
            objectToPush["categoria"] = el.categoria
            
            objectToPush["Revisión cuenta Justificativa - CYTSA"] = getTotalActionsStringify( el["Revisión cuenta Justificativa - CYTSA"], el.categoria )
            objectToPush["Borrador AIPR/I.Cierre - CYTSA"] = getTotalActionsStringify( el["Borrador AIPR/I.Cierre - CYTSA"], el.categoria )
            objectToPush["Borrador RR - CYTSA"] = getTotalActionsStringify( el["Borrador RR - CYTSA"], el.categoria )
            objectToPush["total"] = getTotalActionsStringify(
               {
                  total: el.total.total,
                  breakdown: el.total.breakdown
               },
               el.categoria
            )
            objectToPush["billing"] = `${ formatNumber(el.billing) } €`
            objectToPush["milestone"] = `${ formatNumber(el.milestone) }%`
            
            return objectToPush
         })

         setTable_cols( cols.concat({
            Header: " ",
            accessor: " ",
         }))
         setTable_data( rows )
      }
      
   }, [controls, summary])
   


   return (
      <GridContainer>
         <GridItem xs={12}>
            {
               !!table_data.length
               && 
               <ReactTable
                  columns={table_cols}
                  data={table_data}
                  numFilas={20}
                  ignoreLastCol={true}
               />
            }
         </GridItem>
      </GridContainer>
   )
}

const getTotalActionsStringify = ( actions, category ) => {
   let total = `${ actions.total } `
   if ( actions.breakdown != null ) {
      const breakdownEntries = Object.entries(actions.breakdown)
      if ( breakdownEntries.length === 1 && breakdownEntries[0][0] === category) return total

      breakdownEntries.forEach((entrie, i) => {
         if ( i === 0 ) total += "("
         total += `${ entrie[0] }: ${ entrie[1] }`
         if ( i !== breakdownEntries.length - 1) total += ", "
         else total += ")"
      })
   }

   return total
}


