import axios from 'axios'
import {
   WEEKLY_REPORT_REQUEST,
   WEEKLY_REPORT_SUCCESS,
   WEEKLY_REPORT_FAIL,
   MONTHLY_REPORT_REQUEST,
   MONTHLY_REPORT_SUCCESS,
   MONTHLY_REPORT_FAIL,
   PLANNING_REPORT_REQUEST,
   PLANNING_REPORT_SUCCESS,
   PLANNING_REPORT_FAIL,
   MONTHLY_BILLING_REPORT_REQUEST,
   MONTHLY_BILLING_REPORT_SUCCESS,
   MONTHLY_BILLING_REPORT_FAIL,
} from '../constants/reportConstants.js'
import * as dayjs from "dayjs"

export const getWeeklyReport = ({ block = 1, start_date = new Date("2022-05-03"), end_date = new Date() }) => async (dispatch, getState) => {

   try {
      dispatch({ type: WEEKLY_REPORT_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }
      const body = {
         block,
         start_date: dayjs(start_date).format("YYYY-MM-DD"),
         end_date: dayjs(end_date).format("YYYY-MM-DD")
      }

      const { data } = await axios.post(`/api/informes/informe-semanal/`, body , config)

      dispatch({ type: WEEKLY_REPORT_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: WEEKLY_REPORT_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getMonthlyReport = ({ block, start_date, end_date }) => async (dispatch, getState) => {

   try {
      dispatch({ type: MONTHLY_REPORT_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }
      const body = {
         block,
         start_month: start_date,
         end_month: end_date
      }

      const { data } = await axios.post(`/api/informes/informe-mensual/`, body, config)
      
      dispatch({ type: MONTHLY_REPORT_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: MONTHLY_REPORT_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getPlanningReport = ({ planning }) => async (dispatch, getState) => {

   try {
      dispatch({ type: PLANNING_REPORT_REQUEST })
      
      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }
      
      const { data } = await axios.post(`/api/informes/informe-planificacion/`, planning, config)

      dispatch({ type: PLANNING_REPORT_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: PLANNING_REPORT_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getMonthlyBillingReport = ({ block, start_date, end_date }) => async (dispatch, getState) => {

   try {
      dispatch({ type: MONTHLY_BILLING_REPORT_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }
      const body = {
         block,
         start_month: start_date,
         end_month: end_date
      }

      const { data } = await axios.post(`/api/informes/informe-facturacion-mensual/`, body, config)
      
      dispatch({ type: MONTHLY_BILLING_REPORT_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: MONTHLY_BILLING_REPORT_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}
