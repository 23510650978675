import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import styles from '../styles/tabPanelStyles'

const useStyles = makeStyles(styles)

const InfoActionModal = ({open, handleCloseModal, modalTitle, customWidth, children}) => {

   const classes = useStyles(styles)

   return (
      <Dialog
         classes={{
            root: classes.modalRoot,
            paper: customWidth ? '': classes.modal,
         }}
         maxWidth={customWidth} 
         fullWidth={!!customWidth}
         open={open}
         keepMounted
         onClose={handleCloseModal}
         aria-labelledby='update-modal-slide-title'
         aria-describedby='update-modal-slide-description'
      >
         <DialogTitle id='update-modal-slide-title' disableTypography className={classes.modalHeader}>
            <Button
               justIcon
               className={classes.modalCloseButton}
               key='close'
               aria-label='Close'
               color='transparent'
               onClick={handleCloseModal}
            >
               <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{modalTitle}</h4>
         </DialogTitle>

         <DialogContent id='update-modal-slide-description' className={classes.modalBody}>
            <GridItem xs={12}>{children}</GridItem>
            {/* Add error Snackbar to hire */}
         </DialogContent>
      </Dialog>
   )
}

export default InfoActionModal
