import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomModal from 'components/Modal/CustomModal'

const ChangeTabPanelUpdateModal = ({ open, setOpen, setTabIndex, setCanUpdate, setSuccessUpdate, newTabIndex }) => {
   const onConfirmHandler = () => {
      setTabIndex(newTabIndex)
      setOpen(false)
      setCanUpdate(false)
      setSuccessUpdate(false)
   }

   return (
      <CustomModal
         title='Cambiar Pestaña'
         open={open}
         setOpen={setOpen}
         acceptHandler={onConfirmHandler}
         acceptText='Cambiar Pestaña'
      >
         <GridContainer>
         <GridItem xs={12}>
            <h4>
               Al cambiar de pestaña se perderán las modificaciones realizadas
            </h4>
         </GridItem>
         </GridContainer>
      </CustomModal>
   )
}

export default ChangeTabPanelUpdateModal
