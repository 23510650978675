import {
   drawerWidth,
   drawerMiniWidth,
   transition,
   boxShadow,
   defaultFont,
   primaryColor,
   primaryBoxShadow,
   infoColor,
   successColor,
   warningColor,
   dangerColor,
   roseColor,
   whiteColor,
   blackColor,
   grayColor,
   hexToRgb,
 } from 'assets/jss/material-ui-react'

const styles = (theme) => ({
   cardIconTitle: {
     marginTop: '15px',
     marginBottom: '0px',
   },
   rootItem: {
     padding: '0 !important',
   },
   radioLabel: {
      color: grayColor[0],
      fontSize: "1.3rem",
      marginBottom: "10px",
      '&:focus': {
         color: grayColor[0],
      },
   },
   radioSelector: {
      fontSize: "1.2rem"
   }
 })
 
 export default styles
 