import axios from 'axios'
import {
  CATEGORY_REGISTER_REQUEST,
  CATEGORY_REGISTER_SUCCESS,
  CATEGORY_REGISTER_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
} from '../constants/categoryConstants.js'

export const registerCategory = (category) => async (dispatch, getState) => {
   try {
      dispatch({ type: CATEGORY_REGISTER_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.post('/api/categorias', category, config)

      dispatch({ type: CATEGORY_REGISTER_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: CATEGORY_REGISTER_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getCategories = () => async (dispatch, getState) => {
   try {
      dispatch({ type: CATEGORY_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get('/api/categorias', config)

      dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: CATEGORY_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const categoryUpdateInfo = (category) => async (dispatch, getState) => {
   try {
      dispatch({ type: CATEGORY_UPDATE_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.put(`/api/categorias/${category.id_tipo_categoria}`, category, config)

      dispatch({ type: CATEGORY_UPDATE_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: CATEGORY_UPDATE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const deleteCategory = (id) => async (dispatch, getState) => {
   try {
      dispatch({ type: CATEGORY_DELETE_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      await axios.delete(`/api/categorias/${id}`, config)

      dispatch({ type: CATEGORY_DELETE_SUCCESS })
   } catch (error) {
      dispatch({
         type: CATEGORY_DELETE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}
