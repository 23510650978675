
const styles = (theme) => ({
   cardIconTitle: {
     marginTop: '15px',
     marginBottom: '0px',
   },
   rootItem: {
     padding: '0 !important',
   },
 })
 
 export default styles
 