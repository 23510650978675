import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MONTHLY_REPORT_RESET } from 'redux/constants/reportConstants'
import { getMonthlyBillingReport } from 'redux/actions/reportActions.js'
import { makeStyles, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import DateFnsUtils from "@date-io/date-fns"
import { es } from "date-fns/locale"
import ExcelColumn from 'react-data-export/dist/ExcelPlugin/elements/ExcelColumn.js'
import ClipLoader from "react-spinners/ClipLoader"
import { Checkbox, ListItemText, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import ExcelFile from 'react-data-export/dist/ExcelPlugin/components/ExcelFile.js'

import styles from './styles/monthlyBillingReportScreenStyles'
import SnackbarContent from 'components/Snackbar/SnackbarContent.js'
import { TabPanelContainer } from './components/TabPanelContainer.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal.js'

import { formatNumber } from 'utils/formatNumber'
import { getPlanningLoads } from 'redux/actions/planningActions'
import { PLANNING_LOADS_LIST_RESET } from 'redux/constants/planningConstants'
import { MONTHLY_BILLING_REPORT_RESET } from 'redux/constants/reportConstants'

const useStyles = makeStyles(styles)


const MonthlyBillingReportScreen = () => {
   const dispatch = useDispatch()
   const classes = useStyles()

   const { loadingMonthlyBillingReport, successMonthlyBillingReport, errorMonthlyBillingReport, monthlyBillingReport } = useSelector(
      (state) => state.reportListMonthlyBilling
   )

   const { loadingLoadsList, successLoadsList, errorLoadsList, loads } = useSelector(
      (state) => state.planningLoadsList
   )

   const [blocks, setBlocks] = useState(false)
   const [reportData, setReportData] = useState(null)
   const [filterDates, setFilterDates] = useState({
      start_date: undefined,
      end_date: undefined,
   })
   const [error_block, setError_block] = useState(false)
   const [error_startDate, setError_startDate] = useState(false)
   const [showData, setShowData] = useState(false)
   const [excel, setExcel] = useState(false)
   const [downloadExcel, setDownloadExcel] = useState(false)

   useEffect(() => {
      if ( monthlyBillingReport != null && successMonthlyBillingReport ) {
         setShowData(false)
         
         setReportData(monthlyBillingReport)
         monthlyBillingReport.list.forEach(control => {
            const cont = Object.values(control)[0]

            if ( cont.length ) setShowData(true)
         })
      }
      
   }, [monthlyBillingReport])


   useEffect(() => {

      dispatch( getPlanningLoads() )

      return () => {
         dispatch({ type: MONTHLY_BILLING_REPORT_RESET })
         dispatch({ type: PLANNING_LOADS_LIST_RESET })
         setReportData(null)
      }
   }, [])
   


   // funciones

   const changeBlockSelectHandler = (e) => {
      const newBlocksValues = e.target.value

      setError_block(false)
      setBlocks(newBlocksValues)
   }

   const onSearchClickHandler = () => {
      if (!blocks) {
         setError_block("Introduce el bloque antes de realizar la búsqueda")
         return
      }

      if (filterDates.start_date == null) {
         setError_startDate("Introduce una fecha de inicio antes de realizar la búsqueda")
         return
      }

      setReportData(null)

      dispatch(getMonthlyBillingReport({
         block: blocks,
         start_date: filterDates.start_date,
         end_date: filterDates.end_date,
      }))
   }

   const onChangeStartDate = (newVal) => {
      const newValDate = new Date(newVal)
      const newValDateString = `${ newValDate.getFullYear() }-${ newValDate.getMonth() < 9 ? "0"+(newValDate.getMonth()+1) : (newValDate.getMonth()+1) }`

      if ( filterDates.end_date == null || new Date(`${ newValDateString }-01`) > new Date(`${ filterDates.end_date }-01`) ) {
         setFilterDates({start_date: newValDateString, end_date: newValDateString})
      }else {
         setFilterDates(last => ({...last, start_date: newValDateString}))
      }
      setError_startDate(false)
   }

   const onChangeEndDate = (newVal) => {
      const newValDate = new Date(newVal)
      const newValDateString = `${ newValDate.getFullYear() }-${ newValDate.getMonth() < 9 ? "0"+(newValDate.getMonth()+1) : (newValDate.getMonth()+1) }`

      if ( filterDates.start_date == null || new Date(`${ newValDateString }-01`) < new Date(`${ filterDates.start_date }-01`) ) {
         setFilterDates({start_date: newValDateString, end_date: newValDateString})
      }else {
         setFilterDates(last => ({...last, end_date: newValDateString}))
      }
   }


   if ( loadingLoadsList || loads == null || !loads.length ) {
      return ( 
         <>
         Cargando... 
         <div 
            style={{ marginRight: "10px", display: "flex", justifyContent: "center", marginTop: "20px" }}
         >
            <ClipLoader
               color="#041f24"
               size={50}            
            /> 
         </div>
         </>
      )
   }


   return (
   <>
   <Card>
      <CardBody>
         <GridContainer>
            {/* SELECTOR BLOQUE */}
            <GridItem xs={3}>            
               <FormControl fullWidth>
                  <InputLabel id="select-block-checkbox" htmlFor='select-bloque'>Número de Carga de Datos</InputLabel>
                  <Select
                     labelId="select-block-checkbox"
                     MenuProps={{
                        className: classes.selectMenu,
                     }}
                     // multiple
                     className={classes.select}
                     value={blocks}
                     onChange={changeBlockSelectHandler}
                     error={!blocks}
                     // renderValue={(selected) => selected.join(", ")}
                     renderValue={(selected) => selected}
                     inputProps={{
                        name: 'select-bloque',
                        id: 'select-bloque',
                        required: true,
                     }}
                  >
                     <MenuItem
                        disabled
                        classes={{
                           root: classes.selectMenuItem,
                        }}
                     >
                        Selecciona un Número de Carga de Datos
                     </MenuItem>
                     {
                        loads.map((load, index) => {
                           const value = ""+load.numero_carga

                           return (
                              <MenuItem
                                 value={value}
                                 key={`select_block_checkbox_${ index }`}
                                 classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                              >
                                 {/* <Checkbox checked={blocks.indexOf(value) > -1} /> */}
                                 <ListItemText primary={value} />
                              </MenuItem>
                           )
                        })
                     }
                  </Select>
               </FormControl>
            </GridItem>

            {/* SELECTOR SEMANAS - INICIO */}
            <GridItem xs={3}>
               <LocalizationProvider 
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label={"Seleccione la fecha de inicio"}
                     inputFormat="MM-yyyy"
                     views={["month", "year"]}
                     value={filterDates.start_date ?? null}
                     onChange={onChangeStartDate}
                     maxDate={new Date()}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%"}}
                           InputLabelProps={{ shrink: true }}
                           {...params}
                           error={error_startDate || filterDates.start_date == null}
                        />
                     }}
                                          
                  />
               </LocalizationProvider>
            </GridItem>

            {/* SELECTOR SEMANAS - FIN */}
            <GridItem xs={3}>
               <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label={"Seleccione la fecha de fin"}
                     inputFormat="MM-yyyy"
                     views={["month", "year"]}
                     value={filterDates.end_date ?? null}
                     onChange={onChangeEndDate}
                     maxDate={new Date()}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%"}}
                           InputLabelProps={{ shrink: true }}
                           {...params}
                           error={false}
                        />
                     }}
                     
                     required={false}
                  />
               </LocalizationProvider>
            </GridItem>
            
            {/* BUSCAR */}
            <GridItem
               xs={3}
               style={{ display: "flex", alignItems: "center" }}
            >
               <Button
                  // style={{ width: "100%", height: "100%", cursor: "pointer" }}
                  fullWidth
                  onClick={onSearchClickHandler}
                  type='submit' 
                  color='primary'
               >
                  {
                     loadingMonthlyBillingReport 
                     ?  <>
                        <span style={{ marginRight: "10px" }}>
                           <ClipLoader 
                              color="#041f24"
                              size={20}            
                           /> 
                        </span>
                        Buscando...
                        </>
                     : "Buscar"
                  }
               </Button>
            </GridItem>
         </GridContainer>
      </CardBody>
   </Card>

           
   {
      successMonthlyBillingReport && !showData
      ? (
         <Card>
            <CardBody>
               <GridContainer>
                  <GridItem xs={12}>
                     No hay información para los filtros seleccionados.
                  </GridItem>              
               </GridContainer>
            </CardBody>
         </Card>
      )
      : null
   }
   
   {
   successMonthlyBillingReport && showData && reportData != null
   && <Card>
         <CardBody>
            <GridContainer>
               <GridItem xs={12}>
                  <TabPanelContainer report={JSON.parse(JSON.stringify(reportData))} />
               </GridItem>

               <GridItem xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                     {
                        excel
                        && (
                        <ExcelFile
                           element={<Button color='primary'>Exportar Excel</Button>}
                           filename='Facturación mensual'
                           hideElement={true}
                        >
                           {
                              reportData.list.length
                              && getControlsExcelSheet(reportData.list)
                           }                        
                           {
                              reportData.list.length
                              && getSummaryExcelSheet(reportData.summary)
                           }                        
                        </ExcelFile>
                        )
                     }
                     <Button 
                        color='primary'
                        onClick={() =>setDownloadExcel(true)}
                        style={{ marginLeft: '10px' }}
                     >
                        Exportar a EXCEL
                     </Button>
                  </div>
               </GridItem>
            </GridContainer>
         </CardBody>
      </Card>
   }
   {/* ERRORES RESULTADO BÚSQUEDA */}
   {
      (errorMonthlyBillingReport != null && errorMonthlyBillingReport)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={errorMonthlyBillingReport || errorMonthlyBillingReport.message} color='danger' />
         </GridItem>
      )
   }
   {/* ERRORES CAMPOS */}
   {
      (error_block)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={error_block} color='danger' />
         </GridItem>
      )
   }
   {
      (error_startDate)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={error_startDate} color='danger' />
         </GridItem>
      )
   }
   {/* EXCEL */}
   {
      downloadExcel
      && <DownloadConfirmModal
            downloadFile={downloadExcel}
            setDownloadFile={setDownloadExcel}
            setFile={setExcel}
            tableName='Seguimiento mensual'
            FileExtension='Excel'
         />      
   }
   </>
   )
}

const getControlsExcelSheet = ( controls ) => {
   
   let isData = false
   controls.forEach(control => {
      if ( Object.values(control)[0].length ) isData = true
   })

   if ( !isData ) {
      return   <ExcelFile.ExcelSheet data={[{resultado: "No hay controladores para la búsqueda realizada"}]} name="Controladores">
                  <ExcelColumn label="" value="resultado"/>
               </ExcelFile.ExcelSheet> 
   }
      
   // ============================================================================
   // ======================            COLUMNAS             =====================
   // ============================================================================
   const create_col = ({title, width = 15}) => {
      
      return {
         title,
         width: {wch: width},
         style: {
            fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "12", bold: true},
            alignment: {horizontal: "center", vertical: "center", wrapText: true}
         },
      }
   }

   const cols = [
      create_col({ title: "Técnico AEI" }),
      create_col({ title: "Indicador de Seguimiento", width: 30 }),
      create_col({ title: "Referencia Solicitud", width: 20 }),
      create_col({ title: "Línea" , width: 30}),
      create_col({ title: "Año Convocatoria" }),
      create_col({ title: "Categoría" }),
      create_col({ title: "Actuación" }),
      create_col({ title: "Mes" }),
   ]


   // ============================================================================
   // =======================            FILAS             =======================
   // ============================================================================
   const create_col_row = ({value}) => {
      return {
         value,
         style: {
           border: {
              top: {
                 style: "medium",
                 color: {rgb: "111111"}
              },
              bottom: {
                 style: "medium",
                 color: {rgb: "111111"}
              },
              left: {style: "medium", color: {rgb: "111111"}},
              right: {style: "medium", color: {rgb: "111111"}},
           },
           font: {sz: "10", bold: false},
           alignment: {horizontal: "center", vertical: "center", wrapText: true},
        }
      }
   }
    

   const rows = controls.reduce((acc, control_group, i) => {

      const cg_name = Object.keys(control_group)[0]
      const cg_data = Object.values(control_group)[0]

      if ( !cg_data.length ) return acc
      
      const cg_rows = cg_data.map((row, i) => {
         const header_col_row = create_col_row({value: cg_name})
         const rowCopy = Object.values(row)
         
         rowCopy.splice(0, 0, rowCopy.splice(rowCopy.length - 1, 1)[0])
         
         const excel_row = rowCopy.map(col => {
            if ( col == null ) col = ""
            return create_col_row({value: ""+col})
         })
         
         excel_row.splice(1, 0, header_col_row)
         
         return excel_row
      })

      return acc.concat(cg_rows)
      
   }, [])
   
   const multiDataSet = [{
      columns: cols,
      data: rows,
   }]

   return (
      <ExcelFile.ExcelSheet dataSet={multiDataSet} name="Controles" />
   )                   
}

const getSummaryExcelSheet = ( summary ) => {

   const sum = Object.values(summary)

   if ( !sum.length ) {
      return   <ExcelFile.ExcelSheet data={[{resultado: "No hay información para la búsqueda realizada"}]} name="Resumen">
                  <ExcelColumn label="" value="resultado"/>
               </ExcelFile.ExcelSheet> 
   }
      
   // ============================================================================
   // ======================            COLUMNAS             =====================
   // ============================================================================
   const create_col = ({title, width = 15}) => {
      
      return {
         title,
         width: {wch: width},
         style: {
            fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "12", bold: true},
            alignment: {horizontal: "center", vertical: "center", wrapText: true}
         },
      }
   }

   const cols = [
      create_col({ title: "Líneas", width: 60 }),
      create_col({ title: "Año de convocatoria" }),
      create_col({ title: "Categoría" }),
      create_col({ title: "Revisión cuenta Justificativa", width: 25 }),
      create_col({ title: "Revisión Alegaciones Requerimiento Subsanación", width: 25 }),
      create_col({ title: "Revisión Alegaciones Acuerdo de Inicio", width: 25 }),
      create_col({ title: "Total actuaciones", width: 35 }),
      create_col({ title: "€ Importe" }),
      create_col({ title: "% Ejecución Contrato" }),
   ]


   // ============================================================================
   // =======================            FILAS             =======================
   // ============================================================================
   const create_col_row = ({value, border, bold}) => {
      
      return {
         value,
         style: {
            border: {
               top: {
                  style: border != null && border.includes("top") ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               bottom: {
                  style: border != null && border.includes("bottom") ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               left: {style: border != null && border.includes("left") ? "thick" : "medium", color: {rgb: "111111"}},
               right: {style: border != null && border.includes("right") ? "thick" : "medium", color: {rgb: "111111"}},
            },
            font: {sz: "10", bold: (bold == null ? false : bold)},
            alignment: {horizontal: "center", vertical: "center", wrapText: true},
         }
      }
   }
    
   let total_cj_rs_is = {}
   let total_ra_rs_aipr_ic = {}
   let total_ra_aipr_rr = {}
   let total_actuations = {}
   let total_billing = 0
   let total_milestone = 0
   const rows = sum.map((row, index) => {

      let actualCategory = ""
      return Object.entries(row).map((col, i) => {

         let value = col[1]
         let border = []
         if ( i === 0 || i === Object.entries(row).length - 1) border.push("right")
         if ( index === sum.length - 1 ) border.push("bottom")
         if ( col[0] === "categoria" ) actualCategory = col[1]

         if ( col[0] === "Revisión cuenta Justificativa" ) {
            // formatNumber(total_cj_rs_is += col[1])
            value = getTotalActionsStringify(col[1], actualCategory)
            total_cj_rs_is = sumActions(total_cj_rs_is, col[1], actualCategory)
         }
         if ( col[0] === "Revisión Alegaciones Requerimiento Subsanación" ) {
            // formatNumber(total_ra_rs_aipr_ic += col[1])
            value = getTotalActionsStringify(col[1], actualCategory)
            total_ra_rs_aipr_ic = sumActions(total_ra_rs_aipr_ic, col[1], actualCategory)
         }
         if ( col[0] === "Revisión Alegaciones Acuerdo de Inicio" ) {
            // formatNumber(total_ra_aipr_rr += col[1])
            value = getTotalActionsStringify(col[1], actualCategory)
            total_ra_aipr_rr = sumActions(total_ra_aipr_rr, col[1], actualCategory)
         }
         if ( col[0] === "total" ) {
            // formatNumber(total_actuations += col[1])
            value = getTotalActionsStringify(
               {
                  total: col[1].total,
                  breakdown: col[1].breakdown
               },
               actualCategory
            )
            total_actuations = sumActions(
               total_actuations,
               {
                  total: col[1].total,
                  breakdown: col[1].breakdown
               },
               actualCategory
            )
         }
         if ( col[0] === "billing" ) {
            total_billing += col[1]
            value = `${ formatNumber(col[1])} €`
         }
         if ( col[0] === "milestone" ) {
            total_milestone += col[1]
            value = `${ formatNumber(col[1])}%`
         }

         return create_col_row({value, border})
      })
      
   })

   total_milestone = Number(( (100 * total_billing) / 877900 ).toFixed(2))
   const last_row = [
      create_col_row({ value: "TOTAL", border: ["bottom", "right"], bold: true }),
      create_col_row({ value: "", border: ["bottom"], bold: true  }),
      create_col_row({ value: "", border: ["bottom"], bold: true  }),
      create_col_row({ value: getTotalActionsStringify(total_cj_rs_is), border: ["bottom"], bold: true  }),
      create_col_row({ value: getTotalActionsStringify(total_ra_rs_aipr_ic), border: ["bottom"], bold: true  }),
      create_col_row({ value: getTotalActionsStringify(total_ra_aipr_rr), border: ["bottom"], bold: true  }),
      create_col_row({ value: getTotalActionsStringify(total_actuations), border: ["bottom"], bold: true  }),
      create_col_row({ value: `${ formatNumber(total_billing)} €`, border: ["bottom"], bold: true  }),
      create_col_row({ value: `${ formatNumber(total_milestone)}%`, border: ["bottom"], bold: true  }),
   ]

   rows.push(last_row)
   
   const multiDataSet = [{
      columns: cols,
      data: rows,
   }]

   return (
      <ExcelFile.ExcelSheet dataSet={multiDataSet} name="Resumen" />
   )                   
}

const getTotalActionsStringify = ( actions, category = "" ) => {
   let total = `${ actions.total } `
   if ( actions.breakdown != null ) {
      const breakdownEntries = Object.entries(actions.breakdown)
      if ( breakdownEntries.length === 1 && breakdownEntries[0][0] === category) return total
      breakdownEntries.forEach((entrie, i) => {
         if ( i === 0 ) total += "("
         total += `${ entrie[0] }: ${ entrie[1] }`
         if ( i !== breakdownEntries.length - 1) total += ", "
         else total += ")"
      })
   }

   return total
}

const sumActions = ( curr, next ) => {
   if ( !Object.keys(curr).length ) return next
  
   if ( next.total != null ) curr.total = curr.total + next.total
   if ( next.breakdown != null ) {
      if ( curr.breakdown == null ) curr["breakdown"] = {}

      const breakdownEntries = Object.entries(next.breakdown)
      breakdownEntries.forEach((entrie => {

         if ( curr["breakdown"][`${ entrie[0] }`] == null ) {
            curr["breakdown"][`${ entrie[0] }`] = entrie[1]
         }else {
            curr["breakdown"][`${ entrie[0] }`] = curr["breakdown"][`${ entrie[0] }`] + entrie[1]
         }
      }))
   }
   
   return curr
}



export default MonthlyBillingReportScreen
