import { combineReducers } from "redux"

import {
   userLoginReducer,
   userGetProfileInfoeReducer,
   refreshTokenReducer,
   userListByProfileIdReducer,
   userListByRoleNameReducer,
   technicianListReducer,
} from "./userReducers"

import {
   profileDeleteReducer,
   profileListReducer,
   profileRegisterReducer,
   profileUpdateReducer,
} from "./profileReducer"

import {
   profileToManagerDeleteReducer,
   profileToManagerListReducer,
   profileToManagerRegisterReducer,
   profileToManagerUpdateReducer,
} from "./profileToManagerReducer"

import {
   actDeleteReducer,
   actListReducer,
   actRegisterReducer,
   actUpdateReducer,
} from "./actReducer"

import {
   userlistDeleteReducer,
   userlistListReducer,
   userlistRegisterReducer,
   userlistUpdateReducer,
   userlistResetPasswordReducer,
   userlistChangePasswordReducer,
} from "./userlistReducer"

import {
   categoryDeleteReducer,
   categoryListReducer,
   categoryRegisterReducer,
   categoryUpdateReducer,
} from "./categoryReducer"

import {  
   workListReducer,
   workControlReducer,
   workControlActListReducer,
   workRuleListReducer,
   workUpdateControlActsReducer,
   workUpdateReducer,
   workValidatorReducer,
} from "./workReducer"

import {
   linesRegisterReducer,
} from "./importLinesReducer"

import {
   actividadListReducer,
   actividadUpdateReducer,
} from "./actividadReducer"

import {
   reportListWeeklyReducer,
   reportListMonthlyReducer,
   reportListPlanningReducer,
   reportListMonthlyBillingReducer,
} from "./reportReducer"

import {
   planningListReducer,
   planningRegisterReducer,
   planningUpdateReducer,
   planningDeleteReducer,
   planningLinesListReducer,
   planningIndicatorsListReducer,
   planningLoadsListReducer,
} from "./planningReducer"

import {
   techniciansTrackingListReducer,
} from "./trackingReducer"

export default combineReducers({  
   userLogin: userLoginReducer,
   userProfileInfo: userGetProfileInfoeReducer,
   refreshToken: refreshTokenReducer,
   userListByProfileId: userListByProfileIdReducer,
   userListByRoleName: userListByRoleNameReducer,
   technicianList: technicianListReducer,
   profileDelete: profileDeleteReducer,
   profileList: profileListReducer,
   profileRegister: profileRegisterReducer,
   profileToManagerDelete: profileToManagerDeleteReducer,
   profileToManagerList: profileToManagerListReducer,
   profileToManagerRegister: profileToManagerRegisterReducer,
   profileToManagerUpdate: profileToManagerUpdateReducer,
   profileUpdate: profileUpdateReducer,
   actDelete: actDeleteReducer,
   actList: actListReducer,
   actRegister: actRegisterReducer,
   actUpdate: actUpdateReducer,
   userlistDelete: userlistDeleteReducer,
   userlistList: userlistListReducer,
   userlistRegister: userlistRegisterReducer,
   userlistUpdate: userlistUpdateReducer,
   userlistResetPassword: userlistResetPasswordReducer,
   userlistChangePassword: userlistChangePasswordReducer,
   categoryDelete: categoryDeleteReducer,
   categoryList: categoryListReducer,
   categoryRegister: categoryRegisterReducer,
   categoryUpdate: categoryUpdateReducer,  
   workList: workListReducer,
   workControl: workControlReducer,
   workControlActList: workControlActListReducer,
   workRuleList: workRuleListReducer,
   workUpdateControlActs: workUpdateControlActsReducer,
   workUpdate: workUpdateReducer,
   workValidator: workValidatorReducer,
   linesRegister: linesRegisterReducer,
   actividadList: actividadListReducer,
   actividadUpdate: actividadUpdateReducer,
   reportListWeekly: reportListWeeklyReducer,
   reportListMonthly: reportListMonthlyReducer,
   reportListPlanning: reportListPlanningReducer,
   reportListMonthlyBilling: reportListMonthlyBillingReducer,
   planningList: planningListReducer,
   planningRegister: planningRegisterReducer,
   planningUpdate: planningUpdateReducer,
   planningDelete: planningDeleteReducer,
   planningLinesList: planningLinesListReducer,
   planningIndicatorsList: planningIndicatorsListReducer,
   techniciansTrackingList: techniciansTrackingListReducer,
   planningLoadsList: planningLoadsListReducer,
})
