import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, makeStyles, InputLabel, Select, MenuItem, TextField } from '@material-ui/core'
import { ListItemText } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import DateFnsUtils from "@date-io/date-fns"
import SweetAlert from 'react-bootstrap-sweetalert'
import { es } from "date-fns/locale"
import AddBoxIcon from '@mui/icons-material/AddBox'
import * as dayjs from "dayjs"

import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import CardHeader from 'components/Card/CardHeader'

import { PLANNING_LINES_LIST_RESET, PLANNING_INDICATORS_LIST_RESET, PLANNING_LIST_RESET, PLANNING_LOADS_LIST_RESET } from 'redux/constants/planningConstants'
import { CATEGORY_LIST_RESET } from 'redux/constants/categoryConstants'
import { getPlanningLines, getPlanningIndicators, getPlanningLoads, registerPlanning, updatePlanning } from 'redux/actions/planningActions.js'
import { getCategories } from 'redux/actions/categoryActions'
import styles from '../styles/milestoneStyles'
import { SubMilestone } from './SubMilestone'


const useStyles = makeStyles(styles)

export const Milestone = ({update, view, planning, handleCloseModal, setUpdateError}) => {

   const dispatch = useDispatch()
   const classes = useStyles()

   const { loadingPlanningsLinesList, successPlanningsLinesList, errorPlanningsLinesList, planningsLines } = useSelector(
      (state) => state.planningLinesList
   )

   const { loadingPlanningIndicatorsList, successPlanningIndicatorsList, errorPlanningIndicatorsList, planningsIndicators } = useSelector(
      (state) => state.planningIndicatorsList
   )

   const { loadingPlanningRegister, successPlanningRegister, errorPlanningRegister, planningRegistered } = useSelector(
      (state) => state.planningRegister
   )

   const { loadingCategoryList, successCategoryList, errorCategoryList, categories } = useSelector(
      (state) => state.categoryList
   )

   const { loadingLoadsList, successLoadsList, errorLoadsList, loads } = useSelector(
      (state) => state.planningLoadsList
   )

   // Milestone data
   const [p_name, setP_name] = useState("")
   const [p_percentage, setP_percentage] = useState(0)
   const [p_startDate, setP_startDate] = useState(null)
   const [p_endDate, setP_endDate] = useState(null)
   const [p_block, setP_block] = useState(null)
   const [subMilestones, setSubMilestones] = useState([1])
   const [registerError, setRegisterError] = useState("")
   const [originalPlanning, setOriginalPlanning] = useState(update != null ? JSON.parse(JSON.stringify(planning)) : null)
   const [isAllSubmilestoneRegistered, setIsAllSubmilestoneRegistered] = useState(false)
   // extra
   const [isGlobal, setIsGlobal] = useState(false)
   const [registerAlert, setRegisterAlert] = useState(false)
   const [showRegisterAlert, setShowRegisterAlert] = useState(false)
   const [updateAlert, setUpdateAlert] = useState(false)
   const [showUpdateAlert, setShowUpdateAlert] = useState(false)
   const [showUpdate, setShowUpdate] = useState(false)


   useEffect(() => {

      if (planning != null) {
         setP_name(planning.general_data.nombre)
         setP_percentage(planning.general_data.porcentaje_ejecucion)
         setP_block(planning.general_data.bloque)
         setP_startDate(planning.general_data.fecha_inicio)
         setP_endDate(planning.general_data.fecha_fin)
         setSubMilestones(planning.submilestones)
      }

      dispatch(getPlanningLines())
      dispatch(getPlanningIndicators())
      dispatch(getPlanningLoads())
      dispatch(getCategories())

      return () => {
         if (view == null) {
            dispatch({ type: PLANNING_LINES_LIST_RESET })
            dispatch({ type: PLANNING_INDICATORS_LIST_RESET })
            dispatch({ type: PLANNING_LOADS_LIST_RESET })
            dispatch({ type: CATEGORY_LIST_RESET })
         }
      }
   }, [planning])

   useEffect(() => {
      return () => {
         if (view == null) {
            dispatch({ type: PLANNING_LINES_LIST_RESET })
            dispatch({ type: PLANNING_INDICATORS_LIST_RESET })
            dispatch({ type: PLANNING_LOADS_LIST_RESET })
            dispatch({ type: CATEGORY_LIST_RESET })
         }
      }
   }, [])

   useEffect(() => {
      
      const actualSubmilestones = subMilestones.filter(sm => isNaN(sm))
      
      if (actualSubmilestones != null && actualSubmilestones.every(sm => sm?.submilestone?.general_data?.isRegistered)) {
         setIsAllSubmilestoneRegistered(true)
      }else {
         setIsAllSubmilestoneRegistered(false)
      }
   }, [subMilestones])

   useEffect(() => {
      if ( showRegisterAlert ) {

         setRegisterAlert(
            <SweetAlert
               type={"success"}
               style={{ display: 'block', marginTop: '-100px' }}
               title='Enhorabuena'
               onConfirm={() => confirmSuccess()}
               confirmBtnCssClass={classes.confirmBtnCssClass}
            >
               <p>Planificación <span style={{fontWeight: "bold"}}>"{ p_name }"</span> registrada con éxito</p>           
            </SweetAlert>
         )
      }else if ( showUpdateAlert ) {

         setUpdateAlert(
            <SweetAlert
               type={"success"}
               style={{ display: 'block', marginTop: '-100px' }}
               title='Enhorabuena'
               onConfirm={() => confirmSuccess()}
               confirmBtnCssClass={classes.confirmBtnCssClass}
            >
               <p>Planificación <span style={{fontWeight: "bold"}}>"{ p_name }"</span> actualizada con éxito</p>           
            </SweetAlert>
         )
      }
   }, [showRegisterAlert, showUpdateAlert])

   // functions
   const addMilestoneHandler = () => {

      if (!milestoneValidation()) return

      const general_data = {
         nombre: p_name,
         fecha_inicio: dayjs(new Date(p_startDate)).format("YYYY-MM-DD"),
         fecha_fin: dayjs(new Date(p_endDate)).format("YYYY-MM-DD"),
         porcentaje_ejecucion: Number(p_percentage),
         bloque: Number(p_block),
      }

      const filteredSubMilstones = subMilestones.map(sm => {
         const lines = sm.submilestone.lines.filter(line => isNaN(line))
         sm.submilestone.lines = lines

         return sm
      })

      dispatch(registerPlanning({
         planning: {
            general_data,
            submilestones: filteredSubMilstones
         }
      }))

      setShowRegisterAlert(true)
   }

   const updateMilestoneHandler = () => {
      
      if (!milestoneValidation()) return

      const general_data = {
         id_planificacion: planning.general_data.id_planificacion,
         nombre: p_name,
         fecha_inicio: dayjs(new Date(p_startDate)).format("YYYY-MM-DD"),
         fecha_fin: dayjs(new Date(p_endDate)).format("YYYY-MM-DD"),
         porcentaje_ejecucion: Number(p_percentage),
      }

      const filteredSubMilstones = subMilestones.filter(sm => isNaN(sm) && sm.submilestone.general_data.isRegistered)
         .map((sm, i) => {
            const lines = sm.submilestone.lines.filter(line => isNaN(line))
            sm.submilestone.lines = lines
            sm.submilestone.general_data.id_hito_planificacion = originalPlanning.submilestones[i] == null 
               ? null
               : originalPlanning.submilestones[i].submilestone.general_data.id_hito_planificacion

            return sm
         })

      dispatch(updatePlanning({
         general_data,
         submilestones: filteredSubMilstones
      }))

      setShowUpdateAlert(true)
   }

   const addSubMilestonesHandler = () => {

      setSubMilestones(last => {
         return last.concat([1])
      })
   }

   const onChangeStartDateHandler = (newVal) => {
      if ( p_endDate == null || newVal > p_endDate ) {
         setP_startDate(dayjs(newVal).format("YYYY-MM-DD"))
         setP_endDate(dayjs(newVal).format("YYYY-MM-DD"))
      }else {
         setP_startDate(dayjs(newVal).format("YYYY-MM-DD"))
      }
      setShowUpdate(true)
   }
   
   const onChangeEndDateHandler = (newVal) => {
      if ( p_startDate == null || newVal < p_startDate) {
         setP_startDate(dayjs(newVal).format("YYYY-MM-DD"))
         setP_endDate(dayjs(newVal.format("YYYY-MM-DD")))
      }else {
         setP_endDate(dayjs(newVal).format("YYYY-MM-DD"))
      }
      setShowUpdate(true)
   }

   const onChangePercentageHandler = (newVal) => {

      if (!isNaN(newVal)) setP_percentage(Number(Number(newVal).toFixed(2)))
      setShowUpdate(true)
   }

   const onChangeNameHandler = (newVal) => {
      setP_name(newVal)
      setShowUpdate(true)
   }

   const changeBlockSelectHandler = (e) => {
      const newBlock = e.target.value
      setP_block(newBlock)
   }

   const confirmSuccess = () => {

      setShowRegisterAlert(false)
      setShowUpdateAlert(false)
      setRegisterAlert(false)
      setUpdateAlert(false)
      dispatch({ type: PLANNING_LIST_RESET })
      dispatch({ type: PLANNING_LINES_LIST_RESET })
      dispatch({ type: PLANNING_INDICATORS_LIST_RESET })
      dispatch({ type: CATEGORY_LIST_RESET })
      if (!update) resetAllHandler()
      if (handleCloseModal != null) handleCloseModal()

      // topPlace.current.scrollIntoView()
   }
   
   const milestoneValidation = () => {

      if (p_name.length == 0) {
         if (update) setUpdateError("PLANIFICACIÓN - El nombre de la planificación es obligatorio")
         else setRegisterError("PLANIFICACIÓN - El nombre del planificación es obligatorio")
         return false
      }else if (p_startDate == null) {
         if (update) setUpdateError("PLANIFICACIÓN - La fecha de inicio es obligatoria")
         else setRegisterError("PLANIFICACIÓN - La fecha de inicio es obligatoria")
         return false
      }else if (p_endDate == null) {
         if (update) setUpdateError("PLANIFICACIÓN - La fecha de fin es obligatoria")
         else setRegisterError("PLANIFICACIÓN - La fecha de fin es obligatoria")
         return false
      }else if (p_percentage == 0) {
         if (update) setUpdateError("PLANIFICACIÓN - El porcentaje de ejecución es obligatorio")
         else setRegisterError("PLANIFICACIÓN - El porcentaje de ejecución es obligatorio")
         return false
      }else if (p_percentage > 100) {
         if (update) setUpdateError("PLANIFICACIÓN - El porcentaje de ejecución no puede ser mayor a 100")
         else setRegisterError("PLANIFICACIÓN - El porcentaje de ejecución no puede ser mayor a 100")
         return false
      }else if (p_percentage < 0) {
         if (update) setUpdateError("PLANIFICACIÓN - El porcentaje de ejecución no puede ser menor a 0")
         else setRegisterError("PLANIFICACIÓN - El porcentaje de ejecución no puede ser menor a 0")
         return false
      }else if (p_block === 0 || p_block == null) {
         if (update) setUpdateError("PLANIFICACIÓN - El número de carga de datos es obligatorio")
         else setRegisterError("PLANIFICACIÓN - El número de carga de datos es obligatorio")
         return false
      }else if (subMilestones.length == 0 || subMilestones.length === 1 && subMilestones[0] === 1) {
         if (update) setUpdateError("PLANIFICACIÓN - Debe haber al menos un periodo registrado")
         else setRegisterError("PLANIFICACIÓN - Debe haber al menos un periodo registrado")
         return false
      }

      if (update) {
         
         if (subMilestones.filter(submilestone => isNaN(submilestone))
            .every(submilestone => {
               const sm_ = submilestone.submilestone
               if (sm_.general_data.wasUpdated) return true
               return false      
            }
         )) {
            setUpdateError("PLANIFICACIÓN - Debe haber al menos un periodo registrado")
            return false
         }
         
         subMilestones.filter(submilestone => isNaN(submilestone))
            .map((submilestone, index) => {
               const sm = submilestone.submilestone
            
               let errLineIndex = 1
               if (sm.general_data.fecha_inicio == null || sm.general_data.fecha_fin == null) {
                  setUpdateError(`PERIODO (${index + 1}) - Debe seleccionar una fecha de inicio y una de fin`)
                  return false
               }else if (sm.general_data.fecha_inicio > sm.general_data.fecha_fin) { //
                  setUpdateError(`PERIODO (${index + 1}) - La fecha de inicio no puede ser mayor a la fecha de fin`)
                  return false
               }else if (sm.lines.length == 0) { //
                  setUpdateError(`PERIODO (${index + 1}) - Debe agregar al menos una línea`)
                  return false
               }else if (sm.general_data.porcentaje_ejecucion > 100) {
                  setUpdateError(`PERIODO (${index + 1}) - El porcentaje de ejecución no puede ser mayor a 100`)
                  return false
               }else if (containsDuplicates(sm.lines.filter(line => line.estructura !== " - 0 - ")
                  .map(line => line.estructura))) {
                     setUpdateError(`PERIODO (${index + 1}) - No puede haber dos líneas con la misma estructura`)
                     return false
               }else if (sm.lines.filter(line => isNaN(line))
                  .some(line => {
                     if (line.indicadores.some(ind => ind.executionPercentage > 100)) {
                        errLineIndex = line.index
                        return true
                     }
               })) {
                  setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El porcentaje de ejecución cualquier indicador no puede ser mayor a 100`)
                  return false
               }else if (sm.lines.filter(line => isNaN(line))
                  .some(line => {
                  if (line.indicadores.some(ind => ind.executionPercentage < 0)) {
                     errLineIndex = line.index
                     return true
                  }   
               })) { //
                  setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El porcentaje de ejecución cualquier indicador no puede ser menor a 0`)
                  return false
               }else if (sm.lines.filter(line => isNaN(line))
                  .some(line => {
                  if (!line.linea || line.linea == null) {
                     errLineIndex = line.index
                     return true
                  }
                  return false
               })){
                  setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - No se ha seleccionado la estructura de la línea`)
                  return false
               }else if (sm.lines.filter(line => isNaN(line))
                  .some(line => {
                  if (line.indicadores.every(ind => Number(ind.totalActions) === 0)) {
                     errLineIndex = line.index
                     return true
                  }
                  return false
               })) {
                  setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El total de actuaciones de la línea no puede ser 0`)
                  return false
               }else if(sm.lines.some(line => {
                  if (!isNaN(line)) {
                     errLineIndex = line.index
                     return true
                  }
               })) {
                  setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - La línea está vacía`)
                  return false
               }
         
               setUpdateError("")
               return true
            })
      }
      if (update) setUpdateError("")
      else setRegisterError("")
      return true
   }

   const getSumPercentageSubMilestones = () => {

      const sum = subMilestones.reduce((acc, sub) => {
         if ( isNaN(sub) && sub.submilestone.general_data.isRegistered ) return acc + sub.submilestone.general_data.porcentaje_ejecucion
         else return acc
      }, 0)

      return sum.toFixed(2)
   }

   const resetAllHandler = () => {
      setP_name("")
      setP_percentage(0)
      setP_startDate(null)
      setP_endDate(null)
      setRegisterError("")
      setSubMilestones([2])
   }

   const resetSubMilestonesHandler = () => {
      setRegisterError("")
      setSubMilestones([2])
   }

   return (
      <>
         {
            planningsLines != null && planningsLines.length
            && planningsIndicators != null && planningsIndicators.length
            && categories != null && categories.length
            && loads != null && loads.length
            && (
               <>
                  <Card>
                     <CardHeader style={{paddingBottom: "0", fontWeight: "bold", marginBottom: "0", display: "flex", justifyContent: "space-between"}}>
                        <h3 
                           style={{fontWeight: "bold" }}
                           className={view ? classes.off : ""}
                        >
                           PLANIFICACIÓN
                        </h3>
                        {
                           (view == null)
                           ?
                              update
                                 ? showUpdate && isAllSubmilestoneRegistered 
                                 && (
                                    <Button 
                                       color="primary" 
                                       style={{maxHeight: "40px", fontSize: ".95rem"}}
                                       onClick={updateMilestoneHandler}
                                    >
                                       Sincronizar Planificación
                                    </Button>
                                 )
                                 : (
                                    <Button 
                                       color="primary" 
                                       style={{maxHeight: "40px", fontSize: ".95rem"}}
                                       onClick={addMilestoneHandler}
                                    >
                                       Registrar Planificación
                                    </Button>
                                 )
                           : ""
                        }
                     </CardHeader>
                     <CardBody style={{paddingTop: "0"}}>
                        <GridContainer>
                           <GridItem xs={12}>
                              <GridContainer>
                                 <GridItem xs={6} style={{marginBottom: "10px"}}>
                                    {/* NOMBRE */}
                                    <CustomInput
                                       labelText="Nombre"
                                       formControlProps={{ fullWidth: true}}
                                       inputProps={{
                                          value: p_name,
                                          onChange: (e) => {onChangeNameHandler(e.target.value)},
                                          type: "text",
                                          disabled: view != null,
                                          required: true,
                                       }}
                                    />
                                 </GridItem>
                                 <GridItem xs={6} style={{marginBottom: "10px"}}>
                                    {/* PORCENTAJE EJECUCIÓN */}
                                    <CustomInput
                                       labelText={`Porcentaje de ejecución (%) (suma periodos registrados: ${getSumPercentageSubMilestones()}%)`}
                                       formControlProps={{ fullWidth: true}}
                                       error={p_percentage < 0 || p_percentage > 100}
                                       inputProps={{
                                          value: p_percentage,
                                          onChange: (e) => onChangePercentageHandler(e.target.value),
                                          type: "number",
                                          disabled: view != null,
                                          required: true,
                                       }}

                                    />
                                 </GridItem>
                                 <GridItem xs={4}>
                                    {/* SELECTOR BLOQUE */}          
                                    <FormControl fullWidth>
                                       <InputLabel id="select-block-checkbox" htmlFor='select-bloque'>Número de carga de datos</InputLabel>
                                       <Select
                                          labelId="select-block-checkbox"
                                          MenuProps={{
                                             className: classes.selectMenu,
                                          }}
                                          className={classes.select}
                                          value={p_block}
                                          onChange={changeBlockSelectHandler}
                                          // error={!p_block}
                                          renderValue={(selected) => selected}
                                          inputProps={{
                                             name: 'select-bloque',
                                             id: 'select-bloque',
                                             required: true,
                                          }}
                                       >
                                          <MenuItem
                                             disabled
                                             classes={{
                                                root: classes.selectMenuItem,
                                             }}
                                          >
                                             Selecciona un Número de Carga de Datos
                                          </MenuItem>
                                          {
                                             loads.map((load, index) => {
                                                const value = ""+load.numero_carga

                                                return (
                                                   <MenuItem
                                                      value={value}
                                                      key={`select_block_checkbox_${ index }`}
                                                      classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                   >
                                                      {/* <Checkbox checked={blocks.indexOf(value) > -1} /> */}
                                                      <ListItemText primary={value} />
                                                   </MenuItem>
                                                )
                                             })
                                          }
                                       </Select>
                                    </FormControl>
                                 </GridItem>            
                                 {/* FECHA INICIO */}
                                 <GridItem xs={4}>
                                    <LocalizationProvider
                                       dateAdapter={AdapterDateFns}
                                       locale={es}
                                       utils={DateFnsUtils}
                                    >
                                       <DesktopDatePicker
                                          label="Fecha Inicio"
                                          inputFormat="dd-MM-yyyy"
                                          value={p_startDate || null}
                                          disabled={view != null}
                                          onChange={(newVal) => onChangeStartDateHandler(newVal)}
                                          renderInput={(params) => {
                                             return <TextField
                                                style={{width:"100%"}}
                                                // required={column.error}
                                                {...params}
                                                // error={column.error}
                                             />
                                          }}
                                       />
                                    </LocalizationProvider>
                                 </GridItem> 
                                 {/* FECHA FIN */}
                                 <GridItem xs={4}>
                                    <LocalizationProvider
                                       dateAdapter={AdapterDateFns}
                                       locale={es}
                                       utils={DateFnsUtils}
                                    >
                                       <DesktopDatePicker
                                          label="Fecha Fin"
                                          inputFormat="dd-MM-yyyy"
                                          value={p_endDate || null}
                                          disabled={view != null}
                                          onChange={(newVal) => onChangeEndDateHandler(newVal)}
                                          renderInput={(params) => {
                                             return <TextField
                                                style={{width:"100%"}}
                                                // required={column.error}
                                                {...params}
                                                // error={column.error}
                                             />
                                          }}
                                       />
                                    </LocalizationProvider>
                                 </GridItem> 

                                 {
                                    !isGlobal && (view == null)
                                    &&
                                    (
                                       <><GridItem>
                                             <Button
                                                color="primary"
                                                className={classes.addSub}
                                                onClick={addSubMilestonesHandler}
                                                variant='outlined'
                                                size='sm'
                                             >
                                                <span style={{marginRight: "10px"}}>Añadir Periodo</span> <span><AddBoxIcon style={{width: 27, height: 27}} /></span>
                                             </Button>
                                       </GridItem>
                                       <GridItem>
                                          <Button 
                                             color="danger"
                                             className={classes.deleteSub}
                                             onClick={resetSubMilestonesHandler}
                                             variant='outlined'
                                             size='sm'
                                          >
                                             <span style={{marginRight: "10px"}}>Resetear Periodos</span> <span><IndeterminateCheckBoxIcon style={{width: 27, height: 27}} /></span>
                                          </Button>
                                       </GridItem></>
                                    )
                                 }

                                 {
                                    registerError
                                    && 
                                    <GridItem xs={12}>                                                  
                                       <GridContainer style={{padding: "20px 0px 0px 0px"}}>
                                          <GridItem xs={12} >
                                             <SnackbarContent                                              
                                                message={registerError}
                                                color='danger'
                                             />
                                          </GridItem>
                                       </GridContainer>                                                                                
                                    </GridItem>
                                 }

                              </GridContainer>
                           </GridItem>
                        </GridContainer>

                        
                     </CardBody>
                  </Card>          

                  {
                     subMilestones.length &&
                     subMilestones.map((sub, i) => {

                        return (
                           <SubMilestone
                              subInfo={sub === 1 ? null : sub}
                              initialInfo={sub}
                              subMilestones={subMilestones}
                              setSubMilestones={setSubMilestones}
                              isGlobal={isGlobal}
                              update={update != null ? update : null}
                              view={view ?? false}
                              setIsGlobal={setIsGlobal}
                              linesMainInfo={JSON.parse(JSON.stringify(planningsLines))}
                              indicators={planningsIndicators}
                              categories={categories}
                              setShowUpdate={setShowUpdate}
                              index={i}
                              minStartDate={p_startDate}
                              maxEndDate={p_endDate}
                              setRegisterError={setRegisterError}
                              setUpdateError={setUpdateError}
                           />
                        )
                     })
                  }
                  {
                     registerAlert
                  }
                  {
                     updateAlert
                  }
               </>
               )
         }
      </>    
   )
}

const containsDuplicates = (array) => {
   if (array.length !== new Set(array).size) return true;
 
   return false;
 }
