import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { format } from 'date-fns'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { ACT_UPDATE_RESET } from 'redux/constants/actConstants'
import { actUpdateInfo, getActs } from 'redux/actions/actActions'
import styles from '../styles/updateActModalStyles'

const useStyles = makeStyles(styles)

const UpdateActModal = ({ handleCloseModal, updateActModal, showUpdateAct }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [infoAct, setInfoAct] = useState(showUpdateAct)

  const { loadingActUpdate, errorActUpdate, successActUpdate } = useSelector((state) => state.actUpdate)

  useEffect(() => {
    if (successActUpdate) {
      dispatch(getActs())
      setTimeout(() => {
        dispatch({ type: ACT_UPDATE_RESET })
        handleCloseModal()
      }, 1000)
    }
  }, [successActUpdate])

  const updateActHandler = (e) => {
    e.preventDefault()
    dispatch(actUpdateInfo({ ...infoAct, fecha_baja: format(new Date(infoAct.fecha_baja), 'yyyy-MM-dd') }))
  }
  return (
    <Dialog
      open={updateActModal}
      keepMounted
      onClose={handleCloseModal}
      aria-labelledby='notice-modal-slide-title'
      aria-describedby='notice-modal-slide-description'
    >
      <form onSubmit={updateActHandler} autoComplete='false'>
        <DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{`Editar Acto`}</h4>
        </DialogTitle>
        <DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <CustomInput
                labelText={'COD. ACTO'}
                id='codeAct'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: infoAct.codigo_acto,
                  onChange: (e) => setInfoAct({ ...infoAct, codigo_acto: e.target.value }),
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                labelText={'DESCRIPCION'}
                id='description'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: infoAct.descripcion_acto,
                  onChange: (e) => setInfoAct({ ...infoAct, descripcion_acto: e.target.value }),
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
              <Button type='submit' color={successActUpdate ? 'success' : 'primary'} block>
                {loadingActUpdate
                  ? 'Actualizando...'
                  : successActUpdate
                  ? 'Acto Actualizado'
                  : 'Actualizar Acto'}
              </Button>
            </GridItem>
          </GridContainer>
          {errorActUpdate && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorActUpdate} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
      </form>
    </Dialog>
  )
}

export default UpdateActModal
