import { useState, useEffect } from "react"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import DateFnsUtils from "@date-io/date-fns"
import { es } from "date-fns/locale"
import * as dayjs from 'dayjs'
import { makeStyles, FormControl, InputLabel, MenuItem, Select as Selectable, TextField } from '@material-ui/core'
import { useDispatch } from "react-redux"
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { TrainRounded } from "@material-ui/icons"
import { Pagination } from "@mui/material"

import styles from "../../styles/milestoneStyles"
import GridContainer from "components/Grid/GridContainer"
import Button from 'components/CustomButtons/Button'
import GridItem from "components/Grid/GridItem"
import CustomInput from "components/CustomInput/CustomInput"



const useStyles = makeStyles(styles)

export const TabPanelContentUpdate = ({ planning_data, planning, submilestone_data, setPlanningD, index,
   setPlanningToUpdate, setCanUpdate, submitHandler, setSuccessUpdate, aditionalPlanningInfo }) => {
      
   const classes = useStyles()

   const [actualPage, setActualPage] = useState(1)
   const [totalPages, setTotalPages] = useState(Math.ceil(submilestone_data != null
      ? submilestone_data.lines.length/ 5
      : 0))

   const {planningsLines, planningsIndicators, categories} = aditionalPlanningInfo

   // MILESTONE HANDLERS
   const onChangeMilestoneNameHandler = (newVal) => {
      setPlanningToUpdate(last => {
         last.general_data.nombre = newVal
         return {...last}
      })
      setCanUpdate(true)
   }
   const onChangeMilestonePercentageHandler = (newVal) => {
      setPlanningToUpdate(last => {
         last.general_data.porcentaje_ejecucion = newVal
         return {...last}
      })
      setCanUpdate(true)
   }
   const onChangeMilestoneStartDateHandler = (newVal) => {
      if ( planning.general_data.fecha_fin == null || dayjs(newVal).isAfter(dayjs(planning.general_data.fecha_fin)) ) {
         setPlanningToUpdate(last => {
            last.general_data.fecha_inicio = dayjs(newVal).format("YYYY-MM-DD")
            last.general_data.fecha_fin = dayjs(newVal).format("YYYY-MM-DD")
            
            return {...last}
         })
      }else {
         setPlanningToUpdate(last => {
            last.general_data.fecha_inicio = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }
      
      setCanUpdate(true)
   }
   const onChangeMilesoneEndDateHandler = (newVal) => {
      
      if ( planning.general_data.fecha_inicio == null || dayjs(newVal).isBefore(dayjs(planning.general_data.fecha_inicio)) ) {
         setPlanningToUpdate(last => {
            last.general_data.fecha_fin = dayjs(newVal).format("YYYY-MM-DD")
            last.general_data.fecha_inicio = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }else {
         setPlanningToUpdate(last => {
            last.general_data.fecha_fin = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }

      setCanUpdate(true)
   }

   // SUBMILESTONE HANDLERS
   const onChangeSubmilestoneStartDateHandler = (newVal) => {
      if ( planning.submilestones[index].submilestone.general_data.fecha_fin == null 
         || dayjs(newVal).isAfter(dayjs(planning.submilestones[index].submilestone.general_data.fecha_fin)) )
      {
         setPlanningToUpdate(last => {
            last.submilestones[index].submilestone.general_data.fecha_inicio = dayjs(newVal).format("YYYY-MM-DD")
            last.submilestones[index].submilestone.general_data.fecha_fin = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }else {
         setPlanningToUpdate(last => {
            last.submilestones[index].submilestone.general_data.fecha_inicio = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }

      setCanUpdate(true)
   }
   const onChangeSubmilestoneEndDateHandler = (newVal) => {
      if ( planning.submilestones[index].submilestone.general_data.fecha_inicio == null
         || dayjs(newVal).isBefore(dayjs(planning.submilestones[index].submilestone.general_data.fecha_inicio)) )
      {
         setPlanningToUpdate(last => {
            last.submilestones[index].submilestone.general_data.fecha_fin = dayjs(newVal).format("YYYY-MM-DD")
            last.submilestones[index].submilestone.general_data.fecha_inicio = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }else{
         setPlanningToUpdate(last => {
            last.submilestones[index].submilestone.general_data.fecha_fin = dayjs(newVal).format("YYYY-MM-DD")
            return {...last}
         })
      }

      setCanUpdate(true)
   }

   // LINE HANDLERS
   const onChangeLineStructureHandler = (newVal, i) => {
      setPlanningToUpdate(last => {
         last.submilestones[index].submilestone.lines[i].estructura = newVal
         return {...last}
      })
      setCanUpdate(true)
   }
   const onChangeLineIndicatorHandler = (newVal, i) => {
      setPlanningToUpdate(last => {
         last.submilestones[index].submilestone.lines[i].indicador_descripcion = newVal
         return {...last}
      })
      setCanUpdate(true)
   }
   const onChangeLineTotalActionsHandler = (newVal, i) => {
      setPlanningToUpdate(last => {
         const categorySymbol = last.submilestones[index].submilestone.lines[i].estructura.split(" - ")[2]
         const category = categories.find(cat => cat.categoria_pliego === categorySymbol)

         last.submilestones[index].submilestone.lines[i].total_actuaciones = Number(newVal)
         last.submilestones[index].submilestone.lines[i].porcentaje_ejecucion = 
            (( last.submilestones[index].submilestone.lines[i].total_actuaciones * category.importe * 100) / process.env.REACT_APP_TOTAL_GLOBAL_MILESTONE)
               .toFixed(2)
         last.submilestones[index].submilestone.general_data.porcentaje_ejecucion = 
            (last.submilestones[index].submilestone.lines.reduce((acc, line) => acc + Number(line.porcentaje_ejecucion), 0)).toFixed(2)

         return {...last}
      })
      setCanUpdate(true)
   }
   const getSumPercentageSubMilestones = () => {
      return planning.submilestones.reduce((acc, sub) => {
         return acc + sub.submilestone.general_data.porcentaje_ejecucion
      }, 0)
   }

   const onChangePageHandler = (e, newPage) => {
      setActualPage(newPage)
   }

   const getFormColumn = () => {

      if ( planning_data != null ) {
     
         return (
            <>
            <GridContainer>
            <GridItem xs={6} style={{marginBottom: "10px"}}>
               {/* NOMBRE */}
               <CustomInput
                  labelText="Nombre"
                  formControlProps={{ fullWidth: true}}
                  inputProps={{
                     value: planning_data.nombre || "",
                     onChange: (e) => onChangeMilestoneNameHandler(e.target.value),
                     type: "text",
                     required: true,
                  }}
               />
            </GridItem>
            <GridItem xs={6} style={{marginBottom: "10px"}}>
               {/* PORCENTAJE EJECUCIÓN */}
               <CustomInput
                  labelText={`Porcentaje de ejecución (%) (suma periodos registrados: ${getSumPercentageSubMilestones()}%)`}
                  formControlProps={{ fullWidth: true}}
                  error={planning_data.porcentaje_ejecucion < 0 || planning_data.porcentaje_ejecucion > 100}
                  inputProps={{
                     value: planning_data.porcentaje_ejecucion,
                     onChange: (e) => onChangeMilestonePercentageHandler(e.target.value),
                     type: "number",
                     required: false,
                  }}

               />
            </GridItem>               
            <GridItem xs={6}>
               {/* FECHA INICIO */}
               <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label="Fecha Inicio"
                     inputFormat="dd-MM-yyyy"
                     value={planning_data.fecha_inicio || null}
                     onChange={(newVal) => onChangeMilestoneStartDateHandler(newVal)}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%"}}
                           // required={column.error}
                           {...params}
                           // error={column.error}
                        />
                     }}
                  />
               </LocalizationProvider>
            </GridItem> 
            <GridItem xs={6}>
               {/* FECHA FIN */}
               <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label="Fecha Fin"
                     inputFormat="dd-MM-yyyy"
                     value={planning_data.fecha_fin || null}
                     onChange={(newVal) => onChangeMilesoneEndDateHandler(newVal)}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%"}}
                           // required={column.error}
                           {...params}
                           // error={column.error}
                        />
                     }}
                  />
               </LocalizationProvider>
            </GridItem>
            </GridContainer>
            </>
         )
      }else if ( submilestone_data != null ) {

         return (
            <>
            <GridContainer>
               {/* PORCENTAJE EJECUCIÓN */}
               <GridItem xs={12} style={{marginBottom: "10px"}}>
                  <CustomInput
                     labelText="Porcentaje de ejecución (%) - periodo"
                     formControlProps={{ fullWidth: true}}
                     inputProps={{
                        value: submilestone_data.general_data.porcentaje_ejecucion || 0,
                        type: "text",
                        required: false,
                        disabled: true
                     }}
                  />
               </GridItem>
                  {/* FECHA INICIO */}
               <GridItem xs={6}>
                  <LocalizationProvider
                     dateAdapter={AdapterDateFns}
                     locale={es}
                     utils={DateFnsUtils}
                  >
                     <DesktopDatePicker
                        label="Fecha Inicio"
                        inputFormat="dd-MM-yyyy"
                        minDate={new Date(planning.general_data.fecha_inicio)}
                        maxDate={new Date(planning.general_data.fecha_fin)}
                        value={submilestone_data?.general_data.fecha_inicio || null}
                        onChange={(newVal) => onChangeSubmilestoneStartDateHandler(newVal)}
                        renderInput={(params) => {
                           return <TextField
                              style={{width:"100%"}}
                              {...params}
                           />
                        }}
                     />
                  </LocalizationProvider>
               </GridItem>
                  {/* FECHA FIN */}
               <GridItem xs={6}>
                  <LocalizationProvider
                     dateAdapter={AdapterDateFns}
                     locale={es}
                     utils={DateFnsUtils}
                  >
                     <DesktopDatePicker
                        label="Fecha Fin"
                        inputFormat="dd-MM-yyyy"
                        minDate={new Date(planning.general_data.fecha_inicio)}
                        maxDate={new Date(planning.general_data.fecha_fin)}
                        value={submilestone_data?.general_data.fecha_fin || null}
                        onChange={(newVal) => onChangeSubmilestoneEndDateHandler(newVal)}
                        renderInput={(params) => {
                           return <TextField
                              style={{width:"100%"}}
                              {...params}
                           />
                        }}
                     />
                  </LocalizationProvider>
               </GridItem>
            </GridContainer>

            {
               false &&
               submilestone_data.lines.map((line, i) => {

                  return (                    
                     <GridContainer  style={{marginTop: "15px", paddingLeft: "100px"}}>
                        {/* TÍTULO */}
                        <GridItem xs={12}>
                           <h5 
                              style={{fontWeight: "bold", marginBottom: "0"}}
                              // className={isRegistered ? classes.off : ""}
                           >
                              PLANIFICACIÓN - LINEA ({i + 1})
                           </h5>
                        </GridItem>
                        {/* SELECT "LÍNEA-AÑO-CATEGORÍA" */}
                        <GridItem xs={6}>
                           <FormControl fullWidth>
                              <InputLabel htmlFor='select-line'>Estructura de la línea</InputLabel>
                              <Selectable
                                 MenuProps={{
                                    className: classes.selectMenu,
                                 }}
                                 className={classes.select}
                                 value={line.estructura}
                                 onChange={(e) => onChangeLineStructureHandler(e.target.value, i)}
                                 inputProps={{
                                    name: 'select-line',
                                    id: 'select-line',
                                    required: true,
                                 }}
                              >
                                 <MenuItem
                                    disabled
                                    classes={{
                                       root: classes.selectMenuItem,
                                    }}
                                 >
                                    Seleccione la estructura base de la línea
                                 </MenuItem>
                                 {
                                    planningsLines.map((info, i) => {
                                       const value = `${info.linea} - ${info.anio_convocatoria} - ${info.categoria_pliego}`

                                       return (
                                          <MenuItem
                                             value={value}
                                             key={`${value} - ${i}`}
                                             classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                          >
                                             {value}
                                          </MenuItem>
                                       )
                                    })
                                 }
                              </Selectable>
                           </FormControl>
                        </GridItem>
                        {/* INDICADOR */}
                        <GridItem xs={6}>                       
                           <FormControl fullWidth>
                              <InputLabel htmlFor='select-bloque'>Indicador</InputLabel>
                              <Selectable
                                 MenuProps={{
                                    className: classes.selectMenu,
                                 }}
                                 className={classes.select}
                                 value={line.indicador_descripcion}
                                 onChange={(e) => onChangeLineIndicatorHandler(e.target.value, i)}
                                 inputProps={{
                                    name: 'select-bloque',
                                    id: 'select-bloque',
                                    required: true,
                                 }}
                              >
                                 <MenuItem
                                    disabled
                                    classes={{
                                       root: classes.selectMenuItem,
                                    }}
                                 >
                                    Selecciona un indicador
                                 </MenuItem>
                                 {
                                    planningsIndicators.map((indicator, index) => {
                                       const value = indicator.descripcion

                                       return (
                                          <MenuItem
                                             value={value}
                                             key={`${value} - ${index}`}
                                             classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                          >
                                             {value}
                                          </MenuItem>
                                       )
                                    })
                                 }
                              </Selectable>
                           </FormControl>
                        </GridItem>
                        {/* TOTAL ACTUACIONES */}
                        <GridItem xs={6}>                      
                           <CustomInput
                              labelText="Total actuaciones"
                              formControlProps={{ fullWidth: true}}
                              error={line.total_actuaciones < 0}
                              inputProps={{
                                 value: line.total_actuaciones || 0,
                                 onChange: (e) => onChangeLineTotalActionsHandler(e.target.value, i),
                                 type: "number",
                                 required: false,
                              }}
                           />
                        </GridItem>
                        {/* PORCENTAJE EJECUCIÓN */}
                        <GridItem xs={6}> 
                           <CustomInput
                              labelText="Porcentaje de ejecución (%) - línea"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                 value: `${line.porcentaje_ejecucion}%`,
                                 disabled: true,
                                 type: "text",
                                 required: false,
                              }}
                           />
                        </GridItem>
                     </GridContainer>
                  )   
               })
            }
            <Pagination
               count={totalPages}
               defaultPage={1}
               page={actualPage}
               onChange={onChangePageHandler}
               color="primary"
               variant="outlined"
               style={{marginTop: "30px"}}
            />
            {
               submilestone_data.lines.slice((actualPage - 1) * 5, actualPage * 5)
                  .map((line, i) => {
                     return (
                        <>
                        <GridContainer style={{marginTop: "10px"}}>
                           {/* TÍTULO */}
                           <GridItem xs={12}>
                              <GridContainer style={{display: "flex", alignItems: "center"}}>
                                 {/* NUM LÍNEA */}
                                 <GridItem xs={2} style={{display: "flex", justifyContent: "center", marginTop: "15px", borderBottom: "1px solid #000000", borderRadius: "3px"}}>
                                    <h5
                                       style={{fontWeight: "bold", marginBottom: "0"}}
                                       // className={isRegistered ? classes.off : ""}
                                    >
                                       LÍNEA ({i + 1})
                                    </h5>
                                 </GridItem>             

                                 {/* SELECT ESTRUCTURA BASE LÍNEA */}
                                 <GridItem xs={7}>
                                    <FormControl fullWidth>
                                       {
                                          // !isRegistered && <InputLabel htmlFor='select-line'>Estructura de la línea</InputLabel>
                                          <InputLabel htmlFor='select-line'>Estructura de la línea</InputLabel>
                                       }
                                       <Selectable
                                          MenuProps={{
                                             className: classes.selectMenu,
                                          }}
                                          className={classes.select}
                                          // disabled={isRegistered}
                                          // value={mainInfo}
                                          value={line.estructura}
                                          // onChange={onChangeSelectMainInfoHandler}
                                          inputProps={{
                                             name: 'select-line',
                                             id: 'select-line',
                                             required: true,
                                          }}
                                       >
                                          <MenuItem
                                             disabled
                                             classes={{
                                                root: classes.selectMenuItem,
                                             }}
                                          >
                                             Seleccione la estructura base de la línea
                                          </MenuItem>
                                          {
                                             planningsLines.map((info, index) => {
                                                const value = `${info.linea} - ${info.anio_convocatoria} - ${info.categoria_pliego}`

                                                return (
                                                   <MenuItem
                                                      value={value}
                                                      key={`${value} - ${index}`}
                                                      classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                   >
                                                      {value}
                                                   </MenuItem>
                                                )
                                             })
                                          }
                                       </Selectable>
                                    </FormControl>
                                 </GridItem>
                                 {/* ELIMINAR LÍNEA */}
                                 <GridItem xs={3}>
                                 {
                                    // !isRegistered 
                                    // && 
                                    (               
                                       <Button 
                                          color="danger"
                                          className={classes.deleteSub}
                                          // onClick={onDeleteLineHandler}
                                          variant='outlined'
                                          size='sm'
                                          style={{width: "100%", textOverflow: "...", whiteSpace: "nowrap", overflow: "hidden"}}
                                       >
                                          <span style={{marginRight: "10px", width: "100%", textOverflow: "...", whiteSpace: "nowrap", overflow: "hidden" }}>Eliminar Línea</span> <span><IndeterminateCheckBoxIcon style={{width: 20, height: 20}} /></span>
                                       </Button>
                                    )
                                 }
                                 </GridItem>
                              </GridContainer>
                              
                           </GridItem>

                           {/* INFO LÍNEA */}
                           <GridItem xs={12}>           
                              <GridContainer>
                              {
                                 planningsIndicators.map((indicator, i) => {

                                    return (
                                       <>
                                       {/* INDICADOR */}
                                       <GridItem xs={6}>
                                          <CustomInput
                                             labelText="Indicador"
                                             formControlProps={{ fullWidth: true}}
                                             inputProps={{
                                                value: indicator.descripcion,
                                                disabled: true,
                                                type: "text",
                                             }}
                                          />
                                       </GridItem>
                                       {/* TOTAL ACTUACIONES */}
                                       <GridItem xs={3}>                      
                                          <CustomInput
                                             labelText="Total actuaciones"
                                             formControlProps={{ fullWidth: true}}
                                             // error={indicator.totalActions < 0}
                                             inputProps={{
                                                value: indicator.totalActions || 666,
                                                // disabled: isRegistered,
                                                // onChange: (e) => onChangeTotalActionsHandler(e, indicator.id, i),
                                                type: "number",
                                                required: false,
                                             }}
                                          />
                                       </GridItem>
                                    
                                       {/* % EJECUCIÓN */}
                                       <GridItem xs={3}>
                                          <CustomInput
                                             labelText="Porcentaje de ejecución - línea"
                                             // formControlProps={{ fullWidth: true, color: isRegistered ? "grey" : null, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}
                                             formControlProps={{ fullWidth: true, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}
                                             inputProps={{
                                                // value: `${ indicator.executionPercentage }%`,
                                                value: "0,66%",
                                                disabled: true,
                                                type: "text",
                                                required: false,
                                             }}
                                          />
                                       </GridItem>
                                       </>
                                    )
                                 })
                              }
                                 
                                    
                                    
                                 

                                 
                              </GridContainer>
                           </GridItem>
                        </GridContainer>
                        </>
                     )
                  })
            }
            <Pagination
               count={totalPages}
               defaultPage={1}
               page={actualPage}
               onChange={onChangePageHandler}
               color="primary"
               variant="outlined"
               style={{marginTop: "10px"}}
            />
            <GridContainer>
               <Button
                  style={{margin: "10px 0"}}
                  color="primary" 
                  // onClick={addLineHandler}
                  className={classes.addLine}
                  size='sm'
               >
                  <span style={{marginRight: "10px"}}>Añadir Línea</span> <span><AddBoxIcon style={{width: 25, height: 25}} /></span>
               </Button>
            </GridContainer>
            </>
         )
      }
   }

   return (
      <form onSubmit={submitHandler}>
         <GridContainer spacing={1}>
            <GridItem xs={12}>
               <GridContainer>
                  {
                     getFormColumn()
                  }
               </GridContainer>
            </GridItem>
         </GridContainer>
      </form>
   )
}

