import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteActionModal from 'components/ReactTableActions/Delete/DeleteActionModal'
import { deletePlanning } from 'redux/actions/planningActions'
import { PLANNING_DELETE_RESET, PLANNING_UPDATE_RESET } from 'redux/constants/planningConstants'

const DeletePlanningModal = ({ handleCloseDeletePlanningModal, deletePlanningModal, showDeletePlanningInfo }) => {
   const dispatch = useDispatch()

   const { successPlanningDelete, errorPlanningDelete, loadingPlanningDelete } = useSelector(
      (state) => state.planningDelete
   )
   const [alert, setAlert] = useState(null)

   useEffect(() => {
      let timeOut = ''
      if (successPlanningDelete) {
         timeOut = setTimeout(() => {
            // dispatch({ type: PLANNING_LIST_RESET })
            dispatch({ type: PLANNING_DELETE_RESET })
            handleCloseDeletePlanningModal()
         }, 1000)
      }

      return () => clearTimeout(timeOut)
   }, [successPlanningDelete])

   useEffect(() => {
      return () => dispatch({ type: PLANNING_DELETE_RESET })
   }, [dispatch])

   useEffect(() => {
      return () => dispatch({ type: PLANNING_UPDATE_RESET })
   }, [dispatch])

   const deletePlanningHandler = (e) => {
      e.preventDefault()
      dispatch(deletePlanning(showDeletePlanningInfo?.id_planificacion))
   }

   return (
      <>
         <DeleteActionModal
            open={deletePlanningModal}
            handleCloseModal={handleCloseDeletePlanningModal}
            handleSubmit={deletePlanningHandler}
            modalTitle='Eliminar Planificación'
            showDeleteInfo={showDeletePlanningInfo?.nombre}
            loadingDelete={loadingPlanningDelete}
            successDelete={successPlanningDelete}
            errorDelete={errorPlanningDelete}
         />
         {alert}
      </>
   )
}

export default DeletePlanningModal
