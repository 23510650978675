import role from 'config/roles/roles'
import {
  AccountBox,
  Assignment,
  FormatListBulleted,
  BusinessCenter,
  Class,
  Feed,
  Summarize,
  CollectionsBookmark,
  FactCheck,
  LibraryAddCheck,
  HowToReg,
  Group,
  Folder,
  LocalLibrary,
  Groups,
  Task,
  MilitaryTech,
  PersonSearch,
  MiscellaneousServices,
  Apps,
  Lock,
  Article,
} from '@mui/icons-material'
import { PostAdd } from '@material-ui/icons'
import UserProfileScreen from 'screens/control-panel/UserProfileScreen/UserProfileScreen'
import ChangePasswordModal from 'components/ChangePasswordModal/ChangePasswordModal'
import ProfileListScreen from 'screens/control-panel/Catalog/Profile/ProfileListScreen/ProfileListScreen'
import AltaProfileScreen from 'screens/control-panel/Catalog/Profile/AltaProfileScreen/AltaProfileScreen'
import ProfileToManagerScreen from 'screens/control-panel/Catalog/Profile/ProfileToManagerScreen/ProfileToManagerScreen'
import ActListScreen from 'screens/control-panel/Catalog/Acts/ActListScreen/ActListScreen'
import AltaActScreen from 'screens/control-panel/Catalog/Acts/RegisterActScreen/RegisterActScreen'
import UserListScreen from 'screens/control-panel/Catalog/Users/UserListScreen/UserListScreen'
import AltaUserScreen from 'screens/control-panel/Catalog/Users/RegisterUserScreen/RegisterUserScreen'
import CategoryListScreen from 'screens/control-panel/Catalog/Category/CategoryListScreen/CategoryListScreen'
import AltaCategoryScreen from 'screens/control-panel/Catalog/Category/AltaCategoryScreen/AltaCategoryScreen'
import WeeklyReportScreen from 'screens/control-panel/Reports/WeeklyReportScreen/WeeklyReportScreen'
import MonthlyReportScreen from 'screens/control-panel/Reports/MonthlyReportScreen/MonthlyReportScreen'
import PlanningReportScreen from 'screens/control-panel/Reports/PlanningReportScreen/PlanningReportScreen'
import MonthlyBillingReportScreen from 'screens/control-panel/Reports/MonthlyBillingReportScreen/MonthlyBillingReportScreen'
import WorkListScreen from 'screens/control-panel/Work/WorkListScreen/WorkListScreen'
import WorkTechToDoListScreen from 'screens/control-panel/Work/WorkTechToDoListScreen/WorkTechToDoListScreen'
import WorkTechDoneListScreen from 'screens/control-panel/Work/WorkTechDoneListScreen/WorkTechDoneListScreen'
import WorkTechCorrectionListScreen from 'screens/control-panel/Work/WorkTechCorrectionListScreen/WorkTechCorrectionListScreen'
import WorkValidatorToDoListScreen from 'screens/control-panel/Work/WorkValidatorToDoListScreen/WorkValidatorToDoListScreen'
import WorkAdministrativeOtherProfilesListScreen from 'screens/control-panel/Work/WorkAdministrativeOtherProfilesListScreen/WorkAdministrativeOtherProfilesListScreen'
import WorkAdministrativeNoticeListScreen from 'screens/control-panel/Work/WorkAdministrativeNoticeListScreen/WorkAdministrativeNoticeListScreen'
import WorkAdministrativeToDoListScreen from 'screens/control-panel/Work/WorkAdministrativeToDoListScreen/WorkAdministrativeToDoListScreen'
import ImportLinesScreen from 'screens/control-panel/Utility/ImportLinesScreen/ImportLinesScreen'
import UserActividadesScreen from 'screens/control-panel/Actividades/ActividadesListScreen/UserActividadesScreen'
import PlanningsListScreen from 'screens/control-panel/Plannings/PlanningsListScreen/PlanningsListScreen'
import RegisterPlanningScreen from 'screens/control-panel/Plannings/RegisterPlanningScreen/RegisterPlanningScreen'
import TechniciansTrackingScreen from 'screens/control-panel/Trackings/TechniciansTranking/TechniciansTrackingScreen'

var dashRoutes = [
   // MI PERFIL
   {
      path: '/user-page',
      name: 'MI PERFIL',
      icon: AccountBox,
      component: UserProfileScreen,
      layout: '/admin',
      role: role.ALL_USER_ROLES,
   },
   // CAMBIAR CONTRASEÑA
   {
      path: '/changePassword',
      name: 'Cambiar contraseña de acceso',
      icon: PersonSearch,
      component: ChangePasswordModal,
      layout: '/admin',
      role: role.ALL_USER_ROLES,
   },
   // ACTIVIDADES
   {
      path: '/actividad-list', 
      name: 'ACTIVIDADES',
      icon: AccountBox,
      component: UserActividadesScreen,
      layout: '/admin',
      role: [role.ADMIN_ROLE],  
      
      
   },
   // TRABAJOS
   {
      collapse: true,
      name: 'TRABAJOS',
      icon: BusinessCenter,
      state: 'workCollapse',
      role: [role.VALIDADOR_ROLE, role.TECNICO_ROLE, role.ADMIN_ROLE],
      views: [
         // GENERALES
         {
            path: '/work-list',
            name: 'Controles generales',
            icon: FormatListBulleted,
            component: WorkListScreen,
            role: [role.VALIDADOR_ROLE, role.TECNICO_ROLE, role.ADMIN_ROLE],
            layout: '/admin',
         },
         // TÉCNICO
         {
            path: '/work-tech-todo-list',
            name: 'Controles Pendientes',
            icon: FormatListBulleted,
            component: WorkTechToDoListScreen,
            role: [role.TECNICO_ROLE],
            layout: '/admin',
         },
         {
            path: '/work-tech-done-list',
            name: 'Controles Realizados',
            icon: FormatListBulleted,
            component: WorkTechDoneListScreen,
            role: [role.TECNICO_ROLE],
            layout: '/admin',
         },
         {
            path: '/work-tech-correction-list',
            name: 'Seguimientos',
            icon: FormatListBulleted,
            component: WorkTechCorrectionListScreen,
            role: [role.TECNICO_ROLE],
            layout: '/admin',
         },
         // VALIDADOR
         {
            path: '/work-validator-todo-list',
            name: 'Controles Pendientes',
            icon: FormatListBulleted,
            component: WorkValidatorToDoListScreen,
            role: [role.VALIDADOR_ROLE],
            layout: '/admin',
         },
         // ADMINISTRATIVO
         {
            path: '/work-administrative-other-profiles-list',
            name: 'Controles de Otros Perfiles',
            icon: FormatListBulleted,
            component: WorkAdministrativeOtherProfilesListScreen,
            role: [role.ADMINISTRATIVO_ROLE],
            layout: '/admin',
         },
         {
            path: '/work-administrative-notice-list',
            name: 'Avisos',
            icon: FormatListBulleted,
            component: WorkAdministrativeNoticeListScreen,
            role: [role.ADMINISTRATIVO_ROLE],
            layout: '/admin',
         },
         {
            path: '/work-administrative-todo-list',
            name: 'Controles Pendientes',
            icon: FormatListBulleted,
            component: WorkAdministrativeToDoListScreen,
            role: [role.ADMINISTRATIVO_ROLE],
            layout: '/admin',
         },
      ],
   },
   // UTILIDADES
   {
      collapse: true,
      name: 'UTILIDADES',
      icon: BusinessCenter,
      state: 'utilityCollapse',
      role: [role.ADMIN_ROLE],
      views: [
         {
         path: '/import-lines',
         name: 'Importar Líneas',
         icon: FormatListBulleted,
         component: ImportLinesScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
         },
      ],
   },
   // CATÁLOGOS
   {
      collapse: true,
      name: 'CATÁLOGOS',
      icon: LocalLibrary,
      state: 'catalogsCollapse',
      role: [role.ADMIN_ROLE],
      views: [
         {
         collapse: true,
         name: 'USUARIOS',
         icon: PersonSearch,
         state: 'userCollapse',
         role: [role.ADMIN_ROLE],
         views: [
            {
               path: '/user-list',
               name: 'Listado Usuarios',
               icon: FormatListBulleted,
               component: UserListScreen,
               role: [role.ADMIN_ROLE],
               layout: '/admin',
            },
            {
               path: '/user-register',
               name: 'Alta Usuario',
               icon: PostAdd,
               component: AltaUserScreen,
               layout: '/admin',
               role: [role.ADMIN_ROLE],
            },
         ],
         },
         {
         collapse: true,
         name: 'PERFILES',
         icon: PersonSearch,
         state: 'profileCollapse',
         role: [role.ADMIN_ROLE],
         views: [
            {
               path: '/profile-list',
               name: 'Listado Perfiles',
               icon: FormatListBulleted,
               component: ProfileListScreen,
               role: [role.ADMIN_ROLE],
               layout: '/admin',
            },          
            {
               path: '/profile-register',
               name: 'Alta Perfiles',
               icon: PostAdd,
               component: AltaProfileScreen,
               layout: '/admin',
               role: [role.ADMIN_ROLE],
            },
            {
               path: '/asign-profile',
               name: 'Asignar Perfil',
               icon: PostAdd,
               component: ProfileToManagerScreen,
               layout: '/admin',
               role: [role.ADMIN_ROLE],
            },
         ],
         },
         {
         collapse: true,
         name: 'ACTOS',
         icon: PersonSearch,
         state: 'actCollapse',
         role: [role.ADMIN_ROLE],
         views: [
            {
               path: '/act-list',
               name: 'Listado Tipos de Actos',
               icon: FormatListBulleted,
               component: ActListScreen,
               role: [role.ADMIN_ROLE],
               layout: '/admin',
            },          
            {
               path: '/act-register',
               name: 'Alta Tipo de Acto',
               icon: PostAdd,
               component: AltaActScreen,
               layout: '/admin',
               role: [role.ADMIN_ROLE],
            },
         ],
         },
         {
         collapse: true,
         name: 'CATEGORÍAS',
         icon: PersonSearch,
         state: 'categoryCollapse',
         role: [role.ADMIN_ROLE],
         views: [
            {
               path: '/category-list',
               name: 'Listado de Categorías',
               icon: FormatListBulleted,
               component: CategoryListScreen,
               role: [role.ADMIN_ROLE],
               layout: '/admin',
            },          
            {
               path: '/category-register',
               name: 'Alta de Categorías',
               icon: PostAdd,
               component: AltaCategoryScreen,
               layout: '/admin',
               role: [role.ADMIN_ROLE],
            },
         ],
         },
      ],
   },
   // INFORMES
   {
      collapse: true,
      name: 'INFORMES',
      icon: Folder,
      state: 'reportCollapse',
      role: [role.VALIDADOR_ROLE],
      views: [
      {
         path: '/weekly-report',
         name: 'Seguimiento Semanal',
         icon: FormatListBulleted,
         component: WeeklyReportScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      {
         path: '/monthly-report',
         name: 'Seguimiento Mensual',
         icon: FormatListBulleted,
         component: MonthlyReportScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      {
         path: '/planning-report',
         name: 'Seguimiento Planificación',
         icon: FormatListBulleted,
         component: PlanningReportScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      {
         path: '/monthly-billing-report',
         name: 'Facturación Mensual',
         icon: FormatListBulleted,
         component: MonthlyBillingReportScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      ],
   },
   // SEGUIMIENTOS
   {
      collapse: true,
      name: 'SEGUIMIENTOS',
      icon: Folder,
      state: 'trackingCollapse',
      role: [role.VALIDADOR_ROLE],
      views: [
      {
         path: '/technicians-tracking',
         name: 'Técnicos',
         icon: FormatListBulleted,
         component: TechniciansTrackingScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      ]
   },
   // PLANIFICACIONES
   {
      collapse: true,
      name: 'PLANIFICACIONES',
      icon: Folder,
      state: 'planningsCollapse',
      role: [role.VALIDADOR_ROLE],
      views: [
      {
         path: '/plannings-list',
         name: 'Listado Planificaciones',
         icon: FormatListBulleted,
         component: PlanningsListScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      {
         path: '/planning-register',
         name: 'Alta planificación',
         icon: FormatListBulleted,
         component: RegisterPlanningScreen,
         role: [role.ADMIN_ROLE],
         layout: '/admin',
      },
      ],
   },
]

export default dashRoutes
