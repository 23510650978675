import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { WORK_CONTROL_ACTS_RESET } from 'redux/constants/workConstants'
import { TabPanelUpdate } from './TabPanelUpdate'
import { getControlActsData } from 'redux/actions/workActions'
import { updateControlActsData } from 'redux/actions/workActions'
import UpdateActionModal from 'components/ReactTableActions/Update/UpdateActionModal'

const UpdateWorkInfoActModal = ({ open, closeUpdateInfoModal, controlId, technicians }) => {
   const dispatch = useDispatch()

   const { loadingWorkActsList, errorWorkActsList, successWorkActsList, controlActs, controlActsIni } = useSelector(
      (state) => state.workControlActList
   )

   const { loadingWorkActsUpdate, errorWorkActsUpdate, successWorkActsUpdate, updateInfo } = useSelector(
      (state) => state.workUpdateControlActs
   )

   const [actToUpdate, setActToUpdate] = useState(null)
   const [canUpdate, setCanUpdate] = useState(false)
   const [successUpdate, setSuccessUpdate] = useState(false)

   useEffect(() => {
      if (!successWorkActsList && controlActs != null && !controlActs.length) dispatch(getControlActsData(controlId))         
      
   }, [successWorkActsList, dispatch])

   useEffect(() => {
      dispatch(getControlActsData(controlId))

      return () => dispatch({ type: WORK_CONTROL_ACTS_RESET })
   }, [])

   if (errorWorkActsList) {
      return ( "Hubo algún problema al cargar los datos." )
   }

   const submitHandler = (e) => {
      e.preventDefault()
      setCanUpdate(false)
      setSuccessUpdate(true)

      dispatch(
         updateControlActsData(controlId, [...actToUpdate ])
      )
   }

   return (
      <>
      {loadingWorkActsList
      ? ( "" )
      : (
         <UpdateActionModal
            open={open}
            handleCloseModal={closeUpdateInfoModal}
            handleSubmit={submitHandler}
            successUpdate={successUpdate}           
            loadingUpdate={loadingWorkActsUpdate}
            errorUpdate={errorWorkActsUpdate}
            canUpdate={canUpdate}
            modalTitle={`Actualizar Control - Actos`}
            customWidth={"lg"}
            children={
               <TabPanelUpdate 
                  info={controlActs != null ? JSON.parse(JSON.stringify(controlActs)) : controlActs}
                  setActToUpdate={setActToUpdate}
                  controlId={controlId}
                  canUpdate={canUpdate}
                  technicians={technicians}
                  setCanUpdate={setCanUpdate}
                  submitHandler={submitHandler}
                  setSuccessUpdate={setSuccessUpdate}
               />
            }
         />
      )}
      </>
   )
}

export default UpdateWorkInfoActModal
