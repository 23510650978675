import axios from 'axios'
import {  
  ACTIVIDAD_LIST_REQUEST,
  ACTIVIDAD_LIST_SUCCESS,
  ACTIVIDAD_LIST_FAIL,
  ACTIVIDAD_UPDATE_REQUEST,
  ACTIVIDAD_UPDATE_SUCCESS,
  ACTIVIDAD_UPDATE_FAIL,
} from '../constants/actividadConstants.js'

export const getActividades = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTIVIDAD_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/actividades', config)

    dispatch({ type: ACTIVIDAD_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ACTIVIDAD_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const actividadUpdateInfo = (actividad) => async (dispatch) => {
  try {
    dispatch({ type: ACTIVIDAD_UPDATE_REQUEST })

    const { data } = await axios.put(`/api/actividades/${actividad.id_acto}`, actividad)

    dispatch({ type: ACTIVIDAD_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ACTIVIDAD_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

