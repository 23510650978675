import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'

import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from 'components/ReactTableActions/Delete/styles/deleteActionModalStyles.js'
import { useState } from 'react'
import CustomInput from 'components/CustomInput/CustomInput'

const useStyles = makeStyles(styles)

const CloneActionModal = ({
   open,
   handleCloseModal,
   handleSubmit,
   modalTitle,
   showCloneInfo,
   loadingClone,
   successClone,
   errorClone,
}) => {
   const classes = useStyles()

   const [newName, setNewName] = useState(showCloneInfo)

   const onChangeNewNameHandler = ( e ) => {
      setNewName( e )
   }

   return (
      <Dialog
         classes={{
         root: classes.modalRoot,
         paper: classes.modal,
         }}
         open={open}
         keepMounted
         onClose={handleCloseModal}
         aria-labelledby='modal-clone-title'
         aria-describedby='modal-clone-description'
      >
         <form onSubmit={(e) => handleSubmit(e, newName)}>
         <DialogTitle id='modal-clone-title' disableTypography className={classes.modalHeader}>
            <Button
               justIcon
               className={classes.modalCloseButton}
               key='close'
               aria-label='Close'
               color='transparent'
               onClick={handleCloseModal}
            >
               <Close className={classes.modalClose} />
            </Button>
            <h4>{modalTitle}</h4>
         </DialogTitle>

         <DialogContent id='modal-clone-description' className={classes.modalBody}>
            <GridContainer>
               <GridItem xs={12}>
                  <Typography>
                     ¿Está seguro que quieres duplicar <strong>{showCloneInfo}</strong>?
                  </Typography>
               </GridItem>
               
               <GridItem xs={12}>
                  <CustomInput
                     labelText="Nuevo nombre"
                     formControlProps={{ fullWidth: true}}
                     inputProps={{
                        value: newName,
                        onChange: (e) => {onChangeNewNameHandler(e.target.value)},
                        type: "text",
                        required: true,
                     }}
                  />
               </GridItem>
            </GridContainer>

            {errorClone && (
               <GridContainer>
               <GridItem xs={12}>
                  <SnackbarContent message={errorClone} color='danger' />
               </GridItem>
               </GridContainer>
            )}
         </DialogContent>
         <DialogActions className={classes.modalFooter}>
            <GridContainer>
               <GridItem xs={6}>
               <Button onClick={handleCloseModal} block>
                  Cancelar
               </Button>
               </GridItem>
               <GridItem xs={6}>
               <Button type='onSubmit' color={successClone ? 'success' : 'primary'} block>
                  {loadingClone ? 'Duplicando...' : successClone ? 'Duplicada' : 'Duplicar'}
               </Button>
               </GridItem>
            </GridContainer>
         </DialogActions>
         </form>
      </Dialog>
   )
}

export default CloneActionModal
