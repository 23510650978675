import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl, InputLabel, MenuItem, Select as Selectable } from '@material-ui/core'
import SweetAlert from 'react-bootstrap-sweetalert'
import ClipLoader from "react-spinners/ClipLoader"
import xlsxFile from 'read-excel-file'

import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import ViewInfoActionModal from 'components/ReactTableActions/ViewInfo/ViewInfoActionModal.js'

import { registerLines } from 'redux/actions/importLinesActions'
import { LINES_REGISTER_RESET } from 'redux/constants/importLinesConstants'
import { getPlanningLoads } from 'redux/actions/planningActions.js'
import { PLANNING_LOADS_LIST_RESET } from 'redux/constants/planningConstants.js'
import styles from './styles/importLinesScreenStyles.js'

const useStyles = makeStyles(styles)

const ImportLinesScreen = () => {
   const classes = useStyles()
   const dispatch = useDispatch()
   const [showErrors, setShowErrors] = useState(false)

   const { loadingLinesRegister, successLinesRegister, errorLinesRegister, info_register } = useSelector(
      (state) => state.linesRegister
   )
   const { loadingLoadsList, successLoadsList, errorLoadsList, loads } = useSelector(
      (state) => state.planningLoadsList
   )
   
   const inputFile = useRef(null)
   const topPlace = useRef()

   const [controlInfo, setControlInfo] = useState([{bloque: ""}])
   const [bloque, setBloque] = useState(null)
   const [items, setItems] = useState([])
   const [lastItemsLen, setLastItemsLen] = useState(0)
   const [file, setFile] = useState("")
   const [showSA, setShowSA] = useState(false)
   const [alert, setAlert] = useState(false)
   const [bloqueError, setBloqueError] = useState('')
   const [referenciaError, setReferenciaError] = useState('')
   
   const readExcel = (file) => {
      xlsxFile(file).then((rows) => {
         // headers de la mayor plantilla que potencialmente se puede manejar
         const headers = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z",
         "aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az",
         "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz",
         "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz",
         "da","db","dc","dd","de","df","dg"];
         
         let data = []
         for(let i = 0; i < rows.length; i++) {
            let row_json = {}
            for (let j = 0; j < headers.length; j++) {
               row_json[headers[j]] = rows[i][j];
            }
            data.push(row_json)
         }

         data = [{bloque: bloque}, ...data]

         const splice_data = [...data]
         splice_data.splice(0, 3)
         setLastItemsLen(splice_data.length)
         setItems(splice_data)
         setControlInfo(data)
      })   
   }

   useEffect(() => {
      if (successLinesRegister && info_register && showSA) {
         let type = "success"
         if ( info_register.num_insert_rows === 0 && info_register.num_update_rows === 0 ) type = "danger"
         else if ( info_register.num_error_rows ) type = "warning"

         setAlert(
            <SweetAlert
               type={type}
               style={{ display: 'block', marginTop: '-100px' }}
               title='Hecho!'
               onConfirm={() => confirmSuccess()}
               onCancel={() => confirmSuccess()}
               confirmBtnCssClass={classes.confirmBtnCssClass}
            >
               <p><bold>Número de filas sincronizadas: </bold> { info_register.num_insert_rows || 0 }</p> 
               <p><bold>Número de filas actualizadas: </bold> { info_register.num_update_rows || 0 }</p> 
               <p><bold>Número de filas con errores: </bold> { info_register.num_error_rows || 0 }</p>            
            </SweetAlert>
         )
      }
   }, [successLinesRegister, showSA])

   useEffect(() => {
      
      dispatch( getPlanningLoads() )

      return () => {
         dispatch({ type: LINES_REGISTER_RESET })
         dispatch({ type: PLANNING_LOADS_LIST_RESET })
      }
   }, [])
   

   const confirmSuccess = () => {
      // dispatch({ type: LINES_REGISTER_RESET })
      inputFile.current.value = null
      setControlInfo([{bloque: bloque || ""}])
      setShowSA(false)
      setFile(false)
      setAlert(false)
      setBloqueError('')
      setReferenciaError('')
      setItems([])
      topPlace.current.scrollIntoView(); 
   }

   const handleLinesRegister = (e) => {
      
      if (!file || !controlInfo) {
         return setReferenciaError('Por favor seleccione un archivo de control a cargar')
      }

      if (!controlInfo[0]?.bloque) {
         return setBloqueError('Por favor indique el número de carga de datos')
      }
      
      let filteredControlInfo = [...controlInfo]
      // filteredControlInfo.splice(1, 1)

      filteredControlInfo = filteredControlInfo.filter(line => {
         const lineValues = Object.values(line)

         let isEmpty = true
         
         for (let i = 0; i < lineValues.length; i++) {
            const lineContent = lineValues[i]
            if ( lineContent ) {
               isEmpty = false
               break
            }
         }

         return !isEmpty
      })
      const acts = Object.values(filteredControlInfo[1])
      setShowSA(true)

      dispatch(
         registerLines({
            ...filteredControlInfo
         })
      )
   }

   const showErrorsHandler = (e) => {
      setShowErrors(last => !last)
   }

   const onChangeSelectedBlockHandler = ( e ) => {
      
      const newBlock = e.target.value
      setBloque(newBlock)
      setBloqueError("")
      setControlInfo(last => {
         last[0].bloque = newBlock
         return last
      })
   }


   if ( loadingLoadsList || loads == null || !loads.length ) {
      return ( 
         <>
         Cargando... 
         <div 
            style={{ marginRight: "10px", display: "flex", justifyContent: "center", marginTop: "20px" }}
         >
            <ClipLoader
               color="#041f24"
               size={50}            
            /> 
         </div>
         </>
      )
   }

   return (
      <div>           
         <div ref={topPlace}></div>
         {/* SELECT FILE */}
         <GridItem xs={8}>
            <GridContainer>
               <GridItem xs={4}>
                  Seleccione fichero excel de control a cargar:
                  <Button color='primary' >
                  <input
                     type="file"
                     ref={ inputFile }
                     onChange={(e) => {
                        const file = e.target.files[0]
                        setFile(file)
                        setReferenciaError("")
                        dispatch({ type: LINES_REGISTER_RESET })
                        readExcel(file)
                     }}
                     required={true}
                  />              
                  </Button>
               </GridItem>
            </GridContainer>
         </GridItem>
         <p></p>
         {/* SELECTOR BLOQUE */}
         <GridItem xs={12} md={3}>
            <FormControl fullWidth>
               <InputLabel htmlFor='select-bloque'>Número de Carga de Datos</InputLabel>
               <Selectable
                  MenuProps={{
                     className: classes.selectMenu,
                  }}
                  className={classes.select}
                  value={controlInfo.bloque}
                  onChange={onChangeSelectedBlockHandler}
                  inputProps={{
                     name: 'select-bloque',
                     id: 'select-bloque',
                     required: true,
                  }}
               >
                  <MenuItem
                     disabled
                     classes={{
                        root: classes.selectMenuItem,
                     }}
                  >
                     Selecciona un Número de Carga de Datos
                  </MenuItem>
                  {
                  loads.map((load, index) => {
                     const value = load.numero_carga

                     return (
                        <MenuItem
                           value={value}
                           key={index}
                           classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                        >
                           {`${ value }`}
                        </MenuItem>
                     )
                  })
                  }
               </Selectable>
            </FormControl>
         </GridItem>
         <hr />
         <ReactTable
            columns={[
               {
                  Header: 'REFERENCIA',
                  accessor: 'a',
               },
               {
                  Header: 'LÍNEA',
                  accessor: 'b',
               },
               {
                  Header: 'CONVOCATORIA',
                  accessor: 'c',
               },
               {
                  Header: 'PRIORIDAD',
                  accessor: 'o',
               },
               {
                  Header: 'CATEGORÍA',
                  accessor: 'p',
               },
            ]}
            data={items}
            numFilas={20}
         />
         <GridContainer 
            xs={12}
            style={{ margin: '20px 0 0', display: 'flex', justifyContent: info_register != null && Array.isArray( info_register.message ) ? 'space-between' : 'flex-end' }}
         >
            {/* MOSTRAR ERRORES DE CARGA */}
            {
               info_register && Array.isArray( info_register.message )
               && <GridItem>
                  <Button
                     style={{ marginRight: '20px' }}
                     color={'primary'}
                     onClick={() => showErrorsHandler()}
                  >
                     Registro Errores
                  </Button>
               </GridItem>
            }
            {/* CARGAR DATOS */}
            <GridItem>
               <div
                  style={{ display: "flex", justifyContent: "center", alignItems: "Center" }}
               >
                  {
                  (lastItemsLen || "")
                  && <p style={{ marginRight: "15px" }}>Número de filas a sincronizar: { lastItemsLen }</p>
                  }
                  <Button
                     style={{ marginRight: '20px' }}
                     color={successLinesRegister ? `success` : 'primary'}
                     onClick={loadingLinesRegister ? null : () => handleLinesRegister()}
                  >
                     {loadingLinesRegister
                     ?  <> 
                        <span style={{ marginRight: "10px" }}>
                           <ClipLoader 
                              color="#041f24"
                              size={20}            
                           /> 
                        </span>
                        <span>
                           Cargando... 
                        </span>
                        </>
                     : successLinesRegister
                        ? `Hecho`
                        : `Sincronizar Datos`}
                  </Button>
               </div>
            </GridItem>
         </GridContainer>
         {errorLinesRegister && (
         <GridItem xs={12}>
            <SnackbarContent message={errorLinesRegister.message} color='danger' />
         </GridItem>
         )}
         {bloqueError && !controlInfo.bloque && (
         <GridItem xs={12}>
            <SnackbarContent message={bloqueError} color='danger' />
         </GridItem>
         )}
         {referenciaError && !controlInfo.referencia && (
         <GridItem xs={12}>
            <SnackbarContent message={referenciaError} color='danger' />
         </GridItem>
         )}
         {alert}
            
         <ViewInfoActionModal
            open={ showErrors }
            handleCloseModal={() => setShowErrors(false)}
            modalTitle={`Informe de errores`}
            children={
               <GridContainer>
                  {info_register != null && Array.isArray(info_register.message) && (
                     <GridContainer style={{ width: "110%", margin: "auto" }}>
                        <GridItem xs={12} >
                           <SnackbarContent message={info_register.message} color='danger' />
                        </GridItem>
                     </GridContainer>
                     
                  )}         
               </GridContainer>
            }
         />
      </div>
   )
   
}

export default ImportLinesScreen
