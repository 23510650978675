import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
  Dialog,
  makeStyles,
  Select as Selectable,
} from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { categoryUpdateInfo, getCategories } from 'redux/actions/categoryActions'
import { CATEGORY_UPDATE_RESET } from 'redux/constants/categoryConstants'
import styles from '../styles/updateCategoryStyles'

const useStyles = makeStyles(styles)

const UpdateCategoryModal = ({ handleCloseModal, updateCategoryModal, showUpdateCategory }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [categoryInfo, setCategoryInfo] = useState(showUpdateCategory)
  const [codCategory, setCodCategory] = useState(categoryInfo.categoria_pliego)
  const [codCategoryError, setCodCategoryError] = useState('')
  const [tipoActuacion, setTipoActuacion] = useState(categoryInfo.tipo_actuacion)
  const [importe, setImporte] = useState(categoryInfo.importe)
  const [importeError, setImporteError] = useState('')

  const [alert, setAlert] = useState(null)
  const { loadingCategoryUpdate, errorCategoryUpdate, successCategoryUpdate } = useSelector((state) => state.categoryUpdate)

    useEffect(() => {
    if (successCategoryUpdate) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='¡HECHO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Categoría actualizado correctamente
        </SweetAlert>
      )
    }
  }, [successCategoryUpdate])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!codCategory) {
      return setCodCategoryError('Por favor ingrese una Categoría')
    }
    if (!importe) {
      return setImporteError('Por favor ingrese un importe')
    }
    const category = {
      id_tipo_categoria: categoryInfo.id_tipo_categoria,
      categoria_pliego: codCategory,
      tipo_actuacion: tipoActuacion,
      importe: importe,
    }
    dispatch(categoryUpdateInfo(category))
  }
  const confirmSuccess = () => {
    dispatch(getCategories())
    dispatch({ type: CATEGORY_UPDATE_RESET })
    handleCloseModal()
    setAlert(null)
  }

  return (
    <>
      <Dialog
        open={updateCategoryModal}
        keepMounted
        onClose={handleCloseModal}
        aria-labelledby='notice-modal-slide-title'
        aria-describedby='notice-modal-slide-description'
      >
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'CATEGORÍA'}
                        id='codcategory'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: codCategory,
                          onChange: (e) => setCodCategory(e.target.value),
                          type: 'text',
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'TIPO DE ACTUACIÓN'}
                        id='tipoActuacion'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: tipoActuacion || '',
                          onChange: (e) => setTipoActuacion(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'IMPORTE'}
                        id='importe'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: importe,
                          onChange: (e) => setImporte(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'space-around' }}>
                  <Button color='secondary' onClick={handleCloseModal}>
                    Cerrar
                  </Button>
                  <Button type='submit' color='primary'>
                    {loadingCategoryUpdate ? 'Actualizando...' : 'Actualizar Categoría'}
                  </Button>
                </GridItem>
                {errorCategoryUpdate && (
                  <GridItem xs={12}>
                    <SnackbarContent message={errorCategoryUpdate} color='danger' />
                  </GridItem>
                )}
                {codCategoryError && !codCategory && (
                  <GridItem xs={12}>
                    <SnackbarContent message={codCategoryError} color='danger' />
                  </GridItem>
                )}
                {importeError && !importe && (
                  <GridItem xs={12}>
                    <SnackbarContent message={importeError} color='danger' />
                  </GridItem>
                )}
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </Dialog>
      {alert}
    </>
  )
}

export default UpdateCategoryModal
