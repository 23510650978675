import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
  Select as Selectable,
  makeStyles,
} from '@material-ui/core'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { registerProfile } from 'redux/actions/profileActions'
import { PROFILE_REGISTER_RESET } from 'redux/constants/profileConstants'
import styles from './styles/registerProfileScreenStyles'

const useStyles = makeStyles(styles)

const AltaProfileScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [codProfileError, setCodProfileError] = useState('')
  const [profileInfo, setProfileInfo] = useState({})
  const [codProfile, setCodProfile] = useState('')
  const [description, setDescription] = useState('')
  const [alert, setAlert] = useState(null)

  const { loadingProfileRegister, successProfileRegister, errorProfileRegister } = useSelector(
    (state) => state.profileRegister
  )

  useEffect(() => {
    if (successProfileRegister) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='GUARDADO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Perfil guardado correctamente
        </SweetAlert>
      )
    }
  }, [successProfileRegister])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!codProfile) {
      return setCodProfileError('Por favor ingrese un Código de Perfil')
    }
    dispatch(
      registerProfile({
        ...profileInfo,
        codProfile,
        description,
      })
    )
  }
  const confirmSuccess = () => {
    setAlert(null)
    setCodProfile('')
    setDescription('')
    setProfileInfo({})
    dispatch({ type: PROFILE_REGISTER_RESET })
  }

  return (
    <>
      <Card>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CÓDIGO DE PERFIL'}
                      id='codeprofile'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: codProfile,
                        onChange: (e) => setCodProfile(e.target.value),
                        type: 'text',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'Descripción'}
                      id='description'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: description,
                        onChange: (e) => setDescription(e.target.value),
                        type: 'text',
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='submit' color='primary'>
                  {loadingProfileRegister ? 'Guardando...' : 'Registrar Perfil'}
                </Button>
              </GridItem>
              {errorProfileRegister && (
                <GridItem xs={12}>
                  <SnackbarContent message={errorProfileRegister} color='danger' />
                </GridItem>
              )}
              {codProfileError && !codProfile && (
                <GridItem xs={12}>
                  <SnackbarContent message={codProfileError} color='danger' />
                </GridItem>
              )}
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      {alert}
    </>
  )
}

export default AltaProfileScreen
