import axios from 'axios'
import {
  ACT_REGISTER_REQUEST,
  ACT_REGISTER_SUCCESS,
  ACT_REGISTER_FAIL,
  ACT_LIST_REQUEST,
  ACT_LIST_SUCCESS,
  ACT_LIST_FAIL,
  ACT_UPDATE_SUCCESS,
  ACT_UPDATE_REQUEST,
  ACT_UPDATE_FAIL,
  ACT_DELETE_FAIL,
  ACT_DELETE_REQUEST,
  ACT_DELETE_SUCCESS,
} from '../constants/actConstants'

export const registerAct = (act) => async (dispatch, getState) => {
   try {
      dispatch({ type: ACT_REGISTER_REQUEST })

      const {
         userLogin: { userInfo },
       } = getState()
   
       const config = {
         headers: {
           'Content-Type': 'application/json',
           'Cache-Control': 'no-cache',
           Authorization: `Bearer ${userInfo.token}`,
         },
       }

      const { data } = await axios.post('/api/actos', act, config)

      dispatch({ type: ACT_REGISTER_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: ACT_REGISTER_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getActs = () => async (dispatch, getState) => {
   try {
      dispatch({ type: ACT_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get('/api/actos', config)

      dispatch({ type: ACT_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: ACT_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const actUpdateInfo = (act) => async (dispatch, getState) => {
   try {
      dispatch({ type: ACT_UPDATE_REQUEST })

      const {
         userLogin: { userInfo },
       } = getState()
   
      const config = {
         headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.put(`/api/actos/${act.id_acto}`, act, config)

      dispatch({ type: ACT_UPDATE_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: ACT_UPDATE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const deleteAct = (id) => async (dispatch, getState) => {
   try {
      dispatch({ type: ACT_DELETE_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
      headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         Authorization: `Bearer ${userInfo.token}`,
      },
      }

      await axios.delete(`/api/actos/${id}`, config)

      dispatch({ type: ACT_DELETE_SUCCESS })
   } catch (error) {
      dispatch({
         type: ACT_DELETE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}
