import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import cx from 'classnames'
import {
  BusinessCenter,
  Dashboard,
  Class,
  CollectionsBookmark,
  FactCheck,
  HowToReg,
  LibraryAddCheck,
  AccountBox,
  Assignment,
  FormatListBulleted,
  ViewList,
  Construction,
  InsertInvitation,
  Group,
  Groups,
  Folder,
  LocalLibrary,
  Task,
  MilitaryTech,
  PersonSearch,
  MiscellaneousServices,
  Apps,
  Lock,
  Article,
  PostAdd,
  Timeline,
  Engineering,
} from '@mui/icons-material'
import PsychologyIcon from '@mui/icons-material/Psychology';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Drawer, List, ListItem, ListItemText, Hidden, Collapse, Icon, Tooltip, makeStyles } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomModal from 'components/Modal/CustomModal'
import SidebarWrapper from './components/SidebarWrapper'
import { useWindowsSize } from 'shared/customHooks/useWindowsSize'
import logoMin from 'assets/img/apple-icon.png'
import { getCollapseStates, getCollapseInitialState, activeRoute } from '../../shared/mappers/routesMappers'
import roles from 'config/roles/roles'
import { logout } from 'redux/actions/userActions'
import sidebarStyle from './styles/sidebarStyle'

const useStyles = makeStyles(sidebarStyle)

const Sidebar = ({ color, logo, routes, bgColor, open, handleDrawerToggle, miniActive }) => {
   const dispatch = useDispatch()
   const history = useHistory()
   const location = useLocation()
   const classes = useStyles()
   const windowsSize = useWindowsSize()
   const mainPanel = useRef()
   const [workCollapse, setWorkCollapse] = useState(false)
   const [catalogCollapse, setCatalogCollapse] = useState(false)
   const [actividadesCollapse, setActividadesCollapse] = useState(false)
   const [userCollapse, setUserCollapse] = useState(false)
   const [profileCollapse, setProfileCollapse] = useState(false)
   const [actoCollapse, setActoCollapse] = useState(false)
   const [categoriaCollapse, setCategoriaCollapse] = useState(false)
   const [utilityCollapse, setUtilityCollapse] = useState(false)
   const [reportCollapse, setReportCollapse] = useState(false)
   const [planningsCollapse, setPlanningsCollapse] = useState(false)
   const [trackingCollapse, setTrackingCollapse] = useState(false)

   const [openAvatar, setOpenAvatar] = useState(false)
   const [state, setState] = useState({})
   const [openLogoutAlert, setOpenLogoutAlert] = useState(false)


   const { userInfo } = useSelector((state) => state.userLogin)

   const adminPermission = userInfo?.permiso.includes(roles.ADMIN_ROLE) ? true : false
   const validatorPermission = userInfo?.permiso.includes(roles.VALIDADOR_ROLE) ? true : false
   const administrativePermission = userInfo?.permiso.includes(roles.ADMINISTRATIVO_ROLE) ? true : false
   const technicianPermission = userInfo?.permiso.includes(roles.TECNICO_ROLE) ? true : false

   useEffect(() => {
      if (!userInfo) {
         history.push('/')
      }
   }, [userInfo])
   useEffect(() => {
      // An object with all collapse state e.g. { tasksCollapse: false, activities: true }
      setState(getCollapseStates(routes))
   }, [])
   useEffect(() => {
      if (windowsSize.width < 960 && open) {
         handleDrawerToggle(false)
      }
   }, [location.pathname])

   // this function creates the links and collapses that appear in the sidebar (left menu)
   const createLinks = (routes) => {
      return routes.map((prop, key) => {
         if (prop.redirect) {
         return null
         }
         if (prop.collapse) {
         var st = {}
         st[prop['state']] = !state[prop.state]
         const navLinkClasses =
            classes.itemLink + ' ' + cx({ [' ' + classes.collapseActive]: getCollapseInitialState(prop.views) })
         const itemText = classes.itemText + ' ' + cx({ [classes.itemTextMini]: miniActive })
         const collapseItemText = classes.collapseItemText + ' ' + cx({ [classes.collapseItemTextMini]: miniActive })

         return (
            <ListItem
               key={key}
               className={cx(
               { [classes.item]: prop.icon !== undefined },
               { [classes.collapseItem]: prop.icon === undefined }
               )}
            >
               <Tooltip title={prop.name} disableHoverListener={!miniActive}>
               <NavLink
                  to={'#'}
                  className={navLinkClasses}
                  onClick={(e) => {
                     e.preventDefault()
                     setState({ ...state, ...st })
                  }}
               >
                  {prop.icon !== undefined ? (
                     typeof prop.icon === 'string' ? (
                     <Icon className={classes.itemIcon}>{prop.icon}</Icon>
                     ) : (
                     <prop.icon className={classes.itemIcon} />
                     )
                  ) : (
                     <span className={classes.collapseItemMini}>{prop.mini}</span>
                  )}
                  <ListItemText
                     primary={prop.name}
                     secondary={<b className={classes.caret + ' ' + (state[prop.state] ? classes.caretActive : '')} />}
                     disableTypography={true}
                     className={cx(
                     { [itemText]: prop.icon !== undefined },
                     { [collapseItemText]: prop.icon === undefined }
                     )}
                  />
               </NavLink>
               </Tooltip>
               <Collapse in={state[prop.state]} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>{createLinks(prop.views)}</List>
               </Collapse>
            </ListItem>
         )
         }
         const innerNavLinkClasses =
         classes.collapseItemLink + ' ' + cx({ [' ' + classes[color]]: activeRoute(prop.layout + prop.path) })
         const navLinkClasses =
         classes.itemLink + ' ' + cx({ [' ' + classes[color]]: activeRoute(prop.layout + prop.path) })
         const itemText = classes.itemText + ' ' + cx({ [classes.itemTextMini]: miniActive })
         const collapseItemText = classes.collapseItemText + ' ' + cx({ [classes.collapseItemTextMini]: miniActive })

         return (
         <ListItem
            key={key}
            className={cx(
               { [classes.item]: prop.icon !== undefined },
               { [classes.collapseItem]: prop.icon === undefined }
            )}
         >
            <Tooltip title={prop.name} disableHoverListener={!miniActive}>
               <NavLink
               to={prop.layout + prop.path}
               className={cx(
                  { [navLinkClasses]: prop.icon !== undefined },
                  { [innerNavLinkClasses]: prop.icon === undefined }
               )}
               >
               {prop.icon !== undefined ? (
                  typeof prop.icon === 'string' ? (
                     <Icon className={classes.itemIcon}>{prop.icon}</Icon>
                  ) : (
                     <prop.icon className={classes.itemIcon} />
                  )
               ) : (
                  <span className={classes.collapseItemMini}>{prop.mini}</span>
               )}
               <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={cx({ [itemText]: prop.icon !== undefined }, { [collapseItemText]: prop.icon === undefined })}
               />
               </NavLink>
            </Tooltip>
         </ListItem>
         )
      })
   }

   const itemText = classes.itemText + ' ' + cx({ [classes.itemTextMini]: miniActive })
   const collapseItemText = classes.collapseItemText + ' ' + cx({ [classes.collapseItemTextMini]: miniActive })
   const userWrapperClass = classes.user + ' ' + cx({ [classes.whiteAfter]: bgColor === 'white' })
   const photo = classes.photo + ' ' + classes.roundPhoto

   let user = (
      <div className={userWrapperClass}>
         <div className={photo}>
         <img
            src={`${axios.defaults.baseURL}/public/assets/img/avatars/avatar.jpg`}
            className={classes.avatarImg}
            alt={userInfo?.userName}
         />
         </div>
         <List className={classes.list}>
         <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink to={'/admin/user-page'} className={classes.itemLink + ' ' + classes.userCollapseButton}>
               <ListItemText
               primary={`${userInfo?.userName}`}
               disableTypography={true}
               className={itemText + ' ' + classes.userItemText}
               />
            </NavLink>
         </ListItem>
         </List>
      </div>
   )

   let links = (
      <List className={classes.list}>
         {/* MI PERFIL */}
         <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: false })}>
         <Tooltip title='MI PERFIL' disableHoverListener={!miniActive}>
            <NavLink to={'/admin/user-page'} className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
               <span className={classes.collapseItemMini}>
               <AccountBox />
               </span>
               <ListItemText
               primary='MI PERFIL'
               disableTypography={true}
               className={cx({ [itemText]: true }, { [collapseItemText]: false })}
               />
            </NavLink>
         </Tooltip>
         </ListItem>
         {/* GESTIÓN DE TRABAJO */}
         {/* => ADMIN - VALIDADOR - TÉCNICO - VALIDADOR */}
         {(adminPermission || administrativePermission || technicianPermission || validatorPermission) && (
         <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <Tooltip title="GESTIÓN DE TRABAJO" disableHoverListener={!miniActive}>
               <NavLink
               to="#!"
               onClick={() => setWorkCollapse(!workCollapse)}
               className={`${classes.itemLink}` + " " + classes.collapseItemLink}
               >
               <span className={classes.collapseItemMini}>
                  <BusinessCenter />
               </span>
               <ListItemText
                  primary="GESTIÓN DE TRABAJO"
                  secondary={<b className={classes.caret + " " + (workCollapse ? classes.caretActive : "")} />}
                  disableTypography={true}
                  className={cx({ [itemText]: false }, { [collapseItemText]: true })}
               />
               </NavLink>
            </Tooltip>
            <Collapse in={workCollapse} unmountOnExit>
               <List className={classes.list + " " + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title="CONTROLES GENERALES" disableHoverListener={!miniActive}>
                     <NavLink
                     to={"/admin/work-list"}
                     className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <FormatListBulleted />
                     </span>
                     <ListItemText
                        primary="CONTROLES GENERALES"
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>

               {
               technicianPermission
               && (
                  <>
                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="CONTROLES PENDIENTES" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-tech-todo-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="CONTROLES PENDIENTES"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>

                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="CONTROLES REALIZADOS" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-tech-done-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="CONTROLES REALIZADOS"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>

                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="SEGUIMIENTOS" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-tech-correction-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="SEGUIMIENTOS"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>
                  </>
               )
               }
               {
               validatorPermission
               && (
                  <>
                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="CONTROLES PENDIENTES" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-validator-todo-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="CONTROLES PENDIENTES"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>
                  </>
               )
               }
               {
               administrativePermission
               && (
                  <>
                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="CONTROLES DE OTROS PERFILES" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-administrative-other-profiles-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="CONTROLES DE OTROS PERFILES"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>

                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="AVISOS" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-administrative-notice-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="AVISOS"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>

                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title="CONTROLES PENDIENTES" disableHoverListener={!miniActive}>
                        <NavLink
                           to={"/admin/work-administrative-todo-list"}
                           className={`${classes.itemLink}` + " " + classes.collapseItemLink}
                        >
                           <span className={classes.collapseItemMini}>
                              <FormatListBulleted />
                           </span>
                           <ListItemText
                              primary="CONTROLES PENDIENTES"
                              disableTypography={true}
                              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                           />
                        </NavLink>
                     </Tooltip>
                  </ListItem>
                  </>
               )
               }
               </List>
            </Collapse>
         </ListItem>
         )}
         {/* UTILIDADES */}
         {adminPermission && (
         <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <Tooltip title='UTILIDADES' disableHoverListener={!miniActive}>
               <NavLink
               to='#!'
               onClick={() => setUtilityCollapse(!utilityCollapse)}
               className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
               >
               <span className={classes.collapseItemMini}>
               <Construction />
               </span>
               <ListItemText
                  primary='UTILIDADES'
                  secondary={<b className={classes.caret + ' ' + (utilityCollapse ? classes.caretActive : '')} />}
                  disableTypography={true}
                  className={cx({ [itemText]: false }, { [collapseItemText]: true })}
               />
               </NavLink>
            </Tooltip>
            <Collapse in={utilityCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='IMPORTAR LÍNEAS' disableHoverListener={!miniActive}>
                     <NavLink
                     to={'/admin/import-lines'}
                     className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <Assignment />
                     </span>
                     <ListItemText
                        primary='IMPORTAR LÍNEAS'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               {
               false
               &&
                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title='EXPORTAR LÍNEAS' disableHoverListener={!miniActive}>
                        <NavLink
                        to={'/admin/export-lines'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                        >
                        <span className={classes.collapseItemMini}>
                           <Assignment />
                        </span>
                        <ListItemText
                           primary='EXPORTAR LÍNEAS'
                           disableTypography={true}
                           className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                        />
                        </NavLink>
                     </Tooltip>
                  </ListItem>
               }
               </List>
            </Collapse>
         </ListItem>
         )}
         {/* PLANIFICACIÓN */}
         {adminPermission && (
         <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <Tooltip title='PLANIFICACIONES' disableHoverListener={!miniActive}>
               <NavLink
               to='#!'
               onClick={() => setPlanningsCollapse(!planningsCollapse)}
               className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
               >
               <span className={classes.collapseItemMini}>
                  {/* <PsychologyIcon /> */}
                  <EmojiObjectsIcon />
               </span>
               <ListItemText
                  primary='PLANIFICACIONES'
                  secondary={<b className={classes.caret + ' ' + (planningsCollapse ? classes.caretActive : '')} />}
                  disableTypography={true}
                  className={cx({ [itemText]: false }, { [collapseItemText]: true })}
               />
               </NavLink>
            </Tooltip>
            {/* LISTADO DE PLANIFICACIONES */}
            <Collapse in={planningsCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='...' disableHoverListener={!miniActive}>
                     <NavLink
                        to={'/admin/plannings-list'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <FormatListBulleted />
                     </span>
                     <ListItemText
                        primary='LISTADO DE PLANIFICACIONES'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               </List>
            </Collapse>
            {/* ALTA PLANIFICACIÓN */}
            <Collapse in={planningsCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='...' disableHoverListener={!miniActive}>
                     <NavLink
                        to={'/admin/planning-register'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <PostAdd />
                     </span>
                     <ListItemText
                        primary='ALTA PLANIFICACIÓN'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               </List>
            </Collapse>
         </ListItem>
         )}
         {/* INFORMES */}
         {adminPermission && (
         <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <Tooltip title='INFORMES' disableHoverListener={!miniActive}>
               <NavLink
               to='#!'
               onClick={() => setReportCollapse(!reportCollapse)}
               className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
               >
               <span className={classes.collapseItemMini}>
               <Folder />
               </span>
               <ListItemText
                  primary='INFORMES'
                  secondary={<b className={classes.caret + ' ' + (reportCollapse ? classes.caretActive : '')} />}
                  disableTypography={true}
                  className={cx({ [itemText]: false }, { [collapseItemText]: true })}
               />
               </NavLink>
            </Tooltip>
            {/* SEGUIMIENTO SEMANAL */}
            <Collapse in={reportCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='SEGUIMIENTO SEMANAL' disableHoverListener={!miniActive}>
                     <NavLink
                        to={'/admin/weekly-report'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <Assignment />
                     </span>
                     <ListItemText
                        primary='SEGUIMIENTO SEMANAL'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               </List>
            </Collapse>
            {/* SEGUIMIENTO MENSUAL */}
            <Collapse in={reportCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='SEGUIMIENTO MENSUAL' disableHoverListener={!miniActive}>
                     <NavLink
                        to={'/admin/monthly-report'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <Assignment />
                     </span>
                     <ListItemText
                        primary='SEGUIMIENTO MENSUAL'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               </List>
            </Collapse>
            {/* SEGUIMIENTO PLANIFICACIÓN */}
            <Collapse in={reportCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='SEGUIMIENTO PLANIFICACIÓN' disableHoverListener={!miniActive}>
                     <NavLink
                        to={'/admin/planning-report'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <Assignment />
                     </span>
                     <ListItemText
                        primary='SEGUIMIENTO PLANIFICACIÓN'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               </List>
            </Collapse>
            {/* SEGUIMIENTO FACTURACIÓN MENSUAL */}
            <Collapse in={reportCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
               <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                  <Tooltip title='FACTURACIÓN MENSUAL' disableHoverListener={!miniActive}>
                     <NavLink
                        to={'/admin/monthly-billing-report'}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                     <span className={classes.collapseItemMini}>
                        <Assignment />
                     </span>
                     <ListItemText
                        primary='FACTURACIÓN MENSUAL'
                        disableTypography={true}
                        className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                     />
                     </NavLink>
                  </Tooltip>
               </ListItem>
               </List>
            </Collapse>
         </ListItem>
         )}
         {/* SEGUIMIENTOS */}
         {adminPermission && (
         <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <Tooltip title='SEGUIMIENTOS' disableHoverListener={!miniActive}>
               <NavLink
                  to='#!'
                  onClick={() => setTrackingCollapse(!trackingCollapse)}
                  className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
               >
                  <span className={classes.collapseItemMini}>
                     <Timeline />
                  </span>
                  <ListItemText
                     primary='SEGUIMIENTOS'
                     secondary={<b className={classes.caret + ' ' + (trackingCollapse ? classes.caretActive : '')} />}
                     disableTypography={true}
                     className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                  />
               </NavLink>
            </Tooltip>
            {/* SEGUIMIENTO TÉCNICOS */}
            <Collapse in={trackingCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
                  <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                     <Tooltip title='TÉCNICOS' disableHoverListener={!miniActive}>
                        <NavLink
                           to={'/admin/technicians-tracking'}
                           className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                        >
                        <span className={classes.collapseItemMini}>
                           <Engineering />
                        </span>
                        <ListItemText
                           primary='TÉCNICOS'
                           disableTypography={true}
                           className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                        />
                        </NavLink>
                     </Tooltip>
                  </ListItem>
               </List>
            </Collapse>
         </ListItem>
         )}
         {/* CATALOGOS */}
         {adminPermission && (
         <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <Tooltip title='CATALOGOS' disableHoverListener={!miniActive}>
               <NavLink
               to='#!'
               onClick={() => setCatalogCollapse(!catalogCollapse)}
               className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
               >
               <span className={classes.collapseItemMini}>
                  <LocalLibrary />
               </span>
               <ListItemText
                  primary='CATALOGOS'
                  secondary={<b className={classes.caret + ' ' + (catalogCollapse ? classes.caretActive : '')} />}
                  disableTypography={true}
                  className={cx({ [itemText]: false }, { [collapseItemText]: true })}
               />
               </NavLink>
            </Tooltip>
            <Collapse in={catalogCollapse} unmountOnExit>
               <List className={classes.list + ' ' + classes.collapseList}>
                  <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
                     <Tooltip title='USUARIOS' disableHoverListener={!miniActive}>
                     <NavLink
                        to='#!'
                        onClick={() => setUserCollapse(!userCollapse)}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                        <span className={classes.collapseItemMini}>
                           <PersonSearch />
                        </span>
                        <ListItemText
                           primary='USUARIOS'
                           secondary={<b className={classes.caret + ' ' + (userCollapse ? classes.caretActive : '')} />}
                           disableTypography={true}
                           className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                        />
                     </NavLink>
                     </Tooltip>
                     <Collapse in={userCollapse} unmountOnExit>
                     <List className={classes.list + ' ' + classes.collapseList}>
                        <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='LISTADO DE USUARIOS' disableHoverListener={!miniActive}>
                           <NavLink
                              to={'/admin/user-list'}
                              className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                           >
                              <span className={classes.collapseItemMini}>
                                 <FormatListBulleted />
                              </span>
                              <ListItemText
                                 primary='LISTADO DE USUARIOS'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                              />
                           </NavLink>
                           </Tooltip>
                        </ListItem>
                           <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='ALTA DE USUARIOS' disableHoverListener={!miniActive}>
                              <NavLink
                                 to={'/admin/user-register'}
                                 className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                              >
                                 <span className={classes.collapseItemMini}>
                                 <PostAdd />
                                 </span>
                                 <ListItemText
                                 primary='ALTA DE USUARIOS'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                                 />
                              </NavLink>
                           </Tooltip>
                           </ListItem>
                     </List>
                     </Collapse>
                  </ListItem>
               </List>
               <List className={classes.list + ' ' + classes.collapseList}>
                  <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
                     <Tooltip title='PERFILES' disableHoverListener={!miniActive}>
                     <NavLink
                        to='#!'
                        onClick={() => setProfileCollapse(!profileCollapse)}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                        <span className={classes.collapseItemMini}>
                           <PersonSearch />
                        </span>
                        <ListItemText
                           primary='PERFILES'
                           secondary={<b className={classes.caret + ' ' + (profileCollapse ? classes.caretActive : '')} />}
                           disableTypography={true}
                           className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                        />
                     </NavLink>
                     </Tooltip>
                     <Collapse in={profileCollapse} unmountOnExit>
                     <List className={classes.list + ' ' + classes.collapseList}>
                        <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='LISTADO DE PERFILES' disableHoverListener={!miniActive}>
                           <NavLink
                              to={'/admin/profile-list'}
                              className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                           >
                              <span className={classes.collapseItemMini}>
                                 <FormatListBulleted />
                              </span>
                              <ListItemText
                                 primary='LISTADO DE PERFILES'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                              />
                           </NavLink>
                           </Tooltip>
                        </ListItem>
                           <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='ALTA DE PERFILES' disableHoverListener={!miniActive}>
                              <NavLink
                                 to={'/admin/profile-register'}
                                 className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                              >
                                 <span className={classes.collapseItemMini}>
                                 <PostAdd />
                                 </span>
                                 <ListItemText
                                 primary='ALTA DE PERFILES'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                                 />
                              </NavLink>
                           </Tooltip>
                           </ListItem>
                           <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='ASIGNAR PERFILES' disableHoverListener={!miniActive}>
                              <NavLink
                                 to={'/admin/asign-profile'}
                                 className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                              >
                                 <span className={classes.collapseItemMini}>
                                 <PostAdd />
                                 </span>
                                 <ListItemText
                                 primary='ASIGNAR PERFILES'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                                 />
                              </NavLink>
                           </Tooltip>
                           </ListItem>
                     </List>
                     </Collapse>
                  </ListItem>
               </List>
               <List className={classes.list + ' ' + classes.collapseList}>
                  <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
                     <Tooltip title='TIPOS DE ACTOS' disableHoverListener={!miniActive}>
                     <NavLink
                        to='#!'
                        onClick={() => setActoCollapse(!actoCollapse)}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                        <span className={classes.collapseItemMini}>
                           <PersonSearch />
                        </span>
                        <ListItemText
                           primary='TIPOS DE ACTOS'
                           secondary={<b className={classes.caret + ' ' + (actoCollapse ? classes.caretActive : '')} />}
                           disableTypography={true}
                           className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                        />
                     </NavLink>
                     </Tooltip>
                     <Collapse in={actoCollapse} unmountOnExit>
                     <List className={classes.list + ' ' + classes.collapseList}>
                        <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='LISTADO DE TIPOS DE ACTOS' disableHoverListener={!miniActive}>
                           <NavLink
                              to={'/admin/act-list'}
                              className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                           >
                              <span className={classes.collapseItemMini}>
                                 <FormatListBulleted />
                              </span>
                              <ListItemText
                                 primary='LISTADO DE TIPOS DE ACTOS'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                              />
                           </NavLink>
                           </Tooltip>
                        </ListItem>
                           <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='ALTA DE ACTOS' disableHoverListener={!miniActive}>
                              <NavLink
                                 to={'/admin/act-register'}
                                 className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                              >
                                 <span className={classes.collapseItemMini}>
                                 <PostAdd />
                                 </span>
                                 <ListItemText
                                 primary='ALTA DE ACTOS'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                                 />
                              </NavLink>
                           </Tooltip>
                           </ListItem>
                     </List>
                     </Collapse>
                  </ListItem>
               </List>
               <List className={classes.list + ' ' + classes.collapseList}>
                  <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
                     <Tooltip title='CATEGORÍAS' disableHoverListener={!miniActive}>
                     <NavLink
                        to='#!'
                        onClick={() => setCategoriaCollapse(!categoriaCollapse)}
                        className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                     >
                        <span className={classes.collapseItemMini}>
                           <PersonSearch />
                        </span>
                        <ListItemText
                           primary='CATEGORIAS'
                           secondary={<b className={classes.caret + ' ' + (categoriaCollapse ? classes.caretActive : '')} />}
                           disableTypography={true}
                           className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                        />
                     </NavLink>
                     </Tooltip>
                     <Collapse in={categoriaCollapse} unmountOnExit>
                     <List className={classes.list + ' ' + classes.collapseList}>
                        <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='LISTADO DE CATEGORÍAS' disableHoverListener={!miniActive}>
                           <NavLink
                              to={'/admin/category-list'}
                              className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                           >
                              <span className={classes.collapseItemMini}>
                                 <FormatListBulleted />
                              </span>
                              <ListItemText
                                 primary='LISTADO DE CATEGORÍAS'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                              />
                           </NavLink>
                           </Tooltip>
                        </ListItem>
                           <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
                           <Tooltip title='ALTA DE CATEGORÍAS' disableHoverListener={!miniActive}>
                              <NavLink
                                 to={'/admin/category-register'}
                                 className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
                              >
                                 <span className={classes.collapseItemMini}>
                                 <PostAdd />
                                 </span>
                                 <ListItemText
                                 primary='ALTA DE CATEGORÍAS'
                                 disableTypography={true}
                                 className={cx({ [itemText]: true }, { [collapseItemText]: false })}
                                 />
                              </NavLink>
                           </Tooltip>
                           </ListItem>
                     </List>
                     </Collapse>
                  </ListItem>
               </List>
               </Collapse>
         </ListItem>
         )}
         {/* SALIR */}
         <ListItem
            className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}
            onClick={() => setOpenLogoutAlert(true)}
         >
            <Tooltip title='Salir de la aplicación' disableHoverListener={!miniActive}>
               <NavLink to={'#'} className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
                  <span className={classes.collapseItemMini}>
                  <ExitToApp />
                  </span>
                  <ListItemText
                  primary='Salir'
                  disableTypography={true}
                  className={cx({ [itemText]: true }, { [collapseItemText]: true })}
                  />
               </NavLink>
            </Tooltip>
         </ListItem>
      </List>
   )

   const logoNormal = classes.logoNormal + ' ' + cx({ [classes.logoNormalSidebarMini]: miniActive })
   const logoClasses = classes.logo + ' ' + cx({ [classes.whiteAfter]: bgColor === 'white' })

   let brand = (
      <div className={logoClasses}>
         <a href='/admin/user-page' className={classes.logoMini}>
         <img src={logoMin} alt='logo' style={{ width: '70px' }} />
         </a>
         <a href='/admin/user-page' className={logoNormal}>
         <img src={logo} alt='logo' className={classes.img} />
         </a>
      </div>
   )

   const drawerPaper = classes.drawerPaper + ' ' + cx({ [classes.drawerPaperMini]: miniActive })
   const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
         [classes.drawerPaperMini]: miniActive,
         [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      })

   const handleLogout = () => {
      setOpenLogoutAlert(false)
      dispatch(logout())
   }

   return (
      <div ref={mainPanel}>
         <Hidden mdUp implementation='css'>
         <Drawer
            variant='temporary'
            anchor='right'
            open={open}
            classes={{
               paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
               keepMounted: true, // Better open performance on mobile.
            }}
         >
            {brand}
            <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
         </Drawer>
         </Hidden>
         <Hidden smDown implementation='css'>
         <Drawer
            // onMouseOver={() => setMiniActive(false)}
            // onMouseOut={() => setMiniActive(true)}
            anchor='left'
            variant='permanent'
            open
            classes={{
               paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
         >
            {brand}
            <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
         </Drawer>
         </Hidden>
         <CustomModal
         title='Cerrar Sesión'
         open={openLogoutAlert}
         setOpen={setOpenLogoutAlert}
         acceptText='Sí'
         acceptHandler={handleLogout}
         >
         <GridContainer>
            <GridItem xs={12}>
               <h4>¿Esta seguro que quiere salir de la aplicación?</h4>
            </GridItem>
         </GridContainer>
         </CustomModal>
      </div>
   )
}

Sidebar.defaultProps = {
  bgColor: 'blue',
}
Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
}

export default Sidebar
