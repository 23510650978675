import { useState, useEffect } from "react"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import DateFnsUtils from "@date-io/date-fns"
import { es } from "date-fns/locale"
import { makeStyles, FormControl, InputLabel, MenuItem, Select as Selectable, TextField } from '@material-ui/core'

import styles from "./styles/updateWorkStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CustomInput from "components/CustomInput/CustomInput"

const useStyles = makeStyles(styles)

export const TabPanelContent = ({ act_data, setActsD, acts_data, setActToUpdate, value, index,
   setCanUpdate, submitHandler, setSuccessUpdate, technicians }) => {

   const classes = useStyles()

   // toDo: add useEffect
   const [actD, setActD] = useState([])
   const act_data_length = actD.length
   const act_data_half_length = Math.ceil( actD.length / 2 )
   const act_data_first_column = actD.slice( 0, act_data_half_length )
   const act_data_second_column = actD.slice( act_data_half_length, act_data_length )

   const handleInputFormChange = (e, column, index_column, isDateV) => {
      const new_data = column.data_type === "date" ? e : e.target.value
      const cType = column.data_type
      const cLength = column.data_length
      
      let validData = false
      if ( cType === "date" && new_data && new_data != null && isDate(new_data) ) {

         let validDate = true
         try {           
            if (format(new Date(new_data), "dd-MM-yyy") != "01-01-1900") {
      
               for (let i = 0; i < index + 1; i++) {

                  if ( !validDate ) break

                  const act = acts_data[i]

                  let isDateBefore = true
                  let isActualCol = false
                  for (let j = 0; j < act.length; j++) {
                     const col = act[j]
                     if ( col.id_act === column.id_act && col.name_db === column.name_db ) isActualCol = true
                     
                     if ( col.data_type === "date" && !isActualCol && col.data != null && col.data !="1900-01-01" ) {

                        if ( isDateBefore && col.data && new Date(new_data) < new Date(col.data) ) {
                           validDate = false
                           break
                        }else if ( !isDateBefore && col.data && new Date(new_data) > new Date(col.data) ) {
                           validDate = false
                           break
                        }
                     }

                     if ( isActualCol ) isDateBefore = false            
                  }        
               }
            }
         } catch (error) { }
         validData = validDate        
      }else if(( cType === "int" || cType === "decimal" )
         && ( !isNaN(new_data) || !( cType === "int" && (new_data != null && Number.isInteger(new_data)) ) )) 
      {
         validData = true
      }else if ( cType.includes("string") ) {
         if ( new_data.length > cLength ) return
         validData = true
      }else if ( cType === "user" ) {
         if ( technicians.map(tech => tech.usuario.toLowerCase()).includes( new_data.toLowerCase() ) ) validData = true
      }


      const updated_act = actD.map(col => {
         if (col.name_db === column.name_db) {
            col.data = new_data
            if ( validData ) col.error = false
            else col.error = true
         }
         
         return col
      })

      setSuccessUpdate(false)
      setActD(updated_act)

      if ( validData ) {
         setCanUpdate(true)
         setActToUpdate(updated_act)
         
      }else setCanUpdate(false)
      
   }

   useEffect(() => {
      setActD(act_data)
      return () => setActD([])
    
    }, [value])

   const isDate = (date) => {
      return (new Date(date) !== "Invalid Date") || !isNaN(new Date(date))
   }

   const getFormColumn = ( act ) => {

      return act.map((column, i) => {
         const column2={...column}
         let cData = column.data
         if ( cData != null && cData && column.data_type === "date" ) {

            if (!isDate(cData)) {
               const [day, month, year] = cData.split("-")
               cData = format(new Date(+year, +month -1, +day), "dd-MM-yyyy")
            }

         }
         if ( cData != null && cData && column.data_type === "decimal" ) {

            cData = isNaN(cData) ? cData : cData.toFixed(2)
         }

         let notRegistered = false
         let cType = column.data_type
         if ( cType.includes("string") ) cType = "text"
         else if ( cType === "decimal" ) cType = "text"
         else if ( cType === "int" ) cType = "number"
         else if ( cType == "user" ) {
            if ( cData !== "" && !technicians.map(tech => tech.usuario).includes(cData) ) {
               notRegistered = true
               cData = `${ cData } (Técnico no registrado)`
            }
            return (
               <GridItem xs={12}>
                  <FormControl fullWidth>
                     <InputLabel htmlFor='select-line'>Técnico</InputLabel>
                     <Selectable
                        MenuProps={{
                           className: classes.selectMenu,
                        }}
                        className={classes.select}
                        value={cData !== "" && cData}
                        style={{fontSize: "15px", color: "#111111"}}
                        onChange={(e) => handleInputFormChange(e, column)}
                        error={notRegistered}
                        inputProps={{
                           name: 'select-line',
                           id: 'select-line',
                           required: true,
                        }}
                     >
                        <MenuItem
                           disabled
                           classes={{
                              root: classes.selectMenuItem,
                           }}
                        >
                           Seleccione el técnico para este acto
                        </MenuItem>
                        {
                           cData !== "" && !technicians.map(tech => tech.usuario).includes(cData)
                           && <MenuItem
                                 value={ cData }
                                 disabled                               
                                 classes={{
                                    root: classes.selectMenuItem,
                                 }}
                              >
                                 { cData }
                              </MenuItem>
                        }

                        {
                           technicians.map((tech, i) => {
                              const value = tech.usuario

                              return (
                                 <MenuItem
                                    value={value}
                                    key={tech.id_usuario}
                                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                 >
                                    {value}
                                 </MenuItem>
                              )
                           })
                        }
                     </Selectable>
                  </FormControl>
               </GridItem>
            )
         }
         else if ( cType === "date"){

            return (
               <GridItem xs={12} key={`tab_${column.name_db}_${index}_`}>
                  <LocalizationProvider
                     dateAdapter={AdapterDateFns}
                     locale={es}
                     utils={DateFnsUtils}
                  >
                     <DesktopDatePicker
                        label={column.name_excel}
                        inputFormat="dd-MM-yyyy"
                        value={cData}
                        onChange={(newVal) => handleInputFormChange(newVal, column, i, true)}
                        renderInput={(params) => {
                           return <TextField
                              style={{width:"100%", border: "2px solid blue !important"}}
                              required={column.error}
                              {...params}
                              error={column.error}
                           />
                        }}
                        
                        required={false}
                     />
                  </LocalizationProvider>
               </GridItem>
            )
         }else {
            cType = "text"
         }

         return (
            <GridItem xs={12} key={`tab_${column.name_db}_${index}_`}>
               <CustomInput
                  labelText={column.name_excel}
                  formControlProps={{
                     fullWidth: true,
                  }}
                  error={column.error}
                  inputProps={{
                     value: cData || "",
                     onChange: (e) => handleInputFormChange(e, column2),
                     type: cType,
                     required: false,
                     disabled: index === 0 && column.name_db === "referencia_solicitud"
                  }}
               />
            </GridItem>
         )
      })
   }

   return (
      <form onSubmit={submitHandler}>
         <GridContainer spacing={1}>
            <GridItem xs={6}>
               <GridContainer>
                  {
                     getFormColumn(act_data_first_column)
                  }
               </GridContainer>
            </GridItem>

            <GridItem xs={6}>
               <GridContainer>
                  {
                     getFormColumn(act_data_second_column)
                  }
               </GridContainer>
            </GridItem>
         </GridContainer>
      </form>
   )
}

