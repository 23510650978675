import customSelectStyle from 'assets/jss/material-ui-react/customSelectStyle'

const styles = {
   ...customSelectStyle,
   formControlMargins: {
      margin: '3px 0 !important',
   },
   gridContainer: {
      justifyContent: 'center',
   },
   tableHeadCell: {
      fontSize: "1.063rem",
      textAlign: "left",
      fontWeight: "300",
      lineHeight: "1.3em !important",
      padding: "5px 5px",
      lineHheight: "normal",
      position: "relative",
      transition: "box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      boxShadow: "inset 0 0 0 0 transparent",

      flex: "1 0",
      textOverflow: "ellipsis",
      padding: "7px 5px",
      overflow: "hidden",
      transition: "0.3s ease",
      transitionProperty: "width, min-width, padding, opacity",
      margin: "auto",

   },
   thcChildren: {
      textAlign: "center",
  },
  taLeft: {
   textAlign: "left"
  }
}

export default styles
