const styles = {
    button: {
      padding: '10px 30px',
      color: 'white',
      borderRadius: '6px',
      fontWeight: 'bold',
    },
    success: {
      backgroundColor: 'green',
    },
  }
  
  export default styles
  