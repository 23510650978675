import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
  Dialog,
  makeStyles,
  Select as Selectable,
} from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { profileUpdateInfo, getProfiles } from 'redux/actions/profileActions'
import { PROFILE_UPDATE_RESET } from 'redux/constants/profileConstants'
import styles from '../styles/updateTeamModalStyles'

const useStyles = makeStyles(styles)

const UpdateActividadModal = ({ handleCloseModal, updateActividadModal, showUpdateActividad }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [actividadInfo, setActividadInfo] = useState(showUpdateActividad)
  const [fvalidacion, setFvalidacion] = useState(actividadInfo.fecha_validacion)
  const [codProfileError, setCodProfileError] = useState('')
  const [calidad, setCalidad] = useState(actividadInfo.control_calidad)
  const [frevision, setFrevision] = useState(actividadInfo.fecha_envio_revision)
  const [frevisionaei, setFrevisionAEI] = useState(actividadInfo.fecha_revision_aei)
  const [revisionaei, setRevisionAEI] = useState(actividadInfo.revision_aei)
  const [frevisioncytsa, setFrevisionCYTSA] = useState(actividadInfo.fecha_revision_cytsa)
  const [revisionaeirs, setRevisionAEIRS] = useState(actividadInfo.revision_aei_lista_rs)
  const [resultado, setResultado] = useState(actividadInfo.resultado_validacion)
  const [observaciones, setObservaciones] = useState(actividadInfo.observaciones_validacion)
  const [fgeneracionrs, setFgeneracionRS] = useState(actividadInfo.fecha_generacion_rs)
  const [frevisionrs, setFrevisionRS] = useState(actividadInfo.fecha_revision_rs)
  const [fsubidaportafirmas, setFsubidaPortafirmas] = useState(actividadInfo.fecha_subida_portafirmas)
  const [ffirmars, setFfirmaRS] = useState(actividadInfo.fecha_firma_rs)
  const [fenviors, setFenvioRS] = useState(actividadInfo.fecha_envio_rs)
  const [rsfLecturars, setRsfLecturaRS] = useState(actividadInfo.req_sub_fec_lectura)
  const [rsfCaducidadrs, setrsfCaducidadRS] = useState(actividadInfo.req_sub_fec_caducidad)
  const [fAlegacionesrs, setfAlegacionesRS] = useState(actividadInfo.fecha_alegaciones)
  const [fAlegacionesIGrs, setfAlegacionesIGRS] = useState(actividadInfo.fecha_alegaciones_insta_generica)
  const [fAlegacionesGEIsrs, setfAlegacionesGEIRS] = useState(actividadInfo.fecha_alegaciones_geiser)
  const [observacionesrs, setObservacionesRS] = useState(actividadInfo.fecha_alegaciones_geiser)
  const [frevisionrars, setFrevisionRARS] = useState(actividadInfo.fecha_revision)
  const [ccalidadrars, setCcalidadRARS] = useState(actividadInfo.control_calidad)
  const [erevisionrars, setErevisionRARS] = useState(actividadInfo.revision_aei)
  const [frevisionaeirars, setFrevisionaeiRARS] = useState(actividadInfo.fecha_revision_aei)
  const [frevisioncytsarars, setFrevisioncytsaRARS] = useState(actividadInfo.fecha_revision_cytsa)
  const [revaeigeneaiprrars, setRevaeiGeneAIPRRARS] = useState(actividadInfo.revision_aei_generacion_aipr)
  const [resvalrars, setResValRARS] = useState(actividadInfo.resultado_validacion)
  const [oaeigairars, setObaeiGaiprRARS] = useState(actividadInfo.observaciones_aei_generacion_aipr)
  const [finformecierreic, setFinformecierreIC] = useState(actividadInfo.fecha_informe_cierre)
  const [fenvioic, setFenvioicIC] = useState(actividadInfo.fecha_envio)
  const [obic, setObIC] = useState(actividadInfo.observaciones)
  const [fgeneracionaipr, setFgeneracionAIPR] = useState(actividadInfo.fecha_generacion_aipr)
  const [frevisionaipr, setFrevisionAIPR] = useState(actividadInfo.fecha_revision_aipr)
  const [fsubportaipr, setFsubportaAIPR] = useState(actividadInfo.fecha_subida_portafirmas_aipr)
  const [fvalaipr, setFvalAIPR] = useState(actividadInfo.fecha_valiacion_aipr)
  const [ffiraipr, setFfirAIPR] = useState(actividadInfo.fecha_firma_aipr)
  const [fenvaipr, setFenvAIPR] = useState(actividadInfo.fecha_envio_aipr)
  const [flecaipr, setFlecAIPR] = useState(actividadInfo.fecha_lectura)
  const [fcadaipr, setFcadAIPR] = useState(actividadInfo.fecha_caducidad)
  const [falegaipr, setFalegaiprAIPR] = useState(actividadInfo.fecha_alegaciones)
  const [falegaigaipr, setFfalegaigAIPR] = useState(actividadInfo.fecha_alegaciones_insta_generica)
  const [falegageiseraipr, setFalegageiserAIPR] = useState(actividadInfo.fecha_alegaciones_geiser)
  const [obsevacionesaipr, setObservacionesAIPR] = useState(actividadInfo.observaciones)
  const [frevisionraaipr, setFrevisionRAAIPR] = useState(actividadInfo.fecha_revision)
  const [controlcalraaipr, setControlcalRAAIPR] = useState(actividadInfo.control_calidad)
  const [fenvrevraaipr, setFenvrevrRAAIPR] = useState(actividadInfo.fecha_envio_revision)
  const [frevraaipr, setFrevaeiRAAIPR] = useState(actividadInfo.fecha_revision_aei)
  const [revaeiraaipr, setRevaeiRAAIPR] = useState(actividadInfo.revision_aei)
  const [frevcytraaipr, setFrevcytRAAIPR] = useState(actividadInfo.fecha_revision_cytsa)
  const [revaeigeraaipr, setRevaeigeRAAIPR] = useState(actividadInfo.revision_aei_generacion_rr)
  const [sentalegraaipr, setSentalegRAAIPR] = useState(actividadInfo.sentido_alegaciones)
  const [obsraaipr, setObsRAAIPR] = useState(actividadInfo.obsevaciones)
  const [fgenrr, setFgenerRR] = useState(actividadInfo.fecha_generacion_rr)
  const [frevrr, setFrevRR] = useState(actividadInfo.fecha_revision_rr)
  const [fsubportrr, setFsubportRR] = useState(actividadInfo.fecha_subida_portafirmas_rr)
  const [fvalrr, setFvalRR] = useState(actividadInfo.fecha_validacion_rr)
  const [ffirr, setFfiRR] = useState(actividadInfo.fecha_firma_rr)
  const [irvrr, setIrvRR] = useState(actividadInfo.importe_reintegros_voluntarios)
  const [njcprr, setNjcpRR] = useState(actividadInfo.n_justificante_carta_pago)
  const [cpprr, setCppRR] = useState(actividadInfo.carta_pago_principal)
  const [cpirr, setCpiRR] = useState(actividadInfo.carta_pago_intereses)
  const [fenvrr, setFenvRR] = useState(actividadInfo.fecha_envio_rr)
  const [flecrr, setFlecRR] = useState(actividadInfo.fecha_lectura)
  const [obsrr, setObsRR] = useState(actividadInfo.observaciones)

 
  const [alert, setAlert] = useState(null)
  const { loadingProfileUpdate, errorProfileUpdate, successProfileUpdate } = useSelector((state) => state.profileUpdate)

    useEffect(() => {
    if (successProfileUpdate) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='HECHO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Perfil actualizado correctamente
        </SweetAlert>
      )
    }
  }, [successProfileUpdate])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!fvalidacion) {
      return setCodProfileError('Por favor ingrese un Código de Perfil')
    }
    const profile = {
      id_perfil: profileInfo.id_perfil,
      codigo_perfil: codProfile,
      descripcion_perfil: description,
    }
    dispatch(profileUpdateInfo(profile))
  }
  const confirmSuccess = () => {
    dispatch(getProfiles())
    dispatch({ type: PROFILE_UPDATE_RESET })
    handleCloseModal()
    setAlert(null)
  }

  return (
    <>
      <Dialog
        open={updateActividadModal}
        keepMounted
        onClose={handleCloseModal}
        aria-labelledby='notice-modal-slide-title'
        aria-describedby='notice-modal-slide-description'
      >
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                 {actividadInfo.codigo_acto == 'VALIDACIÓN' && (
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'FECHA DE VALIDACIÓN'}
                        id='fvalidacion'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: fvalidacion,
                          onChange: (e) => setFvalidacion(e.target.value),
                          type: 'text',
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'CONTROL CALIDAD'}
                        id='calidad'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: calidad || '',
                          onChange: (e) => setCalidad(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'FECHA ENVIO REVISION'}
                        id='frevision'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: frevision || '',
                          onChange: (e) => setFrevision(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'FECHA REVISION AEI'}
                        id='frevisionaei'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: frevisionaei || '',
                          onChange: (e) => setFrevisionAEI(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'REVISION AEI'}
                        id='revisionaei'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: revisionaei || '',
                          onChange: (e) => setRevisionAEI(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'FECHA REVISION CYTSA'}
                        id='frevisioncytsa'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: frevisioncytsa || '',
                          onChange: (e) => setFrevisionCYTSA(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'Revisión_AEI (Listo generación RS)'}
                        id='revisionaeirs'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: revisionaeirs || '',
                          onChange: (e) => setRevisionAEIRS(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'RESULTADO VALIDACIÓN'}
                        id='resultado'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: resultado || '',
                          onChange: (e) => setResultado(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'OBSERVACIONES'}
                        id='observaciones'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: observaciones || '',
                          onChange: (e) => setObservaciones(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                 )}
                 {actividadInfo.codigo_acto == 'RS' && (
                   <GridContainer>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA DE GENERACIÓN'}
                       id='fgeneracionrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fgeneracionrs,
                         onChange: (e) => setFgeneracionRS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA REVISIÓN'}
                       id='ferevisionrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevisionrs || '',
                         onChange: (e) => setFrevisionRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA SUBIDA PORTAFIRMAS'}
                       id='fsubidaportafirmas'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fsubidaportafirmas || '',
                         onChange: (e) => setFsubidaPortafirmas(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA FIRMA'}
                       id='ffirmars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: ffirmars || '',
                         onChange: (e) => setFfirmaRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA ENVÍO'}
                       id='fenviors'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fenviors || '',
                         onChange: (e) => setFenvioRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'REQ. SUBSANACIÓN FECHA LECTURA'}
                       id='rsfLecturars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: rsfLecturars || '',
                         onChange: (e) => setRsfLecturaRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'REQ. SUBSANACIÓN FECHA CADUCIDAD'}
                       id='rsfCaducidadrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: rsfCaducidadrs || '',
                         onChange: (e) => setrsfCaducidadRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA ALEGACIONES'}
                       id='fAlegacionesrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fAlegacionesrs || '',
                         onChange: (e) => setfAlegacionesRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA ALEGACIONES INSTA. GENÉRICO'}
                       id='fAlegacionesIGrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fAlegacionesIGrs || '',
                         onChange: (e) => setfAlegacionesIGRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'FECHA ALEGACIONES GEISER'}
                       id='fAlegacionesGEIsrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fAlegacionesGEIsrs || '',
                         onChange: (e) => setfAlegacionesGEIRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   <GridItem xs={12}>
                     <CustomInput
                       labelText={'OBSERVACIONES'}
                       id='observacionesrs'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: observacionesrs || '',
                         onChange: (e) => setObservacionesRS(e.target.value),
                         type: 'text',
                       }}
                     />
                   </GridItem>
                   
                  </GridContainer>
                 )}
                  {actividadInfo.codigo_acto == 'RARS' && (
                   <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA DE REVISIÓN'}
                       id='frevisionrars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevisionrars,
                         onChange: (e) => setFrevisionRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'CONTROL DE CALIDAD'}
                       id='ccalidadrars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: ccalidadrars,
                         onChange: (e) => setCcalidadRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'ENVÍO REVISIÖN'}
                       id='erevisionrars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: erevisionrars,
                         onChange: (e) => setErevisionRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÖN AEI'}
                       id='frevisionaeirars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevisionaeirars,
                         onChange: (e) => setFrevisionaeiRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÖN CYTSA'}
                       id='frevisioncytsarars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevisioncytsarars,
                         onChange: (e) => setFrevisioncytsaRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'REVISIÓN AEI GENERACIÓN AIPR'}
                       id='revaeigeneaiprrars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: revaeigeneaiprrars,
                         onChange: (e) => setRevaeiGeneAIPRRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'RESULTADO VALIDACIÓN'}
                       id='resvalrars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: resvalrars,
                         onChange: (e) => setResValRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'OBSERVACIONES AEI G. AIPR'}
                       id='oaeigairars'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: oaeigairars,
                         onChange: (e) => setObaeiGaiprRARS(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                   </GridContainer>
                   )}
                  {actividadInfo.codigo_acto == 'IC' && (
                   <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA INFORME CIERRE'}
                       id='finformecierreic'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: finformecierreic,
                         onChange: (e) => setFinformecierreIC(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ENVÍO'}
                       id='fenvioic'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fenvioic,
                         onChange: (e) => setFenvioicIC(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>                        
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'OBSERVACIONES'}
                       id='obic'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: obic,
                         onChange: (e) => setObIC(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                  </GridContainer>
                 )}
                  {actividadInfo.codigo_acto == 'AIPR' && (
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA GENERACIÓN AIPR'}
                       id='fgeneracionaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fgeneracionaipr,
                         onChange: (e) => setFgeneracionAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÓN AIPR'}
                       id='frevisionaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevisionaipr,
                         onChange: (e) => setFrevisionAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA SUBISA PORTAFIRMAS'}
                       id='fsubportaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fsubportaipr,
                         onChange: (e) => setFsubportaAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA VALIDACIÓN AIPR'}
                       id='fvalaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fvalaipr,
                         onChange: (e) => setFvalAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA FIRMA AIPR'}
                       id='ffiraipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: ffiraipr,
                         onChange: (e) => setFfirAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ENVÍO AIPR'}
                       id='fenvaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fenvaipr,
                         onChange: (e) => setFenvAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA LECTURA'}
                       id='flecaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: flecaipr,
                         onChange: (e) => setFlecAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA CADUCIDAD'}
                       id='fcadaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fcadaipr,
                         onChange: (e) => setFcadAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ALEGACIONES'}
                       id='falegaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: falegaipr,
                         onChange: (e) => setFalegaiprAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ALEGACIONES INSTA GENÉRICA'}
                       id='falegaigaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: falegaigaipr,
                         onChange: (e) => setFfalegaigAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ALEGACIONES INSTA GENÉRICA'}
                       id='falegageiseraipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: falegageiseraipr,
                         onChange: (e) => setFalegageiserAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem> 

                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ALEGACIONES INSTA GENÉRICA'}
                       id='obsevacionesaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: obsevacionesaipr,
                         onChange: (e) => setObservacionesAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>                   
                  </GridContainer>
                )}
                 {actividadInfo.codigo_acto == 'RAAIPR' && (
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÓN'}
                       id='frevisionraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevisionraaipr,
                         onChange: (e) => setFrevisionRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'CONTROL CALIDAD'}
                       id='controlcalraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: controlcalraaipr,
                         onChange: (e) => setControlcalRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ENVÍO REVISIÖN'}
                       id='fenvrevraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fenvrevraaipr,
                         onChange: (e) => setFenvrevrRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>  
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÖN'}
                       id='frevraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevraaipr,
                         onChange: (e) => setFrevaeiRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'REVISIÓN AEI'}
                       id='revaeiraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: revaeiraaipr,
                         onChange: (e) => setRevaeiRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÓN CYTSA'}
                       id='frevcytraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevcytraaipr,
                         onChange: (e) => setFrevcytRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'REVISIÓN AEI GENERACIÓN RR'}
                       id='revaeigeraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: revaeigeraaipr,
                         onChange: (e) => setRevaeigeRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'SENTIDO ALEGACIONES'}
                       id='sentalegraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: sentalegraaipr,
                         onChange: (e) => setSentalegRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'OBSERVACIONES'}
                       id='obsraaipr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: obsraaipr,
                         onChange: (e) => setObsRAAIPR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>                   
                    </GridContainer>
                 )}
                  {actividadInfo.codigo_acto == 'RR' && (
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA GENERACIÓN RR'}
                       id='fgenrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fgenrr,
                         onChange: (e) => setFgenerRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA REVISIÓN RR'}
                       id='frevrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: frevrr,
                         onChange: (e) => setFrevRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA SUBIDA PORTAFIRMAS RR'}
                       id='fsubportrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fsubportrr,
                         onChange: (e) => setFsubportRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA VALIDACIÓN RR'}
                       id='fvalrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fvalrr,
                         onChange: (e) => setFvalRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA FIRMA RR'}
                       id='ffirr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: ffirr,
                         onChange: (e) => setFfiRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'IMPORTE REINTEGROS VOLUNTARIOS'}
                       id='irvrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: irvrr,
                         onChange: (e) => setIrvRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'N JUSTIFICANTE CARTA PAGO'}
                       id='njcprr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: njcprr,
                         onChange: (e) => setNjcpRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'CARTA PAGO PRINCIPAL'}
                       id='cpprr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: cpprr,
                         onChange: (e) => setCppRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'CARTA PAGO INTERESES'}
                       id='cpirr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: cpirr,
                         onChange: (e) => setCpiRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA ENVÍO RR'}
                       id='fenvrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: fenvrr,
                         onChange: (e) => setFenvRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'FECHA LECTURA'}
                       id='flecrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: flecrr,
                         onChange: (e) => setFlecRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem> 
                    <GridItem xs={12}>
                      <CustomInput
                       labelText={'OBSERVACIONES RR'}
                       id='obsrr'
                       formControlProps={{
                         fullWidth: true,
                       }}
                       inputProps={{
                         value: obsrr,
                         onChange: (e) => setObsRR(e.target.value),
                         type: 'text',
                         required: true,
                       }}
                      />
                    </GridItem>            
                   </ GridContainer>
                  )}

                </GridItem>
                <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'space-around' }}>
                  <Button color='secondary' onClick={handleCloseModal}>
                    Cerrar
                  </Button>
                  <Button type='submit' color='primary'>
                    {loadingProfileUpdate ? 'Actualizando...' : 'Actualizar Acto'}
                  </Button>
                </GridItem>
                {errorProfileUpdate && (
                  <GridItem xs={12}>
                    <SnackbarContent message={errorProfileUpdate} color='danger' />
                  </GridItem>
                )}
                {codProfileError && !fvalidacion && (
                  <GridItem xs={12}>
                    <SnackbarContent message={codProfileError} color='danger' />
                  </GridItem>
                )}
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </Dialog>
      {alert}
    </>
  )
}

export default UpdateActividadModal
