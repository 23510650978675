import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Milestone } from '../components/Milestone'


const RegisterPlanningScreen = () => {

   return (
   <>
   <GridContainer>    
      <GridItem xs={12}>
            <Milestone />
      </GridItem>
   </GridContainer>
   </>
   )
}


export default RegisterPlanningScreen
