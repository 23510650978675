import axios from 'axios'
import {
   USER_LOGIN_REQUEST,
   USER_LOGIN_SUCCESS,
   USER_LOGIN_FAIL,
   USER_LOGOUT,
   USER_REFRESH_TOKEN_REQUEST,
   USER_REFRESH_TOKEN_SUCCESS,
   USER_REFRESH_TOKEN_FAIL,
   USER_LIST_BY_PROFILE_ID_REQUEST,
   USER_LIST_BY_PROFILE_ID_SUCCESS,
   USER_LIST_BY_PROFILE_ID_FAIL,
   USER_LIST_BY_ROLE_NAME_REQUEST,
   USER_LIST_BY_ROLE_NAME_SUCCESS,
   USER_LIST_BY_ROLE_NAME_FAIL,
   TECHNICIAN_LIST_REQUEST,
   TECHNICIAN_LIST_SUCCESS,
   TECHNICIAN_LIST_FAIL,

} from '../constants/userConstants'
import parseJwt from 'shared/middlewares/parseJwt'

export const login = (infoObject) => async (dispatch) => {
   try {
      dispatch({ type: USER_LOGIN_REQUEST })

      const config = {
         headers: {
         'Content-Type': 'application/json',
         },
      }
      
      const {
         data: { token },
      } = await axios.post('/api/users/login', infoObject, config)

      const decoded = parseJwt(token)
      const userSession = {
         ...decoded,
         token: token,
      }

      dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })

      localStorage.setItem('setADA', JSON.stringify(token))
   } catch (error) {
      dispatch({
         type: USER_LOGIN_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const refreshToken = () => async (dispatch, getState) => {
   try {
      dispatch({ type: USER_REFRESH_TOKEN_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      await axios.get(`/api/users/refreshtoken`, config)

      dispatch({ type: USER_REFRESH_TOKEN_SUCCESS })
   } catch (error) {
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error?.response?.status === 401 || error?.response?.status === 403) {
         dispatch({
         type: USER_LOGOUT,
         })
      }
      dispatch({
         type: USER_REFRESH_TOKEN_FAIL,
         payload: message,
      })
   }
}

export const getUsersByProfileId = (profileId) => async (dispatch, getState) => {
   try {
      dispatch({ type: USER_LIST_BY_PROFILE_ID_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      const { data } = await axios.get(`/api/users/perfiles/${profileId}`, config)
      
      dispatch({ type: USER_LIST_BY_PROFILE_ID_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: USER_LIST_BY_PROFILE_ID_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const logout = () => async (dispatch) => {
   try {
      await axios.get(`/logout`, { withCredentials: true })

      dispatch({ type: USER_LOGOUT })
   } catch (error) {
      dispatch({
         type: USER_LOGIN_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   } 
}

export const getUsersByRoleName = (roleName) => async (dispatch, getState) => {
   try {
      dispatch({ type: USER_LIST_BY_ROLE_NAME_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }

      const { data } = await axios.get(`/api/users/roles/${roleName}`, config)

      dispatch({ type: USER_LIST_BY_ROLE_NAME_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: USER_LIST_BY_ROLE_NAME_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getTechnicians = () => async (dispatch, getState) => {
   try {
      dispatch({ type: TECHNICIAN_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
         Authorization: `Bearer ${userInfo.token}`,
         'Cache-Control': 'no-cache',
         },
      }
      const { data } = await axios.get(`/api/users/tecnicos`, config)
      
      dispatch({ type: TECHNICIAN_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: TECHNICIAN_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}