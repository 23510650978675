import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl, InputLabel, MenuItem, Select as Selectable, TextField } from '@material-ui/core'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'

import styles from '../styles/milestoneStyles'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import { Checkbox, FormControlLabel } from '@mui/material'


const useStyles = makeStyles(styles)

export const Line = ({ lineInfo, linesMainInfo, indicators, isRegistered, categories, lines, setLines, index, update,
   wasUpdated, setTotalPages, setActualPage, totalPages, view }) => {

   const classes = useStyles()

   const lineIndicatorsInitialState = indicators.map(indicator => {
      return {
         id: indicator.id_indicador,
         totalActions: 0,
         executionPercentage: "0.00",
         id_linea_hito_planificacion: indicator.id_linea_hito_planificacion,
         import: 0,
         description: indicator.descripcion,
      }
   })
   const mainInfoInitialState = (lineInfo?.linea != null && lineInfo?.anio_convocatoria != null && lineInfo?.categoria_pliego != null)
      ? `${lineInfo.linea} - ${lineInfo.anio_convocatoria} - ${lineInfo.categoria_pliego}`
      : null
   // state
   const [totalActions, setTotalActions] = useState(lineInfo?.total_actuaciones ?? 0)
   const [executionPercentage, setExecutionPercentage] = useState(lineInfo?.porcentaje_ejecucion ?? 0)
   const [mainInfo, setMainInfo] = useState(mainInfoInitialState)
   const [categoryType, setCategoryType] = useState(lineInfo?.linea ?? "")
   const [categoryImport, setCategoryImport] = useState(0)
   const [announcementYear, setAnnouncementYear] = useState(lineInfo?.anio_convocatoria)
   const [categorySymbol, setCategorySymbol] = useState(lineInfo?.categoria_pliego ?? "")
   const [lineIndicators, setLineIndicators] = useState(lineInfo?.indicadores ?? lineIndicatorsInitialState)
   const [newLine, setNewLine] = useState(lineInfo?.linea ?? false)

   useEffect(() => {

      if (lineInfo != null) {
         setAnnouncementYear(lineInfo === 2 ? null : lineInfo.anio_convocatoria)
         setCategorySymbol(lineInfo === 2 ? "" : lineInfo.categoria_pliego)
         setLineIndicators(lineInfo === 2 ? lineIndicatorsInitialState : lineInfo.indicadores)
         setCategoryType(lineInfo === 2 ? "" : lineInfo.linea)
         setExecutionPercentage(lineInfo === 2 ? 0 : lineInfo.porcentaje_ejecucion)
         setTotalActions(lineInfo === 2 ? 0 : lineInfo.total_actuaciones)
         setMainInfo(lineInfo === 2 ? null : `${lineInfo.linea} - ${lineInfo.anio_convocatoria} - ${lineInfo.categoria_pliego}`)
      }
   }, [lineInfo])

   useEffect(() => {

      if (update == null && isRegistered) return
      if (update != null && isNaN(lines.slice(-1)[0]) && !newLine) {
         
         if (isRegistered) return
         if (update != null && update && !wasUpdated) return
      }else if (update != null) {
         setNewLine(true)
      }

      setLines(last => {

         last[index] = {
            anio_convocatoria: Number(announcementYear),
            categoria_pliego: categorySymbol,
            linea: categoryType,
            estructura: mainInfo,
            indicadores: lineIndicators,
            porcentaje_ejecucion: Number(executionPercentage),
            total_actuaciones: totalActions,
            index: last[index].index ?? index,
         }

         return [...last]
      })
   }, [mainInfo, lineIndicators])


   // functions
   const onChangeTotalActionsHandler = (e, indicatorId, index) => {
      const newTotalActions = Number(e.target.value)

      const updatedLineIndicators = lineIndicators.map((indicator) => {
         if (indicator.id !== indicatorId) return indicator

         const ex_percentage = (((categoryImport || indicator.import) * newTotalActions * 100) / process.env.REACT_APP_TOTAL_GLOBAL_MILESTONE).toFixed(2)
         indicator.totalActions = newTotalActions
         if (mainInfo != null) indicator.executionPercentage = ex_percentage

         return indicator
      })

      setLineIndicators(updatedLineIndicators)

      const totalActionSum = updatedLineIndicators.reduce((acc, indicator) => acc + Number(indicator.totalActions), 0)
      setTotalActions(totalActionSum.toFixed(2))
      setExecutionPercentage(((categoryImport * totalActionSum * 100) / process.env.REACT_APP_TOTAL_GLOBAL_MILESTONE).toFixed(2))
   }

   const onChangeSelectMainInfoHandler = (e) => {
      const newVal = e.target.value
      const lineInfoArr = newVal.split(" - ")
      const lineType = lineInfoArr[0]
      const announcement_year = lineInfoArr[1]
      const category = lineInfoArr[2]

      const {
         importe: category_import, 
         categoria_pliego: category_symbol,
      } = categories.find(cat => cat.categoria_pliego === category)

      setCategoryType(lineType)
      setAnnouncementYear(announcement_year)
      setCategorySymbol(category_symbol)
      setCategoryImport(category_import)
      setMainInfo(newVal)

      const updatedLineIndicators = lineIndicators.map(indicator => {
         if (indicator.totalActions == 0) return indicator

         const executionPercentage = ((category_import * indicator.totalActions * 100) / process.env.REACT_APP_TOTAL_GLOBAL_MILESTONE).toFixed(2)
         
         return {
            ...indicator,
            executionPercentage,
         }
      })

      setLineIndicators(updatedLineIndicators)

      const totalActionSum = updatedLineIndicators.reduce((acc, indicator) => acc + Number(indicator.totalActions), 0)
      setTotalActions(totalActionSum.toFixed(2))
      setExecutionPercentage(((category_import * totalActionSum * 100) / process.env.REACT_APP_TOTAL_GLOBAL_MILESTONE).toFixed(2))
   }

   const onDeleteLineHandler = () => {
      const newTotalPages = Math.ceil((lines.length - 1) / 5)
      if (newTotalPages !== totalPages) setActualPage(newTotalPages)      
      setTotalPages(newTotalPages)

      setLines(last => {
         
         last.splice(index, 1)
         
         return [...last]
      } )
   }

   return (
      <GridContainer style={{marginTop: "10px"}}>
         {/* TÍTULO */}
         <GridItem xs={12}>
            <GridContainer style={{display: "flex", alignItems: "center"}}>
               {/* NUM LÍNEA */}
               <GridItem xs={2} style={{display: "flex", justifyContent: "center", marginTop: update ? "0px" : "0px", borderBottom: "1px solid #000000", borderRadius: "3px"}}>
                  <h5
                     style={{fontWeight: "bold", marginBottom: "0"}}
                     className={isRegistered ? classes.off : ""}
                  >
                     LÍNEA ({index + 1})
                  </h5>
               </GridItem>

               {/* SELECT ESTRUCTURA BASE LÍNEA */}
               <GridItem xs={7}>
                  <FormControl fullWidth>
                     {
                        (!isRegistered && (mainInfo == null))
                           ? <InputLabel htmlFor='select-line'>Estructura de la línea</InputLabel>
                           : ""
                     }
                     <Selectable
                        MenuProps={{
                           className: classes.selectMenu,
                        }}
                        className={classes.select}
                        disabled={isRegistered || view}
                        value={mainInfo}
                        onChange={onChangeSelectMainInfoHandler}
                        inputProps={{
                           name: 'select-line',
                           id: 'select-line',
                           required: true,
                        }}
                     >
                        <MenuItem
                           disabled
                           classes={{
                              root: classes.selectMenuItem,
                           }}
                        >
                           Seleccione la estructura base de la línea
                        </MenuItem>
                        {
                           linesMainInfo.map((info, index) => {
                              const value = `${info.linea} - ${info.anio_convocatoria} - ${info.categoria_pliego}`

                              return (
                                 <MenuItem
                                    value={value}
                                    key={`${value} - ${index}`}
                                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                 >
                                    {value}
                                 </MenuItem>
                              )
                           })
                        }
                     </Selectable>
                  </FormControl>
               </GridItem>
               {/* ELIMINAR LÍNEA */}
               <GridItem xs={3}>
               {
                  !isRegistered && !view
                  && (               
                     <Button 
                        color="danger"
                        className={classes.deleteSub}
                        onClick={onDeleteLineHandler}
                        variant='outlined'
                        size='sm'
                        style={{width: "100%", textOverflow: "...", whiteSpace: "nowrap", overflow: "hidden"}}
                     >
                        <span style={{marginRight: "10px", width: "100%", textOverflow: "...", whiteSpace: "nowrap", overflow: "hidden" }}>
                           Eliminar Línea
                        </span> 
                        <span><IndeterminateCheckBoxIcon style={{width: 20, height: 20}} /></span>
                     </Button>
                  )
               }
               </GridItem>
            </GridContainer>
            
         </GridItem>

         {/* INFO LÍNEA */}
         <GridItem xs={12}>           
            <GridContainer>
            {
               lineIndicators.map((indicator, i) => {

                  return (
                     <>
                     {/* INDICADOR */}
                     <GridItem xs={6}>
                        <CustomInput
                           labelText="Indicador"
                           formControlProps={{ fullWidth: true}}
                           inputProps={{
                              value: indicator.description,
                              disabled: true,
                              type: "text",
                           }}
                        />
                     </GridItem>
                     {/* TOTAL ACTUACIONES */}
                     <GridItem xs={3}>                      
                        <CustomInput
                           labelText="Total actuaciones"
                           formControlProps={{ fullWidth: true}}
                           error={indicator.totalActions < 0}
                           inputProps={{
                              value: indicator.totalActions,
                              disabled: isRegistered || view,
                              onChange: (e) => onChangeTotalActionsHandler(e, indicator.id, i),
                              type: "number",
                              required: false,
                           }}
                        />
                     </GridItem>
                  
                     {/* % EJECUCIÓN */}
                     <GridItem xs={3}>
                        <CustomInput
                           labelText="Porcentaje de ejecución - línea"
                           formControlProps={{ fullWidth: true, color: isRegistered ? "grey" : null, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}
                           inputProps={{
                              value: `${ indicator.executionPercentage }%`,
                              disabled: true,
                              type: "text",
                              required: false,
                           }}
                        />
                     </GridItem>
                     </>
                  )
               })
            }
               
            </GridContainer>
         </GridItem>
      </GridContainer>
   )
}
