import axios from 'axios'
import {
  PLANNING_REGISTER_REQUEST,
  PLANNING_REGISTER_SUCCESS,
  PLANNING_REGISTER_FAIL,
  PLANNING_LIST_REQUEST,
  PLANNING_LIST_SUCCESS,
  PLANNING_LIST_FAIL,
  PLANNING_UPDATE_SUCCESS,
  PLANNING_UPDATE_REQUEST,
  PLANNING_UPDATE_FAIL,
  PLANNING_DELETE_FAIL,
  PLANNING_DELETE_REQUEST,
  PLANNING_DELETE_SUCCESS,
  PLANNING_LINES_LIST_REQUEST,
  PLANNING_LINES_LIST_SUCCESS,
  PLANNING_LINES_LIST_FAIL,
  PLANNING_INDICATORS_LIST_REQUEST,
  PLANNING_INDICATORS_LIST_SUCCESS,
  PLANNING_INDICATORS_LIST_FAIL,
  PLANNING_LOADS_LIST_REQUEST,
  PLANNING_LOADS_LIST_SUCCESS,
  PLANNING_LOADS_LIST_FAIL,

} from '../constants/planningConstants.js'

export const registerPlanning = ({ planning, cloneId, newName}) => async ( dispatch, getState ) => {
   try {
      dispatch({ type: PLANNING_REGISTER_REQUEST })
      
      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }
      
      const { data } = await axios.post('/api/planificaciones', { planning, cloneId, newName }, config)
      
      dispatch({ type: PLANNING_REGISTER_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: PLANNING_REGISTER_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getPlannings = () => async (dispatch, getState) => {
   try {
      dispatch({ type: PLANNING_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get('/api/planificaciones/', config)

      dispatch({ type: PLANNING_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: PLANNING_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const updatePlanning = ( planning ) => async ( dispatch, getState ) => {
   try {
      dispatch({ type: PLANNING_UPDATE_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.put(`/api/planificaciones/${planning.general_data.id_planificacion}`, planning, config)

      dispatch({ type: PLANNING_UPDATE_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: PLANNING_UPDATE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const deletePlanning = ( id ) => async ( dispatch, getState ) => {
   try {
      dispatch({ type: PLANNING_DELETE_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      await axios.delete(`/api/planificaciones/${id}`, config)
      
      dispatch({ type: PLANNING_DELETE_SUCCESS })
   } catch (error) {
      dispatch({
         type: PLANNING_DELETE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getPlanningLines = () => async (dispatch, getState) => {
   try {
      dispatch({ type: PLANNING_LINES_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get('/api/planificaciones/lineas', config)

      
      dispatch({ type: PLANNING_LINES_LIST_SUCCESS, payload: data })
   } catch (error) {

      dispatch({
         type: PLANNING_LINES_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getPlanningIndicators = () => async(dispatch, getState) => {
   try {
      dispatch({ type: PLANNING_INDICATORS_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get('/api/planificaciones/indicadores', config)

      dispatch({ type: PLANNING_INDICATORS_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: PLANNING_INDICATORS_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}

export const getPlanningLoads = () => async(dispatch, getState) => {
   try {
      dispatch({ type: PLANNING_LOADS_LIST_REQUEST })

      const {
         userLogin: { userInfo },
      } = getState()

      const config = {
         headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${userInfo.token}`,
         },
      }

      const { data } = await axios.get('/api/planificaciones/cargas', config)
      
      dispatch({ type: PLANNING_LOADS_LIST_SUCCESS, payload: data })
   } catch (error) {
      dispatch({
         type: PLANNING_LOADS_LIST_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
   }
}
