import { useState, useEffect } from "react"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import ReactTable from "components/ReactTable/ReactTable"


export const TabPanelContent = ({ category_data }) => {

   const [table_weeks_cols, setTable_weeks_cols] = useState([])
   const [table_counter_data, setTable_counter_data] = useState([])

   useEffect(() => {
      if (category_data != null && category_data.length) {
         const cols = category_data[0].weeks.map(week => {
            return {
               Header: `${ week.week_name.toUpperCase() }`,
               accessor: `${ week.week_name.toLowerCase() }`,
            }
         })
         const global_act_count = [{
               Header: "Actuaciones acumuladas",
               accessor: "actuaciones_acumuladas",
         }]
         
         const rows = category_data.map((row, index) => {
            const object_to_push = {}

            object_to_push["row_header"] = row.description
            row.weeks.forEach(week => {
               object_to_push[week.week_name.toLowerCase()] = week.cont              
            })
               
            object_to_push["actuaciones_acumuladas"] = row.global_count
            
            return object_to_push
         })
      
         const row_header = {
            Header: "",
            accessor: "row_header",
         } 
      
         setTable_weeks_cols( [row_header].concat(cols).concat(global_act_count) )
         setTable_counter_data( rows )
      }
      

   }, [category_data])
   


   return (
      <GridContainer>
         <GridItem xs={12}>
            {
               !!table_counter_data.length
               && 
               <ReactTable
                  columns={table_weeks_cols}
                  data={table_counter_data}
                  numFilas={20}
                  colWidth={"160px"}
                  lastColWidth={"100px"}
                  search={false}
                  stickyFirst={true}
                  stickyLast={true}
               />
            }
         </GridItem>
      </GridContainer>
   )
}

