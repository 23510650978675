import { Typography } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ViewInfoActionModal from 'components/ReactTableActions/ViewInfo/ViewInfoActionModal'

const ViewActividadInfoModal = ({ viewInfo, closeViewInfoModal, info }) => {
  const { id_acto, codigo_acto, descripcion_acto,referencia, linea, convocatoria, cif, organismo, centro, fini_gasto, ffin_gasto, concedido, presentado, situacion, costes, obs_costes,
    cambio_entidad, bloque, categoria, informe_ct, tecnico_ven, fval_ven, calidad, fenvio_revision, frevision_AEI, revision_AEI, frevision_CYTSA, revision_AEI_RS, 
    resultado_val, observaciones_ven, tecnico_RS, fgeneracion_RS, frevision_RS, fportafirmas, ffirma_RS, fenvio_RS, flectura_F, fcaducidad, falegaciones, 
    falegaciones_generica, falegaciones_geiser, observaciones_RS, tecnico_RARS, frevision_RARS, calidad_RARS, fenvio_revision_RARS, frevision_AEI_RARS, 
    revision_AEI_RARS, frevicion_CUTSA_RARS, revision_AEI_listo_RARS, resultado_val_RARS, observaciones_RARS, tecnico_IC, fcierre, fenvio, observaciones_IC,
    tecnico_AI, fgeneracion_AI, frevision_AI, fportafirmas_AI, fvalidacion_AI, ffirma_AI, fenvio_AI, flectura, fcaducidad_AI, falegaciones_AI, falegaciones_generica_AI,
    falegaciones_geiser_AI, observaciones_AI, tecnico_RAAI, frevision_RAAI, calidad_RAAI, fenvio_RAAI, frevision_AEI_RAAI, revision_AEI_RAAI, frevision_CYTSA_RAAI, 
    revision_AEI_listo_RAAI, sentido, observaciones_RAAI, tecnico_RR, fgeneracion_RR, frevision_RR, fportafirmas_RR, fvalidacion_RR, ffirma_RR, importe_RR, 
    num_justificante, carta, carta_intereses, fenvio_RR, flectura_RR, observaciones_RR } = info
  debugger;

  return (    
    <ViewInfoActionModal    
      open={viewInfo}
      handleCloseModal={closeViewInfoModal}
      modalTitle={`Informacion de Acto`}
      children={
        codigo_acto == 'VALIDACIÓN' ? (
        <GridContainer>
          <GridItem xs={12} md={6}>
            <Typography variant='body1' gutterBottom>
              Acto: <strong>{codigo_acto}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Typography variant='body1' gutterBottom>
              Fecha de validación: <strong>{fval_ven}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Control calidad: <strong>{calidad}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Fecha envío a revisión: <strong>{fenvio_revision}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Fecha de revisión AEI: <strong>{frevision_AEI}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Revisión AEI: <strong>{revision_AEI}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Fecha revisión CYTSA: <strong>{frevision_CYTSA}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
            Revisión AEI (Listo generación RS): <strong>{revision_AEI_RS}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Resultado validación: <strong>{resultado_val}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Observaciones: <strong>{observaciones_ven}</strong>
            </Typography>
          </GridItem>
        </GridContainer>
        ) :
        codigo_acto == 'RS' ? (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                Acto: <strong>{codigo_acto}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                Fecha de generación: <strong>{fgeneracion_RS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Fecha de revisión: <strong>{frevision_RS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Fecha subida portafirmas: <strong>{fportafirmas}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Fecha de firma: <strong>{ffirma_RS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Fecha de envío: <strong>{fenvio_RS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Requerimiento subsanación fecha de lectura: <strong>{flectura_F}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>              
                Requerimiento subsanación fecha de caducidad: <strong>{fcaducidad}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Fecha de alegaciones: <strong>{falegaciones}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>              
                Fecha alegaciones insta. generico: <strong>{falegaciones_generica}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Fecha alegaciones geiser: <strong>{falegaciones_geiser}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant='body1' gutterBottom>
                Observaciones: <strong>{observaciones_RS}</strong>
              </Typography>
            </GridItem>
          </GridContainer>
          ) :
          codigo_acto == 'RARS' ? (
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Acto: <strong>{codigo_acto}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>                  
                  Fecha revisión: <strong>{frevision_RARS}</strong>
                </Typography>
              </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Control calidad: <strong>{calidad_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Envío revisión: <strong>{fenvio_revision_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Fecha revisión AEI: <strong>{frevision_AEI_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Revisión EAI: <strong>{revision_AEI_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Fecha revisión CYTSA: <strong>{frevicion_CUTSA_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Revision AEI generación AIPR: <strong>{revision_AEI_listo_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Resultado validación: <strong>{ resultado_val_RARS}</strong>
              </Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                 Observaciones AEI generación AIPR: <strong>{observaciones_RARS}</strong>
              </Typography>
            </GridItem>
            </GridContainer>
              ) :
          codigo_acto == 'IC' ? (
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Acto: <strong>{codigo_acto}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>  
                <Typography variant='body1' gutterBottom>
                  Fecha informe cierre: <strong>{fcierre}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha envío: <strong>{fenvio}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Observaciones: <strong>{observaciones_IC}</strong>
                </Typography>
              </GridItem>
            </GridContainer>
              ) :
          codigo_acto == 'AIPR' ? (
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Acto: <strong>{codigo_acto}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha generación AIPR: <strong>{fgeneracion_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha revisioón AIPR: <strong>{frevision_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha subida portafirmas AIPR: <strong>{fportafirmas_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha validación AIPR: <strong>{fvalidacion_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha firma AIPR: <strong>{ffirma_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha envío AIPR: <strong>{fenvio_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha lectura: <strong>{flectura}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha caducidad: <strong>{fcaducidad_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha alegaciones: <strong>{falegaciones_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha alegaciones insta generica: <strong>{falegaciones_generica_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Fecha alegaciones geiser: <strong>{falegaciones_geiser_AI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                  Otras observaciones: <strong>{observaciones_AI}</strong>
                </Typography>
              </GridItem>
            </GridContainer>
              ) :                     
          codigo_acto == 'RAAIPR' ? (
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Acto: <strong>{codigo_acto}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Fecha revisión: <strong>{frevision_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Control calidad: <strong>{calidad_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Fecha envío revisión: <strong>{fenvio_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Fecha revisión : <strong>{frevision_AEI_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Revisión AEI: <strong>{revision_AEI_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Fecha revisión CYTSA: <strong>{frevision_CYTSA_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Revisión AEI generación RR: <strong>{revision_AEI_listo_RAAI}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Sentido alegaciones: <strong>{sentido}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                   Observaciones <strong>{observaciones_RAAI}</strong>
                </Typography>
              </GridItem>
            </GridContainer>
              )  :   
          codigo_acto == 'RR' && (
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Acto: <strong>{codigo_acto}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha generación RR: <strong>{fgeneracion_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha revisión: <strong>{frevision_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha subida portafirmas RR: <strong>{fportafirmas_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha validación RR: <strong>{fvalidacion_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha firma RR: <strong>{ffirma_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Importe reintegros voluntarios: <strong>{importe_RR}</strong>
                </Typography>
              </GridItem>              
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Número Justificante carta pago: <strong>{num_justificante}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Carta pago principal: <strong>{carta}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Carta pago intereses: <strong>{carta_intereses}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha envío RR: <strong>{fenvio_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Fecha lectura: <strong>{flectura_RR}</strong>
                </Typography>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography variant='body1' gutterBottom>
                       Observaciones: <strong>{observaciones_RR}</strong>
                </Typography>
              </GridItem>
            </GridContainer>
                  )
        
        }
    />
  )
}

export default ViewActividadInfoModal
