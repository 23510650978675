import {
  // actualizacion y listado
  ACTIVIDAD_LIST_REQUEST,
  ACTIVIDAD_LIST_SUCCESS,
  ACTIVIDAD_LIST_FAIL,
  ACTIVIDAD_LIST_RESET, 
  ACTIVIDAD_UPDATE_REQUEST,
  ACTIVIDAD_UPDATE_SUCCESS,
  ACTIVIDAD_UPDATE_FAIL,
  ACTIVIDAD_UPDATE_RESET,
} from '../constants/actividadConstants.js'

export const actividadListReducer = (state = { actividades: [] }, action) => {
  switch (action.type) {
    case ACTIVIDAD_LIST_REQUEST:
      return { loadingActividadList: true }
    case ACTIVIDAD_LIST_SUCCESS:
      return {
        loadingActividadList: false,
        successActividadList: true,
        actividades: action.payload,
      }
    case ACTIVIDAD_LIST_FAIL:
      return { loadingActividadList: false, errorActividadList: action.payload }
    case ACTIVIDAD_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const actividadUpdateReducer = (state = { actividadUpdated: {} }, action) => {
  switch (action.type) {
    case ACTIVIDAD_UPDATE_REQUEST:
      return { loadingActividadUpdate: true }
    case ACTIVIDAD_UPDATE_SUCCESS:
      return {
        loadingActividadUpdate: false,
        successActividadUpdate: true,
        actividadUpdated: action.payload,
      }
    case ACTIVIDAD_UPDATE_FAIL:
      return { loadingActividadUpdate: false, errorActividadUpdate: action.payload }
    case ACTIVIDAD_UPDATE_RESET:
      return {}
    default:
      return state
  }
}


