export const USERLIST_REGISTER_REQUEST = 'USERLIST_REGISTER_REQUEST'
export const USERLIST_REGISTER_SUCCESS = 'USERLIST_REGISTER_SUCCESS'
export const USERLIST_REGISTER_FAIL = 'USERLIST_REGISTER_FAIL'
export const USERLIST_REGISTER_RESET = 'USERLIST_REGISTER_RESET'

export const USERLIST_LIST_REQUEST = 'USERLIST_LIST_REQUEST'
export const USERLIST_LIST_SUCCESS = 'USERLIST_LIST_SUCCESS'
export const USERLIST_LIST_FAIL = 'USERLIST_LIST_FAIL'
export const USERLIST_LIST_RESET = 'USERLIST_LIST_RESET'

export const USERLIST_UPDATE_REQUEST = 'USERLIST_UPDATE_REQUEST'
export const USERLIST_UPDATE_SUCCESS = 'USERLIST_UPDATE_SUCCESS'
export const USERLIST_UPDATE_FAIL = 'USERLIST_UPDATE_FAIL'
export const USERLIST_UPDATE_RESET = 'USERLIST_UPDATE_RESET'

export const USERLIST_DELETE_REQUEST = 'USERLIST_DELETE_REQUEST'
export const USERLIST_DELETE_SUCCESS = 'USERLIST_DELETE_SUCCESS'
export const USERLIST_DELETE_FAIL = 'USERLIST_DELETE_FAIL'
export const USERLIST_DELETE_RESET = 'USERLIST_DELETE_RESET'

export const USERLIST_RESETPASSWORD_REQUEST = 'USERLIST_RESETPASSWORD_REQUEST'
export const USERLIST_RESETPASSWORD_SUCCESS = 'USERLIST_RESETPASSWORD_SUCCESS'
export const USERLIST_RESETPASSWORD_FAIL = 'USERLIST_RESETPASSWORD_FAIL'
export const USERLIST_RESETPASSWORD_RESET = 'USERLIST_RESETPASSWORD_RESET'

export const USERLIST_CHANGEPASSWORD_REQUEST = 'USERLIST_CHANGEPASSWORD_REQUEST'
export const USERLIST_CHANGEPASSWORD_SUCCESS = 'USERLIST_CHANGEPASSWORD_SUCCESS'
export const USERLIST_CHANGEPASSWORD_FAIL = 'USERLIST_CHANGEPASSWORD_FAIL'
export const USERLIST_CHANGEPASSWORD_RESET = 'USERLIST_CHANGEPASSWORD_RESET'
