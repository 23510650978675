import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ReactExport from 'react-data-export'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Visibility, Delete, Edit } from '@mui/icons-material'

import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import ViewActividadInfoModal from './components/ViewActividadInfoModal'
import UpdateActividadModal from './components/UpdateActividadModal'
import { ACTIVIDAD_LIST_RESET } from 'redux/constants/actividadConstants'
import { getActividades } from 'redux/actions/actividadActions'
import styles from './styles/actividadesScreenStyles'
const useStyles = makeStyles(styles)

const UserActividadesScreen = () => {
  const dispatch = useDispatch()
  const ExcelFile = ReactExport.ExcelFile
  const classes = useStyles()

  const [data, setData] = useState([])
  const [updateActividadModal, setUpdateActividadModal] = useState(false)
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [downloadPdf, setDownloadPdf] = useState(false)
  const [excel, setExcel] = useState(false)
  const [pdf, setPdf] = useState(false)
  const [showUpdateActividad, setShowUpdateActividad] = useState({})
  const [viewInfoModal, setViewInfoModal] = useState(false)
  const [showViewInfoModal, setShowViewInfoModal] = useState({})

  const { loadingActividadList, actividades, successActividadList, errorActividadList } = useSelector(
    (state) => state.actividadList
  ) 

  useEffect(() => {
    if (successActividadList) {
      const list = actividades.map((actividad) => {
        return {
          ...actividad,
          descripcion_acto: `${actividad.descripcion_acto || '-'}`,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => showUpdateActividadHandler(actividad.id_acto)}
                color='primary'
                className='edit'
              >
                <Tooltip title='Editar Información'>
                  <Edit />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showViewInfoHandler(actividad.id_acto)}
                color='success'
                className='view'
              >
                <Tooltip title='Ver Información'>
                  <Visibility />
                </Tooltip>
              </Button>
            </div>
          ),
        }
      })
      setData(list)
    } else {
      dispatch(getActividades())
    }
  }, [successActividadList, dispatch])

  useEffect(() => {
    return () => dispatch({ type: ACTIVIDAD_LIST_RESET })
  }, [dispatch])

  useEffect(() => {
    if (pdf) {
      exportPDF()
    }
  }, [pdf])

  const handleCloseModal = () => {
    setUpdateActividadModal(false)
    setShowUpdateActividad({})
  }
  const showUpdateActividadHandler = (id) => {
    const actividad = actividades.find((el) => el.id_acto === id)
    setShowUpdateActividad(actividad)
    setUpdateActividadModal(true)
  }

  const showViewInfoHandler = (id) => {
    const actividad = actividades.find((el) => el.id_acto === id)
    setShowViewInfoModal(actividad)
    setViewInfoModal(true)
  }

  const closeViewInfoModal = () => {
    setViewInfoModal(false)
    setShowViewInfoModal({})
  }

  const exportPDF = () => {
    const doc = new jsPDF()
    doc.text('Actividades', 20, 10)
    doc.autoTable({
      body: data,
      columns: [
        { header: 'Referencia solicitud', dataKey: 'referencia_solicitud' },
        { header: 'Línea', dataKey: 'linea' },
        { header: 'Año convocatoria', dataKey: 'anio_convocatoria' },
        { header: 'Bloque / orden / revisión', dataKey: 'bloque_orden_revision' },
        { header: 'Categoría', dataKey: 'categoria_pliego' },
        { header: 'Acto', dataKey: 'descripcion_acto' },
      ],
    })
    doc.save('actividades.pdf')
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} className={classes.rootItem}>
          <Card>
            <CardBody>
              {loadingActividadList ? (
                'Cargando Actividades...'
              ) : (
                <ReactTable
                  columns={[
                    {
                      Header: 'REFERENCIA SOLICITUD',
                      accessor: 'referencia_solicitud',
                    },
                    {
                      Header: 'LÍNEA',
                      accessor: 'linea',
                    },
                    {
                      Header: 'AÑO CONVOCATORIA',
                      accessor: 'anio_convocatoria',
                    },
                    {
                      Header: 'BLOQUE ORDEN REVISIÖN',
                      accessor: 'bloque_orden_revision',
                    },
                    {
                      Header: 'CATEGORÍA PLIEGO',
                      accessor: 'categoria_pliego',
                    },
                    {
                      Header: 'DESCRIPCIÓN ACTO',
                      accessor: 'descripcion_acto',
                    },
                    {
                      Header: 'ACCIONES',
                      accessor: 'actions',
                    },
                  ]}
                  data={data}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {excel && (
                  <ExcelFile
                    element={<Button color='primary'>Exportar Excel</Button>}
                    filename='actividades'
                    hideElement={true}
                  >
                    <ExcelFile.ExcelSheet data={data} name='Actividades'>
                      <ExcelFile.ExcelColumn label='Referencia solicitud' value='referencia_solicitud' />
                      <ExcelFile.ExcelColumn label='Línea' value='linea' />
                      <ExcelFile.ExcelColumn label='Convocatoria' value='anio_convocatoria' />
                      <ExcelFile.ExcelColumn label='Bloque/orden/revisión' value='bloque_orden_revision' />
                      <ExcelFile.ExcelColumn label='Categoría' value='categoria_pliego' />
                      <ExcelFile.ExcelColumn label='Acto' value='descripcion_acto' />
                    </ExcelFile.ExcelSheet>
                  </ExcelFile>
                )}
                <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                  Exportar EXCEL
                </Button>
                <Button color='primary' onClick={() => setDownloadPdf(true)} style={{ marginLeft: '10px' }}>
                  Exportar PDF
                </Button>
              </div>
            </CardBody>
            {errorActividadList && (
              <GridContainer>
                <GridItem xs={12}>
                  <SnackbarContent message={errorActividadList} color='danger' />
                </GridItem>
              </GridContainer>
            )}
          </Card>
        </GridItem>
      </GridContainer>
      {updateActividadModal && (
        <UpdateActividadModal
          handleCloseModal={handleCloseModal}
          updateActividadModal={updateActividadModal}
          showUpdateActividad={showUpdateActividad}
        />
      )}
      {viewInfoModal && (
        <ViewActividadInfoModal
          viewInfo={viewInfoModal}
          closeViewInfoModal={closeViewInfoModal}
          info={showViewInfoModal}
        />
      )}
      {downloadExcel && (
        <DownloadConfirmModal
          downloadFile={downloadExcel}
          setDownloadFile={setDownloadExcel}
          setFile={setExcel}
          tableName='Actividades'
          FileExtension='Excel'
        />
      )}
      {downloadPdf && (
        <DownloadConfirmModal
          downloadFile={downloadPdf}
          setDownloadFile={setDownloadPdf}
          setFile={setPdf}
          tableName='Actividades'
          FileExtension='PDF'
        />
      )}
    </>
  )
}

export default UserActividadesScreen
