import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ReactExport from 'react-data-export'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Visibility, Delete, Edit } from '@mui/icons-material'
import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import DeleteCategoryModal from './components/DeleteCategoryModal'
import UpdateCategoryModal from './components/UpdateCategoryModal'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal'
import ViewCategoryInfoModal from './components/ViewCategoryInfoModal'
import { CATEGORY_LIST_RESET } from 'redux/constants/categoryConstants'
import { getCategories } from 'redux/actions/categoryActions'
import styles from './styles/categoryListScreenStyles'

const useStyles = makeStyles(styles)

const CategoryListScreen = () => {
  const dispatch = useDispatch()
  const ExcelFile = ReactExport.ExcelFile
  const classes = useStyles()

  const [data, setData] = useState([])
  const [updateCategoryModal, setUpdateCategoryModal] = useState(false)
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false)
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [downloadPdf, setDownloadPdf] = useState(false)
  const [excel, setExcel] = useState(false)
  const [pdf, setPdf] = useState(false)
  const [showUpdateCategory, setShowUpdateCategory] = useState({})
  const [showDeleteCategoryInfo, setShowDeleteCategoryInfo] = useState({})
  const [viewInfoModal, setViewInfoModal] = useState(false)
  const [showViewInfoModal, setShowViewInfoModal] = useState({})

  const { loadingCategoryList, categories, successCategoryList, errorCategoryList } = useSelector(
    (state) => state.categoryList
  )

  useEffect(() => {
    if (successCategoryList) {
      const list = categories.map((category) => {
        return {
          ...category,
          tipo_actuacion: `${category.tipo_actuacion || '-'}`,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => showUpdateCategoryHandler(category.id_tipo_categoria)}
                color='primary'
                className='active-deactive'
              >
                <Tooltip title='Editar Información'>
                  <Edit />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showViewInfoHandler(category.id_tipo_categoria)}
                color='success'
                className='edit'
              >
                <Tooltip title='Ver Información'>
                  <Visibility />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showDeleteCategoryHandler(category.id_tipo_categoria)}
                color='danger'
                className='delete'
              >
                <Tooltip title='Borrar'>
                  <Delete />
                </Tooltip>
              </Button>
            </div>
          ),
        }
      })
      setData(list)
    } else {
      dispatch(getCategories())
    }
  }, [successCategoryList, dispatch])

  useEffect(() => {
    return () => dispatch({ type: CATEGORY_LIST_RESET })
  }, [dispatch])

  useEffect(() => {
    if (pdf) {
      exportPDF()
    }
  }, [pdf])

  const handleCloseModal = () => {
    setUpdateCategoryModal(false)
    setShowUpdateCategory({})
  }
  const showUpdateCategoryHandler = (id) => {
    const category = categories.find((el) => el.id_tipo_categoria === id)
    setShowUpdateCategory(category)
    setUpdateCategoryModal(true)
  }

  const showDeleteCategoryHandler = (id) => {
    const category = categories.find((el) => el.id_tipo_categoria === id)
    setShowDeleteCategoryInfo(category)
    setDeleteCategoryModal(true)
  }

  const handleCloseDeleteCategoryModal = () => {
    setDeleteCategoryModal(false)
    setShowDeleteCategoryInfo({})
  }

  const showViewInfoHandler = (id) => {
    const category = categories.find((category) => category.id_tipo_categoria === id)
    setShowViewInfoModal(category)
    setViewInfoModal(true)
  }

  const closeViewInfoModal = () => {
    setViewInfoModal(false)
    setShowViewInfoModal({})
  }

  const exportPDF = () => {
    const doc = new jsPDF()
    doc.text('Categoriaes', 20, 10)
    doc.autoTable({
      body: data,
      columns: [
        { header: 'Categoría', dataKey: 'categoria_pliego' },
        { header: 'Tipo de actuación', dataKey: 'tipo_actuacion' },
        { header: 'Importe', dataKey: 'importe' },
      ],
    })
    doc.save('categorias.pdf')
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} className={classes.rootItem}>
          <Card>
            <CardBody>
              {loadingCategoryList ? (
                'Cargando Categorias...'
              ) : (
                <ReactTable
                  columns={[
                    {
                      Header: 'CATEGORÍA',
                      accessor: 'categoria_pliego',
                    },
                    {
                      Header: 'TIPO DE ACTUACIÓN',
                      accessor: 'tipo_actuacion',
                    },
                    {
                      Header: 'IMPORTE',
                      accessor: 'importe',
                    },
                    {
                      Header: 'ACCIONES',
                      accessor: 'actions',
                    },
                  ]}
                  data={data}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {excel && (
                  <ExcelFile
                    element={<Button color='primary'>Exportar Excel</Button>}
                    filename='categorias'
                    hideElement={true}
                  >
                    <ExcelFile.ExcelSheet data={data} name='Categorias'>
                      <ExcelFile.ExcelColumn label='Codigo de Categoría' value='categoria_pliego' />
                      <ExcelFile.ExcelColumn label='Tipo de actuación' value='tipo_actuacion' />
                      <ExcelFile.ExcelColumn label='Importe' value='importe' />
                    </ExcelFile.ExcelSheet>
                  </ExcelFile>
                )}
                <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                  Exportar EXCEL
                </Button>
                <Button color='primary' onClick={() => setDownloadPdf(true)} style={{ marginLeft: '10px' }}>
                  Exportar PDF
                </Button>
              </div>
            </CardBody>
            {errorCategoryList && (
              <GridContainer>
                <GridItem xs={12}>
                  <SnackbarContent message={errorCategoryList} color='danger' />
                </GridItem>
              </GridContainer>
            )}
          </Card>
        </GridItem>
      </GridContainer>
      {updateCategoryModal && (
        <UpdateCategoryModal
          handleCloseModal={handleCloseModal}
          updateCategoryModal={updateCategoryModal}
          showUpdateCategory={showUpdateCategory}
        />
      )}
      {viewInfoModal && (
        <ViewCategoryInfoModal
          viewInfo={viewInfoModal}
          closeViewInfoModal={closeViewInfoModal}
          info={showViewInfoModal}
        />
      )}
      {deleteCategoryModal && (
        <DeleteCategoryModal
          handleCloseDeleteCategoryModal={handleCloseDeleteCategoryModal}
          deleteCategoryModal={deleteCategoryModal}
          showDeleteCategoryInfo={showDeleteCategoryInfo}
        />
      )}
      {downloadExcel && (
        <DownloadConfirmModal
          downloadFile={downloadExcel}
          setDownloadFile={setDownloadExcel}
          setFile={setExcel}
          tableName='Categorias'
          FileExtension='Excel'
        />
      )}
      {downloadPdf && (
        <DownloadConfirmModal
          downloadFile={downloadPdf}
          setDownloadFile={setDownloadPdf}
          setFile={setPdf}
          tableName='Categorias'
          FileExtension='PDF'
        />
      )}
    </>
  )
}

export default CategoryListScreen
