import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios'
import { makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardAvatar from 'components/Card/CardAvatar'
import Button from 'components/CustomButtons/Button'
import styles from 'assets/jss/material-ui-react/views/userProfileStyles'

const useStyles = makeStyles(styles)

const UserProfileScreen = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { userInfo } = useSelector((state) => state.userLogin)
  const [userInformation, setUserInfo] = useState(userInfo)
  const [alert, setAlert] = useState(null)

  const { loadingUserProfileInfo, profileInfo } = useSelector((state) => state.userProfileInfo)

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo])

  useEffect(() => {
    if (profileInfo) {
      setUserInfo({ ...userInformation, profileInfo })   
      if (userInfo.primer_acceso == 'SI') {
        setAlert(
          <SweetAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title='¡CAMBIO DE CONTRASEÑA!'
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.confirmBtnCssClass}
          >
            Por ser el primer acceso debe cambiar su contraseña. Pulse en el botón de cambiar contraseña de acceso
          </SweetAlert>
        )
      }   
    }
  }, [profileInfo])


  return (
    <div>
      {loadingUserProfileInfo ? (
        <>Cargando información del usuario</>
      ) : (
        <>
          <GridContainer>
            <GridItem xs={12} sm={6} style={{ margin: 'auto' }}>
              <Card profile>
                <CardAvatar profile round={true}>
                  <a href='#user' onClick={(e) => e.preventDefault()}>
                    <img src={`${axios.defaults.baseURL}/public/assets/img/avatars/avatar.jpg`} alt='...' />
                  </a>
                </CardAvatar>
                <CardBody profile>
                  <p className={classes.description}>Usuario · {userInfo?.userName}</p>
                </CardBody>
                <CardBody profile>
                  {userInfo?.permiso?.map((p) => (
                    <h6 key={p}>{`${p}`}</h6>
                  ))}

                </CardBody>
              </Card>
              <GridContainer>
                <GridItem xs={12} md={4} style={{textAlign: "center"}}>
                  <NavLink to={'/admin/changePassword'} >
                    <Button color='primary'>
                       Cambiar contraseña de acceso
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </GridItem>            
          </GridContainer>
          {userInfo && userInfo.primer_acceso == 'SI' && (
            alert
          )}          
        </>
      )}
    </div>
  )
}

export default UserProfileScreen
