export const PROFILE_TO_MANAGER_REGISTER_REQUEST = 'PROFILE_TO_MANAGER_REGISTER_REQUEST'
export const PROFILE_TO_MANAGER_REGISTER_SUCCESS = 'PROFILE_TO_MANAGER_REGISTER_SUCCESS'
export const PROFILE_TO_MANAGER_REGISTER_FAIL = 'PROFILE_TO_MANAGER_REGISTER_FAIL'
export const PROFILE_TO_MANAGER_REGISTER_RESET = 'PROFILE_TO_MANAGER_REGISTER_RESET'

export const PROFILE_TO_MANAGER_LIST_REQUEST = 'PROFILE_TO_MANAGER_LIST_REQUEST'
export const PROFILE_TO_MANAGER_LIST_SUCCESS = 'PROFILE_TO_MANAGER_LIST_SUCCESS'
export const PROFILE_TO_MANAGER_LIST_FAIL = 'PROFILE_TO_MANAGER_LIST_FAIL'
export const PROFILE_TO_MANAGER_LIST_RESET = 'PROFILE_TO_MANAGER_LIST_RESET'

export const PROFILE_TO_MANAGER_UPDATE_REQUEST = 'PROFILE_TO_MANAGER_UPDATE_REQUEST'
export const PROFILE_TO_MANAGER_UPDATE_SUCCESS = 'PROFILE_TO_MANAGER_UPDATE_SUCCESS'
export const PROFILE_TO_MANAGER_UPDATE_FAIL = 'PROFILE_TO_MANAGER_UPDATE_FAIL'
export const PROFILE_TO_MANAGER_UPDATE_RESET = 'PROFILE_TO_MANAGER_UPDATE_RESET'

export const PROFILE_TO_MANAGER_DELETE_REQUEST = 'PROFILE_TO_MANAGER_DELETE_REQUEST'
export const PROFILE_TO_MANAGER_DELETE_SUCCESS = 'PROFILE_TO_MANAGER_DELETE_SUCCESS'
export const PROFILE_TO_MANAGER_DELETE_FAIL = 'PROFILE_TO_MANAGER_DELETE_FAIL'
export const PROFILE_TO_MANAGER_DELETE_RESET = 'PROFILE_TO_MANAGER_DELETE_RESET'
