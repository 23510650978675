import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ReactExport from 'react-data-export'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Visibility, Delete, Lightbulb, LightbulbOutlined, Edit, Calculate } from '@mui/icons-material'
import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import DeleteUserModal from './components/DeleteUserModal'
import UpdateUserModal from './components/UpdateUserModal'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal'
import ActiveAndDesactiveUserModal from './components/ActiveAndDesactiveUserModal'
import ViewUserInfoModal from './components/ViewUserInfoModal'
import ResetPasswordModal from './components/ResetPasswordModal'
import { USERLIST_LIST_RESET } from 'redux/constants/userlistConstants'
import { getUserlists } from 'redux/actions/userlistActions'
import styles from './styles/userListScreenStyles'

const useStyles = makeStyles(styles)

const UserListScreen = () => {
  const dispatch = useDispatch()
  const ExcelFile = ReactExport.ExcelFile
  const classes = useStyles()

  const [data, setData] = useState([])
  const [updateUserModal, setUpdateUserModal] = useState(false)
  const [deleteUserModal, setDeleteUserModal] = useState(false)
  const [desactiveModal, setDesactiveModal] = useState(false)
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [downloadPdf, setDownloadPdf] = useState(false)
  const [activeModal, setActiveModal] = useState(false)
  const [excel, setExcel] = useState(false)
  const [pdf, setPdf] = useState(false)
  const [showActiveInfo, setShowActiveInfo] = useState({})
  const [showDesactiveInfo, setShowDesactiveInfo] = useState({})
  const [showUpdateUser, setShowUpdateUser] = useState({})
  const [showDeleteUserInfo, setShowDeleteUserInfo] = useState({})
  const [viewInfoModal, setViewInfoModal] = useState(false)
  const [showViewInfoModal, setShowViewInfoModal] = useState({})
  const [resetPassword, setResetPassword] = useState(false)
  const [showResetPassword, setShowResetPassword] = useState({})

  const { loadingUserlistList, userlist, successUserlistList, errorUserlistList } = useSelector(
    (state) => state.userlistList
  )  

  useEffect(() => {
    if (successUserlistList) {
      const list = userlist.map((user) => {
        return {
          ...user,
          nombre: `${user.nombre || '-'}`,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => handlerActiveAndDesactive(user)}
                color='warning'
                className='active-deactive'
              >
                <Tooltip title='activar/desactivar'>
                  {user.activo === 'SI' ? <Lightbulb /> : <LightbulbOutlined />}
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showUpdateUserHandler(user.id_usuario)}
                color='primary'
                className='active-deactive'
              >
                <Tooltip title='Editar Información'>
                  <Edit />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showViewInfoHandler(user.id_usuario)}
                color='success'
                className='edit'
              >
                <Tooltip title='Ver Información'>
                  <Visibility />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showDeleteUserHandler(user.id_usuario)}
                color='danger'
                className='delete'
              >
                <Tooltip title='Borrar'>
                  <Delete />
                </Tooltip>
              </Button>
              <Button 
                justIcon 
                round 
                simple 
                onClick={() => showResetPasswordHandler(user.id_usuario)} 
                color='info' 
                className='reset'>
                <Tooltip title='Resetear contraseña'>
                  <Calculate />
                </Tooltip>
              </Button>
            </div>
          ),
        }
      })
      setData(list)
    } else {
      dispatch(getUserlists())
    }
  }, [successUserlistList, dispatch])

  useEffect(() => {
    return () => dispatch({ type: USERLIST_LIST_RESET })
  }, [dispatch])

  useEffect(() => {
    if (pdf) {
      exportPDF()
    }
  }, [pdf])

  const handlerActiveAndDesactive = (user) => {
    if (user.activo === 'SI') {
      showDesactiveUserHandler(user.id_usuario)
      return
    }
    showActiveUserHandler(user.id_usuario)
  }

  const showActiveUserHandler = (id) => {
    const user = userlist.find((el) => el.id_usuario === id)
    setShowActiveInfo(user)
    setActiveModal(true)
  }

  const closeActiveModal = () => {
    setActiveModal(false)
    setShowActiveInfo({})
  }
  const showDesactiveUserHandler = (id) => {
    const user = userlist.find((el) => el.id_usuario === id)
    setShowDesactiveInfo(user)
    setDesactiveModal(true)
  }

  const closeDesactiveModal = () => {
    setDesactiveModal(false)
    setShowDesactiveInfo({})
  }

  const handleCloseModal = () => {
    setUpdateUserModal(false)
    setShowUpdateUser({})
  }
  const showUpdateUserHandler = (id) => {
    const user = userlist.find((el) => el.id_usuario === id)
    setShowUpdateUser(user)
    setUpdateUserModal(true)
  }

  const showDeleteUserHandler = (id) => {
    const user = userlist.find((el) => el.id_usuario === id)
    setShowDeleteUserInfo(user)
    setDeleteUserModal(true)
  }

  const handleCloseDeleteUserModal = () => {
    setDeleteUserModal(false)
    setShowDeleteUserInfo({})
  }

  const showViewInfoHandler = (id) => {
    const user = userlist.find((el) => el.id_usuario === id)
    setShowViewInfoModal(user)
    setViewInfoModal(true)
  }

  const closeViewInfoModal = () => {
    setViewInfoModal(false)
    setShowViewInfoModal({})
  }

  const showResetPasswordHandler = (id) => {
    const user = userlist.find((el) => el.id_usuario === id)
    setShowResetPassword(user)
    setResetPassword(true)
  }

  const closeResetPasswordModal = () => {
    setResetPassword(false)
    setShowResetPassword({})
  }

  const exportPDF = () => {
    const doc = new jsPDF()
    doc.text('Usuarios', 20, 10)
    doc.autoTable({
      body: data,
      columns: [
        { header: 'Identificador de Usuario', dataKey: 'usuario' },
        { header: 'Nombre', dataKey: 'nombre' },
        { header: 'Activo', dataKey: 'activo' },
        { header: 'Fecha de alta', dataKey: 'fecha_alta' },
        { header: 'Fecha de baja', dataKey: 'fecha_baja' },
      ],
    })
    doc.save('usuarios.pdf')
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} className={classes.rootItem}>
          <Card>
            <CardBody>
              {loadingUserlistList ? (
                'Cargando Usuarios...'
              ) : (
                <ReactTable
                  columns={[
                    {
                      Header: 'IDENTIFICADOR USUARIO',
                      accessor: 'usuario',
                    },
                    {
                      Header: 'NOMBRE',
                      accessor: 'nombre',
                    },
                    {
                      Header: 'ACTIVO',
                      accessor: 'activo',
                    },
                    {
                      Header: 'ACCIONES',
                      accessor: 'actions',
                    },
                  ]}
                  data={data}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {excel && (
                  <ExcelFile
                    element={<Button color='primary'>Exportar Excel</Button>}
                    filename='usuarios'
                    hideElement={true}
                  >
                    <ExcelFile.ExcelSheet data={data} name='Usuarios'>
                      <ExcelFile.ExcelColumn label='Identificador de usuario' value='usuario' />
                      <ExcelFile.ExcelColumn label='Nombre' value='nombre' />
                      <ExcelFile.ExcelColumn label='Activo' value='activo' />
                      <ExcelFile.ExcelColumn label='Fecha de alta' value='fecha_alta' />
                      <ExcelFile.ExcelColumn label='Fecha de baja' value='fecha_baja' />
                    </ExcelFile.ExcelSheet>
                  </ExcelFile>
                )}
                <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                  Exportar EXCEL
                </Button>
                <Button color='primary' onClick={() => setDownloadPdf(true)} style={{ marginLeft: '10px' }}>
                  Exportar PDF
                </Button>
              </div>
            </CardBody>
            {errorUserlistList && (
              <GridContainer>
                <GridItem xs={12}>
                  <SnackbarContent message={errorUserlistList} color='danger' />
                </GridItem>
              </GridContainer>
            )}
          </Card>
        </GridItem>
      </GridContainer>
      {activeModal && (
        <ActiveAndDesactiveUserModal
          activeModal={activeModal}
          closeActiveModal={closeActiveModal}
          info={showActiveInfo}
          active={true}
        />
      )}
      {desactiveModal && (
        <ActiveAndDesactiveUserModal
          activeModal={desactiveModal}
          closeActiveModal={closeDesactiveModal}
          info={showDesactiveInfo}
          active={false}
        />
      )}
      {updateUserModal && (
        <UpdateUserModal
          handleCloseModal={handleCloseModal}
          updateUserModal={updateUserModal}
          showUpdateUser={showUpdateUser}
        />
      )}
      {viewInfoModal && (
        <ViewUserInfoModal
          viewInfo={viewInfoModal}
          closeViewInfoModal={closeViewInfoModal}
          info={showViewInfoModal}
        />
      )}
      {deleteUserModal && (
        <DeleteUserModal
          handleCloseDeleteUserModal={handleCloseDeleteUserModal}
          deleteUserModal={deleteUserModal}
          showDeleteUserInfo={showDeleteUserInfo}
        />
      )}
      {resetPassword && (
        <ResetPasswordModal
          handleCloseModal={closeResetPasswordModal}
          resetPassword={resetPassword}
          showResetPassword={showResetPassword}
        />
      )}
      {downloadExcel && (
        <DownloadConfirmModal
          downloadFile={downloadExcel}
          setDownloadFile={setDownloadExcel}
          setFile={setExcel}
          tableName='Usuarios'
          FileExtension='Excel'
        />
      )}
      {downloadPdf && (
        <DownloadConfirmModal
          downloadFile={downloadPdf}
          setDownloadFile={setDownloadPdf}
          setFile={setPdf}
          tableName='Usuarios'
          FileExtension='PDF'
        />
      )}
    </>
  )
}

export default UserListScreen
