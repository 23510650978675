import { useEffect, useState } from "react"
import { Box, Tab, Tabs, Typography } from "@mui/material"

import { TabPanelContent } from "./TabPanelContent"


export const TabPanelContainer = ({ report }) => {
   const report_controls = report != null ? report.list : []
   const report_summary = report != null ? report.summary : {}

   const [value, setValue] = useState(0)

   const handleChange = (e, newValue) => {
      setValue( newValue )
   }


   return (
      <Box sx={{ width: "100%" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
               value={value} 
               onChange={handleChange}
               variant="scrollable"
               aria-label="basic tabs example"
            >

                  <Tab
                     key={"tab_controls"}
                     label={"Controles"}
                     {...allyProps(0)}
                  />
                  <Tab
                     key={"tab_summary"}
                     label={"Resumen"}
                     {...allyProps(1)}
                  />

            </Tabs>
         </Box>

            {/* CONTROLS */}
            <TabPanel value={value} index={0} key={"tabPanel_controls"}>
               <TabPanelContent controls={report_controls} />
            </TabPanel>
            {/* SUMMARY */}
            <TabPanel value={value} index={1} key={"tabPanel_summary"}>
               <TabPanelContent summary={report_summary} />
            </TabPanel>


      </Box>
   )
}

const TabPanel = ({ children, value, index, ...other }) => {

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${ index }`}
         aria-labelledby={`simple-tab-${ index }`}
         {...other}
      >
         {
            value === index
            && <Box sx={{ p:3 }}>
               <Typography>{ children }</Typography>
            </Box>
         }
      </div>
   )
}

const allyProps = ( index ) => {

   return {
      id: `simple-tab-${ index }`,
      "aria-controls": `simple-tabpanel-${ index }`,
   }
}


