import {
   TECHNICIANSTRACKING_LIST_REQUEST,
   TECHNICIANSTRACKING_LIST_SUCCESS,
   TECHNICIANSTRACKING_LIST_FAIL,
   TECHNICIANSTRACKING_LIST_RESET,
} from '../constants/trackingConstants.js'

 
export const techniciansTrackingListReducer = (state = { techniciansTracking: [] }, action) => {
   switch (action.type) {
      case TECHNICIANSTRACKING_LIST_REQUEST:
         return { loadingTechniciansTrackingList: true }
      case TECHNICIANSTRACKING_LIST_SUCCESS:
         return {
            loadingTechniciansTrackingList: false,
            successTechniciansTrackingList: true,
            techniciansTracking: action.payload,
         }
      case TECHNICIANSTRACKING_LIST_FAIL:
         return { 
            loadingTechniciansTrackingList: false,
            errorTechniciansTrackingList: action.payload 
         }
      case TECHNICIANSTRACKING_LIST_RESET:
         return {}
      default:
         return state
   }
}

 