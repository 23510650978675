import { useState, useEffect } from "react"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import ReactTable from "components/ReactTable/ReactTable"
import { formatNumber } from "utils/formatNumber"


export const TabPanelContentGlobal = ({ global_data }) => {

   const [table_global_cols, setTable_global_cols] = useState([])
   const [table_global_data, setTable_global_data,] = useState([])
   const [showContent, setShowContent] = useState(false)

   useEffect(() => {
      if (global_data != null && global_data[0].length) {

         const cols =  [
            {
               Header: "",
               accessor: "categories",
            },
            {
               Header: "Nº actuaciones",
               accessor: "num_actions",
            },
            {
               Header: "Facturación ",
               accessor: "facturacion",
            },
            {
               Header: "Planificación",
               accessor: "hito",
            },
         ]

         let total_num_actions = 0
         let total_billing = 0
         const rows = global_data[0].map((row, index) => {
            const object_to_push = {}

            object_to_push["categories"] = `Categoría ${ row.code }`
            object_to_push["num_actions"] = formatNumber(row.num_actions, 0)
            object_to_push["facturacion"] = `${ formatNumber(row.billing) } €`
            object_to_push["hito"] = `${ formatNumber(row.milestone) }%`

            total_num_actions += row.num_actions
            total_billing += row.billing

            return object_to_push
         })
      
         rows.push({
            categories: "TOTAL",
            num_actions: formatNumber(total_num_actions, 0),
            facturacion: `${ formatNumber(total_billing) } €`,
            hito: `${ formatNumber((total_billing * 100) / process.env.REACT_APP_TOTAL_GLOBAL_MILESTONE) }%`,
         })

         setTable_global_cols( cols )
         setTable_global_data( rows )
         setShowContent(true)
      }
      

   }, [global_data])
   

   return (
      <GridContainer>
         <GridItem xs={12}>
            {
               (!!table_global_data.length && showContent)
               && 
               <ReactTable
                  columns={table_global_cols}
                  data={table_global_data}
                  numFilas={20}
                  search={false}
               />
            }
         </GridItem>
      </GridContainer>
   )
}



