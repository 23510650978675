export const PROFILE_REGISTER_REQUEST = 'PROFILE_REGISTER_REQUEST'
export const PROFILE_REGISTER_SUCCESS = 'PROFILE_REGISTER_SUCCESS'
export const PROFILE_REGISTER_FAIL = 'PROFILE_REGISTER_FAIL'
export const PROFILE_REGISTER_RESET = 'PROFILE_REGISTER_RESET'

export const PROFILE_LIST_REQUEST = 'PROFILE_LIST_REQUEST'
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS'
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL'
export const PROFILE_LIST_RESET = 'PROFILE_LIST_RESET'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL'
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET'

export const PROFILE_DELETE_REQUEST = 'PROFILE_DELETE_REQUEST'
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS'
export const PROFILE_DELETE_FAIL = 'PROFILE_DELETE_FAIL'
export const PROFILE_DELETE_RESET = 'PROFILE_DELETE_RESET'
