import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
  Dialog,
  makeStyles,
  Select as Selectable,
} from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { profileUpdateInfo, getProfiles } from 'redux/actions/profileActions'
import { PROFILE_UPDATE_RESET } from 'redux/constants/profileConstants'
import styles from '../styles/updateProfileStyles'

const useStyles = makeStyles(styles)

const UpdateProfileModal = ({ handleCloseModal, updateProfileModal, showUpdateProfile }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [profileInfo, setProfileInfo] = useState(showUpdateProfile)
  const [codProfile, setCodProfile] = useState(profileInfo.codigo_perfil)
  const [codProfileError, setCodProfileError] = useState('')
  const [description, setDescription] = useState(profileInfo.descripcion_perfil)

  const [alert, setAlert] = useState(null)
  const { loadingProfileUpdate, errorProfileUpdate, successProfileUpdate } = useSelector((state) => state.profileUpdate)

    useEffect(() => {
    if (successProfileUpdate) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='HECHO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Perfil actualizado correctamente
        </SweetAlert>
      )
    }
  }, [successProfileUpdate])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!codProfile) {
      return setCodProfileError('Por favor ingrese un Código de Perfil')
    }
    const profile = {
      id_perfil: profileInfo.id_perfil,
      codigo_perfil: codProfile,
      descripcion_perfil: description,
    }
    dispatch(profileUpdateInfo(profile))
  }
  const confirmSuccess = () => {
    dispatch(getProfiles())
    dispatch({ type: PROFILE_UPDATE_RESET })
    handleCloseModal()
    setAlert(null)
  }

  return (
    <>
      <Dialog
        open={updateProfileModal}
        keepMounted
        onClose={handleCloseModal}
        aria-labelledby='notice-modal-slide-title'
        aria-describedby='notice-modal-slide-description'
      >
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'CÓDIGO DE PERFIL'}
                        id='codeprofile'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: codProfile,
                          onChange: (e) => setCodProfile(e.target.value),
                          type: 'text',
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'DESCRIPCIÓN DE PERFIL'}
                        id='description'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: description || '',
                          onChange: (e) => setDescription(e.target.value),
                          type: 'text',
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'space-around' }}>
                  <Button color='secondary' onClick={handleCloseModal}>
                    Cerrar
                  </Button>
                  <Button type='submit' color='primary'>
                    {loadingProfileUpdate ? 'Actualizando...' : 'Actualizar Perfil'}
                  </Button>
                </GridItem>
                {errorProfileUpdate && (
                  <GridItem xs={12}>
                    <SnackbarContent message={errorProfileUpdate} color='danger' />
                  </GridItem>
                )}
                {codProfileError && !codProfile && (
                  <GridItem xs={12}>
                    <SnackbarContent message={codProfileError} color='danger' />
                  </GridItem>
                )}
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </Dialog>
      {alert}
    </>
  )
}

export default UpdateProfileModal
