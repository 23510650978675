const styles = (theme) => ({
   confirmBtnCssClass: {
      backgroundColor: '#3cba55',
      color: 'white',
      padding: '10px',
      width: '5rem',
      borderRadius: '3px',
      '&:hover': {
        color: 'white',
      },
   },
   cardIconTitle: {
      marginTop: '15px',
      marginBottom: '0px',
   },
   rootItem: {
      padding: '0 !important',
   },
 })
 
 export default styles