export const PLANNING_REGISTER_REQUEST = 'PLANNING_REGISTER_REQUEST'
export const PLANNING_REGISTER_SUCCESS = 'PLANNING_REGISTER_SUCCESS'
export const PLANNING_REGISTER_FAIL = 'PLANNING_REGISTER_FAIL'
export const PLANNING_REGISTER_RESET = 'PLANNING_REGISTER_RESET'

export const PLANNING_LIST_REQUEST = 'PLANNING_LIST_REQUEST'
export const PLANNING_LIST_SUCCESS = 'PLANNING_LIST_SUCCESS'
export const PLANNING_LIST_FAIL = 'PLANNING_LIST_FAIL'
export const PLANNING_LIST_RESET = 'PLANNING_LIST_RESET'

export const PLANNING_UPDATE_REQUEST = 'PLANNING_UPDATE_REQUEST'
export const PLANNING_UPDATE_SUCCESS = 'PLANNING_UPDATE_SUCCESS'
export const PLANNING_UPDATE_FAIL = 'PLANNING_UPDATE_FAIL'
export const PLANNING_UPDATE_RESET = 'PLANNING_UPDATE_RESET'

export const PLANNING_DELETE_REQUEST = 'PLANNING_DELETE_REQUEST'
export const PLANNING_DELETE_SUCCESS = 'PLANNING_DELETE_SUCCESS'
export const PLANNING_DELETE_FAIL = 'PLANNING_DELETE_FAIL'
export const PLANNING_DELETE_RESET = 'PLANNING_DELETE_RESET'

export const PLANNING_LINES_LIST_REQUEST = 'PLANNING_LINES_LIST_REQUEST'
export const PLANNING_LINES_LIST_SUCCESS = 'PLANNING_LINES_LIST_SUCCESS'
export const PLANNING_LINES_LIST_FAIL = 'PLANNING_LINES_LIST_FAIL'
export const PLANNING_LINES_LIST_RESET = 'PLANNING_LINES_LIST_RESET'

export const PLANNING_INDICATORS_LIST_REQUEST = 'PLANNING_INDICATORS_REQUEST'
export const PLANNING_INDICATORS_LIST_SUCCESS = 'PLANNING_INDICATORS_SUCCESS'
export const PLANNING_INDICATORS_LIST_FAIL = 'PLANNING_INDICATORS_FAIL'
export const PLANNING_INDICATORS_LIST_RESET = 'PLANNING_INDICATORS_RESET'

export const PLANNING_LOADS_LIST_REQUEST = 'PLANNING_LOADS_REQUEST'
export const PLANNING_LOADS_LIST_SUCCESS = 'PLANNING_LOADS_SUCCESS'
export const PLANNING_LOADS_LIST_FAIL = 'PLANNING_LOADS_FAIL'
export const PLANNING_LOADS_LIST_RESET = 'PLANNING_LOADS_RESET'
