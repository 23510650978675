import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, makeStyles, Select, MenuItem, InputLabel, FormControlLabel, Radio, FormLabel, RadioGroup, Typography } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ReactExport from 'react-data-export'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Visibility, Edit } from '@mui/icons-material'

import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal'
import ViewWorkInfoActModal from '../components/ViewWorkInfoActModal'

import { WORK_LIST_RESET } from 'redux/constants/workConstants'
import { TECHNICIAN_LIST_RESET } from 'redux/constants/userConstants'
import { getWorks } from 'redux/actions/workActions'
import { getTechnicians } from 'redux/actions/userActions'
import styles from './styles/workAdministrativeOtherProfileListScreenStyles'
import { getPlanningLines } from 'redux/actions/planningActions'
import { PLANNING_LINES_LIST_RESET } from 'redux/constants/planningConstants'
import { ClipLoader } from 'react-spinners'
import { getActs } from 'redux/actions/actActions'
import { ACT_LIST_RESET } from 'redux/constants/actConstants'

const useStyles = makeStyles(styles)

const WorkAdministrativeOtherProfilesListScreen = () => {
   const dispatch = useDispatch()
   const ExcelFile = ReactExport.ExcelFile
   const classes = useStyles()

   const techActIds = [1, 3, 6, 7]
   const validatorActIds = [1, 2, 3, 5, 6, 7, 999]
   const act999 = {
      id_acto: 999,
      descripcion_acto: "Solicitud Cartas de Pago",
   }
   
   const [data, setData] = useState(null)
   const [downloadExcel, setDownloadExcel] = useState(false)
   const [downloadPdf, setDownloadPdf] = useState(false)
   const [excel, setExcel] = useState(false)
   const [pdf, setPdf] = useState(false)
   const [viewInfoActModal, setViewInfoActModal] = useState(false)
   const [showViewInfoActModal, setShowViewInfoActModal] = useState({})
   const [reactTableCols, setReactTableCols] = useState([])
   const [lineStructures, setLineStructures] = useState([])
   const [lineStructure, setLineStructure] = useState(null)
   const [lineYears, setLineYears] = useState([])
   const [lineYear, setLineYear] = useState(null)
   const [filterActs, setFilterActs] = useState([])
   const [selectedAct, setSelectedAct] = useState(null)
   const [actError, setActError] = useState("")
   const [notControlsMessage, setNotControlsMessage] = useState("")
   const [searchError, setSearchError] = useState("")
   const [userType, setUserType] = useState(null)
   const [selectedTech, setSelectedTech] = useState(null)

   const { loadingWorkList, works, successWorkList, errorWorkList } = useSelector(
      (state) => state.workList
   )

   const { loadingTechnicianList, successTechnicianList, errorTechnicianList, technicians } = useSelector(
      (state) => state.technicianList
   )

   const { loadingPlanningsLinesList, successPlanningsLinesList, errorPlanningsLinesList, planningsLines } = useSelector(
      (state) => state.planningLinesList
   )

   const { loadingActList, successActList, errorActList, acts } = useSelector(
      (state) => state.actList
   )

   useEffect(() => {
      
      if (successWorkList && works != null && works.length) {
         const { columns_data, control_data } = works[0]

         const reactTableCols_info = []
         control_data.forEach(data => {
            const table_name_db = Object.keys(data)[0]
            let table_name_excel = ""

            if ( table_name_db !== "categoria_derivada" ) {
               const col = columns_data.find(column => {
                  return column?.nombre_columna_tabla === table_name_db
               })

               table_name_excel = col.nombre_columna_excel

               reactTableCols_info.push({
                  Header: table_name_excel,
                  accessor: (table_name_db === "categoria_pliego") ? "categoria_pliego_derivada" : table_name_db, 
               })
            }
         })

         reactTableCols_info.push({
            Header: 'ACCIONES',
            accessor: 'actions',
         })

         setReactTableCols( reactTableCols_info )
         const list = getTableData(works[0].result, showViewInfoActHandler)

         setData(list)      
      }
      
      if (successActList && !loadingWorkList && works != null && !works.length) {
         setNotControlsMessage("No hay controles pendientes para este acto")
      }

      if ( successPlanningsLinesList && planningsLines != null && planningsLines.length && !loadingWorkList && selectedAct != null && works != null && works.length ) {
         const newLineStructures = Array.from(new Set(planningsLines.map(line => line.linea)))
         .filter(line => works[0].result.map(row => row.linea).includes(line))
      
         setLineStructures( newLineStructures )
      }
   }, [successWorkList, works])

   useEffect(() => {
      dispatch(getTechnicians())
      dispatch(getPlanningLines())
      dispatch(getActs())

      return () => {
         dispatch({ type: WORK_LIST_RESET })
         dispatch({ type: TECHNICIAN_LIST_RESET })
         dispatch({ type: PLANNING_LINES_LIST_RESET })
         dispatch({ type: ACT_LIST_RESET })
      }
   }, [])


   useEffect(() => {

      setLineStructures([])
      setLineStructure(null)
      setLineYears([])
      setLineYear(null)
      
      
   }, [selectedAct])

   useEffect(() => {
      if (pdf) {
         exportPDF()
      }
   }, [pdf])

   useEffect(() => {
      if ( successActList && acts != null && acts.length ) {
         let newFilterActs = []
         if ( userType === "technician" ) {
            newFilterActs = acts.filter(act => techActIds.includes(act.id_acto))
         }else {
            newFilterActs = acts.filter(act => validatorActIds.includes(act.id_acto))
            newFilterActs.push(act999)
         }

         setFilterActs(newFilterActs)
      }
      setNotControlsMessage("")
      setSearchError("")

   }, [successActList, userType])

   // funciones
   const onChangeLineStructureHandler = (e) => {
      const newStructure = e.target.value
      const newYears = Array.from(new Set(works[0].result.filter(row => row.linea === newStructure)
         .map(row => row.anio_convocatoria)))

      setLineStructure( newStructure )
      setLineYears(newYears)
      setData([])
      setLineYear(null)
   }

   const onChangeLineYearHandler = (e) => {
      const newVal = e.target.value
      setData([])
      setLineYear( newVal )   
   }

   const onChangeActHandler = (e) => {
      const newActToFilter = e.target.value
      setData([])
      setSelectedAct( newActToFilter )
      if ( userType === "validator" ) dispatch(getWorks({all: false, toDoAct: newActToFilter, validator: true}))
   }

   const onSearchClickHandler = () => { 

      setSearchError("")
      if ( userType === "technician" ) {
         if ( selectedTech == null ) setSearchError("No se ha seleccionado el técnico")
         else if ( selectedAct == null ) setSearchError("No se ha seleccionado el acto")
         else {
            dispatch(getWorks({ all: false, toDoAct: selectedAct, userName: selectedTech.usuario, tech: true }))
         }

      }else if ( userType === "validator" ) {
         
         if ( selectedAct == null ) setSearchError("No se ha seleccionado el acto")
         else if ( lineStructure == null ) setSearchError("No se ha seleccionado la estructura de la línea")
         else if ( lineYear == null ) setSearchError("No se ha seleccionado el año")
         else {
            setSearchError("")

            const newData = works[0].result.filter(row => row.linea === lineStructure && row.anio_convocatoria === lineYear)      
            setData( getTableData(newData, showViewInfoActHandler) )
         }
      }
      
   }

   const onChangeUserTypeHandler = (e) => {    
      const newUserType = e.target.value
      setSelectedAct(null)
      setSelectedTech(null)
      setUserType( newUserType )
   }
   
   const onChangeTechnicianHandler = (e) => {
      const newTech = e.target.value
      setSelectedTech( newTech )
   }


   // ...
   const showViewInfoActHandler = (id) => {
      setShowViewInfoActModal(id)
      setViewInfoActModal(true)
   }

   const closeViewInfoActModal = () => {
      setViewInfoActModal(false)
      setShowViewInfoActModal({})
   }

   const exportPDF = () => {
      const doc = new jsPDF()
      doc.text('Trabajos', 20, 10)
      doc.autoTable({
         body: data,
         columns: [
            { header: 'Referencia solicitud', dataKey: 'referencia_solicitud' },
            { header: 'Linea', dataKey: 'linea' },
            { header: 'Año convocatoria', dataKey: 'anio_convocatoria' },
            { header: 'Bloque / orden / revisión', dataKey: 'bloque_orden_revision' },
            { header: 'Categoría', dataKey: 'categoria_pliego' },
            { header: 'Acto', dataKey: 'descripcion_acto' },
            { header: 'Usuario', dataKey: 'usuario' },
         ],
      })
      doc.save('trabajos.pdf')
   }


   return (
   <>
      <GridContainer>
         <GridItem xs={12} className={classes.rootItem}>
            
            <Card>
               <CardBody>
                  <GridContainer>
                     <GridItem xs={12}>
                        <Typography 
                           variant='h5' 
                           gutterBottom
                           style={{color: "gray"}}
                           >
                           Tipo de usuario
                        </Typography>
                     </GridItem>
                     {/* SELECTOR TIPO USUARIO */}
                     <GridItem xs={3} style={{marginBottom: "20px"}}>    
                        <FormControl>
                           {/* <FormLabel id="radio-buttons-group-label" className={classes.radioLabel} style={{color: "gray"}}>Tipo de usuario</FormLabel> */}
                           <RadioGroup
                              row
                              aria-labelledby="radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={userType}
                              onChange={onChangeUserTypeHandler}
                           >
                           <FormControlLabel
                              className={classes.radioSelector}
                              value="technician"
                              control={<Radio color="primary" />}
                              label="Técnico"
                           />
                           <FormControlLabel 
                              className={classes.radioSelector}
                              value="validator"
                              control={<Radio color="primary" />}
                              label="Validador"
                              />
                           </RadioGroup>
                        </FormControl>
                     </GridItem>
                     {/* SELECTOR TÉCNICO */}
                     <GridItem xs={9}>
                        {
                           userType === "technician" && technicians != null
                           ? (
                              <FormControl fullWidth>
                                 <InputLabel htmlFor='select-line'>Técnico</InputLabel>
                                 <Select
                                    MenuProps={{
                                       className: classes.selectMenu,
                                    }}
                                    className={classes.select}
                                    value={selectedTech}
                                    onChange={onChangeTechnicianHandler}
                                    inputProps={{
                                       name: 'select-line',
                                       id: 'select-line',
                                       required: true,
                                    }}
                                 >
                                    <MenuItem
                                       disabled
                                       classes={{
                                          root: classes.selectMenuItem,
                                       }}
                                    >
                                       Seleccione el técnico
                                    </MenuItem>
                                    {
                                       technicians.map((act, index) => {
                                          const value = act

                                          return (
                                             <MenuItem
                                                value={value}
                                                key={`${value} - ${index}`}
                                                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                             >
                                                {value.usuario}
                                             </MenuItem>
                                          )
                                       })
                                    }
                                 </Select>
                              </FormControl>
                           )
                           : ""
                        }
                     </GridItem>

                     {/* FILTROS TÉCNICO */}
                     {
                        ( userType === "technician" )
                        ? !filterActs.length
                           ? ( 'Cargando...' ) 
                           : (
                              <>
                              {/* SELECTOR ACTO */}
                              <GridItem xs={9}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor='select-line'>Acto</InputLabel>
                                    <Select
                                       MenuProps={{
                                          className: classes.selectMenu,
                                       }}
                                       className={classes.select}
                                       value={selectedAct ?? ""}
                                       onChange={onChangeActHandler}
                                       inputProps={{
                                          name: 'select-line',
                                          id: 'select-line',
                                          required: true,
                                       }}
                                    >
                                       <MenuItem
                                          disabled
                                          classes={{
                                             root: classes.selectMenuItem,
                                          }}
                                       >
                                          Seleccione el acto
                                       </MenuItem>
                                       {
                                          filterActs.map((act, index) => {
                                             const value = act
   
                                             return (
                                                <MenuItem
                                                   value={value}
                                                   key={`${value} - ${index}`}
                                                   classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                >
                                                   {value.descripcion_acto}
                                                </MenuItem>
                                             )
                                          })
                                       }
                                    </Select>
                                 </FormControl>
                              </GridItem>
                                        
                           <GridItem
                              xs={3}
                              style={{ display: "flex", alignItems: "center" }}
                           >
                              <Button
                                 fullWidth
                                 onClick={onSearchClickHandler}
                                 type='submit' 
                                 color='primary'
                              >
                                 {
                                    loadingWorkList
                                    ?  <>
                                       <span style={{ marginRight: "10px" }}>
                                          <ClipLoader 
                                             color="#041f24"
                                             size={20}            
                                          /> 
                                       </span>
                                       Buscando...
                                       </>
                                    : "Buscar"
                                 }
                              </Button>
                           </GridItem>
                        </>
                        )
                        // FILTROS VALIDADOR
                        : ( userType === "validator" )
                           ?
                              ( filterActs != null && filterActs.length )
                              ? (
                              <>
                                 {/* SELECTOR ACTO */}
                                 <GridItem xs={9}>
                                    <FormControl fullWidth>
                                       <InputLabel htmlFor='select-act'>Acto</InputLabel>
                                       <Select
                                          MenuProps={{
                                             className: classes.selectMenu,
                                          }}
                                          error={selectedAct == null}
                                          className={classes.select}
                                          value={selectedAct ?? ""}
                                          onChange={onChangeActHandler}
                                          inputProps={{
                                             name: 'select-act',
                                             id: 'select-act',
                                             required: true,
                                          }}
                                       >
                                          <MenuItem
                                             disabled
                                             classes={{
                                                root: classes.selectMenuItem,
                                             }}
                                          >
                                             Seleccione el acto
                                          </MenuItem>
                                          {
                                             filterActs.map((act, index) => {
                                                const value = act
                                                
                                                return (
                                                   <MenuItem
                                                      value={value}
                                                      key={`${value.descripcion_acto} - ${index}`}
                                                      classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                   >
                                                      {value.descripcion_acto}
                                                   </MenuItem>
                                                )
                                             })
                                          }
                                       </Select>
                                    </FormControl>
                                 </GridItem>

                                 {/* BUSCAR  */}
                                 <GridItem
                                    xs={3}
                                    style={{ display: "flex", alignItems: "center" }}
                                 >
                                    <Button
                                       fullWidth
                                       onClick={onSearchClickHandler}
                                       type='submit' 
                                       color='primary'
                                    >
                                       {
                                          loadingWorkList
                                          ?  <>
                                             <span style={{ marginRight: "10px" }}>
                                                <ClipLoader 
                                                   color="#041f24"
                                                   size={20}            
                                                /> 
                                             </span>
                                             Buscando...
                                             </>
                                          : "Buscar"
                                       }
                                    </Button>
                                 </GridItem>                        
                                 {
                                    ( !lineStructures.length )
                                    ? ""
                                    : (
                                       <>
                                       {/* SELECTOR LÍNEA */}
                                       <GridItem xs={8}>
                                          <FormControl fullWidth>
                                             <InputLabel htmlFor='select-line'>Estructura de la línea</InputLabel>
                                             <Select
                                                MenuProps={{
                                                   className: classes.selectMenu,
                                                }}
                                                error={lineStructure == null}
                                                className={classes.select}
                                                value={lineStructure}
                                                onChange={onChangeLineStructureHandler}
                                                inputProps={{
                                                   name: 'select-line',
                                                   id: 'select-line',
                                                   required: true,
                                                }}
                                             >
                                                <MenuItem
                                                   disabled
                                                   classes={{
                                                      root: classes.selectMenuItem,
                                                   }}
                                                >
                                                   Seleccione la estructura base de la línea
                                                </MenuItem>
                                                {
                                                   lineStructures.map((structure, index) => {
                                                      const value = structure

                                                      return (
                                                         <MenuItem
                                                            value={value}
                                                            key={`${value} - ${index}`}
                                                            classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                         >
                                                            {value}
                                                         </MenuItem>
                                                      )
                                                   })
                                                }
                                             </Select>
                                          </FormControl>
                                       </GridItem>
                                       </>
                                    )
                                 }

                                 {                        
                                    ( lineStructure != null && lineYears.length )
                                    ? (
                                    <>
                                       {/* SELECTOR AÑO */}
                                       <GridItem xs={4}>
                                          <FormControl fullWidth>
                                             <InputLabel htmlFor='select-year'>Año de la línea</InputLabel>
                                             <Select
                                                MenuProps={{
                                                   className: classes.selectMenu,
                                                }}                   
                                                error={lineYear == null}
                                                className={classes.select}
                                                value={lineYear}
                                                onChange={onChangeLineYearHandler}
                                                inputProps={{
                                                   name: 'select-year',
                                                   id: 'select-year',
                                                   required: true,
                                                   disabled: lineStructure == null || !lineYears.length
                                                }}
                                             >
                                                <MenuItem
                                                   disabled
                                                   classes={{
                                                      root: classes.selectMenuItem,
                                                   }}
                                                >
                                                   Seleccione la estructura base de la línea
                                                </MenuItem>
                                                {
                                                   lineYears.map((year, index) => {
                                                      const value = year

                                                      return (
                                                         <MenuItem
                                                            value={value}
                                                            key={`${value} - ${index}`}
                                                            classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                         >
                                                            {value}
                                                         </MenuItem>
                                                      )
                                                   })
                                                   
                                                }
                                             </Select>
                                          </FormControl>
                                       </GridItem>
                                    </>
                                    )
                                    : ""
                                 }
                              </>
                              )
                              : ""
                           : ""
                     }         
                  </GridContainer>
               </CardBody>
            </Card>
            
                    
            {
               works != null && works.length 
                  ?
                     (loadingWorkList || loadingTechnicianList) &&
                     (
                        ( userType === "validator" && selectedAct != null && lineStructure != null && lineYear != null)
                        || (userType === "technician" && selectedAct != null)
                     )
                     ? ( 
                     <>
                        <Card>
                           <CardBody>
                              Cargando Trabajos... 
                              <div style={{ marginRight: "10px", display: "flex", justifyContent: "center", marginTop: "20px" }} >
                                 <ClipLoader
                                    color="#041f24"
                                    size={50}            
                                 /> 
                              </div>
                           </CardBody>
                        </Card>
                     </>
                     )
                     : 
                     works != null && works.length && data != null && data.length &&
                     (
                        ( userType === "validator" && selectedAct != null && lineStructure != null && lineYear != null)
                        || (userType === "technician" && selectedAct != null)
                     )
                     
                        ?
                        <>
                        <Card>
                        <CardBody>
                           <ReactTable
                              columns={reactTableCols}
                              data={data}
                              stickyLast
                           />
                           <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                              {excel && (
                              <ExcelFile
                                 element={<Button color='primary'>Exportar Excel</Button>}
                                 filename='trabajos'
                                 hideElement={true}
                              >
                                 <ExcelFile.ExcelSheet data={data} name='Trabajos'>
                                    <ExcelFile.ExcelColumn label='Referencia solicitud' value='referencia_solicitud' />
                                    <ExcelFile.ExcelColumn label='Línea' value='linea' />
                                    <ExcelFile.ExcelColumn label='Convocatoria' value='anio_convocatoria' />
                                    <ExcelFile.ExcelColumn label='Bloque/orden/revisión' value='bloque_orden_revision' />
                                    <ExcelFile.ExcelColumn label='Categoría' value='categoria_pliego' />
                                    <ExcelFile.ExcelColumn label='Acto' value='descripcion_acto' />
                                    <ExcelFile.ExcelColumn label='Usuario' value='usuario' />
                                 </ExcelFile.ExcelSheet>
                              
                              </ExcelFile>
                              )}
                              {
                                 !( loadingWorkList || loadingTechnicianList || data == null || !data.length )
                                 && (
                                    <>
                                    <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                                    Exportar EXCEL
                                    </Button>
                                    <Button color='primary' onClick={() => setDownloadPdf(true)} style={{ marginLeft: '10px' }}>
                                    Exportar PDF
                                    </Button>
                                    </>
                                 )
                              }
                           </div>
                        </CardBody>
                        </Card>
                        </>
                        
                        : ""
                  : (
                     selectedAct != null && notControlsMessage
                     ?
                     <Card>
                        <CardBody>
                           <GridContainer>
                              <GridItem>                 
                                 <span style={{ fontSize: "1rem" }}>{notControlsMessage}</span>
                              </GridItem>
                           </GridContainer>
                        </CardBody>
                     </Card>                           
                     : ""
                  ) 
               }

               {/* ERRORS */}
               <GridItem>
                  {
                  errorWorkList
                  &&
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={errorWorkList || errorWorkList.message} color='danger' />
                        </GridItem>
                     </GridContainer>
                  }
               </GridItem>

               <GridItem>   
                  {
                  actError
                  &&
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={actError} color='danger' />
                        </GridItem>
                     </GridContainer>                 
                  }
               </GridItem>

               <GridItem>
                  {
                  searchError 
                  && 
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={searchError} color='danger' />
                        </GridItem>
                     </GridContainer>
                  }
               </GridItem>
                  
               <GridItem>
                  {
                  errorTechnicianList 
                  && 
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={errorTechnicianList || errorTechnicianList.message} color='danger' />
                        </GridItem>
                      </GridContainer>
                  }
               </GridItem>
               <GridItem>
                  {
                  errorPlanningsLinesList 
                  && 
                     <GridContainer>
                        <GridItem xs={12}>
                           <SnackbarContent message={errorPlanningsLinesList || errorPlanningsLinesList.message} color='danger' />
                        </GridItem>
                    </GridContainer>
                  }
               </GridItem>
               
            
            
         </GridItem>
      </GridContainer>
   
      {/* MODAL SHOW - CONTROL + ACTS */}
      {viewInfoActModal && (
      <ViewWorkInfoActModal
         viewInfo={viewInfoActModal}
         closeViewInfoModal={closeViewInfoActModal}
         info={showViewInfoActModal}
         technicians={technicians}
      />
      )}
      {/* MODAL EXCEL */}
      {downloadExcel && (
      <DownloadConfirmModal
         downloadFile={downloadExcel}
         setDownloadFile={setDownloadExcel}
         setFile={setExcel}
         tableName='Trabajos'
         FileExtension='Excel'
      />
      )}
      {/* MODAL PDF */}
      {downloadPdf && (
      <DownloadConfirmModal
         downloadFile={downloadPdf}
         setDownloadFile={setDownloadPdf}
         setFile={setPdf}
         tableName='Trabajos'
         FileExtension='PDF'
      />
      )}
   </>
   )
}

const getTableData = ( data, showViewInfoActHandler ) => {

   return data.map((work) => {
      if ( work.categoria_derivada != null && work.categoria_pliego !== work.categoria_derivada ) {
         work.categoria_pliego_derivada = `${ work.categoria_pliego } => ${ work.categoria_derivada }`
      }else {
         work.categoria_pliego_derivada = work.categoria_pliego
      }
            
      return {  
         ...work,
         usuario: `${work.usuario || '-'}`,
         actions: (
            <div className='actions-right'>

               <Button
                  justIcon
                  round
                  simple
                  onClick={() => showViewInfoActHandler(work.id_control)}
                  color='success'
                  className='edit'
               >
                  <Tooltip title='Ver más información'>
                     <Visibility fontSize="large" />
                  </Tooltip>
               </Button>
            </div>
         )
      }
   }) 
}

export default WorkAdministrativeOtherProfilesListScreen