import { useEffect, useState } from "react"
import { Box, Tab, Tabs, Typography } from "@mui/material"

import { TabPanelContent } from "./TabPanelContent"
import { TabPanelContentGlobal } from "./TabPanelContentGlobal"


export const TabPanelContainer = ({ report }) => {

   const categories = report != null ? report.res : []
   // const categories = report != null ? report.res : []
   const [value, setValue] = useState(null)
   const [cats, setCats] = useState(categories)

   const handleChange = (e, newValue) => {
      
      setValue( newValue )
   }
   
   useEffect(() => {
      if ( categories.length ) {
         const global_tab_data = categories.map(category => {
  
            const cat = Object.values(category)[0]
            
            return {
              code: cat.code,
              num_actions: cat.global?.num_acts ?? 0,
              billing: cat.global?.billing ?? 0,
              milestone: cat.global?.milestone ?? 0,
            }
         })

         const object_to_push = {}
         object_to_push["global"] = global_tab_data

         
         setCats(last => {
            const new_last = [...last]
            new_last.splice(-1, 1, object_to_push)
            
            return new_last
         })
      }
      

   }, [categories])


   return (
      <Box sx={{ width: "100%" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
               value={value} 
               onChange={handleChange}
               variant="scrollable"
               aria-label="basic tabs example"
            >
               {
                  cats.map((category, i) => {
                     const category_name = Object.keys(category)[0]
                     const cat = Object.values(category)[0]
                     const disabled = cat.rows_info != null && !cat.rows_info.length
                     if ( !disabled && value == null ) setValue(i)

                     return (
                        <Tab
                           key={category_name}
                           label={category_name}
                           disabled={disabled}
                           {...allyProps(0)}
                        />
                     )
                  })
               }
            </Tabs>
         </Box>
         {         
            cats.map((category, index) => {

               if ( index === cats.length - 1 ) {
                  return   <TabPanel value={value} index={index} key={Object.values(category)[0].code}>
                              <TabPanelContentGlobal global_data={Object.values(category)} />
                           </TabPanel>
               }

               return (
                  <TabPanel value={value} index={index} key={Object.values(category)[0].code}>
                     <TabPanelContent category_data={Object.values(category)[0].rows_info}/>
                  </TabPanel>
               )
            })
         }

      </Box>
   )
}

const TabPanel = ({ children, value, index, ...other }) => {

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${ index }`}
         aria-labelledby={`simple-tab-${ index }`}
         {...other}
      >
         {
            value === index
            && <Box sx={{ p:3 }}>
               <Typography>{ children }</Typography>
            </Box>
         }
      </div>
   )
}

const allyProps = ( index ) => {

   return {
      id: `simple-tab-${ index }`,
      "aria-controls": `simple-tabpanel-${ index }`,
   }
}


