import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
// import styles from './styles/WeeklyReportScreenStyles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ClipLoader from "react-spinners/ClipLoader"
import styles from './styles/importLinesScreenStyles.js'
import { Checkbox, ListItemText, TextField } from '@mui/material'
import Button from 'components/CustomButtons/Button'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { WEEKLY_REPORT_RESET } from 'redux/constants/reportConstants'
import { getWeeklyReport } from 'redux/actions/reportActions.js'
import { TabPanelContainer } from './components/TabPanelContainer.js'
import SnackbarContent from 'components/Snackbar/SnackbarContent.js'
import ExcelFile from 'react-data-export/dist/ExcelPlugin/components/ExcelFile.js'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal.js'
import ExcelColumn from 'react-data-export/dist/ExcelPlugin/elements/ExcelColumn.js'
import DateFnsUtils from "@date-io/date-fns"
import { es } from "date-fns/locale"
import { formatNumber } from 'utils/formatNumber.js'
import { getPlanningLoads } from 'redux/actions/planningActions.js'
import { PLANNING_LOADS_LIST_RESET } from 'redux/constants/planningConstants.js'

const useStyles = makeStyles(styles)

const WeeklyReportScreen = () => {
   const dispatch = useDispatch()
   const classes = useStyles()

   const { loadingWeeklyReport, successWeeklyReport, errorWeeklyReport, weeklyReport } = useSelector(
      (state) => state.reportListWeekly
   )

   const { loadingLoadsList, successLoadsList, errorLoadsList, loads } = useSelector(
      (state) => state.planningLoadsList
   )

   const [blocks, setBlocks] = useState(false)
   const [reportData, setReportData] = useState(null)
   const [filterDates, setFilterDates] = useState({
      start_date: undefined,
      end_date: undefined,
   })
   const [error_block, setError_block] = useState(false)
   const [showData, setShowData] = useState(false)
   const [excel, setExcel] = useState(false)
   const [downloadExcel, setDownloadExcel] = useState(false)

   useEffect(() => {
      if ( weeklyReport != null && successWeeklyReport ) {
         setShowData(false)
         setReportData(weeklyReport)
         weeklyReport.res.forEach(category => {
            const cat = Object.values(category)[0]
            if ( cat.rows_info.length ) setShowData(true)
         })
      }
      
   }, [weeklyReport])


   useEffect(() => {

      dispatch( getPlanningLoads() )

      return () => {
         dispatch({ type: WEEKLY_REPORT_RESET })
         dispatch({ type: PLANNING_LOADS_LIST_RESET })
         setReportData(null)
      }
   }, [])
   


   // funciones

   const changeBlockSelectHandler = (e) => {
      const newBlocksValues = e.target.value

      setError_block(false)
      setBlocks(newBlocksValues)
   }

   const onSearchClickHandler = () => {
      if (!blocks) {
         setError_block("Introduce el número de carga de datos antes de realizar la búsqueda")
         return
      }
      
      setReportData(null)

      dispatch(getWeeklyReport({
         block: blocks,
         start_date: new Date(filterDates.start_date),
         end_date: new Date(filterDates.end_date),
      }))
   }

   const onChangeStartDate = (newVal) => {
      if ( filterDates.end_date == null || newVal > filterDates.end_date ) {
         setFilterDates({start_date: newVal, end_date: newVal})
      }else {
         setFilterDates(last => ({...last, start_date: newVal}))
      }
   }

   const onChangeEndDate = (newVal) => {
      if ( filterDates.start_date == null || newVal < filterDates.start_date ) {
         setFilterDates({start_date: newVal, end_date: newVal})
      }else {
         setFilterDates(last => ({...last, end_date: newVal}))
      }
   }

   if ( loadingLoadsList || loads == null || !loads.length ) {
      return ( 
         <>
         Cargando... 
         <div 
            style={{ marginRight: "10px", display: "flex", justifyContent: "center", marginTop: "20px" }}
         >
            <ClipLoader
               color="#041f24"
               size={50}            
            /> 
         </div>
         </>
      )
   }


   return (
   <>
   <Card>
      <CardBody>
         <GridContainer>
            {/* SELECTOR BLOQUE */}
            <GridItem xs={3}>            
               <FormControl fullWidth>
                  <InputLabel id="select-block-checkbox" htmlFor='select-bloque'>Número de carga de datos</InputLabel>
                  <Select
                     labelId="select-block-checkbox"
                     MenuProps={{
                        className: classes.selectMenu,
                     }}
                     // multiple
                     className={classes.select}
                     value={blocks}
                     onChange={changeBlockSelectHandler}
                     error={!blocks}
                     // renderValue={(selected) => selected.join(", ")}
                     renderValue={(selected) => selected}
                     inputProps={{
                        name: 'select-bloque',
                        id: 'select-bloque',
                        required: true,
                     }}
                  >
                     <MenuItem
                        disabled
                        classes={{
                           root: classes.selectMenuItem,
                        }}
                     >
                        Selecciona un Número de Carga de Datos
                     </MenuItem>
                     {
                        loads.map((load, index) => {
                           const value = ""+load.numero_carga

                           return (
                              <MenuItem
                                 value={value}
                                 key={`select_block_checkbox_${ index }`}
                                 classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                              >
                                 {/* <Checkbox checked={blocks.indexOf(value) > -1} /> */}
                                 <ListItemText primary={value} />
                              </MenuItem>
                           )
                        })
                     }
                  </Select>
               </FormControl>
            </GridItem>

            {/* SELECTOR SEMANAS - INICIO */}
            <GridItem xs={3}>
               <LocalizationProvider 
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label={"Seleccione la fecha de inicio"}
                     inputFormat="dd-MM-yyyy"
                     value={filterDates.start_date ?? null}
                     onChange={onChangeStartDate}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%"}}
                           InputLabelProps={{ shrink: true }}
                           {...params}
                           error={false}
                        />
                     }}
                     
                     required={false}
                  />
               </LocalizationProvider>
            </GridItem>

            {/* SELECTOR SEMANAS - FIN */}
            <GridItem xs={3}>
               <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={es}
                  utils={DateFnsUtils}
               >
                  <DesktopDatePicker
                     label={"Seleccione la fecha de fin"}
                     inputFormat="dd-MM-yyyy"
                     value={filterDates.end_date ?? null}
                     onChange={onChangeEndDate}
                     renderInput={(params) => {
                        return <TextField
                           style={{width:"100%"}}
                           InputLabelProps={{ shrink: true }}
                           {...params}
                           error={false}
                        />
                     }}
                     
                     required={false}
                  />
               </LocalizationProvider>
            </GridItem>
            
            {/* BUSCAR */}
            <GridItem
               xs={3}
               style={{ display: "flex", alignItems: "center" }}
            >
               <Button
                  // style={{ width: "100%", height: "100%", cursor: "pointer" }}
                  fullWidth
                  onClick={onSearchClickHandler}
                  type='submit' 
                  color='primary'
               >
                  {
                     loadingWeeklyReport 
                     ?  <>
                        <span style={{ marginRight: "10px" }}>
                           <ClipLoader 
                              color="#041f24"
                              size={20}            
                           /> 
                        </span>
                        Buscando...
                        </>
                     : "Buscar"
                  }
               </Button>
            </GridItem>
         </GridContainer>
      </CardBody>
   </Card>


   {
      successWeeklyReport && !showData
      ? (
         <Card>
            <CardBody>
               <GridContainer>
                  <GridItem xs={12}>
                     No hay información para los filtros seleccionados.
                  </GridItem>              
               </GridContainer>
            </CardBody>
         </Card>
      )
      : null
   }

   {
   // weeklyReport
   successWeeklyReport && showData && reportData != null
   && <Card>
         <CardBody>
            <GridContainer>
               <GridItem xs={12}>
                  <TabPanelContainer report={JSON.parse(JSON.stringify(reportData))} />
               </GridItem>

               <GridItem xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                     {
                        excel
                        && (
                        <ExcelFile
                           element={<Button color='primary'>Exportar Excel</Button>}
                           filename='Seguimiento semanal'
                           hideElement={true}
                        >
                           {
                              reportData.res.length
                              && reportData.res.map(cat => {                                                                                     
                                    return getCategoryExcelSheet(cat)
                                 })
                           } 
                           {
                              reportData.res.length
                              && getGlobalExcelSheet(reportData.res)
                           }                        
                        </ExcelFile>
                        )
                     }
                     <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                        Exportar a EXCEL
                     </Button>
                  </div>
               </GridItem>
            </GridContainer>
         </CardBody>
      </Card>
   }

   {
      (errorWeeklyReport != null && errorWeeklyReport)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={errorWeeklyReport || errorWeeklyReport.message} color='danger' />
         </GridItem>
      )
   }
   {
      (error_block)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={error_block} color='danger' />
         </GridItem>
      )
   }
   {
      downloadExcel 
      && <DownloadConfirmModal
            downloadFile={downloadExcel}
            setDownloadFile={setDownloadExcel}
            setFile={setExcel}
            tableName='Seguimiento semanal'
            FileExtension='Excel'
         />      
   }
   </>
   )
}

const getCategoryExcelSheet = (cat) => {
   const code = Object.values(cat)[0].code
   const global = Object.values(cat)[0].global
   const info = Object.values(cat)[0].rows_info

   if ( !info.length ) {
      return   <ExcelFile.ExcelSheet data={[{resultado: "No hay actuaciones para esta categoría"}]} name={`Categoría ${code}`}>
                  <ExcelColumn label="" value="resultado"/>
               </ExcelFile.ExcelSheet> 
   }
      
   // ============================================================================
   // ======================            COLUMNAS             =====================
   // ============================================================================
   const first_col = [{
      title: "",
      width: {wch: 40},
      style: {
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         }
      },
   }]

   const second_col = [{
      title: "Fechas",
      width: {wch: 35},
      style: {
         fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "12", bold: true},
         alignment: {horizontal: "center", vertical: "center"}
      },
   }]

   const last_col = [{
      title: "Actuaciones acumuladas",
      width: {wch: 15},
      style: {
         fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "12", bold: true},
         alignment: {horizontal: "center", vertical: "center", wrapText: true}
      },
   }]

   const middle_cols = info[0].weeks.map((week, i) => {
      const object_to_push = {}
      
      object_to_push["title"] = week.week_name
      object_to_push["width"] = {wch: 15}
      object_to_push["height"] = {hpx: 150}
      object_to_push["style"] = {
         fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: i===0 ? "thick" : "medium", color: {rgb: "111111"}},
            right: {style: (i===info[0].weeks - 1) ? "thick" : "medium", color: {rgb: "111111"}},
         },
         font: {sz: "12", bold: true},
         alignment: {horizontal: "center", vertical: "center", wrapText: true},
      }
      
      return object_to_push
   })

   const cols = first_col
      .concat(second_col)
      .concat(middle_cols)
      .concat(last_col)


   // ============================================================================
   // =======================            FILAS             =======================
   // ============================================================================
   const rows = info.map((row, i) => {
      const object_to_push = {}
      object_to_push["row_header"] = {
         value: row.description,
         style: {
            fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "10", bold: true},
            alignment: {horizontal: true, vertical: true},
         }
      }
      
      // DATOS PARA LA PRIMERA COLUMNA
      let first_row_col_data = {}
      if ( i === 0 || i === 2 || i === 4 || i === 6 || i === 8 || i === 10) {
         first_row_col_data = [{
            value: "",
            style: {
               fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
               border: {
                  top: {style: (i === 0 || i === 4 || i === 8) ? "thick" : "", color: {rgb: "111111"}},
                  bottom: {style: (i === 2 || i === 6 || i === 10) ? "thick" : "", color: {rgb: "111111"}},
                  left: {style: "thick", color: {rgb: "111111"}},
                  right: {style: "thick", color: {rgb: "111111"}},
               },
               alignment: {horizontal: "center", vertical: "center"},
            }
         }]
      }else if (i === 1 || i === 3 || i === 5 || i === 7 || i === 9) {
         let value
         if ( i === 1 ) value = "Requerimiento de subsanación"
         else if( i===3 || i===7 ) value = "IC"
         else if( i===5 ) value = "AIPR"
         else if( i===9 ) value = "RR"

         first_row_col_data = [{
            value,
            style: {
               fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
               border: {
                  top: {style: (i===3 || i===7) ? "thick" : "", color: {rgb: "111111"}},
                  bottom: {style: (i===3 || i===7) ? "thick" : "", color: {rgb: "111111"}},
                  left: {style: "thick", color: {rgb: "111111"}},
                  right: {style: "thick", color: {rgb: "111111"}},
               },
               font: {sz: "12", bold: true},
               alignment: {horizontal: "center", vertical: "center"},
            }
         }]
      }

      // DATOS PARA LA SEGUNDA COLUMNA
      const second_row_col_data = [{
         value: row.description,
         style: {
            border: {
               top: {
                  style: (i===0 || i===3 || i===4 || i===7 || i===8) ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               bottom: {
                  style: (i===2 || i===3 || i===6 || i===7 || i===10) ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               left: {style: "medium", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "10"},
            alignment: {horizontal: "center", vertical: "center", wrapText: true},
         }
      }]
      // TOTAL
      const last_row_col_data = [{
         value: formatNumber(row.global_count, 0),
         style: {
            border: {
               top: {
                  style: (i===0 || i===3 || i===4 || i===7 || i===8) ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               bottom: {
                  style: (i===2 || i===3 || i===6 || i===7 || i===10) ? "thick" : "medium",
                  color: {rgb: "111111"}
               },
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "10", bold: true},
            alignment: {horizontal: "center", vertical: "center"},
         }
      }]

      // DATOS DE LA SEMANA
      const middle_row_cols_data = row.weeks.map((week) => {
         return {
            value: week.cont === 0 ? "" : week.cont,
            style: {
               border: {
                  top: {
                     style: (i===0 || i===3 || i===4 || i===7 || i===8) ? "thick" : "medium",
                     color: {rgb: "111111"}
                  },
                  bottom: {
                     style: (i===2 || i===3 || i===6 || i===7 || i===10) ? "thick" : "medium",
                     color: {rgb: "111111"}
                  },
                  left: {style: "medium", color: {rgb: "111111"}},
                  right: {style: "medium", color: {rgb: "111111"}},
               },
               font: {sz: "10", bold: true},
               alignment: {horizontal: "center", vertical: "center"},
            }
         }
      })
      

      
      return first_row_col_data
         .concat(second_row_col_data)
         .concat(middle_row_cols_data)
         .concat(last_row_col_data)
   })

   // AÑADIMOS LAS FILAS VACÍAS DE SEPARACIÓN
   const empty_row = rows[0].map(row => ({value: ""}) )
   rows.splice(3, 0, empty_row)
   rows.splice(5, 0, empty_row)
   rows.splice(9, 0, empty_row)
   rows.splice(11, 0, empty_row)
   rows.push(empty_row)
   rows.push(empty_row.slice(0, -1).concat([{
      value: formatNumber(global.num_acts, 0),
      style: {
         border: {
            top: {
               style: "thick",
               color: {rgb: "111111"}
            },
            bottom: {
               style: "thick",
               color: {rgb: "111111"}
            },
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "10", bold: true},
         alignment: {horizontal: "center", vertical: "center"},
      }
   }]))
   
   const multiDataSet = [{
      columns: cols,
      data: rows,
   }]

   return (
      <ExcelFile.ExcelSheet dataSet={multiDataSet} name={`Categoría ${code}`} />
   )                   
} 

const getGlobalExcelSheet = (report) => {
   const global = report.map(category => {

      const cat = Object.values(category)[0]
      
      return {
        code: cat.code,
        num_actions: cat.global?.num_acts ?? 0,
        billing: cat.global?.billing ?? 0,
        milestone: cat.global?.milestone ?? 0,
      }
   })

   // COLUMNAS
   const cols = [{
      title: "",
      width: {wch: 35},
   },
   {
      title: "Nº actuaciones",
      width: {wch: 20},
      style: {
         fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "12", bold: true},
         alignment: {horizontal: "center", vertical: "center", wrapText: true}
      },
      
   }, 
   {
      title: "Facturación",
      width: {wch: 20},
      style: {
         fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "12", bold: true},
         alignment: {horizontal: "center", vertical: "center", wrapText: true}
      }
   },
   {
      title: "Planificación",
      width: {wch: 12},
      style: {
         fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "12", bold: true},
         alignment: {horizontal: "center", vertical: "center", wrapText: true}
      }
   }]

   // FILAS
   let total_actions = 0
   let total_billing = 0
   let total_milestone = 0
   const rows = global.map(row => {
      total_actions += row.num_actions
      total_billing += row.billing
      total_milestone += row.milestone

      return  [{
         value: `Categoría ${ row.code }`,
         style: {
            fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "12", bold: true},
            alignment: {horizontal: "center", vertical: "center"},
         }
      },
      {
         value: formatNumber(row.num_actions, 0),
         style: {

            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "10", bold: true},
            alignment: {horizontal: "center", vertical: "center"},
         }
      },
      {
         value: `${ formatNumber(row.billing) } €`,
         style: {

            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "10", bold: true},
            alignment: {horizontal: "center", vertical: "center"},
         }
      },
      {
         value: `${ formatNumber(row.milestone) }%`,
         style: {

            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: "10", bold: true},
            alignment: {horizontal: "center", vertical: "center"},
         }
      }]
   })

   
   rows.push([{
      value: "",
   },
   {
      value: formatNumber(total_actions, 0),
      style: {
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "10", bold: true},
         alignment: {horizontal: "center", vertical: "center"},
      },
   },
   {
      value: `${ formatNumber(total_billing) } €`,
      style: {
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "10", bold: true},
         alignment: {horizontal: "center", vertical: "center"},
      }
   },
   {
      value: `${ Number(( (100 * total_billing) / 877900 ).toFixed(2)) }%`,
      style: {
         border: {
            top: {style: "thick", color: {rgb: "111111"}},
            bottom: {style: "thick", color: {rgb: "111111"}},
            left: {style: "thick", color: {rgb: "111111"}},
            right: {style: "thick", color: {rgb: "111111"}},
         },
         font: {sz: "10", bold: true},
         alignment: {horizontal: "center", vertical: "center"},
      }
   }])

   const multiDataSet = [{
      columns: cols,
      data: rows,
   }]
      
   

   return (
      <ExcelFile.ExcelSheet dataSet={multiDataSet} name={`Global`} />
   )                                                                      
}

export default WeeklyReportScreen
