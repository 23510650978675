import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Card, makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Typography from '@mui/material/Typography'
import { registerUserlist } from 'redux/actions/userlistActions'
import { USERLIST_REGISTER_RESET } from 'redux/constants/userlistConstants'
import styles from './styles/RegisterUserScreenStyles'

const useStyles = makeStyles(styles)
const RegisterUserlistScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [alert, setAlert] = useState(null)
  const [codUserError, setCodUserError] = useState('')
  const [codUser, setCodUser] = useState('')
  const [name, setName] = useState('')
  const [clave1, setClave1] = useState('')
  const [clave2, setClave2] = useState('')
  const [codClaveError, setCodClaveError] = useState('')

  const style={
    boxShadow:"2px 2px 3px 3px #ccc",
    border:"2px #eee",
    padding:"20px",
    marginTop:"25px"
}

  const { loadingUserlistRegister, successUserlistRegister, errorUserlistRegister } = useSelector(
    (state) => state.userlistRegister
  )

  useEffect(() => {
    if (successUserlistRegister) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='GUARDADO!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Usuario creado correctamente
        </SweetAlert>
      )
    }
  }, [successUserlistRegister])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!codUser) {
      return setCodUserError('Por favor ingrese un identificador de usuario')
    }
    if (clave1.length < 8) {
      return setCodClaveError('La contraseña debe tener al menos 8 caracteres')
    }
    if (!clave1.match(/[A-Z]/)) {
      return setCodClaveError('La contraseña debe tener al menos una mayúscula')
    }
    if (!clave1.match(/[a-z]/)) {
      return setCodClaveError('La contraseña debe tener al menos una minúscula')
    }
    if (!clave1.match(/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/)) {
      return setCodClaveError('La contraseña debe tener al menos un número o un caracter especial')
    }
    if (!(clave1 === clave2 && clave1!=="")) {
      return setCodClaveError('La contraseña debe coincidir con la confirmación de contraseña')
    }

    dispatch(
      registerUserlist({
        codUser,
        name,
        password: clave1,
        registerDate: format(new Date(), 'yyyy-MM-dd'),
      })
    )
  }

  const confirmSuccess = () => {
    setCodUser('')
    setName('')
    setClave1('')
    setClave2('')
    setCodUserError('')
    setCodClaveError('')
    setAlert(null)
    dispatch({ type: USERLIST_REGISTER_RESET })
  }

  return (
    <>    
      <GridContainer>
        <GridItem xs={12} md={8} style={{ margin: 'auto' }}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'IDENTIFICADOR USUARIO'}
                      id='codeUser'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: codUser,
                        onChange: (e) => setCodUser(e.target.value),
                        type: 'text',
                        maxLength: 50,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'NOMBRE USUARIO'}
                      id='name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: name,
                        onChange: (e) => setName(e.target.value),
                        type: 'text',
                        maxLength: 200,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONTRASEÑA'}
                      id='clave1'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: clave1,
                        onChange: (e) => setClave1(e.target.value),
                        type: 'text',
                        maxLength: 20,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={'CONFIRMAR CONTRASEÑA'}
                      id='clave2'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: clave2,
                        onChange: (e) => setClave2(e.target.value),
                        type: 'text',
                        maxLength: 20,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                     <Typography>
                        <p style={{color:"blue",fontSize:"20px"}}>IMPORTANTE: Comunique al nuevo usuario su contraseña para que pueda acceder a la aplicación</p>
                     </Typography>
                  </GridItem>
                  <div className="container"> 
                    <div className="row">
                        <div className="col-md-4"></div>      
                        <div className="col-md-4">
                          <div style={style}>
                              <p style={{fontWeight:"bold"}}>Todos los checks deben ponerse verdes. La contraseña debe cumplir:</p>
                              {clave1.length >= "8" ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos 8 caracteres</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos 8 caracteres</p> 
                              )}
                              {clave1.match(/[A-Z]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra mayúscula</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra mayúscula</p> 
                              )}
                              {clave1.match(/[a-z]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra minúscula</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos una letra minúscula</p> 
                              )}
                              {clave1.match(/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/) ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos un número o un caracter especial</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Al menos un número o un caracter especial</p> 
                              )}
                              {clave1 === clave2 && clave1!=="" ? (
                                <p><i style={{color:"green",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Contraseña === Confirmar contraseña</p>
                              ) : ( <p><i style={{color:"red",fontSize:"20px"}} class="fa fa-check-circle" aria-hidden="true"></i> Contraseña === Confirmar contraseña</p> 
                              )}
                          </div>
                        </div>     
                        <div className="col-md-4"></div>
                    </div>
                  </div>
                  {clave2 === "" ? "" :
                     (clave1 === clave2  ? <p style={{color:"green",fontWeight:"bold"}}> Contraseñas iguales </p> :
                      <p style={{color:"red",fontWeight:"bold"}}> Contraseñas diferentes </p>
                  )}

                  <GridItem xs={12} style={{ margin: '20px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='submit' color='primary'>
                      {loadingUserlistRegister ? 'Creando usuario...' : 'Registrar usuario'}
                    </Button>
                  </GridItem>
                </GridContainer>
                
              </form>              
              {errorUserlistRegister && (
                <GridContainer>
                  <GridItem xs={12}>
                    <SnackbarContent message={errorUserlistRegister} color='danger' />
                  </GridItem>
                </GridContainer>
              )}
              {codUserError && !codUser && (
                <GridItem xs={12}>
                  <SnackbarContent message={codUserError} color='danger' />
                </GridItem>
              )}
              {codClaveError && (
                <GridItem xs={12}>
                  <SnackbarContent message={codClaveError} color='danger' />
                </GridItem>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </>
  )
}

export default RegisterUserlistScreen
