import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import ClipLoader from "react-spinners/ClipLoader"
import { ListItemText } from '@mui/material'
import dayjs from "dayjs"

import { PLANNING_REPORT_RESET } from 'redux/constants/reportConstants'
import { PLANNING_LIST_RESET } from 'redux/constants/planningConstants'
import { getPlanningReport } from 'redux/actions/reportActions.js'
import { getPlannings } from 'redux/actions/planningActions'
import styles from './styles/planningReportScreenStyles'
import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent.js'
import ExcelFile from 'react-data-export/dist/ExcelPlugin/components/ExcelFile.js'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal.js'
import ExcelColumn from 'react-data-export/dist/ExcelPlugin/elements/ExcelColumn.js'
import { TabPanelContainer } from './components/TabPanelContainer'
import { formatNumber } from 'utils/formatNumber'

const useStyles = makeStyles(styles)

const PlanningReportScreen = () => {
   const dispatch = useDispatch()
   const classes = useStyles()

   const { loadingPlanningsList, successPlanningsList, errorPlanningsList, plannings } = useSelector(
      (state) => state.planningList
   )

   const { loadingPlanningReport, successPlanningReport, errorPlanningReport, planningReport } = useSelector(
      (state) => state.reportListPlanning
   )
   const [reportData, setReportData] = useState(null)
   const [planning, setPlanning] = useState(null)
   const [error_block, setError_block] = useState(false)
   const [showData, setShowData] = useState(false)
   const [excel, setExcel] = useState(false)
   const [downloadExcel, setDownloadExcel] = useState(false)

   useEffect(() => {
     
      if (successPlanningReport && planningReport != null && planningReport.length) {
         
         const planningReportWithBreakdown = planningReport.map(report => {
            return {
               ...report
               ,
               breakdown_real: {
                  J: 2,
                  X: 78,
                  K: 90
               }
            }
         })

         setReportData(
            planningReport.sort((a, b)=> {
               if (a.fecha_inicio === b.fecha_inicio){
                  if (a.linea === b.linea) {
                     return a.anio_convocatoria < b.anio_convocatoria ? -1 : 1
                  }
                  else return a.linea < b.linea ? -1 : 1
               } else {
                  return a.fecha_inicio < b.fecha_inicio ? -1 : 1
               }
            })
         )
      }
   }, [successPlanningReport, planningReport])
   


   useEffect(() => {
      dispatch(getPlannings())
        
      return () => {
         setReportData(null)
         dispatch({ type: PLANNING_LIST_RESET })
         dispatch({ type: PLANNING_REPORT_RESET })
      }
   }, [])
   


   // funciones
   const onChangePlanningHandler = (e) => {
      const newVal = e.target.value

      setPlanning(newVal)      
   }

   const onSearchClickHandler = (e) => {
      if (planning == null) {
         setError_block("Selecciona una planificación antes de realizar la búsqueda")
         return
      }

      setError_block("")

      dispatch(getPlanningReport({
         planning: plannings.find(p => p.general_data.nombre === planning)
      }))
   }

   if ( loadingPlanningsList ) {
      return (
         <Card>
            <CardBody>
               <GridContainer>
                  <GridItem>
                     Cargando...
                  </GridItem>
               </GridContainer>
            </CardBody>
         </Card>
      )
   }

   if ( plannings == null || plannings.length == 0 ) {
      return <Card>
            <CardBody>
               <GridContainer>
                  <GridItem>
                     No hay planificaciones almacenadas aún
                  </GridItem>
               </GridContainer>
            </CardBody>
         </Card>
   }

   return (
   <>
   <Card>
      <CardBody>
         <GridContainer>
            {/* SELECTOR PLANIFICACIÓN */}
            <GridItem xs={9}>            
               <FormControl fullWidth>
                  <InputLabel id="select-block" htmlFor='select-planning'>Planificaciones disponibles</InputLabel>
                  <Select
                     labelId="select-block"
                     MenuProps={{ className: classes.selectMenu }}
                     className={classes.select}
                     value={planning}
                     onChange={onChangePlanningHandler}
                     // error={!blocks}
                     renderValue={(selected) => selected}
                     inputProps={{
                        name: 'select-planning',
                        id: 'select-planning',
                        required: true,
                     }}
                  >
                     <MenuItem
                        disabled
                        classes={{
                           root: classes.selectMenuItem,
                        }}
                     >
                        Selecciona una planificación
                     </MenuItem>
                     {
                        plannings.map((planning, index) => {
                           const value = planning.general_data.nombre

                           return <MenuItem
                              value={value}
                              key={`select_planning_${ planning.general_data.id_planificacion }`}
                              classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                           >
                              <ListItemText primary={value} />
                           </MenuItem>
                        })
                     }
                  </Select>
               </FormControl>
            </GridItem>

            {/* BUSCAR */}
            <GridItem
               xs={3}
               style={{ display: "flex", alignItems: "center" }}
            >
               <Button
                  fullWidth
                  onClick={onSearchClickHandler}
                  type='submit' 
                  color='primary'
               >
                  {
                     loadingPlanningReport
                     ?  <>
                        <span style={{ marginRight: "10px" }}>
                           <ClipLoader 
                              color="#041f24"
                              size={20}            
                           /> 
                        </span>
                        Buscando...
                        </>
                     : "Buscar"
                  }
               </Button>
            </GridItem>
         </GridContainer>
      </CardBody>
   </Card>

   {
   successPlanningReport && reportData != null
   && <Card>
         <CardBody>
            <GridContainer>
               {/* TABLE */}
               <GridItem xs={12}>
                  <TabPanelContainer report={JSON.parse(JSON.stringify(reportData))} />
               </GridItem>


               {/* EXCEL */}
               <GridItem xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                     {
                        excel
                        && (
                        <ExcelFile
                           element={<Button color='primary'>Exportar Excel</Button>}
                           filename='Seguimiento planificacion'
                           hideElement={true}
                        >
                           {
                              reportData.length
                              &&  getComparisonExcelSheet(reportData)

                           }                      
                        </ExcelFile>
                        )
                     }
                     <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                        Exportar a EXCEL
                     </Button>
                  </div>
               </GridItem>
            </GridContainer>
         </CardBody>
      </Card>
   }

   {
      (errorPlanningReport != null && errorPlanningReport)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={errorPlanningReport || errorPlanningReport.message} color='danger' />
         </GridItem>
      )
   }
   {
      (error_block)
      && (
         <GridItem xs={12}>
            <SnackbarContent message={error_block} color='danger' />
         </GridItem>
      )
   }
   {
      downloadExcel 
      && <DownloadConfirmModal
            downloadFile={downloadExcel}
            setDownloadFile={setDownloadExcel}
            setFile={setExcel}
            tableName='Seguimiento planificación'
            FileExtension='Excel'
         />      
   }
   </>
   )
}

const getComparisonExcelSheet = ( reportData ) => {

   let isData = false
   reportData.forEach(line => {
      if ( Object.values(line)[0].length ) isData = true
   })

   if ( !isData ) {
      return   <ExcelFile.ExcelSheet data={[{resultado: "No hay información para poder comparar con la planificación"}]} name="Comparison">
                  <ExcelColumn label="" value="resultado"/>
               </ExcelFile.ExcelSheet> 
   }
      
   // ============================================================================
   // ======================            COLUMNAS             =====================
   // ============================================================================
   const create_col = ({title, width = 15, font = 10, bold = false, color = "#000000"}) => {
      
      return {
         title,
         width: {wch: width},
         style: {
            fill: {patternType: "solid", bgColor: {rgb: "8EA9DB"}, fgColor: {rgb: "8EA9DB"}},
            border: {
               top: {style: "thick", color: {rgb: "111111"}},
               bottom: {style: "thick", color: {rgb: "111111"}},
               left: {style: "thick", color: {rgb: "111111"}},
               right: {style: "thick", color: {rgb: "111111"}},
            },
            font: {sz: font, bold, color: {rgb: color}},
            alignment: {horizontal: "center", vertical: "center", wrapText: true}
         },
      }
   }

   const cols = [
      create_col({ title: "Periodo", font: 14, bold: true }),
      create_col({ title: "Línea", width: 30, font: 14, bold: true }),
      create_col({ title: "Año convocatoria", font: 14, bold: true}),
      create_col({ title: "Categoría", font: 14, bold: true}),
      create_col({ title: "Indicador", width: 30, font: 14, bold: true }),
      create_col({ title: "Actuaciones Ejecutadas /  Planificadas", width: 35, font: 14, bold: true }),
      create_col({ title: "% Ejecutado / Planificado", font: 14, bold: true }),   
   ]


   // ============================================================================
   // =======================            FILAS             =======================
   // ============================================================================
   const create_col_row = ({value, border, bold = false, color = "000000"}) => {
      return {
         value,
         style: {
           border: {
              top: {style: border != null && border.includes("top") ? "thick" : "medium", color: {rgb: "111111"}},
              bottom: {style: border != null && border.includes("bottom") ? "thick" : "medium", color: {rgb: "111111"}},
              left: {style: border != null && border.includes("left") ? "thick" : "medium", color: {rgb: "111111"}},
              right: {style: border != null && border.includes("right") ? "thick" : "medium", color: {rgb: "111111"}},
           },
           font: {sz: "10", bold, color: {rgb: color}},
           alignment: {horizontal: "center", vertical: "center", wrapText: true},
        }
      }
   }

   let sumPlanPercentage = 0
   let sumRealPercentage = 0
   let sumPlanTotalActions = 0
   let sumRealTotalActions = 0
   let sumBreakDownActions = {} 

   const rows = reportData.map((dataLine, i) => {

      const lineValues = Object.values(dataLine)

      if ( !lineValues.length ) return acc
      
      const valuesRow = []
      const valuesColor = []
      let valColor = "#000000"
      valuesRow.push(`${dayjs(dataLine.fecha_inicio).format('YYYY/MM/DD')} - ${dayjs(dataLine.fecha_fin).format('YYYY/MM/DD')}`)
      valuesColor.push("#000000")

      valuesRow.push(dataLine.linea)
      valuesColor.push("#000000")
      const year = ""+dataLine.anio_convocatoria
      
      valuesRow.push(Number(year.replace(",", "")))

      valuesColor.push("#000000")
      valuesRow.push(dataLine.categoria_pliego)
      valuesColor.push("#000000")

      valuesRow.push(dataLine.indicador)
      valuesColor.push("#000000")

      // TOTAL
      let breakDown = false
      if ( dataLine.breakdown_real != null ) {
         breakDown = getTotalActionsStringify(dataLine.breakdown_real , dataLine.categoria_pliego)
         sumBreakDownActions = sumActions(sumBreakDownActions, dataLine.breakdown_real)

      }
      valuesRow.push(`${formatNumber(dataLine.ta_real, 0) }/ ${formatNumber(dataLine.ta_planificado, 0)} ${ breakDown ? breakDown : "" }`)
      valColor = "#000000"
      if (dataLine.ta_planificado > dataLine.ta_real) valColor = "ff0000"
      else if (dataLine.ta_planificado < dataLine.ta_real) valColor = "008000"
      valuesColor.push(valColor)

      // % EJECUCIÓN
      valuesRow.push(`${formatNumber(dataLine.pe_real)}/ ${formatNumber(dataLine.pe_planificado)}%`)
      valColor = "#000000"
      if (dataLine.pe_planificado > dataLine.pe_real) valColor = "ff0000"
      else if (dataLine.pe_planificado < dataLine.pe_real) valColor = "008000"
      valuesColor.push(valColor)
      

      sumPlanPercentage += dataLine.pe_planificado
      sumRealPercentage += dataLine.pe_real
      sumPlanTotalActions += dataLine.ta_planificado
      sumRealTotalActions += dataLine.ta_real

      return valuesRow.map((value, i) => create_col_row({value, color: valuesColor[i]}))
      
   }, [])

   const last_row = [
      create_col_row({ value: "TOTAL", border: ["bottom", "right"], bold: true }),
      create_col_row({ value: "", border: ["top", "bottom"], bold: true }),
      create_col_row({ value: "", border: ["top", "bottom"], bold: true }),
      create_col_row({ value: "", border: ["top", "bottom"], bold: true }),
      create_col_row({ value: "", border: ["top", "bottom"], bold: true }),
      create_col_row({ value: `${ formatNumber(sumRealTotalActions, 0)}/ ${formatNumber(sumPlanTotalActions, 0)} ${ Object.keys(sumBreakDownActions).length ? getTotalActionsStringify(sumBreakDownActions) : "" }`, border: ["top", "bottom", "right"], bold: true  }),
      create_col_row({ value: `${ formatNumber(sumRealPercentage)}/ ${formatNumber(sumPlanPercentage)}%`, border: ["top", "bottom"], bold: true  }),
   ]
   rows.push(last_row)

   const multiDataSet = [{
      columns: cols,
      data: rows,
   }]

   return (
      <ExcelFile.ExcelSheet dataSet={multiDataSet} name="Tabla Comparativa" />
   )                   
}

const getTotalActionsStringify = ( actions, category = "" ) => {
   let finalBreakdown = ""
   if ( actions != null ) {
      const actionEntries = Object.entries(actions)
      if ( actionEntries.length === 1 && actionEntries[0][0] === category) return finalBreakdown
      actionEntries.forEach((entrie, i) => {
         if ( i === 0 ) finalBreakdown += "("

         finalBreakdown += `${ entrie[0] }: ${ entrie[1] }`

         if ( i !== actionEntries.length - 1) finalBreakdown += ", "
         else finalBreakdown += ")"
      })
   }else {
      return ""
   }

   return finalBreakdown
}

const sumActions = ( curr, next ) => {
   if ( !Object.keys(curr).length ) return next

   const nextEntries = Object.entries(next)
   if ( nextEntries != null ) {
      if ( curr == null ) curr = next
      nextEntries.forEach((entrie => {

         if ( curr[`${ entrie[0] }`] == null ) {
            curr[`${ entrie[0] }`] = entrie[1]
         }else {
            curr[`${ entrie[0] }`] = curr[`${ entrie[0] }`] + entrie[1]
         }

      }))
   }else {
      return curr
   }
   
   return curr
}

export default PlanningReportScreen
