import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormControl, InputLabel, FormHelperText, Input, makeStyles } from '@material-ui/core'
import styles from 'assets/jss/material-ui-react/components/customInputStyle'
import { Typography } from '@mui/material'

const useStyles = makeStyles(styles)

export default function CustomInput(props) {
   const classes = useStyles()
   const {
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success,
      helperText,
      rtlActive,
   } = props

   const labelClasses = classNames({
      [' ' + classes.labelRootError]: error,
      [' ' + classes.labelRootSuccess]: success && !error,
      [classes.labelRTL]: rtlActive,
   })
   const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true,
      [classes.whiteUnderline]: white,
   })
   const marginTop = classNames({
      [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
   })
   const inputClasses = classNames({
      [classes.input]: true,
      [classes.whiteInput]: white,
   })
   var formControlClasses
   if (formControlProps !== undefined) {
      formControlClasses = classNames(formControlProps.className, classes.formControl)
   } else {
      formControlClasses = classes.formControl
   }
   var helpTextClasses = classNames({
      [classes.labelRootError]: error,
      [classes.labelRootSuccess]: success && !error,
   })
   let newInputProps = {
      maxLength: inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
      minLength: inputProps && inputProps.minLength ? inputProps.minLength : undefined,
      step: inputProps && inputProps.step ? inputProps.step : undefined,
   }
   return (
      <FormControl {...formControlProps} className={formControlClasses}>
         {labelText !== undefined ? (
         <InputLabel className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
            <Typography variant='body1' gutterBottom><span style={{ fontWeight: 600 }}>{labelText}</span></Typography>
         </InputLabel>
         ) : null}
         <Input
            classes={{
               input: inputClasses,
               root: marginTop,
               disabled: classes.disabled,
               underline: underlineClasses,
            }}
            id={id}
            {...inputProps}
            inputProps={newInputProps}
         />
         {helperText !== undefined ? (
            <FormHelperText id={id + '-text'} className={helpTextClasses}>
               {helperText}
            </FormHelperText>
         ) : null}
      </FormControl>
   )
}

CustomInput.propTypes = {
   labelText: PropTypes.node,
   labelProps: PropTypes.object,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   inputRootCustomClasses: PropTypes.string,
   error: PropTypes.bool,
   success: PropTypes.bool,
   white: PropTypes.bool,
   helperText: PropTypes.node,
   rtlActive: PropTypes.bool,
}
