export const WEEKLY_REPORT_REQUEST = 'WEEKLY_REPORT_REQUEST'
export const WEEKLY_REPORT_SUCCESS = 'WEEKLY_REPORT_SUCCESS'
export const WEEKLY_REPORT_FAIL = 'WEEKLY_REPORT_FAIL'
export const WEEKLY_REPORT_RESET = 'WEEKLY_REPORT_RESET'

export const MONTHLY_REPORT_REQUEST = 'MONTHLY_REPORT_REQUEST'
export const MONTHLY_REPORT_SUCCESS = 'MONTHLY_REPORT_SUCCESS'
export const MONTHLY_REPORT_FAIL = 'MONTHLY_REPORT_FAIL'
export const MONTHLY_REPORT_RESET = 'MONTHLY_REPORT_RESET'


export const PLANNING_REPORT_REQUEST = 'PLANNING_REPORT_REQUEST'
export const PLANNING_REPORT_SUCCESS = 'PLANNING_REPORT_SUCCESS'
export const PLANNING_REPORT_FAIL = 'PLANNING_REPORT_FAIL'
export const PLANNING_REPORT_RESET = 'PLANNING_REPORT_RESET'

export const MONTHLY_BILLING_REPORT_REQUEST = 'MONTHLY_BILLING_REPORT_REQUEST'
export const MONTHLY_BILLING_REPORT_SUCCESS = 'MONTHLY_BILLING_REPORT_SUCCESS'
export const MONTHLY_BILLING_REPORT_FAIL = 'MONTHLY_BILLING_REPORT_FAIL'
export const MONTHLY_BILLING_REPORT_RESET = 'MONTHLY_BILLING_REPORT_RESET'


