import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ReactExport from 'react-data-export'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Visibility, Delete, Lightbulb, LightbulbOutlined, Edit } from '@mui/icons-material'
import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import DeleteActModal from './components/DeleteActModal'
import UpdateActModal from './components/UpdateActModal'
import DownloadConfirmModal from 'components/DownloadConfirmModal/DownloadConfirmModal'
import ActiveAndDesactiveActModal from './components/ActiveAndDesactiveActModal'
import ViewActInfoModal from './components/ViewActInfoModal'
import { ACT_LIST_RESET } from 'redux/constants/actConstants'
import { getActs } from 'redux/actions/actActions'
import styles from './styles/actListScreenStyles'

const useStyles = makeStyles(styles)

const ActListScreen = () => {
  const dispatch = useDispatch()
  const ExcelFile = ReactExport.ExcelFile
  const classes = useStyles()

  const [data, setData] = useState([])
  const [updateActModal, setUpdateActModal] = useState(false)
  const [deleteActModal, setDeleteActModal] = useState(false)
  const [desactiveModal, setDesactiveModal] = useState(false)
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [downloadPdf, setDownloadPdf] = useState(false)
  const [activeModal, setActiveModal] = useState(false)
  const [excel, setExcel] = useState(false)
  const [pdf, setPdf] = useState(false)
  const [showActiveInfo, setShowActiveInfo] = useState({})
  const [showDesactiveInfo, setShowDesactiveInfo] = useState({})
  const [showUpdateAct, setShowUpdateAct] = useState({})
  const [showDeleteActInfo, setShowDeleteActInfo] = useState({})
  const [viewInfoModal, setViewInfoModal] = useState(false)
  const [showViewInfoModal, setShowViewInfoModal] = useState({})

  const { loadingActList, acts, successActList, errorActList } = useSelector(
    (state) => state.actList
  )  

  useEffect(() => {
    if (successActList) {
      const list = acts.map((act) => {
        return {
          ...act,
          descripcion_acto: `${act.descripcion_acto || '-'}`,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => handlerActiveAndDesactive(act)}
                color='warning'
                className='active-deactive'
              >
                <Tooltip title='activar/desactivar'>
                  {act.activo === 'SI' ? <Lightbulb /> : <LightbulbOutlined />}
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showUpdateActHandler(act.id_acto)}
                color='primary'
                className='active-deactive'
              >
                <Tooltip title='Editar Información'>
                  <Edit />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showViewInfoHandler(act.id_acto)}
                color='success'
                className='edit'
              >
                <Tooltip title='Ver Información'>
                  <Visibility />
                </Tooltip>
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => showDeleteActHandler(act.id_acto)}
                color='danger'
                className='delete'
              >
                <Tooltip title='Borrar'>
                  <Delete />
                </Tooltip>
              </Button>
            </div>
          ),
        }
      })
      setData(list)
    } else {
      dispatch(getActs())
    }
  }, [successActList, dispatch])

  useEffect(() => {
    return () => dispatch({ type: ACT_LIST_RESET })
  }, [dispatch])

  useEffect(() => {
    if (pdf) {
      exportPDF()
    }
  }, [pdf])

  const handlerActiveAndDesactive = (act) => {
    if (act.activo === 'SI') {
      showDesactiveUserHandler(act.id_acto)
      return
    }
    showActiveUserHandler(act.id_acto)
  }

  const showActiveUserHandler = (id) => {
    const act = acts.find((act) => act.id_acto === id)
    setShowActiveInfo(act)
    setActiveModal(true)
  }

  const closeActiveModal = () => {
    setActiveModal(false)
    setShowActiveInfo({})
  }
  const showDesactiveUserHandler = (id) => {
    const act = acts.find((act) => act.id_acto === id)
    setShowDesactiveInfo(act)
    setDesactiveModal(true)
  }

  const closeDesactiveModal = () => {
    setDesactiveModal(false)
    setShowDesactiveInfo({})
  }

  const handleCloseModal = () => {
    setUpdateActModal(false)
    setShowUpdateAct({})
  }
  const showUpdateActHandler = (id) => {
    const act = acts.find((el) => el.id_acto === id)
    setShowUpdateAct(act)
    setUpdateActModal(true)
  }

  const showDeleteActHandler = (id) => {
    const act = acts.find((el) => el.id_acto === id)
    setShowDeleteActInfo(act)
    setDeleteActModal(true)
  }

  const handleCloseDeleteActModal = () => {
    setDeleteActModal(false)
    setShowDeleteActInfo({})
  }

  const showViewInfoHandler = (id) => {
    const act = acts.find((act) => act.id_acto === id)
    setShowViewInfoModal(act)
    setViewInfoModal(true)
  }

  const closeViewInfoModal = () => {
    setViewInfoModal(false)
    setShowViewInfoModal({})
  }

  const exportPDF = () => {
    const doc = new jsPDF()
    doc.text('Tipos de actos', 20, 10)
    doc.autoTable({
      body: data,
      columns: [
        { header: 'Codigo de Acto', dataKey: 'codigo_acto' },
        { header: 'Descripcion', dataKey: 'descripcion_acto' },
        { header: 'Activo', dataKey: 'activo' },
        { header: 'Fecha de alta', dataKey: 'fecha_alta' },
        { header: 'Fecha de baja', dataKey: 'fecha_baja' },
      ],
    })
    doc.save('tipos_actos.pdf')
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} className={classes.rootItem}>
          <Card>
            <CardBody>
              {loadingActList ? (
                'Cargando Tipos de Actos...'
              ) : (
                <ReactTable
                  columns={[
                    {
                      Header: 'CÓDIGO DE ACTO',
                      accessor: 'codigo_acto',
                    },
                    {
                      Header: 'DESCRIPCIÓN DE ACTO',
                      accessor: 'descripcion_acto',
                    },
                    {
                      Header: 'ACTIVO',
                      accessor: 'activo',
                    },
                    {
                      Header: 'ACCIONES',
                      accessor: 'actions',
                    },
                  ]}
                  data={data}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {excel && (
                  <ExcelFile
                    element={<Button color='primary'>Exportar Excel</Button>}
                    filename='tipos_actos'
                    hideElement={true}
                  >
                    <ExcelFile.ExcelSheet data={data} name='Tipos_Actos'>
                      <ExcelFile.ExcelColumn label='Codigo de Acto' value='codigo_acto' />
                      <ExcelFile.ExcelColumn label='Descripcion' value='descripcion_acto' />
                      <ExcelFile.ExcelColumn label='Activo' value='activo' />
                      <ExcelFile.ExcelColumn label='Fecha de alta' value='fecha_alta' />
                      <ExcelFile.ExcelColumn label='Fecha de baja' value='fecha_baja' />
                    </ExcelFile.ExcelSheet>
                  </ExcelFile>
                )}
                <Button color='primary' onClick={() => setDownloadExcel(true)} style={{ marginLeft: '10px' }}>
                  Exportar EXCEL
                </Button>
                <Button color='primary' onClick={() => setDownloadPdf(true)} style={{ marginLeft: '10px' }}>
                  Exportar PDF
                </Button>
              </div>
            </CardBody>
            {errorActList && (
              <GridContainer>
                <GridItem xs={12}>
                  <SnackbarContent message={errorActList} color='danger' />
                </GridItem>
              </GridContainer>
            )}
          </Card>
        </GridItem>
      </GridContainer>
      {activeModal && (
        <ActiveAndDesactiveActModal
          activeModal={activeModal}
          closeActiveModal={closeActiveModal}
          info={showActiveInfo}
          active={true}
        />
      )}
      {desactiveModal && (
        <ActiveAndDesactiveActModal
          activeModal={desactiveModal}
          closeActiveModal={closeDesactiveModal}
          info={showDesactiveInfo}
          active={false}
        />
      )}
      {updateActModal && (
        <UpdateActModal
          handleCloseModal={handleCloseModal}
          updateActModal={updateActModal}
          showUpdateAct={showUpdateAct}
        />
      )}
      {viewInfoModal && (
        <ViewActInfoModal
          viewInfo={viewInfoModal}
          closeViewInfoModal={closeViewInfoModal}
          info={showViewInfoModal}
        />
      )}
      {deleteActModal && (
        <DeleteActModal
          handleCloseDeleteActModal={handleCloseDeleteActModal}
          deleteActModal={deleteActModal}
          showDeleteActInfo={showDeleteActInfo}
        />
      )}
      {downloadExcel && (
        <DownloadConfirmModal
          downloadFile={downloadExcel}
          setDownloadFile={setDownloadExcel}
          setFile={setExcel}
          tableName='Tipos_actos'
          FileExtension='Excel'
        />
      )}
      {downloadPdf && (
        <DownloadConfirmModal
          downloadFile={downloadPdf}
          setDownloadFile={setDownloadPdf}
          setFile={setPdf}
          tableName='Tipos_actos'
          FileExtension='PDF'
        />
      )}
    </>
  )
}

export default ActListScreen
