import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import ActiveAndDesactiveActionModal from 'components/ReactTableActions/ActiveAndDesactive/ActiveAndDesactiveActionModal'
import { userlistUpdateInfo } from 'redux/actions/userlistActions'
import { USERLIST_LIST_RESET, USERLIST_UPDATE_RESET } from 'redux/constants/userlistConstants'

const ActiveAndDesactiveUserModal = ({ activeModal, closeActiveModal, info, active }) => {
  const dispatch = useDispatch()

  const { loadingUserlistUpdate, errorUserlistUpdate, successUserlistUpdate } = useSelector((state) => state.userlistUpdate)

  useEffect(() => {
    if (successUserlistUpdate) {
      dispatch({ type: USERLIST_LIST_RESET })
      setTimeout(() => {
        dispatch({ type: USERLIST_UPDATE_RESET })
        closeActiveModal()
      }, 1000)
    }
  }, [successUserlistUpdate])
  const activeOrDesactive = (e) => {
    e.preventDefault()
    if (active) {
      dispatch(userlistUpdateInfo({ ...info, activo: 'SI' }))
    } else {
      dispatch(userlistUpdateInfo({ ...info, activo: 'NO', fecha_baja: format(new Date(), 'yyyy-MM-dd') }))
    }
  }
  return (
    <ActiveAndDesactiveActionModal
      open={activeModal}
      handleCloseModal={closeActiveModal}
      handleSubmit={activeOrDesactive}
      message={
        <>
          Va a {active ? 'activar' : 'desactivar'} el usuario <strong>{info.usuario}</strong>
        </>
      }
      modalTitle={`${active ? 'Activar' : 'Desactivar'} Acto`}
      error={errorUserlistUpdate}
      success={successUserlistUpdate}
      loading={loadingUserlistUpdate}
      loadingMessageButton={active ? 'Activando...' : 'Desactivando'}
      succesMessageButton={active ? 'Activar' : 'Desactivar'}
    />
  )
}

export default ActiveAndDesactiveUserModal
