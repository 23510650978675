import { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import styles from 'assets/jss/material-ui-react/components/buttonStyle'

const useStyles = makeStyles(styles)

const RegularButton = forwardRef((props, ref) => {
   const classes = useStyles()
   const {
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className,
      muiClasses,
      backgroundColor,
      ...rest
   } = props
   const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes[backgroundColor]]: backgroundColor,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
   })
   return (
      <Button {...rest} ref={ref} classes={muiClasses} className={btnClasses}>
         {children}
      </Button>
   )
})

RegularButton.propTypes = {
   color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'rose',
      'white',
      'twitter',
      'facebook',
      'google',
      'linkedin',
      'pinterest',
      'youtube',
      'tumblr',
      'github',
      'behance',
      'dribbble',
      'reddit',
      'transparent',
   ]),
   size: PropTypes.oneOf(['sm', 'lg']),
   simple: PropTypes.bool,
   round: PropTypes.bool,
   fullWidth: PropTypes.bool,
   disabled: PropTypes.bool,
   block: PropTypes.bool,
   link: PropTypes.bool,
   justIcon: PropTypes.bool,
   className: PropTypes.string,
   muiClasses: PropTypes.object,
   children: PropTypes.node,
}

export default RegularButton
