import {
   WEEKLY_REPORT_REQUEST,
   WEEKLY_REPORT_SUCCESS,
   WEEKLY_REPORT_FAIL,
   WEEKLY_REPORT_RESET,
   MONTHLY_REPORT_REQUEST,
   MONTHLY_REPORT_SUCCESS,
   MONTHLY_REPORT_FAIL,
   MONTHLY_REPORT_RESET,
   PLANNING_REPORT_REQUEST,
   PLANNING_REPORT_SUCCESS,
   PLANNING_REPORT_FAIL,
   PLANNING_REPORT_RESET,
   MONTHLY_BILLING_REPORT_REQUEST,
   MONTHLY_BILLING_REPORT_SUCCESS,
   MONTHLY_BILLING_REPORT_FAIL,
   MONTHLY_BILLING_REPORT_RESET,
} from '../constants/reportConstants.js'

export const reportListWeeklyReducer = (state = {}, action) => {
   switch (action.type) {
      case WEEKLY_REPORT_REQUEST:
         return { loadingWeeklyReport: true }
      case WEEKLY_REPORT_SUCCESS:
         return {
            loadingWeeklyReport: false,
            successWeeklyReport: true,
            weeklyReport: action.payload,
         }
      case WEEKLY_REPORT_FAIL:
         return {
            loadingWeeklyReport: false,
            errorWeeklyReport: action.payload,
         }
      case WEEKLY_REPORT_RESET:
         return {}
      default:
         return state
   }
}

export const reportListMonthlyReducer = (state = {}, action) => {
   switch (action.type) {
      case MONTHLY_REPORT_REQUEST:
         return { loadingMonthlyReport: true }
      case MONTHLY_REPORT_SUCCESS:
         return {
            loadingMonthlyReport: false,
            successMonthlyReport: true,
            monthlyReport: action.payload,
         }
      case MONTHLY_REPORT_FAIL:
         return {
            loadingMonthlyReport: false,
            errorMonthlyReport: action.payload,
         }
      case MONTHLY_REPORT_RESET:
         return {}
      default:
         return state
   }
}

export const reportListPlanningReducer = (state = {}, action) => {
   switch (action.type) {
      case PLANNING_REPORT_REQUEST:
         return { loadingPlanningReport: true }
      case PLANNING_REPORT_SUCCESS:
         return {
            loadingPlanningReport: false,
            successPlanningReport: true,
            planningReport: action.payload,
         }
      case PLANNING_REPORT_FAIL:
         return {
            loadingPlanningReport: false,
            errorPlanningReport: action.payload,
         }
      case PLANNING_REPORT_RESET:
         return {}
      default:
         return state
   }
}

export const reportListMonthlyBillingReducer = (state = {}, action) => {
   switch (action.type) {
      case MONTHLY_BILLING_REPORT_REQUEST:
         return { loadingMonthlyBillingReport: true }
      case MONTHLY_BILLING_REPORT_SUCCESS:
         return {
            loadingMonthlyBillingReport: false,
            successMonthlyBillingReport: true,
            monthlyBillingReport: action.payload,
         }
      case MONTHLY_BILLING_REPORT_FAIL:
         return {
            loadingMonthlyBillingReport: false,
            errorMonthlyBillingReport: action.payload,
         }
      case MONTHLY_BILLING_REPORT_RESET:
         return {}
      default:
         return state
   }
}
