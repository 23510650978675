import { useState, useEffect } from "react"
import dayjs from 'dayjs'

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import ReactTable from "components/ReactTable/ReactTable"
import { formatNumber } from "utils/formatNumber"


export const TabPanelContent = ({ comparison }) => {

   const [table_cols, setTable_cols] = useState([])
   const [table_data, setTable_data] = useState([])

   useEffect(() => {
      // COMPARISON
      if (comparison != null && comparison.length) {
         // COLUMNAS

         const cols = [{
            Header: "Periodo",
            accessor: "period",
         },
         {
            Header: "Línea",
            accessor: "line",
         },
         {
            Header: "Año convocatoria",
            accessor: "year",
         },
         {
            Header: "Categoría",
            accessor: "category",
         },
         {
            Header: "Indicador",
            accessor: "indicator",
         },
         {
            Header: "Act. Ejecutadas/ Planificadas",
            accessor: "total_actions",
         },
         {
            Header: "% Ejecutado/ Planificado",
            accessor: "execution_percentage",
         }]
         // FILAS
         const rows = comparison.map(col => {
            const object_to_push = {}
            object_to_push["period"] = `${dayjs(col.fecha_inicio).format('YYYY/MM/DD')} - 
               ${dayjs(col.fecha_fin).format('YYYY/MM/DD')}`
            object_to_push["line"] = col.linea
            object_to_push["year"] = formatNumber(col.anio_convocatoria, 0)
            object_to_push["category"] = col.categoria_pliego
            object_to_push["indicator"] = col.indicador

            let breakDown = ""

            if (col.breakdown_real != null) {

            
            const breakDownEntries = Object.entries(col.breakdown_real)
            if ( breakDownEntries.length !== 1 || (breakDownEntries.length === 1 && breakDownEntries[0][0] !== col.categoria_pliego) ) {
               breakDownEntries.forEach((entrie, i) => {
                  if ( i === 0 ) breakDown += "("

                  breakDown += `${ entrie[0] }: ${ entrie[1] }`

                  if ( i === breakDownEntries.length - 1 ) breakDown += ")"
                  else breakDown += ", "
               })
            }
         }
            object_to_push["total_actions"] = `${formatNumber(col.ta_real, 0) }/ 
               ${formatNumber(col.ta_planificado, 0)} ${ breakDown ? breakDown : "" }`
            object_to_push["execution_percentage"] = `${formatNumber(col.pe_real)}/
               ${formatNumber(col.pe_planificado)}`
            

            return object_to_push
         })
      
         setTable_cols( cols )
         setTable_data( rows )
      }
      
   }, [comparison])
   

   return (
      <GridContainer>
         <GridItem xs={12}>
            {
               !!table_data.length
               && 
               <ReactTable
                  columns={table_cols}
                  data={table_data}
                  numFilas={10}
                  ignoreLastCol={true}
                  whiteSpace={"normal"}
                  colWidth={"230px"}
                  // compareValue={[5, 6]}
               />
            }
         </GridItem>
      </GridContainer>
   )
}