import { useState, useEffect } from 'react'
import { makeStyles, FormControl, InputLabel, MenuItem, Select as Selectable, TextField } from '@material-ui/core'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import DateFnsUtils from "@date-io/date-fns"
import { es } from "date-fns/locale"
import * as dayjs from "dayjs"

import styles from '../styles/milestoneStyles'
import AddBoxIcon from '@mui/icons-material/AddBox'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import { Checkbox, FormControlLabel, Divider, Pagination } from '@mui/material'
import { Line } from './Line'
import CardHeader from 'components/Card/CardHeader'

const useStyles = makeStyles(styles)

export const SubMilestone = ({subInfo, subMilestones, setSubMilestones, isGlobal, setIsGlobal, linesMainInfo, indicators, categories,
    index, minStartDate, maxEndDate, setRegisterError, initialInfo, update, setShowUpdate, setUpdateError, view}) => {

   const classes = useStyles()
   
   // main state
   const [sm_startDate, setSm_startDate] = useState(null)
   const [sm_endDate, setSm_endDate] = useState(null)
   const [sm_isGlobal, setSm_isGlobal] = useState(null)
   const [sm_percentage, setSm_percentage] = useState(0)
   const [lines, setLines] = useState([1])
   const [isRegistered, setIsRegistered] = useState(false)
   const [wasUpdated, setWasUpdated] = useState(false)
   const [initialSubmilestoneData, setInitialSubmilestoneData] = useState(JSON.parse(JSON.stringify(initialInfo)))
   // pagination state
   const [actualPage, setActualPage] = useState(1)
   const [totalPages, setTotalPages] = useState(Math.ceil(lines.length/ 5))


   useEffect(() => {

      if (subInfo != null) {
         setSm_startDate(subInfo === 2 ? null : subInfo.submilestone.general_data.fecha_inicio)
         setSm_endDate(subInfo === 2 ? null : subInfo.submilestone.general_data.fecha_fin)
         setSm_isGlobal(subInfo === 2 ? null : subInfo.submilestone.general_data.isGlobal)
         setSm_percentage(subInfo === 2 ? 0 : (Number(subInfo.submilestone.general_data.porcentaje_ejecucion)).toFixed(2))
         setIsRegistered(subInfo === 2 ? false : subInfo.submilestone.general_data.isRegistered)
         setLines(subInfo === 2 ? [2] : subInfo.submilestone.lines)
      }
   }, [subInfo])

   useEffect(() => {

      const new_sm_percentage = lines.reduce((acc, line) => {
         if (line === 1 || line === 2) return acc
         const lineSumPercentage = line.indicadores.reduce(((acc2, ind) => acc2 + Number(ind.executionPercentage) ), 0)

         return acc + lineSumPercentage
      }, 0)
      
      setSm_percentage(new_sm_percentage.toFixed(2))

      if (lines.length === 0) {
         setActualPage(1)
         setTotalPages(1)
      }else {
         setTotalPages(Math.ceil(lines.length/ 5))
      }
   }, [lines])

   // functions
   const addLineHandler = () => {

      const newTotalPages = Math.ceil((lines.length + 1) / 5)
      // if (newTotalPages !== totalPages) setActualPage(newTotalPages)
      
      setTotalPages(newTotalPages)
      setLines(last => {
         return last.concat([2])
      })
   }


   const onChangeStartDateHandler = (newVal) => {
      if ( sm_endDate == null || newVal > sm_endDate ) {
         setSm_startDate(dayjs(newVal).format("YYYY-MM-DD"))
         setSm_endDate(dayjs(newVal).format("YYYY-MM-DD"))
      }else {
         setSm_startDate(dayjs(newVal).format("YYYY-MM-DD"))
      }
   }
   
   const onChangeEndDateHandler = (newVal) => {
      if ( sm_startDate == null || newVal < sm_startDate) {
         setSm_startDate(dayjs(newVal).format("YYYY-MM-DD"))
         setSm_endDate(dayjs(newVal).format("YYYY-MM-DD"))
      }else {
         setSm_endDate(dayjs(newVal).format("YYYY-MM-DD"))
      }
   }   

   const onRegisterSubmilestoneHandler = () => {

      if (!submilestoneValidation()) return

      if (sm_isGlobal) setIsGlobal(true)
      setIsRegistered(true)
      setWasUpdated(false)
      setSubMilestones(last => {    

         last[sm_isGlobal ? 0 : index] = {
            submilestone: {
               general_data: {
                  fecha_inicio: sm_isGlobal ? minStartDate : dayjs(new Date(sm_startDate)).format("YYYY-MM-DD"),
                  fecha_fin: sm_isGlobal ? maxEndDate : dayjs(new Date(sm_endDate)).format("YYYY-MM-DD"),
                  porcentaje_ejecucion: Number(sm_percentage),
                  isRegistered: true,
                  isGlobal: sm_isGlobal,
                  wasUpdated: false,
                  index
               },
               lines
            }         
         }

         if (sm_isGlobal) {

            const sm = []
            sm[0] = [...last.filter(sm => {
               if (typeof sm != "object") return false
               else if (sm?.submilestone?.general_data?.isGlobal) return true
               else return false
            })][0]

            return sm
         }

         return [...last]
      })

      setShowUpdate( true )
   }

   const onDiscardMilestoneHandler = () => {
      const startDate = initialSubmilestoneData?.submilestone?.general_data?.fecha_inicio ?? null
      const endDate = initialSubmilestoneData?.submilestone?.general_data?.fecha_fin ?? null
      const isGlobal = initialSubmilestoneData?.submilestone?.general_data?.isGlobal ?? false
      const percentage = initialSubmilestoneData?.submilestone?.general_data?.porcentaje_ejecucion != null
         ? (Number(initialSubmilestoneData?.submilestone?.general_data?.porcentaje_ejecucion))?.toFixed(2)
         : 0
      const isRegistered = initialSubmilestoneData?.submilestone?.general_data?.isRegistered ?? false
      const lines = initialSubmilestoneData?.submilestone?.lines ?? [2]
      setSm_startDate(startDate)
      setSm_endDate(endDate)
      setSm_isGlobal(isGlobal)
      setSm_percentage(percentage)
      setIsRegistered(isRegistered)
      setLines(lines)

      setSubMilestones(last => {    

         last[index] = {
            submilestone: {
               general_data: {
                  fecha_inicio: startDate,
                  fecha_fin: endDate,
                  porcentaje_ejecucion: percentage,
                  isRegistered: isRegistered,
                  isGlobal: isGlobal,
                  wasUpdated: false,
                  index
               },
               lines: lines
            }         
         }

         return [...last]
      })

      setWasUpdated(false)
      setUpdateError("")
      setIsRegistered(true)
   }

   const onDeleteMilestoneHandler = () => {
      setSubMilestones(last => {

         last.splice(index, 1)

         return [...last.map(sm => {
            if (sm === 1) return 2
            return sm
         })]
      })
      setShowUpdate(true)
   }

   const onChangePageHandler = (e, newPage) => {
      setActualPage(newPage)
   }

   const onChangeGlobalHandler = (newVal) => {
      
      setSm_isGlobal(newVal)
   }

   const onEditMilestoneHandler = () => {
      setIsRegistered(false)
      // setWasUpdated(true)
      setShowUpdate(true)
      setSubMilestones(last => {    

         last[index] = {
            submilestone: {
               general_data: {
                  fecha_inicio: sm_isGlobal ? minStartDate : dayjs(new Date(sm_startDate)).format("YYYY-MM-DD"),
                  fecha_fin: sm_isGlobal ? maxEndDate : dayjs(new Date(sm_endDate)).format("YYYY-MM-DD"),
                  porcentaje_ejecucion: Number(sm_percentage),
                  isRegistered: false,
                  isGlobal: sm_isGlobal,
                  // wasUpdated: true,
                  wasUpdated: false,
                  index
               },
               lines
            }         
         }

         return [...last]
      })
   }

   const submilestoneValidation = () => {

      let errLineIndex = 1
      if (sm_startDate == null || sm_endDate == null) {
         if (update) setUpdateError(`PERIODO (${index + 1}) - Debe seleccionar una fecha de inicio y una de fin`)         
         else setRegisterError(`PERIODO (${index + 1}) - Debe seleccionar una fecha de inicio y una de fin`)
         return false
      }else if (sm_startDate > sm_endDate) { //
         if (update) setUpdateError(`PERIODO (${index + 1}) - La fecha de inicio no puede ser mayor a la fecha de fin`)         
         else setRegisterError(`PERIODO (${index + 1}) - La fecha de inicio no puede ser mayor a la fecha de fin`)
         return false
      }else if (lines.length == 0) { //
         if (update) setUpdateError(`PERIODO (${index + 1}) - Debe agregar al menos una línea`)         
         else setRegisterError(`PERIODO (${index + 1}) - Debe agregar al menos una línea`)
         return false
      }else if (sm_percentage > 100) {
         if (update) setUpdateError(`PERIODO (${index + 1}) - El porcentaje de ejecución no puede ser mayor a 100`)         
         else setRegisterError(`PERIODO (${index + 1}) - El porcentaje de ejecución no puede ser mayor a 100`)
         return false
      }else if (containsDuplicates(lines.filter(line => line.estructura !== " - 0 - ")
         .map(line => line.estructura))) {
            if (update) setUpdateError(`PERIODO (${index + 1}) - No puede haber dos líneas con la misma estructura`)         
            else setRegisterError(`PERIODO (${index + 1}) - No puede haber dos líneas con la misma estructura`)
            return false
      }else if (lines.filter(line => isNaN(line))
         .some(line => {
            if (line.indicadores.some(ind => ind.executionPercentage > 100)) {
               errLineIndex = line.index
               return true
            }
      })) {
         if (update) setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El porcentaje de ejecución cualquier indicador no puede ser mayor a 100`)         
         else setRegisterError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El porcentaje de ejecución cualquier indicador no puede ser mayor a 100`)
         return false
      }else if (lines.filter(line => isNaN(line))
         .some(line => {
         if (line.indicadores.some(ind => ind.executionPercentage < 0)) {
            errLineIndex = line.index
            return true
         }   
      })) { //
         if (update) setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El porcentaje de ejecución cualquier indicador no puede ser menor a 0`)         
         else setRegisterError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El porcentaje de ejecución cualquier indicador no puede ser menor a 0`)
         return false
      }else if (lines.filter(line => isNaN(line))
         .some(line => {
         if (!line.linea || line.linea == null) {
            errLineIndex = line.index
            return true
         }
         return false
      })){
         if (update) setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - No se ha seleccionado la estructura de la línea`)         
         else setRegisterError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - No se ha seleccionado la estructura de la línea`)
         return false
      }else if (lines.filter(line => isNaN(line))
         .some(line => {
         if (line.indicadores.every(ind => Number(ind.totalActions) === 0)) {
            errLineIndex = line.index
            return true
         }
         return false
      })) {
         if (update) setUpdateError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El total de actuaciones de la línea no puede ser 0`)         
         else setRegisterError(`PERIODO (${index + 1}) - LÍNEA (${errLineIndex + 1}) - El total de actuaciones de la línea no puede ser 0`)
         return false
      }

      if (update) setUpdateError("")
      else setRegisterError("")
      return true
   }


   return (
      <Card>
         <CardHeader style={{paddingBottom: "0", fontWeight: "bold", marginBottom: "0", display: "flex", justifyContent: "space-between"}}>
            <h4 
               style={{fontWeight: "bold"}}
               className={isRegistered ? classes.off : ""}
            >
               PERIODO ({index + 1})
            </h4>
            <div>
            {
               view
               ? ""
               :  (
                  isRegistered
                  ?  (
                     <Button 
                        color="danger"
                        onClick={onDeleteMilestoneHandler}
                        style={{fontSize: ".95rem"}}
                     >
                        Eliminar Periodo
                     </Button>
                     )
                  :  (
                     <>
                     {
                        update != null
                        && (
                           <Button 
                              color="danger"
                              variant="outlined"
                              onClick={onDiscardMilestoneHandler}
                              style={{fontSize: ".95rem"}}
                           >
                              Descartar cambios
                           </Button>
                        )
                     }
                     <Button 
                        color="primary"
                        onClick={onRegisterSubmilestoneHandler}
                        style={{fontSize: ".95rem"}}
                     >
                        Guardar Periodo
                     </Button>
                     </>
                     )
                  )          
            }
            
            {
               isRegistered
               && update
               && !wasUpdated
               && !view
               && (
                  <Button 
                     color="primary"
                     onClick={onEditMilestoneHandler}
                     style={{fontSize: ".95rem"}}
                  >
                     Editar Periodo
                  </Button>
               )
            }
            </div>
         </CardHeader>
         <CardBody style={{paddingTop: "0"}}>
            <GridContainer>
               <GridItem xs={12}>          
                  <GridContainer 
                     style={{paddingTop: "0"}}
                  >      
                     <GridItem xs={6} style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>                                     
                        {/* CHECKBOX */}
                        <FormControlLabel
                           value={false}
                           control={
                              <Checkbox 
                                 checked={sm_isGlobal}
                                 onChange={(e) => onChangeGlobalHandler(e.target.checked)}                         
                              />
                           }
                           label={"El periodo comprende a toda la planificación"}
                           labelPlacement="right"
                           disabled={isRegistered || view}
                        />
                     </GridItem>
                     {/* PORCENTAJE EJECUCIÓN */}
                     <GridItem xs={6} style={{marginBottom: "10px"}}>            
                        <CustomInput
                           labelText="Porcentaje de ejecución - periodo"
                           formControlProps={{ fullWidth: true}}
                           // error={column.error}
                           inputProps={{
                              value: `${ sm_percentage }%` || 0,
                              type: "text",
                              required: false,
                              disabled: true
                           }}
                        />
                     </GridItem>         
                     <GridItem xs={6}>
                        {/* FECHA INICIO */}
                        <LocalizationProvider
                           dateAdapter={AdapterDateFns}
                           locale={es}
                           utils={DateFnsUtils}
                        >
                           <DesktopDatePicker
                              label="Fecha Inicio"
                              inputFormat="dd-MM-yyyy"
                              minDate={new Date(minStartDate)}
                              maxDate={new Date(maxEndDate)}
                              // shouldDisableDate={getDisabledDates}
                              disabled={isRegistered || ( minStartDate == null || maxEndDate == null || view )}
                              value={sm_startDate}
                              onChange={(newVal) => onChangeStartDateHandler(newVal)}
                              renderInput={(params) => {
                                 return <TextField
                                    style={{width:"100%"}}
                                    // required={column.error}
                                    {...params}
                                    // error={column.error}
                                 />
                              }}
                           />
                        </LocalizationProvider>
                     </GridItem>
                     <GridItem xs={6}>
                        {/* FECHA FIN */}
                        <LocalizationProvider
                           dateAdapter={AdapterDateFns}
                           locale={es}
                           utils={DateFnsUtils}
                        >
                           <DesktopDatePicker
                              label="Fecha Fin"
                              inputFormat="dd-MM-yyyy"
                              minDate={new Date(minStartDate)}
                              maxDate={new Date(maxEndDate)}
                              disabled={isRegistered || ( minStartDate == null || maxEndDate == null || view )}
                              value={sm_endDate}
                              onChange={(newVal) => onChangeEndDateHandler(newVal)}
                              renderInput={(params) => {
                                 return <TextField
                                    style={{width:"100%"}}
                                    // required={column.error}
                                    {...params}
                                    // error={column.error}
                                 />
                              }}
                           />
                        </LocalizationProvider>
                     </GridItem>
                     
                  </GridContainer>
               </GridItem>
            </GridContainer>
         
            <Pagination 
               count={totalPages}
               defaultPage={1}
               page={actualPage}
               onChange={onChangePageHandler}
               color="primary"
               variant="outlined"
               style={{marginTop: "25px"}}
            />
            {
               lines.length
                  && lines.slice((actualPage - 1) * 5, actualPage * 5)
                     .map((line, i) => {
                        
                        return (
                           <>
                           <Line
                              lineInfo={line === 1 ? null : line}
                              linesMainInfo={linesMainInfo}
                              indicators={indicators}
                              isRegistered={isRegistered}
                              update={update != null ? update : null}
                              view={view || false}
                              categories={categories}
                              lines={lines}
                              setTotalPages={setTotalPages}
                              totalPages={totalPages}
                              setActualPage={setActualPage}
                              setLines={setLines}
                              index={(actualPage - 1) * 5 + i}
                              wasUpdated={wasUpdated}
                           />

                           <Divider />
                           </>
                        )   
                     })
            }
            <Pagination 
               count={totalPages}
               defaultPage={1}
               page={actualPage}
               onChange={onChangePageHandler}
               color="primary"
               variant="outlined"
               style={{marginTop: "10px"}}
            />

            {
               !isRegistered && !view 
               && (

                  <Button
                     style={{margin: "20px 0"}}
                     color="primary" 
                     onClick={addLineHandler}
                     className={classes.addLine}
                     size='sm'
                  >
                     <span style={{marginRight: "10px"}}>
                        Añadir Línea
                     </span>
                     <span><AddBoxIcon style={{width: 25, height: 25}} /></span>
                  </Button>
               )
            }
            
         </CardBody>
      </Card>
   )
}

const containsDuplicates = (array) => {
   if (array.length !== new Set(array).size) return true;
 
   return false;
 }

const getDaysBetweenDates = (startDate_, endDate_) => {
   const endDate = dayjs(endDate_)
   let auxDate = dayjs(startDate_)
   const daysArr = []

   while ( auxDate.isBefore(endDate.add(1, "day")) ) {
      daysArr.push(auxDate.format("YYYY-MM-DD"))
      auxDate = auxDate.add(1, "day")
   }

   return daysArr
}
