import {
   WORK_CONTROL_REQUEST,
   WORK_CONTROL_SUCCESS,
   WORK_CONTROL_FAIL,
   WORK_CONTROL_RESET,
   WORK_CONTROLS_REQUEST,
   WORK_CONTROLS_SUCCESS,
   WORK_CONTROLS_FAIL,
   WORK_CONTROLS_RESET,
   WORK_LIST_REQUEST,
   WORK_LIST_SUCCESS,
   WORK_LIST_FAIL,
   WORK_LIST_RESET,
   WORK_UPDATE_REQUEST,
   WORK_UPDATE_SUCCESS,
   WORK_UPDATE_FAIL,
   WORK_UPDATE_RESET,
   WORK_VALIDATOR_REQUEST,
   WORK_VALIDATOR_SUCCESS,
   WORK_VALIDATOR_FAIL,
   WORK_VALIDATOR_RESET,
   WORK_CONTROL_ACTS_REQUEST,
   WORK_CONTROL_ACTS_SUCCESS,
   WORK_CONTROL_ACTS_FAIL,
   WORK_CONTROL_ACTS_RESET,
   WORK_UPDATE_CONTROL_ACTS_REQUEST,
   WORK_UPDATE_CONTROL_ACTS_SUCCESS,
   WORK_UPDATE_CONTROL_ACTS_FAIL,
   WORK_UPDATE_CONTROL_ACTS_RESET,
   WORK_CONTROL_RULES_REQUEST,
   WORK_CONTROL_RULES_SUCCESS,
   WORK_CONTROL_RULES_FAIL,
   WORK_CONTROL_RULES_RESET,
} from '../constants/workConstants.js'

export const workControlReducer = (state = {}, action) => {
   switch (action.type) {
      case WORK_CONTROL_REQUEST:
         return { loadingWorkControl: true }
      case WORK_CONTROL_SUCCESS:
         return {
         loadingWorkControl: false,
         successWorkControl: true,
         control: action.payload,
         }
      case WORK_CONTROL_FAIL:
         return {
         loadingWorkControl: false,
         errorWorkControl: action.payload,
         }
      case WORK_CONTROL_RESET:
         return {}
      default:
         return state
   }
}

export const workControlsReducer = (state = {}, action) => {
   switch (action.type) {
      case WORK_CONTROLS_REQUEST:
         return { loadingWorkControl: true }
      case WORK_CONTROLS_SUCCESS:
         return {
            loadingWorkControl: false,
            successWorkControl: true,
            controls: action.payload,
         }
      case WORK_CONTROLS_FAIL:
         return {
            loadingWorkControl: false,
            errorWorkControl: action.payload,
         }
      case WORK_CONTROLS_RESET:
         return {}
      default:
         return state
   }
}

export const workListReducer = (state = { works: [] }, action) => {
   switch (action.type) {
      case WORK_LIST_REQUEST:
         return { loadingWorkList: true }
      case WORK_LIST_SUCCESS:
         return {
            loadingWorkList: false,
            successWorkList: true,
            works: action.payload,
         }
      case WORK_LIST_FAIL:
         return {
            loadingWorkList: false,
            errorWorkList: action.payload
         }
      case WORK_LIST_RESET:
         return {}
      default:
         return state
   }
}

export const workUpdateReducer = (state = { workUpdated: {} }, action) => {
  switch (action.type) {
    case WORK_UPDATE_REQUEST:
      return { loadingWorkUpdate: true }
    case WORK_UPDATE_SUCCESS:
      return {
        loadingWorkUpdate: false,
        successWorkUpdate: true,
        workUpdated: action.payload,
      }
    case WORK_UPDATE_FAIL:
      return { loadingWorkUpdate: false, errorWorkUpdate: action.payload }
    case WORK_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const workValidatorReducer = (state = {}, action) => {
  switch (action.type) {
    case WORK_VALIDATOR_REQUEST:
      return { loadingWorkValidator: true }
    case WORK_VALIDATOR_SUCCESS:
      return { 
        loadingWorkValidator: false, 
        successWorkValidator: true ,
        workValidatorData: action.payload,
      }
    case WORK_VALIDATOR_FAIL:
      return { loadingWorkValidator: false, errorWorkValidator: action.payload }
    case WORK_VALIDATOR_RESET:
      return {}
    default:
      return state
  }
}

export const workControlActListReducer = (state = {}, action) => {
   switch (action.type) {
      case WORK_CONTROL_ACTS_REQUEST:
         return { loadingWorkActsList: true }
      case WORK_CONTROL_ACTS_SUCCESS:
         return {
            loadingWorkActsList: false,
            successWorkActsList: true,
            controlActs: action.payload,
            controlActsIni: action.payload
         }
      case WORK_CONTROL_ACTS_FAIL:
         return {
            loadingWorkActsList: false,
            errorWorkActsList: action.payload
         }
      case WORK_CONTROL_ACTS_RESET:
         return {}
      default:
         return state
   }
}

export const workUpdateControlActsReducer = (state = {}, action) => {
   switch (action.type) {
      case WORK_UPDATE_CONTROL_ACTS_REQUEST:
         return { loadingWorkActsUpdate: true }
      case WORK_UPDATE_CONTROL_ACTS_SUCCESS:
         return {
            loadingWorkActsUpdate: false,
            successWorkActsUpdate: true,
            updateInfo: action.payload,
         }
      case WORK_UPDATE_CONTROL_ACTS_FAIL:
         return {
            loadingWorkActsUpdate: false,
            errorWorkActsUpdate: action.payload
         }
      case WORK_UPDATE_CONTROL_ACTS_RESET:
         return {}
      default:
         return state
   }
}

export const workRuleListReducer = (state = {}, action) => {
   switch (action.type) {
      case WORK_CONTROL_RULES_REQUEST:
         return { loadingWorkRulesList: true }
      case WORK_CONTROL_RULES_SUCCESS:
         return {
            loadingWorkRulesList: false,
            successWorkRulesList: true,
            rules: action.payload,
         }
      case WORK_CONTROL_RULES_FAIL:
         return {
            loadingWorkRulesList: false,
            errorWorkRulesList: action.payload
         }
      case WORK_CONTROL_RULES_RESET:
         return {}
      default:
         return state
   }
}