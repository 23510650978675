import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as dayjs from 'dayjs'

import { TabPanelUpdate } from './TabPanelUpdate'
import { updatePlanning } from 'redux/actions/planningActions'
// import UpdateActionModal from 'components/ReactTableActions/Update/UpdateActionModal'
import { getPlannings } from 'redux/actions/planningActions'
import { Milestone } from '../../components/Milestone'
import UpdateActionModal from './UpdateActionModal'


const UpdatePlanningInfoModal = ({ open, closeViewInfoModal, planning, aditionalPlanningInfo, view, update }) => {

   const dispatch = useDispatch()
   
   const { loadingPlanningUpdate, errorPlanningUpdate, successPlanningUpdate, planningUpdated } = useSelector(
      (state) => state.planningUpdate
   )
   // state
   const [planningToUpdate, setPlanningToUpdate] = useState(planning)
   const [canUpdate, setCanUpdate] = useState(false)
   const [successUpdate, setSuccessUpdate] = useState(false)
   const [registerError, setRegisterError] = useState("")
   const [updateError, setUpdateError] = useState("")

   const planningValidation = (planning) => {

      let errSmIndex = 0
      let errLineIndex = 0
      // ERRORES GENERALES/ HITO
      if (planning.general_data.nombre.length == 0) {
         setRegisterError("PLANIFICACIÓN - El nombre de la planificación es obligatorio")
         return false
      }else if (planning.general_data.fecha_inicio == null) {
         setRegisterError("PLANIFICACIÓN - La fecha de inicio es obligatoria")
         return false
      }else if (planning.general_data.fecha_fin == null) {
         setRegisterError("PLANIFICACIÓN - La fecha de fin es obligatoria")
         return false
      }else if (planning.general_data.porcentaje_ejecucion == 0) {
         setRegisterError("PLANIFICACIÓN - El porcentaje de ejecución es obligatorio")
         return false
      }else if (planning.general_data.porcentaje_ejecucion < 0 || planning.general_data.porcentaje_ejecucion > 100) {
         setRegisterError("PLANIFICACIÓN - El porcentaje de ejecución no puede ser menor que 0 ni mayor a 100")
         return false
      }else if ((planning.submilestones.reduce((acc, sub) => acc + Number(sub.submilestone.general_data.porcentaje_ejecucion), 0)).toFixed(2)
         != planning.general_data.porcentaje_ejecucion) 
      {
         setRegisterError(`PLANIFICACIÓN - La suma del porcentaje de ejecución de los periodos no coincide con la de la planificación`)
         return false
      }
      // ERRORES SUBHITO
      else if (planning.submilestones.some((sub, i) => {
         if ( dayjs(sub.submilestone.general_data.fecha_inicio).isBefore(planning.general_data.fecha_inicio)
            || dayjs(sub.submilestone.general_data.fecha_fin).isBefore(planning.general_data.fecha_inicio)
            || dayjs(sub.submilestone.general_data.fecha_inicio).isAfter(planning.general_data.fecha_fin)
            || dayjs(sub.submilestone.general_data.fecha_fin).isAfter(planning.general_data.fecha_fin) )
         {
            errSmIndex = i
            return true
         }
         return false
      })) { // fechas subhito fuera de rango
         setRegisterError(`PERIODO (${errSmIndex + 1}) - Las fechas del periodo deben estar dentro del rango de las de la planificación`)
         return false
      }else if (planning.submilestones.some((sub, i) => {
         if (sub.submilestone.general_data.fecha_inicio > sub.submilestone.general_data.fecha_fin) {
            errSmIndex = i
            return true
         }
         return false
      })) { // fechas incongruentes
         setRegisterError(`PEERIODO (${errSmIndex + 1}) - La fecha de inicio no puede ser mayor a la fecha de fin`  )
         return false
      }
      // ERRORES LÍNEA
      else if (planning.submilestones.some((sub, i) => { // líneas sumadas con más de 100% de ejecución
         if (sub.submilestone.lines.reduce((acc, line) => acc + line.porcentaje_ejecucion, 0) > 100) {
            errSmIndex = i
            return true
         }
         return false
      })) { 
         setRegisterError(`PERIODO (${errSmIndex + 1}) - La suma del porcentaje de ejecución de las líneas no puede ser mayor a 100`)
         return false
      }else if (planning.submilestones.some((sub, i) => { // línea con más de 100% de ejecución
         if (sub.submilestone.lines.some((line, j) => {
            if (line.porcentaje_ejecucion > 100) {
               errSmIndex = i
               errLineIndex = j
               return true
            }
            return false
         })) return true
         return false
      })) { 
         setRegisterError(`PERIODO (${errSmIndex + 1}) - Línea (${errLineIndex + 1}) - El porcentaje de ejecución de la línea no puede ser mayor a 100`)
         return false
      }else if (planning.submilestones.some((sub, i) => { // línea sin estructura
         if (sub.submilestone.lines.some((line, j) => {
            if (line.estructura == null || !line.estructura) {
               errSmIndex = i
               errLineIndex = j
               return true
            }
            return false
         })) return true
         return false
      })) { 
         setRegisterError(`PERIODO (${errSmIndex + 1}) - LÍNEA (${errLineIndex + 1}) - No se ha seleccionado la estructura de la línea`)
         return false
      }else if (planning.submilestones.some((sub, i) => { // línea sin indicador
         if (sub.submilestone.lines.some((line, j) => {
            if (line.indicador_descripcion == null || !line.indicador_descripcion) {
               errSmIndex = i
               errLineIndex = j
               return true
            }
         })) return true
         return false
      })) {
         setRegisterError(`PERIODO (${errSmIndex + 1}) - LÍNEA (${errLineIndex + 1}) - No se ha seleccionado el indicador de la línea`)
         return false
      }else if (planning.submilestones.some((sub, i) => { // línea con 0 actuaciones
         if (sub.submilestone.lines.some((line, j) => {
            if (line.total_actuaciones == 0) {
               errSmIndex = i
               errLineIndex = j
               return true
            }
            return false
         })) return true
         return false
      })) { 
         setRegisterError(`PERIODO (${errSmIndex + 1}) - LÍNEA (${errLineIndex + 1}) - El total de actuaciones de la línea no puede ser 0`)
         return false
      }

      setRegisterError("")
      return true
   }

   const submitHandler = (e) => {
      e.preventDefault()
      
      // setRegisterError("errorsito")
      if (!planningValidation(planningToUpdate)) {
         setSuccessUpdate(false)
         return
      }

      setCanUpdate(false)
      setSuccessUpdate(true)

      dispatch(
         updatePlanning(planningToUpdate)
      )
      dispatch(getPlannings())
   }


   return (
      <>
         <UpdateActionModal
            open={open}
            handleCloseModal={closeViewInfoModal}
            handleSubmit={submitHandler}
            successUpdate={successUpdate}          
            loadingUpdate={loadingPlanningUpdate}
            canUpdate={canUpdate}
            modalTitle={`Actualizar Planificación - ${planning.general_data.nombre}`}
            updateError={updateError}
            customWidth={"lg"}
            children={
               <Milestone
                  handleCloseModal={closeViewInfoModal}
                  update={update}
                  view={view}
                  planning={planning}
                  setUpdateError={setUpdateError}
               />
            }
         />
      </>
   )
}

export default UpdatePlanningInfoModal
