import {
   LINES_REGISTER_REQUEST,
   LINES_REGISTER_SUCCESS,
   LINES_REGISTER_FAIL,
   LINES_REGISTER_RESET,
} from '../constants/importLinesConstants'
  
export const linesRegisterReducer = (state = {}, action) => {
   switch (action.type) {
      case LINES_REGISTER_REQUEST:
         return { loadingLinesRegister: true }
      case LINES_REGISTER_SUCCESS:
         return {
            loadingLinesRegister: false,
            successLinesRegister: true,
            info_register: action.payload,
         }
      case LINES_REGISTER_FAIL:
         return {
            loadingLinesRegister: false,
            errorLinesRegister: action.payload,
         }
      case LINES_REGISTER_RESET:
         return {
            info_register: action.payload,
         }
      default:
         return state
   }
}