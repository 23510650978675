import {
   PLANNING_REGISTER_REQUEST,
   PLANNING_REGISTER_SUCCESS,
   PLANNING_REGISTER_FAIL,
   PLANNING_REGISTER_RESET,
   PLANNING_LIST_REQUEST,
   PLANNING_LIST_SUCCESS,
   PLANNING_LIST_FAIL,
   PLANNING_LIST_RESET,
   PLANNING_UPDATE_SUCCESS,
   PLANNING_UPDATE_REQUEST,
   PLANNING_UPDATE_FAIL,
   PLANNING_UPDATE_RESET,
   PLANNING_DELETE_FAIL,
   PLANNING_DELETE_REQUEST,
   PLANNING_DELETE_SUCCESS,
   PLANNING_DELETE_RESET,
   PLANNING_LINES_LIST_REQUEST,
   PLANNING_LINES_LIST_SUCCESS,
   PLANNING_LINES_LIST_FAIL,
   PLANNING_LINES_LIST_RESET,
   PLANNING_INDICATORS_LIST_REQUEST,
   PLANNING_INDICATORS_LIST_SUCCESS,
   PLANNING_INDICATORS_LIST_FAIL,
   PLANNING_INDICATORS_LIST_RESET,
   PLANNING_LOADS_LIST_REQUEST,
   PLANNING_LOADS_LIST_SUCCESS,
   PLANNING_LOADS_LIST_FAIL,
   PLANNING_LOADS_LIST_RESET,

} from '../constants/planningConstants.js'
 
export const planningRegisterReducer = (state = {}, action) => {
   switch (action.type) {
      case PLANNING_REGISTER_REQUEST:
         return { loadingPlanningRegister: true }
      case PLANNING_REGISTER_SUCCESS:
         return {
            loadingPlanningRegister: false,
            successPlanningRegister: true,
            planningRegistered: action.payload,
         }
      case PLANNING_REGISTER_FAIL:
         return {
            loadingPlanningRegister: false,
            errorPlanningRegister: action.payload,
         }
      case PLANNING_REGISTER_RESET:
         return {}
      default:
         return state
   }
}
 
export const planningListReducer = (state = { plannings: [] }, action) => {
   switch (action.type) {
      case PLANNING_LIST_REQUEST:
         return { loadingPlanningsList: true }
      case PLANNING_LIST_SUCCESS:
         return {
            loadingPlanningsList: false,
            successPlanningsList: true,
            plannings: action.payload,
         }
      case PLANNING_LIST_FAIL:
         return { 
            loadingPlanningsList: false,
            errorPlanningsList: action.payload 
         }
      case PLANNING_LIST_RESET:
         return {}
      default:
         return state
   }
}
 
export const planningUpdateReducer = (state = { planningUpdated: {} }, action) => {
   switch (action.type) {
      case PLANNING_UPDATE_REQUEST:
         return { loadingPlanningUpdate: true }
      case PLANNING_UPDATE_SUCCESS:
         return {
            loadingPlanningUpdate: false,
            successPlanningUpdate: true,
            planningUpdated: action.payload,
         }
      case PLANNING_UPDATE_FAIL:
         return { 
            loadingPlanningUpdate: false,
            errorPlanningUpdate: action.payload 
         }
      case PLANNING_UPDATE_RESET:
         return {}
      default:
         return state
   }
}
 
export const planningDeleteReducer = (state = {}, action) => {
   switch (action.type) {
      case PLANNING_DELETE_REQUEST:
         return { loadingPlanningDelete: true }
      case PLANNING_DELETE_SUCCESS:
         return { 
            loadingPlanningDelete: false,
            successPlanningDelete: true
         }
      case PLANNING_DELETE_FAIL:
         return { 
            loadingPlanningDelete: false,
            errorPlanningDelete: action.payload
         }
      case PLANNING_DELETE_RESET:
         return {}
      default:
         return state
   }
}

export const planningLinesListReducer = (state = {}, action) => {
   switch (action.type) {
      case PLANNING_LINES_LIST_REQUEST:
         return { loadingPlanningsLinesList: true }
      case PLANNING_LINES_LIST_SUCCESS:
         return {
            loadingPlanningsLinesList: false,
            successPlanningsLinesList: true,
            planningsLines: action.payload,
         }
      case PLANNING_LINES_LIST_FAIL:
         return { 
            loadingPlanningsLinesList: false,
            errorPlanningsLinesList: action.payload 
         }
      case PLANNING_LINES_LIST_RESET:
         return {}
      default:
         return state
   }
}

export const planningIndicatorsListReducer = (state = {}, action) => {
   switch (action.type) {
      case PLANNING_INDICATORS_LIST_REQUEST:
         return { loadingPlanningsIndicatorsList: true }
      case PLANNING_INDICATORS_LIST_SUCCESS:
         return {
            loadingPlanningsIndicatorsList: false,
            successPlanningsIndicatorsList: true,
            planningsIndicators: action.payload,
         }
      case PLANNING_INDICATORS_LIST_FAIL:
         return { 
            loadingPlanningsIndicatorsList: false,
            errorPlanningsIndicatorsList: action.payload 
         }
      case PLANNING_INDICATORS_LIST_RESET:
         return {}
      default:
         return state
   }
}

export const planningLoadsListReducer = (state = {}, action) => {
   switch (action.type) {
      case PLANNING_LOADS_LIST_REQUEST:
         return { loadingLoadsList: true }
      case PLANNING_LOADS_LIST_SUCCESS:
         return {
            loadingLoadsList: false,
            successLoadsList: true,
            loads: action.payload,
         }
      case PLANNING_LOADS_LIST_FAIL:
         return { 
            loadingLoadsList: false,
            errorLoadsList: action.payload 
         }
      case PLANNING_LOADS_LIST_RESET:
         return {}
      default:
         return state
   }
}
 