import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ViewInfoActionModal from 'components/ReactTableActions/ViewInfo/ViewInfoActionModal'
import { WORK_CONTROL_ACTS_RESET } from 'redux/constants/workConstants'
import { getControlActsData } from 'redux/actions/workActions'
import { TabPanelInfo } from './TabPanelInfo'

const ViewWorkInfoActModal = ({ viewInfo, closeViewInfoModal, info, technicians }) => {
   const dispatch = useDispatch()

   const { loadingWorkActsList, errorWorkActsList, successWorkActsList, controlActs } = useSelector(
      (state) => state.workControlActList
   )

   const [actsData, setActsData] = useState(null)

   useEffect(() => {
      if (successWorkActsList) {
         setActsData(controlActs)
      } else {
         if (controlActs != null && !controlActs.length) dispatch(getControlActsData(info))
      }
   }, [successWorkActsList, dispatch])

   useEffect(() => {
      dispatch(getControlActsData(info))

      return () => dispatch({ type: WORK_CONTROL_ACTS_RESET })
   }, [])

   if (errorWorkActsList) {
      return ( "Hubo algún problema al cargar los datos." )
   }

   return (
      <>
      {loadingWorkActsList
      ? ( "Cargando Información..." )
      : (
         <ViewInfoActionModal
            open={viewInfo}
            handleCloseModal={closeViewInfoModal}
            modalTitle={`Información Control - Actos`}
            customWidth={"lg"}
            children={
               <TabPanelInfo 
                  info={actsData}
                  technicians={technicians}
               />
            }
         />
      )}
      </>
   )
}

export default ViewWorkInfoActModal
