import {
   USER_LOGIN_REQUEST,
   USER_LOGIN_SUCCESS,
   USER_LOGIN_FAIL,
   USER_LOGOUT,
   USER_REFRESH_TOKEN_REQUEST,
   USER_REFRESH_TOKEN_SUCCESS,
   USER_REFRESH_TOKEN_FAIL,
   USER_REFRESH_TOKEN_RESET,
   USER_PROFILE_INFO_RESET,
   USER_PROFILE_INFO_REQUEST,
   USER_PROFILE_INFO_SUCCESS,
   USER_PROFILE_INFO_FAIL,
   USER_LIST_BY_PROFILE_ID_REQUEST,
   USER_LIST_BY_PROFILE_ID_SUCCESS,
   USER_LIST_BY_PROFILE_ID_RESET,
   USER_LIST_BY_PROFILE_ID_FAIL,
   USER_LIST_BY_ROLE_NAME_REQUEST,
   USER_LIST_BY_ROLE_NAME_SUCCESS,
   USER_LIST_BY_ROLE_NAME_FAIL,
   USER_LIST_BY_ROLE_NAME_RESET,
   TECHNICIAN_LIST_REQUEST,
   TECHNICIAN_LIST_SUCCESS,
   TECHNICIAN_LIST_FAIL,
   TECHNICIAN_LIST_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
   switch (action.type) {
      case USER_LOGIN_REQUEST:
         return { loadingUserInfo: true }
      case USER_LOGIN_SUCCESS:
         return { loadingUserInfo: false, userInfo: action.payload }
      case USER_LOGIN_FAIL:
         return { loadingUserInfo: false, errorUserInfo: action.payload }
      case USER_LOGOUT:
         localStorage.removeItem('setADA')
         return {}
      default:
         return state
   }
}

export const userGetProfileInfoeReducer = (state = { profileInfo: {} }, action) => {
   switch (action.type) {
      case USER_PROFILE_INFO_REQUEST:
         return { loadingUserProfileInfo: true }
      case USER_PROFILE_INFO_SUCCESS:
         return {
         loadingUserProfileInfo: false,
         successUserProfileInfo: true,
         profileInfo: action.payload,
         }
      case USER_PROFILE_INFO_FAIL:
         return { loadingUserProfileInfo: false, errorUserProfileInfo: action.payload }
      case USER_PROFILE_INFO_RESET:
         return {}
      default:
         return state
   }
}

export const refreshTokenReducer = (state = {}, action) => {
   switch (action.type) {
      case USER_REFRESH_TOKEN_REQUEST:
         return { loadingRefreshToken: true }
      case USER_REFRESH_TOKEN_SUCCESS:
         return {
         loadingRefreshToken: false,
         successRefreshToken: true,
         }
      case USER_REFRESH_TOKEN_FAIL:
         return {
         loadingRefreshToken: false,
         errorRefreshToken: action.payload,
         }
      case USER_REFRESH_TOKEN_RESET:
         return {}
      default:
         return state
   }
}

export const userListByProfileIdReducer = (state = {}, action) => {
   switch (action.type) {
      case USER_LIST_BY_PROFILE_ID_REQUEST:
         return { loadingUserListByProfileId: true }
      case USER_LIST_BY_PROFILE_ID_SUCCESS:
         return {
         successUserListByProfileId: true,
         loadingUserListByProfileId: false,
         userListByProfileIdData: action.payload,
         }
      case USER_LIST_BY_PROFILE_ID_FAIL:
         return {
         loadingUserListByProfileId: false,
         errorUserListByProfileId: action.payload,
         }
      case USER_LIST_BY_PROFILE_ID_RESET:
         return {}
      default:
         return state
   }
}

export const userListByRoleNameReducer = (state = {}, action) => {
   switch (action.type) {
      case USER_LIST_BY_ROLE_NAME_REQUEST:
         return { loadingUserListByRoleName: true }
      case USER_LIST_BY_ROLE_NAME_SUCCESS:
         return {
         successUserListByRoleName: true,
         loadingUserListByRoleName: false,
         userListByRoleNameData: action.payload,
         }
      case USER_LIST_BY_ROLE_NAME_FAIL:
         return {
         loadingUserListByRoleName: false,
         errorUserListByRoleName: action.payload,
         }
      case USER_LIST_BY_ROLE_NAME_RESET:
         return {}
      default:
         return state
   }
}

export const technicianListReducer = (state = {}, action) => {
   switch (action.type) {
      case TECHNICIAN_LIST_REQUEST:
         return { loadingTechnicianList: true }
      case TECHNICIAN_LIST_SUCCESS:
         return {
            successTechnicianList: true,
            loadingTechnicianList: false,
            technicians: action.payload,
         }
      case TECHNICIAN_LIST_FAIL:
         return {
            loadingTechnicianList: false,
            errorTechnicianList: action.payload,
         }
      case TECHNICIAN_LIST_RESET:
         return {}
      default:
         return state
   }
}
