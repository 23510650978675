import { Typography } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ViewInfoActionModal from 'components/ReactTableActions/ViewInfo/ViewInfoActionModal'

const ViewCategoryInfoModal = ({ viewInfo, closeViewInfoModal, info }) => {
  const { id_categoria, categoria_pliego, tipo_actuacion, importe } = info
  return (
    <ViewInfoActionModal
      open={viewInfo}
      handleCloseModal={closeViewInfoModal}
      modalTitle={`Información de Categoría`}
      children={
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Categoría: <strong>{categoria_pliego}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Tipo de actuación: <strong>{tipo_actuacion}</strong>
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant='body1' gutterBottom>
              Importe: <strong>{importe}</strong>
            </Typography>
          </GridItem>
        </GridContainer>
      }
    />
  )
}

export default ViewCategoryInfoModal
