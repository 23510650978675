import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import DeleteActionModal from 'components/ReactTableActions/Delete/DeleteActionModal'
import { deleteProfile } from 'redux/actions/profileActions'
import { USER_LIST_BY_PROFILE_ID_RESET } from 'redux/constants/userConstants'
import { PROFILE_LIST_RESET, PROFILE_DELETE_RESET, PROFILE_UPDATE_RESET } from 'redux/constants/profileConstants'
import { getUsersByProfileId } from 'redux/actions/userActions'

const DeleteProfileModal = ({ handleCloseDeleteProfileModal, deleteProfileModal, showDeleteProfileInfo }) => {
  const dispatch = useDispatch()
  const active = showDeleteProfileInfo.activo !== 'SI'

  const { successProfileDelete, errorProfileDelete, loadingProfileDelete } = useSelector((state) => state.profileDelete)
  const { loadingUserListByProfileId, userListByProfileIdData,  } = useSelector((state) => state.userListByProfileId)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    dispatch(getUsersByProfileId(showDeleteProfileInfo.id_perfil))
  }, [])

  useEffect(() => {
    let timeOut = ''
    if (successProfileDelete) {
      timeOut = setTimeout(() => {
        dispatch({ type: PROFILE_LIST_RESET })
        dispatch({ type: PROFILE_DELETE_RESET })
        handleCloseDeleteProfileModal()
      }, 1000)
    }
    return () => clearTimeout(timeOut)
  }, [successProfileDelete])

  useEffect(() => {
    return () => dispatch({ type: PROFILE_DELETE_RESET })
  }, [dispatch])
  useEffect(() => {
    return () => dispatch({ type: PROFILE_UPDATE_RESET })
  }, [dispatch])
  useEffect(() => {
    return () => dispatch({ type: USER_LIST_BY_PROFILE_ID_RESET })
  }, [dispatch])

  const handleDeleteProfile = (e) => {
    e.preventDefault()
    dispatch(deleteProfile(showDeleteProfileInfo?.id_perfil))
  }

  return (
    <>
      {loadingUserListByProfileId ? (
        <>Cargando Perfil</>
      ) : (
        <DeleteActionModal
          open={deleteProfileModal}
          handleCloseModal={handleCloseDeleteProfileModal}
          handleSubmit={handleDeleteProfile}
          modalTitle='Eliminar Perfil'
          showDeleteInfo={showDeleteProfileInfo?.codigo_perfil}
          loadingDelete={loadingProfileDelete}
          successDelete={successProfileDelete}
          errorDelete={errorProfileDelete}
        />
      )}
      {alert}
    </>
  )
}

export default DeleteProfileModal
