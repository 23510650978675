import {
   drawerWidth,
   drawerMiniWidth,
   transition,
   boxShadow,
   defaultFont,
   primaryColor,
   primaryBoxShadow,
   infoColor,
   successColor,
   warningColor,
   dangerColor,
   roseColor,
   whiteColor,
   blackColor,
   grayColor,
   hexToRgb,
 } from 'assets/jss/material-ui-react'

const styles = (theme) => ({
   cardIconTitle: {
     marginTop: '15px',
     marginBottom: '0px',
   },
   rootItem: {
     padding: '0 !important',
   },
   addLine: {
      marginTop: "20px",
      fontSize: "0.85rem",
      marginLeft: "100px",
      border: `2px solid ${grayColor[2]}`,
      backgroundColor: "#ffffff",
      color: `${grayColor[2]}`,
      '&:focus': {
         backgroundColor: "#ffffff",
         color: `${grayColor[2]}`,
      },
   },
   addSub: {
      marginTop: "20px",
      fontSize: "0.9rem",
      border: `2px solid ${grayColor[2]}`,
      backgroundColor: "#ffffff",
      color: `${grayColor[2]}`,
      '&:focus': {
         backgroundColor: "#ffffff",
         color: `${grayColor[2]}`,
      },
   },
   deleteSub: {
      marginTop: "20px",
      fontSize: "0.9rem",
      border: `2px solid ${dangerColor[2]}`,
      backgroundColor: "#ffffff",
      color: `${dangerColor[2]}`,
      '&:focus': {
         backgroundColor: "#ffffff",
         color: `${dangerColor[2]}`,
      },
   },
   off: {
      color: "grey"
   },
   confirmBtnCssClass: {
      backgroundColor: '#3cba55',
      color: 'white',
      padding: '10px',
      width: '5rem',
      borderRadius: '3px',
      '&:hover': {
        color: 'white',
      },
   },
 })
 
 export default styles