import {
  USERLIST_REGISTER_REQUEST,
  USERLIST_REGISTER_SUCCESS,
  USERLIST_REGISTER_FAIL,
  USERLIST_REGISTER_RESET,
  USERLIST_LIST_REQUEST,
  USERLIST_LIST_SUCCESS,
  USERLIST_LIST_FAIL,
  USERLIST_LIST_RESET,
  USERLIST_UPDATE_SUCCESS,
  USERLIST_UPDATE_REQUEST,
  USERLIST_UPDATE_FAIL,
  USERLIST_UPDATE_RESET,
  USERLIST_DELETE_FAIL,
  USERLIST_DELETE_REQUEST,
  USERLIST_DELETE_SUCCESS,
  USERLIST_DELETE_RESET,
  USERLIST_RESETPASSWORD_FAIL,
  USERLIST_RESETPASSWORD_REQUEST,
  USERLIST_RESETPASSWORD_SUCCESS,
  USERLIST_RESETPASSWORD_RESET,
  USERLIST_CHANGEPASSWORD_FAIL,
  USERLIST_CHANGEPASSWORD_REQUEST,
  USERLIST_CHANGEPASSWORD_SUCCESS,
  USERLIST_CHANGEPASSWORD_RESET,
} from '../constants/userlistConstants'

export const userlistRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USERLIST_REGISTER_REQUEST:
      return { loadingUserlistRegister: true }
    case USERLIST_REGISTER_SUCCESS:
      return {
        loadingUserlistRegister: false,
        successUserlistRegister: true,
        userlist: action.payload,
      }
    case USERLIST_REGISTER_FAIL:
      return {
        loadingUserlistRegister: false,
        errorUserlistRegister: action.payload,
      }
    case USERLIST_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const userlistListReducer = (state = { userlist: [] }, action) => {
  switch (action.type) {
    case USERLIST_LIST_REQUEST:
      return { loadingUserlistList: true }
    case USERLIST_LIST_SUCCESS:
      return {
        loadingUserlistList: false,
        successUserlistList: true,
        userlist: action.payload,
      }
    case USERLIST_LIST_FAIL:
      return { loadingUserlistList: false, errorUserlistList: action.payload }
    case USERLIST_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const userlistUpdateReducer = (state = { userlistUpdated: {} }, action) => {
  switch (action.type) {
    case USERLIST_UPDATE_REQUEST:
      return { loadingUserlistUpdate: true }
    case USERLIST_UPDATE_SUCCESS:
      return {
        loadingUserlistUpdate: false,
        successUserlistUpdate: true,
        userlistUpdated: action.payload,
      }
    case USERLIST_UPDATE_FAIL:
      return { loadingUserlistUpdate: false, errorUserlistUpdate: action.payload }
    case USERLIST_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userlistDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USERLIST_DELETE_REQUEST:
      return { loadingUserlistDelete: true }
    case USERLIST_DELETE_SUCCESS:
      return { loadingUserlistDelete: false, successUserlistDelete: true }
    case USERLIST_DELETE_FAIL:
      return { loadingUserlistDelete: false, errorUserlistDelete: action.payload }
    case USERLIST_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const userlistResetPasswordReducer = (state = { userlistResetPassword: {} }, action) => {
  switch (action.type) {
    case USERLIST_RESETPASSWORD_REQUEST:
      return { loadingUserlistResetPassword: true }
    case USERLIST_RESETPASSWORD_SUCCESS:
      return {
        loadingUserlistResetPassword: false,
        successUserlistResetPassword: true,
        userlistResetPassword: action.payload,
      }
    case USERLIST_RESETPASSWORD_FAIL:
      return { loadingUserlistResetPassword: false, errorUserlistResetPassword: action.payload }
    case USERLIST_RESETPASSWORD_RESET:
      return {}
    default:
      return state
  }
}

export const userlistChangePasswordReducer = (state = { userlistChangePassword: {} }, action) => {
  switch (action.type) {
    case USERLIST_CHANGEPASSWORD_REQUEST:
      return { loadingUserlistChangePassword: true }
    case USERLIST_CHANGEPASSWORD_SUCCESS:
      return {
        loadingUserlistChangePassword: false,
        successUserlistChangePassword: true,
        userlistChangePassword: action.payload,
      }
    case USERLIST_CHANGEPASSWORD_FAIL:
      return { loadingUserlistChangePassword: false, errorUserlistChangePassword: action.payload }
    case USERLIST_CHANGEPASSWORD_RESET:
      return {}
    default:
      return state
  }
}